import React from 'react';
import Section from './Section';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SectionHeader from './SectionHeader';
import { getIconColor } from './FeaturesSection';

function HeroSection2(props) {
  return (
    <Section bgColor={props.bgColor} size={props.size} bgImage={props.bgImage} bgImageOpacity={props.bgImageOpacity}>
      <Container>
        <Box textAlign="center">
          {props.value && (
            <Box color={getIconColor(props.value)} fontSize="4.5rem">
              <AssessmentIcon fontSize="inherit" />
            </Box>
          )}
          <SectionHeader title={props.title} subtitle={props.subtitle} size={4} />
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSection2;
