import styled from 'styled-components';
import { Button, withStyles, makeStyles } from '@material-ui/core';

/** Colors */
export const greenColor = '#91CF50';
export const darkGreenColor = '#82bf41';

const fontStyle = 'font-family: Arial, Helvetica, sans-serif;';

export const StepContainer = styled.div`
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h3`
  font-weight: normal;
  ${fontStyle}
`;

export const Text = styled.p`
  ${fontStyle}
  padding-left: 16px;
`;

export const Image = styled.img`
  padding-top: 24px;
  padding-bottom: 24px;
  margin: auto;
`;

export const CustomizedButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: greenColor,
    '&:hover': {
      backgroundColor: darkGreenColor,
    },
    '&:disabled': {
      backgroundColor: '#D3D3D3',
    },
    textTransform: 'none',
    fontFamily: 'Arial, "Helvetica Neue", sans-serif',
  },
  outlined: {
    color: greenColor,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
    textTransform: 'none',
    fontFamily: 'Arial, "Helvetica Neue", sans-serif',
  },
})(Button);

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
  button:first-child {
    margin-right: 16px;
  }
`;

export const useFormStyles = makeStyles((theme) => ({
  formControl: {
    //minWidth: '100%',
    margin: theme.spacing(2),
  },
}), { index: 999 });
