import React from 'react';
import { translations } from './assets/content';

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = React.useState({
    width: 0,
    height: 0,
  });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

const isValidKey = (key) => {
  return key in translations;
};

export const getContent = (contentKey) => {
  if (isValidKey(contentKey)) {
    return translations[contentKey];
  }
  return contentKey;
};

/** Calculate default dates */
const today = new Date().toISOString().slice(0, 10);
const oneYearBeforeToday = new Date();
oneYearBeforeToday.setFullYear(oneYearBeforeToday.getFullYear() - 1);
const oneYearBeforeTodayNormalized = oneYearBeforeToday.toISOString().slice(0, 10);

export const defaultFormData = {
  address: {
    country: 'DEU',
    postalCode: '',
    city: ''
  },
  type: "HP",
  ageCurrentHeatingSystem: 20,
  energySource: 'oil',

  volumeConsumptionAccountingPeriod: 0,
  consumptionAccountingPeriod: 0,
  accountingPeriodStartDate: oneYearBeforeTodayNormalized,
  accountingPeriodEndDate: today,

  spaceHeatSystem: "underfloorHeating",
  tempPreFlow: 0,


  buildingType: '',
  buildingUsefulArea: '',
  buildingConstructionYear: 1990,

  hotDomesticWater: true,
  inhabs: 0,
  hotDomesticWaterFutur: false,

  bivalentMode: false,
  energySourceBiva: 'wood',
  volumeConsumptionAccountingPeriodBiva: 0,
  consumptionAccountingPeriodBiva: 0,
  energySubSource: null,
  energySubSourceBiva: 'hardwoodMix',
  accountingPeriodStartDateBiva: oneYearBeforeTodayNormalized,
  accountingPeriodEndDateBiva: today,


  distanceRuleIsRespected: false,
  heaterLocationOld: null,
  pvSettings: 'notAvailableNotInterested',
  heatingSystemChangePlannedMonths: null,
  hpFeatures: [],
  bivaColdDayBooster: null,
  powerBivaSource: 0,
  isOwner: false,
  acceptedTerms: false,
  acceptedDataProtection: false,
  contactPerson: { name: "", phone: "", email: "" }
};
