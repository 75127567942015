export const getCity = {
    "01067": "Dresden",
    "01069": "Dresden",
    "01097": "Dresden",
    "01099": "Dresden",
    "01108": "Dresden",
    "01109": "Dresden",
    "01127": "Dresden",
    "01129": "Dresden",
    "01139": "Dresden",
    "01156": "Dresden",
    "01157": "Dresden",
    "01159": "Dresden",
    "01169": "Dresden",
    "01187": "Dresden",
    "01189": "Dresden",
    "01217": "Dresden",
    "01219": "Dresden",
    "01237": "Dresden",
    "01239": "Dresden",
    "01257": "Dresden",
    "01259": "Dresden",
    "01277": "Dresden",
    "01279": "Dresden",
    "01307": "Dresden",
    "01309": "Dresden",
    "01324": "Dresden",
    "01326": "Dresden",
    "01328": "Dresden",
    "01445": "Radebeul",
    "01454": "Radeberg, Wachau",
    "01458": "Ottendorf-Okrilla",
    "01465": "Dresden",
    "01468": "Moritzburg",
    "01471": "Radeburg",
    "01477": "Arnsdorf b. Dresden",
    "01558": "Großenhain",
    "01561": "Großenhain, Ebersbach u.a.",
    "01587": "Riesa",
    "01589": "Riesa",
    "01591": "Riesa",
    "01594": "Riesa, Stauchitz, Hirschstein",
    "01609": "Gröditz, Wülknitz, Röderaue",
    "01612": "Nünchritz, Glaubitz",
    "01616": "Strehla",
    "01619": "Zeithain",
    "01623": "Lommatzsch",
    "01640": "Coswig",
    "01662": "Meißen",
    "01665": "Käbschütztal, Klipphausen, Diera-Zehren",
    "01683": "Nossen",
    "01689": "Weinböhla",
    "01705": "Freital",
    "01723": "Wilsdruff",
    "01728": "Bannewitz",
    "01731": "Kreischa",
    "01734": "Rabenau",
    "01737": "Tharandt u.a.",
    "01738": "Dorfhain",
    "01744": "Dippoldiswalde",
    "01762": "Hartmannsdorf-Reichenau",
    "01768": "Glashütte",
    "01773": "Altenberg",
    "01774": "Klingenberg",
    "01776": "Hermsdorf",
    "01778": "Altenberg",
    "01796": "Pirna, Struppen, Dohma",
    "01809": "Heidenau",
    "01814": "Bad Schandau",
    "01816": "Bad Gottleuba-Berggießhübel",
    "01819": "Bahretal",
    "01824": "Königstein/Sächs.Schw.",
    "01825": "Liebstadt",
    "01829": "Wehlen",
    "01833": "Stolpen, Dürrröhrsdorf-Dittersbach",
    "01844": "Neustadt i. Sa.",
    "01847": "Lohmen",
    "01848": "Hohnstein",
    "01855": "Sebnitz",
    "01877": "Bischofswerda u.a.",
    "01896": "Pulsnitz",
    "01900": "Großröhrsdorf, Bretnig-Hauswalde",
    "01904": "Neukirch/Lausitz",
    "01906": "Burkau",
    "01909": "Großharthau, Frankenthal",
    "01917": "Kamenz",
    "01920": "Elstra, Oßling u.a.",
    "01936": "Königsbrück u.a.",
    "01945": "Ruhland",
    "01968": "Senftenberg",
    "01979": "Lauchhammer",
    "01983": "Großräschen",
    "01987": "Schwarzheide N.L.",
    "01990": "Ortrand",
    "01993": "Schipkau",
    "01994": "Schipkau",
    "01996": "Senftenberg",
    "01998": "Schipkau",
    "02625": "Bautzen",
    "02627": "Weißenberg, Hochkirch u.a.",
    "02633": "Göda",
    "02681": "Wilthen",
    "02689": "Sohland a. d. Spree",
    "02692": "Doberschau-Gaußig, Großpostwitz, Obergurig",
    "02694": "Großdubrau, Malschwitz",
    "02699": "Königswartha",
    "02708": "Löbau, Kottmar u.a.",
    "02727": "Ebersbach-Neugersdorf",
    "02730": "Ebersbach-Neugersdorf",
    "02733": "Cunewalde",
    "02736": "Beiersdorf, Oppach",
    "02739": "Kottmar",
    "02742": "Neusalza-Spremberg",
    "02747": "Herrnhut",
    "02748": "Bernstadt",
    "02763": "Zittau u.a.",
    "02779": "Großschönau",
    "02782": "Seifhennersdorf",
    "02785": "Olbersdorf",
    "02788": "Zittau",
    "02791": "Oderwitz",
    "02794": "Leutersdorf, Spitzkunnersdorf",
    "02796": "Jonsdorf",
    "02797": "Oybin",
    "02799": "Großschönau",
    "02826": "Görlitz",
    "02827": "Görlitz",
    "02828": "Görlitz",
    "02829": "Markersdorf, Neißeaue u.a.",
    "02894": "Reichenbach, Vierkirchen",
    "02899": "Östritz, Schönau-Berzdorf",
    "02906": "Niesky, Hohendubrau u.a.",
    "02923": "Hähnichen, Horka, Kodersdorf",
    "02929": "Rothenburg/O.L.",
    "02943": "Weißwasser, Boxberg",
    "02953": "Bad Muskau, Groß Düben, Gablenz",
    "02956": "Rietschen",
    "02957": "Krauschwitz, Weißkeißel",
    "02959": "Schleife",
    "02977": "Hoyerswerda",
    "02979": "Spreetal, Elsterheide",
    "02991": "Lauta",
    "02994": "Bernsdorf",
    "02997": "Wittichenau",
    "02999": "Lohsa",
    "03042": "Cottbus",
    "03044": "Cottbus",
    "03046": "Cottbus",
    "03048": "Cottbus",
    "03050": "Cottbus",
    "03051": "Cottbus",
    "03052": "Cottbus",
    "03053": "Cottbus",
    "03054": "Cottbus",
    "03055": "Cottbus",
    "03058": "Neuhausen/Spree",
    "03096": "Burg/Spreewald u.a.",
    "03099": "Kolkwitz",
    "03103": "Neu-Seeland, Neupetershain",
    "03116": "Drebkau",
    "03119": "Welzow",
    "03130": "Spremberg, Tschernitz u.a.",
    "03149": "Forst/ Lausitz",
    "03159": "Döbern",
    "03172": "Guben, Schenkendöbern",
    "03185": "Peitz",
    "03197": "Jänschwalde",
    "03205": "Calau, Bronkow",
    "03222": "Lübbenau/ Spreewald",
    "03226": "Vetschau",
    "03229": "Altdöbern, Luckaitztal",
    "03238": "Finsterwalde",
    "03246": "Crinitz",
    "03249": "Sonnewalde",
    "03253": "Doberlug-Kirchhain",
    "04103": "Leipzig",
    "04105": "Leipzig",
    "04107": "Leipzig",
    "04109": "Leipzig",
    "04129": "Leipzig",
    "04155": "Leipzig",
    "04157": "Leipzig",
    "04158": "Leipzig",
    "04159": "Leipzig",
    "04177": "Leipzig",
    "04178": "Leipzig",
    "04179": "Leipzig",
    "04205": "Leipzig",
    "04207": "Leipzig",
    "04209": "Leipzig",
    "04229": "Leipzig",
    "04249": "Leipzig",
    "04275": "Leipzig",
    "04277": "Leipzig",
    "04279": "Leipzig",
    "04288": "Leipzig",
    "04289": "Leipzig",
    "04299": "Leipzig",
    "04315": "Leipzig",
    "04316": "Leipzig",
    "04317": "Leipzig",
    "04318": "Leipzig",
    "04319": "Leipzig",
    "04328": "Leipzig",
    "04329": "Leipzig",
    "04347": "Leipzig",
    "04349": "Leipzig",
    "04356": "Leipzig",
    "04357": "Leipzig",
    "04416": "Markkleeberg",
    "04420": "Markranstädt",
    "04425": "Taucha",
    "04435": "Schkeuditz",
    "04442": "Zwenkau",
    "04451": "Borsdorf",
    "04463": "Großpösna",
    "04509": "Delitzsch, Krostitz u.a.",
    "04519": "Rackwitz",
    "04523": "Pegau, Elstertrebnitz",
    "04539": "Groitzsch",
    "04552": "Borna",
    "04564": "Böhlen",
    "04565": "Regis-Breitingen",
    "04567": "Kitzscher",
    "04571": "Rötha",
    "04575": "Neukieritzsch, Deutzen",
    "04600": "Altenburg",
    "04603": "Nobitz, Göhren, Windischleuba",
    "04610": "Meuselwitz",
    "04613": "Lucka",
    "04617": "Rositz, Starkenberg, Treben",
    "04618": "Langenleuba-Niederhain",
    "04626": "Schmölln, Altkirchen, Nöbdenitz u.a.",
    "04639": "Gößnitz",
    "04643": "Geithain",
    "04651": "Bad Lausick",
    "04654": "Frohburg",
    "04655": "Kohren-Sahlis",
    "04657": "Narsdorf",
    "04668": "Grimma",
    "04680": "Colditz",
    "04683": "Naunhof",
    "04687": "Trebsen/Mulde",
    "04703": "Leisnig",
    "04720": "Döbeln, Großweitzschen u.a.",
    "04736": "Waldheim",
    "04741": "Roßwein",
    "04746": "Hartha",
    "04749": "Ostrau",
    "04758": "Oschatz",
    "04769": "Mügeln",
    "04774": "Dahlen",
    "04779": "Wermsdorf",
    "04808": "Wurzen",
    "04821": "Brandis",
    "04824": "Brandis",
    "04827": "Machern",
    "04828": "Bennewitz, Machern",
    "04838": "Eilenburg u.a.",
    "04849": "Bad Düben",
    "04860": "Torgau, Dreiheide",
    "04861": "Torgau",
    "04862": "Mockrehna",
    "04874": "Belgern-Schildau",
    "04880": "Dommitzsch",
    "04886": "Arzberg, Beilrode",
    "04889": "Belgern-Schildau",
    "04895": "Falkenberg/ Elster",
    "04910": "Elsterwerda",
    "04916": "Herzberg/ Elster",
    "04924": "Bad Liebenwerda",
    "04928": "Plessa, Schraden",
    "04931": "Mühlberg, Bad Liebenwerda",
    "04932": "Röderland, Großthiemig u.a.",
    "04934": "Hohenleipisch",
    "04936": "Schlieben",
    "04938": "Uebigau-Wahrenbrück",
    "06108": "Halle/ Saale",
    "06110": "Halle/ Saale",
    "06112": "Halle/ Saale",
    "06114": "Halle/ Saale",
    "06116": "Halle/ Saale",
    "06118": "Halle/ Saale",
    "06120": "Halle/ Saale",
    "06122": "Halle/ Saale",
    "06124": "Halle/ Saale",
    "06126": "Halle/ Saale",
    "06128": "Halle/ Saale",
    "06130": "Halle/ Saale",
    "06132": "Halle/ Saale",
    "06179": "Teutschenthal",
    "06184": "Kabelsketal",
    "06188": "Landsberg",
    "06193": "Petersberg",
    "06198": "Salzatal",
    "06217": "Merseburg",
    "06231": "Bad Dürrenberg",
    "06237": "Leuna",
    "06242": "Braunsbedra",
    "06246": "Bad Lauchstädt",
    "06249": "Mücheln/ Geiseltal",
    "06255": "Mücheln",
    "06258": "Schkopau",
    "06259": "Frankleben",
    "06268": "Querfurt, Obhausen, Mücheln u.a.",
    "06279": "Schraplau, Farnstädt",
    "06295": "Eisleben",
    "06308": "Klostermansfeld, Benndorf",
    "06311": "Helbra",
    "06313": "Hergisdorf",
    "06317": "Seegebiet Mansfelder Land",
    "06333": "Hettstedt, Endorf",
    "06343": "Mansfeld",
    "06347": "Gerbstedt",
    "06366": "Köthen",
    "06369": "Südliches Anhalt u.a.",
    "06385": "Aken (Elbe)",
    "06386": "Aken, Osternienburger Land, Südliches Anhalt",
    "06388": "Südliches Anhalt, Köthen",
    "06406": "Bernburg (Saale)",
    "06408": "Ilberstedt",
    "06420": "Könnern",
    "06425": "Alsleben/Saale, Plötzkau",
    "06429": "Nienburg (Saale)",
    "06449": "Aschersleben",
    "06456": "Arnstein",
    "06458": "Hedersleben",
    "06463": "Falkenstein",
    "06464": "Seeland",
    "06466": "Seeland",
    "06467": "Seeland",
    "06469": "Seeland",
    "06484": "Quedlinburg",
    "06485": "Quedlinburg, Ballenstedt",
    "06493": "Ballenstedt, Harzgerode",
    "06502": "Thale, Blankenburg",
    "06526": "Sangerhausen",
    "06528": "Wallhausen, Blankenheim",
    "06536": "Südharz, Berga",
    "06537": "Kelbra (Kyffhäuser)",
    "06542": "Allstedt",
    "06543": "Falkenstein, Arnstein",
    "06556": "Artern/ Unstrut u.a.",
    "06567": "Bad Frankenhausen/ Kyffhäuser",
    "06571": "Roßleben, Wiehe, Donndorf, Nausitz, Gehofen",
    "06577": "Heldrungen",
    "06578": "Bilzingsleben Kannawurf Oldisleben",
    "06618": "Naumburg",
    "06628": "Lanitz-Hassel-Tal, Molauer Land",
    "06632": "Freyburg, Balgstädt",
    "06636": "Laucha an der Unstrut",
    "06638": "Karsdorf",
    "06642": "Nebra, Kaiserpfalz",
    "06647": "Bad Bibra, Finne u.a.",
    "06648": "Eckartsberga",
    "06667": "Weißenfels, Stößen",
    "06679": "Hohenmölsen",
    "06682": "Teuchern",
    "06686": "Lützen",
    "06688": "Weißenfels",
    "06711": "Zeitz",
    "06712": "Zeitz, Gutenborn u.a.",
    "06721": "Meineweh, Osterfeld",
    "06722": "Droyßig, Wetterzeube",
    "06729": "Elsteraue",
    "06749": "Bitterfeld-Wolfen",
    "06766": "Bitterfeld-Wolfen",
    "06772": "Gräfenhainichen",
    "06773": "Gräfenhainichen",
    "06774": "Muldestausee",
    "06779": "Raguhn-Jeßnitz",
    "06780": "Zörbig",
    "06785": "Oranienbaum-Wörlitz",
    "06792": "Sandersdorf-Brehna",
    "06794": "Sandersdorf-Brehna",
    "06796": "Sandersdorf-Brehna",
    "06800": "Raguhn-Jeßnitz",
    "06803": "Bitterfeld-Wolfen",
    "06808": "Bitterfeld-Wolfen",
    "06809": "Roitzsch, Petersroda",
    "06842": "Dessau-Roßlau",
    "06844": "Dessau-Roßlau",
    "06846": "Dessau-Roßlau",
    "06847": "Dessau-Roßlau",
    "06849": "Dessau-Roßlau",
    "06861": "Dessau-Roßlau",
    "06862": "Dessau-Roßlau",
    "06868": "Coswig (Anhalt)",
    "06869": "Coswig (Anhalt)",
    "06886": "Wittenberg",
    "06888": "Wittenberg",
    "06889": "Wittenberg",
    "06895": "Zahna-Elster",
    "06901": "Kemberg",
    "06905": "Bad Schmiedeberg",
    "06917": "Jessen (Elster)",
    "06925": "Annaburg",
    "07318": "Saalfeld/Saale, Wittgendorf",
    "07330": "Probstzella",
    "07333": "Unterwellenborn",
    "07334": "Kamsdorf",
    "07338": "Kaulsdorf",
    "07343": "Wurzbach",
    "07349": "Lehesten",
    "07356": "Lobenstein, Neundorf",
    "07366": "Blankenstein, Blankenberg u.a.",
    "07368": "Remptendorf",
    "07381": "Pößneck",
    "07387": "Krölpa",
    "07389": "Ranis",
    "07407": "Rudolstadt",
    "07422": "Bad Blankenburg, Saalfelder Höhe",
    "07426": "Königsee-Rottenbach u.a.",
    "07427": "Schwarzburg",
    "07429": "Döschnitz, Sitzendorf, Rohrbach",
    "07545": "Gera",
    "07546": "Gera",
    "07548": "Gera",
    "07549": "Gera",
    "07551": "Gera",
    "07552": "Gera",
    "07554": "Brahmenau",
    "07557": "Gera, Zedlitz u.a.",
    "07570": "Weida, Harth-Pöllnitz, Wünschendorf",
    "07580": "Ronneburg, Braunichswalde, Großenstein u.a.",
    "07586": "Bad Köstritz",
    "07589": "Münchenbernsdorf, Schwarzbach, Bocka",
    "07607": "Eisenberg, Gösen, Hainspitz",
    "07613": "Crossen, Heideland u.a.",
    "07616": "Bürgel u.a.",
    "07619": "Schkölen",
    "07629": "Hermsdorf",
    "07639": "Bad Klosterlausnitz",
    "07646": "Stadtroda u.a.",
    "07743": "Jena",
    "07745": "Jena",
    "07747": "Jena",
    "07749": "Jena",
    "07751": "Jena, Bucha, Großpürschütz u.a.",
    "07768": "Kahla",
    "07774": "Dornburg-Camburg u.a.",
    "07778": "Neuengönna u.a.",
    "07806": "Neustadt/ Orla",
    "07819": "Triptis",
    "07907": "Schleiz",
    "07919": "Kirschkau, Pausa-Mühltroff",
    "07922": "Tanna",
    "07924": "Ziegenrück",
    "07926": "Gefell",
    "07927": "Hirschberg",
    "07929": "Saalburg-Ebersdorf",
    "07937": "Zeulenroda-Triebes, Langenwolschendorf",
    "07950": "Zeulenroda-Triebes, Weißendorf",
    "07952": "Pausa-Mühltroff",
    "07955": "Auma-Weidatal",
    "07957": "Langenwetzendorf",
    "07958": "Hohenleuben",
    "07973": "Greiz",
    "07980": "Berga/Elster",
    "07985": "Elsterberg",
    "07987": "Mohlsdorf-Teichwolframsdorf",
    "08056": "Zwickau",
    "08058": "Zwickau",
    "08060": "Zwickau",
    "08062": "Zwickau",
    "08064": "Zwickau",
    "08066": "Zwickau",
    "08107": "Kirchberg",
    "08112": "Wilkau-Haßlau",
    "08115": "Lichtentanne",
    "08118": "Hartenstein",
    "08132": "Mülsen",
    "08134": "Langenweißbach, Wildenfels",
    "08141": "Reinsdorf",
    "08144": "Hirschfeld",
    "08147": "Crinitzberg",
    "08209": "Auerbach/Vogtl.",
    "08223": "Falkenstein/Vogtl.",
    "08228": "Rodewisch",
    "08233": "Treuen",
    "08236": "Ellefeld",
    "08237": "Steinberg",
    "08239": "Bergen",
    "08248": "Klingenthal/Sa.",
    "08258": "Markneukirchen",
    "08261": "Schöneck/Vogtl.",
    "08262": "Muldenhammer",
    "08267": "Klingenthal",
    "08280": "Aue",
    "08289": "Schneeberg",
    "08294": "Lößnitz",
    "08297": "Zwönitz",
    "08301": "Schlema",
    "08304": "Schönheide",
    "08309": "Eibenstock",
    "08315": "Lauter-Bernsbach",
    "08321": "Zschorlau",
    "08324": "Bockau",
    "08328": "Stützengrün",
    "08340": "Schwarzenberg/Erzgeb.",
    "08344": "Grünhain",
    "08349": "Johanngeorgenstadt",
    "08352": "Raschau",
    "08359": "Breitenbrunn/Erzgeb.",
    "08371": "Glauchau",
    "08373": "Remse",
    "08393": "Meerane",
    "08396": "Waldenburg",
    "08412": "Werdau",
    "08427": "Fraureuth",
    "08428": "Langenbernsdorf",
    "08451": "Crimmitschau",
    "08459": "Neukirchen/Pleiße",
    "08468": "Reichenbach/Vogtl.",
    "08485": "Lengenfeld",
    "08491": "Netzschkau, Limbach",
    "08496": "Neumark",
    "08499": "Mylau",
    "08523": "Plauen",
    "08525": "Plauen",
    "08527": "Plauen, Rößnitz",
    "08529": "Plauen",
    "08538": "Weischlitz u.a.",
    "08539": "Rosenbach",
    "08541": "Neuensalz",
    "08543": "Pöhl",
    "08547": "Jößnitz",
    "08548": "Rosenbach",
    "08606": "Oelsnitz",
    "08626": "Adorf",
    "08645": "Bad Elster",
    "08648": "Bad Brambach",
    "09111": "Chemnitz",
    "09112": "Chemnitz",
    "09113": "Chemnitz",
    "09114": "Chemnitz",
    "09116": "Chemnitz",
    "09117": "Chemnitz",
    "09119": "Chemnitz",
    "09120": "Chemnitz",
    "09122": "Chemnitz",
    "09123": "Chemnitz",
    "09125": "Chemnitz",
    "09126": "Chemnitz",
    "09127": "Chemnitz",
    "09128": "Chemnitz",
    "09130": "Chemnitz",
    "09131": "Chemnitz",
    "09212": "Limbach-Oberfrohna",
    "09217": "Burgstädt",
    "09221": "Neukirchen/Erzgeb.",
    "09224": "Chemnitz",
    "09228": "Chemnitz",
    "09232": "Hartmannsdorf",
    "09235": "Burkhardtsdorf",
    "09236": "Claußnitz",
    "09241": "Mühlau",
    "09243": "Niederfrohna",
    "09244": "Lichtenau",
    "09247": "Röhrsdorf",
    "09249": "Taura b. Burgstädt",
    "09306": "Rochlitz",
    "09322": "Penig",
    "09326": "Geringswalde",
    "09328": "Lunzenau",
    "09337": "Callenberg, Hohenstein-Ernstthal, Bernsdorf",
    "09350": "Lichtenstein",
    "09353": "Oberlungwitz",
    "09355": "Gersdorf",
    "09356": "St. Egidien",
    "09366": "Stollberg/Erzgeb.",
    "09376": "Oelsnitz/Erzgebirge",
    "09380": "Thalheim/Erzgebirge",
    "09385": "Lugau/Erzgeb.",
    "09387": "Jahnsdorf",
    "09390": "Gornsdorf",
    "09392": "Auerbach",
    "09394": "Hohndorf",
    "09399": "Niederwürschnitz",
    "09405": "Zschopau, Gornau",
    "09419": "Thum",
    "09423": "Gelenau/Erzgeb.",
    "09427": "Ehrenfriedersdorf",
    "09429": "Wolkenstein",
    "09430": "Drebach",
    "09432": "Großolbersdorf",
    "09434": "Großolbersdorf, Zschopau",
    "09437": "Börnichen, Gornau",
    "09439": "Amtsberg",
    "09456": "Annaberg-Buchholz, Mildenau",
    "09465": "Sehma",
    "09468": "Geyer",
    "09471": "Bärenstein",
    "09474": "Crottendorf",
    "09477": "Jöhstadt",
    "09481": "Scheibenberg",
    "09484": "Oberwiesenthal",
    "09487": "Schlettau",
    "09488": "Wiesa",
    "09496": "Marienberg",
    "09509": "Pockau-Lengefeld (Pockau)",
    "09514": "Pockau-Lengefeld (Lengefeld)",
    "09518": "Großrückerswalde",
    "09526": "Olbernhau, Pfaffroda, Heidersdorf",
    "09544": "Neuhausen/Erzgeb.",
    "09548": "Seiffen/Erzgeb.",
    "09557": "Flöha",
    "09569": "Oederan",
    "09573": "Leubsdorf, Gornau, Augustusburg",
    "09575": "Eppendorf",
    "09577": "Niederwiesa",
    "09579": "Grünhainichen",
    "09599": "Freiberg",
    "09600": "Weißenborn, Oberschöna",
    "09603": "Großschirma",
    "09618": "Brand-Erbisdorf, Großhartmannsdorf",
    "09619": "Dorfchemnitz, Mulda, Sayda",
    "09623": "Frauenstein",
    "09627": "Bobritzsch",
    "09629": "Reinsberg",
    "09633": "Halsbrücke",
    "09634": "Reinsberg",
    "09638": "Lichtenberg/Erzgeb.",
    "09648": "Mittweida, Kriebstein",
    "09661": "Hainichen, Rossau, Striegistal",
    "09669": "Frankenberg",
    "10115": "Berlin Mitte",
    "10117": "Berlin Mitte",
    "10119": "Berlin Mitte",
    "10178": "Berlin Mitte",
    "10179": "Berlin Mitte",
    "10243": "Berlin Friedrichshain",
    "10245": "Berlin Friedrichshain",
    "10247": "Berlin Friedrichshain",
    "10249": "Berlin Friedrichshain",
    "10315": "Berlin Friedrichsfelde",
    "10317": "Berlin Rummelsburg",
    "10318": "Berlin Karlshorst",
    "10319": "Berlin",
    "10365": "Berlin Lichtenberg",
    "10367": "Berlin Lichtenberg",
    "10369": "Berlin Lichtenberg",
    "10405": "Berlin Prenzlauer Berg",
    "10407": "Berlin Prenzlauer Berg",
    "10409": "Berlin Prenzlauer Berg",
    "10435": "Berlin Prenzlauer Berg",
    "10437": "Berlin Prenzlauer Berg",
    "10439": "Berlin Prenzlauer Berg",
    "10551": "Berlin Moabit",
    "10553": "Berlin Moabit",
    "10555": "Berlin Moabit",
    "10557": "Berlin Moabit",
    "10559": "Berlin Moabit",
    "10585": "Berlin Charlottenburg",
    "10587": "Berlin Charlottenburg",
    "10589": "Berlin Charlottenburg",
    "10623": "Berlin Charlottenburg",
    "10625": "Berlin Charlottenburg",
    "10627": "Berlin Charlottenburg",
    "10629": "Berlin Charlottenburg",
    "10707": "Berlin Wilmersdorf",
    "10709": "Berlin Wilmersdorf",
    "10711": "Berlin Halensee",
    "10713": "Berlin Wilmersdorf",
    "10715": "Berlin Wilhelmsdorf",
    "10717": "Berlin Wilmersdorf",
    "10719": "Berlin Wilmersdorf",
    "10777": "Berlin Wilmersdorf",
    "10779": "Berlin Schöneberg",
    "10781": "Berlin Schöneberg",
    "10783": "Berlin Schöneberg",
    "10785": "Berlin Tiergarten",
    "10787": "Berlin Tiergarten",
    "10789": "Berlin Schöneberg",
    "10823": "Berlin-West",
    "10825": "Berlin",
    "10827": "Berlin",
    "10829": "Berlin Schöneberg",
    "10961": "Berlin Kreuzberg",
    "10963": "Berlin Kreuzberg",
    "10965": "Berlin Neukölln",
    "10967": "Berlin Kreuzberg",
    "10969": "Berlin Kreuzberg",
    "10997": "Berlin Kreuzberg",
    "10999": "Berlin Kreuzberg",
    "12043": "Berlin Neukölln",
    "12045": "Berlin Neukölln",
    "12047": "Berlin Kreuzberg",
    "12049": "Berlin Neukölln",
    "12051": "Berlin Neukölln",
    "12053": "Berlin Neukölln",
    "12055": "Berlin Neukölln",
    "12057": "Berlin Neukölln",
    "12059": "Berlin Neukölln",
    "12099": "Berlin Tempelhof",
    "12101": "Berlin Tempelhof",
    "12103": "Berlin Tempelhof",
    "12105": "Berlin Mariendorf",
    "12107": "Berlin Mariendorf",
    "12109": "Berlin Mariendorf",
    "12157": "Berlin Schöneberg",
    "12159": "Berlin Friedenau",
    "12161": "Berlin Friedenau",
    "12163": "Berlin Steglitz",
    "12165": "Berlin Steglitz",
    "12167": "Berlin Steglitz",
    "12169": "Berlin Steglitz",
    "12203": "Berlin Lichtenfelde",
    "12205": "Berlin Lichtenfelde",
    "12207": "Berlin Lichtenfelde",
    "12209": "Berlin Lichtenfelde",
    "12247": "Berlin Lankwitz",
    "12249": "Berlin Lankwitz",
    "12277": "Berlin",
    "12279": "Berlin",
    "12305": "Berlin",
    "12307": "Berlin",
    "12309": "Berlin",
    "12347": "Berlin Britz",
    "12349": "Berlin Buckow",
    "12351": "Berlin Buckow",
    "12353": "Berlin Gropiusstadt",
    "12355": "Berlin Rudow",
    "12357": "Berlin Rudow",
    "12359": "Berlin Britz",
    "12435": "Berlin Alt Treptow",
    "12437": "Berlin Baumschulenweg",
    "12439": "Berlin Niederschöneweide",
    "12459": "Berlin Oberschöneweide",
    "12487": "Berlin",
    "12489": "Berlin Teltowkanal III",
    "12524": "Berlin Altglienicke",
    "12526": "Berlin Bohnsdorf",
    "12527": "Berlin Schmöckwitz",
    "12529": "Schönefeld",
    "12555": "Berlin Köpenik",
    "12557": "Berlin",
    "12559": "Berlin Köpenick",
    "12587": "Berlin Wiesengrund",
    "12589": "Berlin Rahnsdorf",
    "12619": "Berlin",
    "12621": "Berlin Kaulsdorf",
    "12623": "Berlin Mahlsdorf",
    "12627": "Berlin Hellersdorf",
    "12629": "Berlin",
    "12679": "Berlin",
    "12681": "Berlin",
    "12683": "Berlin Biesdorf",
    "12685": "Berlin",
    "12687": "Berlin",
    "12689": "Berlin",
    "13051": "Berlin Neu-Schönhausen",
    "13053": "Berlin Alt-Hohenschönhausen",
    "13055": "Berlin Alt-Hohenschönhausen",
    "13057": "Berlin Falkenberg",
    "13059": "Berlin Wartenberg",
    "13086": "Berlin Weißensee",
    "13088": "Berlin Weißensee",
    "13089": "Berlin Heinelsdorf",
    "13125": "Berlin Buch",
    "13127": "Berlin Französisch Buchholz",
    "13129": "Berlin Blankenburg",
    "13156": "Berlin Niederschönhausen",
    "13158": "Berlin Rosenthal",
    "13159": "Berlin Blankenfelde",
    "13187": "Berlin Pankow",
    "13189": "Berlin Pankow",
    "13347": "Berlin Wedding",
    "13349": "Berlin Wedding",
    "13351": "Berlin Wedding",
    "13353": "Berlin Wedding",
    "13355": "Berlin Wedding",
    "13357": "Berlin Gesundbrunnen",
    "13359": "Berlin Gesundbrunnen",
    "13403": "Berlin Reinickendorf",
    "13405": "Berlin Wedding",
    "13407": "Berlin-West",
    "13409": "Berlin-West",
    "13435": "Berlin Märkisches Viertel",
    "13437": "Berlin Reinickendorf",
    "13439": "Berlin Märkisches Viertel",
    "13465": "Berlin Frohnau",
    "13467": "Berlin Hermsdorf",
    "13469": "Berlin Lübars",
    "13503": "Berlin Tegel",
    "13505": "Berlin Tegel",
    "13507": "Berlin Tegel",
    "13509": "Berlin Reinickendorf",
    "13581": "Berlin Spandau",
    "13583": "Berlin Spandau",
    "13585": "Berlin Spandau",
    "13587": "Berlin Hakenfelde",
    "13589": "Berlin Falkenhagener Feld",
    "13591": "Berlin Staaken",
    "13593": "Berlin Wilhelmstadt",
    "13595": "Berlin Wlhelmstadt",
    "13597": "Berlin Spandau",
    "13599": "Berlin Haselhorst",
    "13627": "Berlin Charlottenburg-Nord",
    "13629": "Berlin Siemensstadt",
    "14050": "Berlin Westend",
    "14052": "Berlin Westend",
    "14053": "Berlin Westend",
    "14055": "Berlin Westend",
    "14057": "Berlin Charlottenburg",
    "14059": "Berlin Charlottenburg",
    "14089": "Berlin Gatow",
    "14109": "Berlin Wannsee",
    "14129": "Berlin Nikolassee",
    "14163": "Berlin Zehlendorf",
    "14165": "Berlin Zehlendorf",
    "14167": "Berlin Zehlendorf",
    "14169": "Berlin Zehlendorf",
    "14193": "Berlin Grunewald",
    "14195": "Berlin Dahlem",
    "14197": "Berlin Wilmersdorf",
    "14199": "Berlin Schmargendorf",
    "14467": "Potsdam",
    "14469": "Potsdam",
    "14471": "Potsdam",
    "14473": "Potsdam",
    "14476": "Potsdam",
    "14478": "Potsdam",
    "14480": "Potsdam",
    "14482": "Potsdam",
    "14513": "Teltow",
    "14532": "Kleinmachnow",
    "14542": "Werder/ Havel",
    "14547": "Beelitz",
    "14548": "Schwielowswee",
    "14550": "Groß Kreutz",
    "14552": "Michendorf",
    "14554": "Seddinger See",
    "14558": "Nuthetal",
    "14612": "Falkensee",
    "14621": "Schönwalde",
    "14624": "Dallgow-Döberitz",
    "14641": "Nauen",
    "14656": "Brieselang",
    "14662": "Friesack",
    "14669": "Ketzin",
    "14712": "Rathenow",
    "14715": "Milower Land, Schollene, Nennhausen u.a.",
    "14727": "Premnitz",
    "14728": "Rhinow",
    "14770": "Brandenburg/ Havel",
    "14772": "Brandenburg/ Havel",
    "14774": "Brandenburg/ Havel",
    "14776": "Brandenburg/ Havel",
    "14778": "Beetzsee, Wollin, Wenzlow, Golzow u.a",
    "14789": "Wusterwitz, Rosenau, Bensdorf",
    "14793": "Ziesar",
    "14797": "Lehnin",
    "14798": "Havelsee",
    "14806": "Belzig",
    "14822": "Brück, Borkheide u.a",
    "14823": "Niemegk",
    "14827": "Wiesenburg",
    "14828": "Görzke",
    "14913": "Jüterbog",
    "14929": "Treuenbrietzen",
    "14943": "Luckenwalde",
    "14947": "Nuthe-Urstromtal",
    "14959": "Trebbin",
    "14974": "Ludwigsfelde",
    "14979": "Großbeeren",
    "15230": "Frankfurt/ Oder",
    "15232": "Frankfurt/ Oder",
    "15234": "Frankfurt/ Oder",
    "15236": "Treplin, Jacobsdorf, Frankfurt (Oder)",
    "15295": "Brieskow-Finkenheerd",
    "15299": "Müllrose",
    "15306": "Seelow, Lietzen u.a.",
    "15320": "Neutrebbin, Neuhardenberg",
    "15324": "Letschin",
    "15326": "Zeschdorf, Podelzig, Lebus",
    "15328": "Golzow, Zechin u.a.",
    "15344": "Strausberg",
    "15345": "Lichtenow, Altlandsberg u.a.",
    "15366": "Neuenhagen, Hoppegarten",
    "15370": "Fredersdorf-Vogelsdorf, Petershagen",
    "15374": "Müncheberg",
    "15377": "Oberbarnim, Märkische Höhe u.a.",
    "15378": "Rüdersdorf",
    "15517": "Fürstenwalde/ Spree",
    "15518": "Briesen, Rauen u.a.",
    "15526": "Bad Saarow-Pieskow",
    "15528": "Spreenhagen",
    "15537": "Erkner",
    "15562": "Rüdersdorf",
    "15566": "Schöneiche bei Berlin",
    "15569": "Woltersdorf",
    "15711": "Königs Wusterhausen",
    "15712": "Königs Wusterhausen",
    "15713": "Königs Wusterhausen",
    "15732": "Schulzendorf b. Eichenwade",
    "15738": "Zeuthen",
    "15741": "Bestensee",
    "15745": "Wildau",
    "15746": "Groß Köris",
    "15748": "Märkisch Buchholz",
    "15749": "Mittenwalde",
    "15754": "Heidesee",
    "15755": "Teupitz",
    "15757": "Halbe",
    "15806": "Zossen",
    "15827": "Blankenfelde-Mahlow",
    "15831": "Blankenfelde-Mahlow u.a.",
    "15834": "Rangsdorf",
    "15837": "Baruth",
    "15838": "Am Mellensee",
    "15848": "Beeskow",
    "15859": "Storkow",
    "15864": "Wendisch Rietz",
    "15868": "Lieberose",
    "15890": "Eisenhüttenstadt",
    "15898": "Neuzelle",
    "15907": "Lübben/ Spreewald",
    "15910": "Schönwalde",
    "15913": "Straupitz",
    "15926": "Luckau, Waldrehna, Heideblick, Fürstlich Drehna",
    "15936": "Dahme u.a.",
    "15938": "Golßen",
    "16225": "Eberswalde",
    "16227": "Eberswalde",
    "16230": "Melchow, Chorin u.a.",
    "16244": "Schorfheide",
    "16247": "Joachimsthal u.a.",
    "16248": "Oderberg u.a.",
    "16259": "Bad Freienwalde u.a.",
    "16269": "Wriezen",
    "16278": "Angermünde",
    "16303": "Schwedt",
    "16306": "Biesendahlshof, Berkholz-Meyenburg",
    "16307": "Gartz (Oder)",
    "16321": "Bernau",
    "16341": "Panketal",
    "16348": "Wandlitz",
    "16356": "Werneuchen",
    "16359": "Biesenthal",
    "16515": "Oranienburg, Mühlenbecker Land",
    "16540": "Hohen Neuendorf",
    "16547": "Birkenwerder",
    "16548": "Glienicke/Nordbahn",
    "16552": "Mühlenbecker Land",
    "16556": "Borgsdorf",
    "16559": "Liebenwalde",
    "16562": "Hohen Neuendorf OT Bergfelde",
    "16567": "Mühlenbecker Land",
    "16727": "Velten, Oberkrämer",
    "16761": "Hennigsdorf",
    "16766": "Kremmen",
    "16767": "Leegebruch",
    "16775": "Gransee, Löwenberg",
    "16792": "Zehdenick",
    "16798": "Fürstenberg",
    "16816": "Neuruppin",
    "16818": "Fehrbellin, Temnitzquell, Märkisch Linden u.a.",
    "16827": "Neuruppin",
    "16831": "Rheinsberg",
    "16833": "Fehrbellin",
    "16835": "Lindow u.a.",
    "16837": "Rheinsberg",
    "16845": "Neustadt (Dosse) u.a.",
    "16866": "Kyritz u.a.",
    "16868": "Wusterhausen",
    "16909": "Wittstock/Dosse, Heiligengrabe",
    "16928": "Pritzwalk, Groß Pankow",
    "16945": "Meyenburg, Kümmernitztal u.a.",
    "16949": "Triglitz, Putlitz",
    "17033": "Neubrandenburg",
    "17034": "Neubrandenburg",
    "17036": "Neubrandenburg",
    "17039": "Sponholz, Neunkirchen u.a.",
    "17087": "Altentreptow",
    "17089": "Burow",
    "17091": "Rosenow",
    "17094": "Cölpin",
    "17098": "Friedland",
    "17099": "Friedland, Galenbeck, Datzetal",
    "17109": "Demmin",
    "17111": "Demmin u.a.",
    "17121": "Loitz",
    "17126": "Jarmen",
    "17129": "Bentzin",
    "17139": "Malchin",
    "17153": "Stavenhagen",
    "17154": "Neukalen",
    "17159": "Dargun",
    "17166": "Dahmen, Groß Wokern, Teterow",
    "17168": "Jördenstorf, Prebberede u.a.",
    "17179": "Gnoien u.a.",
    "17192": "Waren/ Müritz",
    "17194": "Grabowhöfe, Moltzow u.a.",
    "17207": "Röbel/ Müritz",
    "17209": "Wredenhagen",
    "17213": "Malchow u.a.",
    "17214": "Nossentiner Hütte",
    "17217": "Penzlin",
    "17219": "Möllenhagen",
    "17235": "Neustrelitz",
    "17237": "Möllenbeck",
    "17248": "Rechlin",
    "17252": "Mirow",
    "17255": "Wesenberg",
    "17258": "Feldberger Seenlandschaft",
    "17268": "Templin, Boitzenburg u.a.",
    "17279": "Lychen",
    "17291": "Prenzlau u.a.",
    "17309": "Pasewalk u.a.",
    "17321": "Löcknitz, Rothenklempenow",
    "17322": "Blankensee, Grambow u.a.",
    "17326": "Brüssow",
    "17328": "Penkun u.a.",
    "17329": "Nadrensee u.a.",
    "17335": "Strasburg",
    "17337": "Uckerland, Groß Luckow, Schönhausen",
    "17348": "Woldegk",
    "17349": "Groß Miltzow",
    "17358": "Torgelow",
    "17367": "Eggesin",
    "17373": "Ueckermünde",
    "17375": "Vogelsang-Warsin, Meiersberg, Mönkebude u.a.",
    "17379": "Ferdinandshof u.a.",
    "17389": "Anklam",
    "17390": "Klein Bünzow",
    "17391": "Krien u.a.",
    "17392": "Spantekow",
    "17398": "Ducherow",
    "17406": "Usedom u.a.",
    "17419": "Seebad Ahlbeck",
    "17424": "Ostseebad Heringsdorf",
    "17429": "Benz, Heringsdorf u.a.",
    "17438": "Wolgast",
    "17440": "Kröslin, Krummin, Lassan u.a.",
    "17449": "Karlshagen",
    "17454": "Zinnowitz",
    "17459": "Koserow",
    "17489": "Greifswald",
    "17491": "Greifswald",
    "17493": "Greifswald",
    "17495": "Karlsburg",
    "17498": "Neuenkirchen",
    "17506": "Gützkow",
    "17509": "Lubmin",
    "18055": "Rostock",
    "18057": "Rostock",
    "18059": "Rostock, Papendorf u.a.",
    "18069": "Rostock, Lambrechtshagen",
    "18106": "Rostock",
    "18107": "Elmenhorst/Lichtenhagen, Rostock",
    "18109": "Rostock",
    "18119": "Rostock",
    "18146": "Rostock",
    "18147": "Rostock",
    "18181": "Rostock, Graal-Müritz",
    "18182": "Rostock, Gelbensande, Rövershagen u.a.",
    "18184": "Roggentin, Broderstorf u.a.",
    "18190": "Sanitz",
    "18195": "Tessin, Grammow u.a.",
    "18196": "Dummerstorf",
    "18198": "Stäbelow, Kritzmow",
    "18209": "Bad Doberan, Bartenshagen-Parkentin u.a.",
    "18211": "Retschow, Admannshagen-Bargeshagen u.a.",
    "18225": "Kühlungsborn",
    "18230": "Rerik, Bastorf, Biendorf",
    "18233": "Neubukow, Ravensberg, Westenbrügge u.a.",
    "18236": "Kröpelin, Carinerland",
    "18239": "Satow",
    "18246": "Bützow u.a.",
    "18249": "Bernitt, Qualitz, Warnow, Zernin u.a.",
    "18258": "Schwaan u.a.",
    "18273": "Güstrow",
    "18276": "Reimershagen, Lohmen, Zehna, Hägerfelde u.a.",
    "18279": "Lalendorf, Langhagen",
    "18292": "Krakow, Dobbin-Linstow u.a.",
    "18299": "Laage, Wardow u.a.",
    "18311": "Ribnitz-Damgarten",
    "18314": "Löbnitz",
    "18317": "Saal",
    "18320": "Ahrenshagen-Daskow, Trinwillershagen u.a.",
    "18334": "Bad Sülze",
    "18337": "Marlow",
    "18347": "Dierhagen",
    "18356": "Barth",
    "18374": "Zingst a. Darß",
    "18375": "Prerow a. Darß",
    "18435": "Stralsund",
    "18437": "Stralsund",
    "18439": "Stralsund",
    "18442": "Niepars",
    "18445": "Prohn",
    "18461": "Franzburg, Richtenberg, u.a.",
    "18465": "Tribsees",
    "18469": "Velgast",
    "18507": "Grimmen",
    "18510": "Wittenhagen",
    "18513": "Glewitz",
    "18516": "Rakow",
    "18519": "Miltzow u.a.",
    "18528": "Bergen/ Rügen",
    "18546": "Sassnitz",
    "18551": "Sagard",
    "18556": "Dranske",
    "18565": "Hiddensee",
    "18569": "Gingst",
    "18573": "Samtens",
    "18574": "Garz/ Rügen",
    "18581": "Putbus",
    "18586": "Sellin",
    "18609": "Binz",
    "19053": "Schwerin",
    "19055": "Schwerin",
    "19057": "Schwerin",
    "19059": "Schwerin",
    "19061": "Schwerin",
    "19063": "Schwerin",
    "19065": "Pinnow",
    "19067": "Leezen",
    "19069": "Lübstorf",
    "19071": "Brüsewitz",
    "19073": "Wittenförden u.a.",
    "19075": "Pampow",
    "19077": "Rastow",
    "19079": "Banzkow, Sukow",
    "19086": "Plate",
    "19089": "Crivitz",
    "19205": "Gadebusch",
    "19209": "Lützow",
    "19217": "Rehna, Carlow u.a.",
    "19230": "Hagenow u.a.",
    "19243": "Wittenburg u.a.",
    "19246": "Zarrentin",
    "19249": "Lübtheen",
    "19258": "Boizenburg/ Elbe",
    "19260": "Vellahn",
    "19273": "Amt Neuhaus, Stapel",
    "19288": "Ludwigslust",
    "19294": "Neu Kaliß",
    "19300": "Grabow u.a.",
    "19303": "Dömitz",
    "19306": "Neustadt-Glewe",
    "19309": "Lenzen",
    "19322": "Wittenberge, Rühstädt",
    "19336": "Legde/Quitzöbel, Bad Wilsnack",
    "19339": "Plattenburg",
    "19348": "Perleberg, Berge u.a.",
    "19357": "Karstädt, Dambeck, Klüß",
    "19370": "Parchim",
    "19372": "Spornitz",
    "19374": "Mestlin, Severin u.a.",
    "19376": "Siggelkow",
    "19386": "Lübz, Passow",
    "19395": "Plau am See",
    "19399": "Goldberg",
    "19406": "Sternberg",
    "19412": "Brüel",
    "19417": "Warin",
    "20095": "Hamburg",
    "20097": "Hamburg",
    "20099": "Hamburg",
    "20144": "Hamburg",
    "20146": "Hamburg",
    "20148": "Hamburg",
    "20149": "Hamburg",
    "20249": "Hamburg",
    "20251": "Hamburg",
    "20253": "Hamburg",
    "20255": "Hamburg",
    "20257": "Hamburg",
    "20259": "Hamburg",
    "20354": "Hamburg",
    "20355": "Hamburg",
    "20357": "Hamburg",
    "20359": "Hamburg",
    "20457": "Hamburg",
    "20459": "Hamburg",
    "20535": "Hamburg",
    "20537": "Hamburg",
    "20539": "Hamburg",
    "21029": "Hamburg",
    "21031": "Hamburg",
    "21033": "Hamburg",
    "21035": "Hamburg",
    "21037": "Hamburg",
    "21039": "Hamburg",
    "21073": "Hamburg",
    "21075": "Hamburg",
    "21077": "Hamburg",
    "21079": "Hamburg",
    "21107": "Hamburg",
    "21109": "Hamburg",
    "21129": "Hamburg",
    "21147": "Hamburg",
    "21149": "Hamburg",
    "21217": "Seevetal",
    "21218": "Seevetal",
    "21220": "Seevetal",
    "21224": "Rosengarten",
    "21227": "Bendestorf",
    "21228": "Harmstorf",
    "21244": "Buchholz in der Nordheide",
    "21255": "Tostedt, Kakenstorf u.a.",
    "21256": "Handeloh",
    "21258": "Heidenau",
    "21259": "Otter",
    "21261": "Welle",
    "21266": "Jesteburg",
    "21271": "Hanstedt, Asendorf",
    "21272": "Eggestorf",
    "21274": "Undeloh",
    "21279": "Hollenstedt, Drestedt u.a.",
    "21335": "Lüneburg",
    "21337": "Lüneburg",
    "21339": "Lüneburg",
    "21354": "Bleckede",
    "21357": "Bardowick, Wittorf, Barum",
    "21358": "Mechtersen",
    "21360": "Vögelsen",
    "21365": "Adendorf",
    "21368": "Dahlenburg",
    "21369": "Nahrendorf",
    "21371": "Tosterglope",
    "21376": "Salzhausen",
    "21379": "Scharnebeck, Echem, Lüdersburg, Rullstorf",
    "21380": "Artlenburg",
    "21382": "Brietlingen",
    "21385": "Oldendorf (Luhe), Amelinghausen, Rehlingen",
    "21386": "Betzendorf",
    "21388": "Soderstorf",
    "21391": "Reppenstedt, Lüneburg",
    "21394": "Kirchgellersen, Westergellersen, Südergellersen",
    "21395": "Tespe",
    "21397": "Barendorf, Vastorf",
    "21398": "Neetze",
    "21400": "Reinstorf",
    "21401": "Thomasburg",
    "21403": "Wendisch Evern",
    "21406": "Melbeck, Barnstedt",
    "21407": "Deutsch Evern",
    "21409": "Embsen",
    "21423": "Winsen",
    "21435": "Stelle",
    "21436": "Marschacht",
    "21438": "Brackel",
    "21439": "Marxen",
    "21441": "Garstedt",
    "21442": "Toppenstedt",
    "21444": "Vierhöfen",
    "21445": "Wulfsen",
    "21447": "Handorf",
    "21449": "Radbruch",
    "21465": "Reinbek",
    "21481": "Lauenburg/Elbe",
    "21483": "Gülzow",
    "21493": "Fuhlenhagen",
    "21502": "Geesthacht",
    "21509": "Glinde",
    "21514": "Bröthen",
    "21516": "Woltersdorf, Müssen u.a.",
    "21521": "Aumühle",
    "21522": "Hohnstorf (Elbe), Hittbergen",
    "21524": "Brunstorf",
    "21526": "Hohenhorn",
    "21527": "Kollow",
    "21529": "Kröppelshagen-Fahrendorf",
    "21614": "Buxtehude",
    "21629": "Neu Wulmstorf",
    "21635": "Jork",
    "21640": "Horneburg",
    "21641": "Apensen",
    "21643": "Beckdorf",
    "21644": "Sauensiek",
    "21646": "Halvesbostel",
    "21647": "Moisburg",
    "21649": "Regesbostel",
    "21680": "Stade",
    "21682": "Stade",
    "21683": "Stade",
    "21684": "Stade",
    "21698": "Harsefeld",
    "21702": "Ahlerstedt",
    "21706": "Drochtersen",
    "21709": "Himmelpforten",
    "21710": "Engelschoff",
    "21712": "Großenwörden",
    "21714": "Hammah",
    "21717": "Fredenbeck",
    "21720": "Gründeich",
    "21723": "Hollern-Twielenfleth",
    "21726": "Oldendorf",
    "21727": "Estorf",
    "21729": "Freiburg (Elbe)",
    "21730": "Balje",
    "21732": "Krummendeich",
    "21734": "Oederquart",
    "21737": "Wischhafen",
    "21739": "Dollern",
    "21745": "Hemmoor",
    "21755": "Hechthausen",
    "21756": "Osten",
    "21762": "Otterndorf",
    "21763": "Neuenkirchen",
    "21765": "Nordleda",
    "21769": "Lamstedt",
    "21770": "Mittelstenahe",
    "21772": "Stinstedt",
    "21775": "Ihlienworth",
    "21776": "Wanna",
    "21781": "Cadenberge",
    "21782": "Bülkau",
    "21784": "Geversdorf",
    "21785": "Neuhaus (Oste)",
    "21787": "Oberndorf",
    "21789": "Wingst",
    "22041": "Hamburg",
    "22043": "Hamburg",
    "22045": "Hamburg",
    "22047": "Hamburg",
    "22049": "Hamburg",
    "22081": "Hamburg",
    "22083": "Hamburg",
    "22085": "Hamburg",
    "22087": "Hamburg",
    "22089": "Hamburg",
    "22111": "Hamburg",
    "22113": "Hamburg, Oststeinbek",
    "22115": "Hamburg",
    "22117": "Hamburg",
    "22119": "Hamburg",
    "22143": "Hamburg",
    "22145": "Hamburg",
    "22147": "Hamburg",
    "22149": "Hamburg",
    "22159": "Hamburg",
    "22175": "Hamburg",
    "22177": "Hamburg",
    "22179": "Hamburg",
    "22297": "Hamburg",
    "22299": "Hamburg",
    "22301": "Hamburg",
    "22303": "Hamburg",
    "22305": "Hamburg",
    "22307": "Hamburg",
    "22309": "Hamburg",
    "22335": "Hamburg",
    "22337": "Hamburg",
    "22339": "Hamburg",
    "22359": "Hamburg",
    "22391": "Hamburg",
    "22393": "Hamburg",
    "22395": "Hamburg",
    "22397": "Hamburg",
    "22399": "Hamburg",
    "22415": "Hamburg",
    "22417": "Hamburg",
    "22419": "Hamburg",
    "22453": "Hamburg",
    "22455": "Hamburg",
    "22457": "Hamburg",
    "22459": "Hamburg",
    "22523": "Hamburg",
    "22525": "Hamburg",
    "22527": "Hamburg",
    "22529": "Hamburg",
    "22547": "Hamburg",
    "22549": "Hamburg",
    "22559": "Hamburg",
    "22587": "Hamburg",
    "22589": "Hamburg",
    "22605": "Hamburg",
    "22607": "Hamburg",
    "22609": "Hamburg",
    "22761": "Hamburg",
    "22763": "Hamburg",
    "22765": "Hamburg",
    "22767": "Hamburg",
    "22769": "Hamburg",
    "22844": "Norderstedt",
    "22846": "Norderstedt",
    "22848": "Norderstedt",
    "22850": "Norderstedt",
    "22851": "Norderstedt",
    "22869": "Schenefeld",
    "22880": "Wedel",
    "22885": "Barsbüttel",
    "22889": "Tangstedt",
    "22926": "Ahrensburg",
    "22927": "Großhansdorf",
    "22929": "Hamfelde, Kasseburg, Köthel, Rausdorf, Schönberg",
    "22941": "Bargteheide, Delingsdorf u.a.",
    "22946": "Trittau",
    "22949": "Ammersbek",
    "22952": "Lütjensee",
    "22955": "Hoisdorf",
    "22956": "Grönwohld",
    "22958": "Kuddewörde",
    "22959": "Linau",
    "22961": "Hoisdorf",
    "22962": "Siek",
    "22964": "Steinburg",
    "22965": "Todendorf",
    "22967": "Tremsbüttel",
    "22969": "Witzhave",
    "23552": "Lübeck",
    "23554": "Lübeck St. Lorenz Nord",
    "23556": "Lübeck",
    "23558": "Lübeck",
    "23560": "Lübeck",
    "23562": "Lübeck",
    "23564": "Lübeck",
    "23566": "Lübeck",
    "23568": "Lübeck Schlutup/St. Gertrud",
    "23569": "Lübeck",
    "23570": "Lübeck",
    "23611": "Bad Schwartau",
    "23617": "Stockelsdorf",
    "23619": "Zarpen",
    "23623": "Ahrensbök",
    "23626": "Ratekau",
    "23627": "Groß Grönau",
    "23628": "Krummesse, Klempau",
    "23629": "Sarkwitz",
    "23669": "Timmendorfer Strand",
    "23683": "Scharbeutz",
    "23684": "Scharbeutz, Süsel",
    "23689": "Pansdorf",
    "23701": "Eutin, Süsel",
    "23714": "Malente, Kirchnüchel",
    "23715": "Bosau",
    "23717": "Kasseedorf",
    "23719": "Glasau",
    "23730": "Neustadt",
    "23738": "Lensahn",
    "23743": "Grömitz",
    "23744": "Schönwalde am Bungsberg",
    "23746": "Kellenhusen",
    "23747": "Dahme",
    "23749": "Grube",
    "23758": "Oldenburg in Holstein",
    "23769": "Fehmarn",
    "23774": "Heiligenhafen",
    "23775": "Großenbrode",
    "23777": "Heringsdorf",
    "23779": "Neukirchen",
    "23795": "Bad Segeberg",
    "23812": "Wahlstedt",
    "23813": "Nehms",
    "23815": "Geschendorf",
    "23816": "Leezen",
    "23818": "Neuengörs",
    "23820": "Pronstorf",
    "23821": "Rohlstorf",
    "23823": "Seedorf",
    "23824": "Tensfeld",
    "23826": "Bark",
    "23827": "Wensin",
    "23829": "Wittenborn",
    "23843": "Bad Oldesloe",
    "23845": "Seth",
    "23847": "Lasbek",
    "23858": "Reinfeld (Holstein)",
    "23860": "Klein Wesenberg",
    "23863": "Bargfeld-Stegen",
    "23866": "Nahe",
    "23867": "Sülfeld",
    "23869": "Elmenhorst",
    "23879": "Mölln",
    "23881": "Breitenfelde, Lankau",
    "23883": "Sterley",
    "23896": "Nusse",
    "23898": "Sandesneben u.a.",
    "23899": "Gudow",
    "23909": "Ratzeburg",
    "23911": "Ziethen",
    "23919": "Berkenthin",
    "23923": "Schönberg",
    "23936": "Grevesmühlen, Stepenitztal, Upahl u.a.",
    "23942": "Dassow",
    "23946": "Boltenhagen",
    "23948": "Klütz",
    "23966": "Wismar, Groß Krankow u.a.",
    "23968": "Barnekow, Gägelow u.a.",
    "23970": "Wismar",
    "23972": "Dorf Mecklenburg, Lübow u.a.",
    "23974": "Neuburg-Steinhausen, Hornstorf",
    "23992": "Neukloster",
    "23996": "Bad Kleinen u.a.",
    "23999": "Insel Poel",
    "24103": "Kiel",
    "24105": "Kiel",
    "24106": "Kiel",
    "24107": "Kiel",
    "24109": "Melsdorf",
    "24111": "Kiel Russee",
    "24113": "Kiel",
    "24114": "Kiel",
    "24116": "Kiel",
    "24118": "Kiel",
    "24119": "Kronshagen",
    "24143": "Kiel",
    "24145": "Kiel",
    "24146": "Kiel",
    "24147": "Kiel",
    "24148": "Kiel",
    "24149": "Kiel",
    "24159": "Kiel",
    "24161": "Altenholz",
    "24211": "Preetz",
    "24214": "Gettorf u.a.",
    "24217": "Schönberg (Holstein)",
    "24220": "Flintbek",
    "24222": "Schwentinental",
    "24223": "Schwentinetal",
    "24226": "Heikendorf",
    "24229": "Dänischenhagen",
    "24232": "Schönkirchen",
    "24235": "Laboe",
    "24238": "Selent",
    "24239": "Achterwehr",
    "24241": "Blumenthal",
    "24242": "Felde",
    "24244": "Felm",
    "24245": "Kirchbarkau",
    "24247": "Mielkendorf",
    "24248": "Mönkeberg",
    "24250": "Nettelsee",
    "24251": "Osdorf",
    "24253": "Probsteierhagen",
    "24254": "Rumohr",
    "24256": "Fargau-Pratjau",
    "24257": "Hohenfelde",
    "24259": "Westensee",
    "24306": "Plön",
    "24321": "Lütjenburg",
    "24326": "Ascheberg",
    "24327": "Blekendorf",
    "24329": "Grebin",
    "24340": "Eckernförde",
    "24351": "Damp",
    "24354": "Kosel, Rieseby u.a.",
    "24357": "Fleckeby u.a.",
    "24358": "Ascheffel",
    "24360": "Barkelsby",
    "24361": "Groß Wittensee",
    "24363": "Holtsee",
    "24364": "Holzdorf",
    "24366": "Loose",
    "24367": "Osterby",
    "24369": "Waabs",
    "24376": "Kappeln",
    "24392": "Süderbrarup",
    "24395": "Gelting",
    "24398": "Dörphof",
    "24399": "Arnis, Marienhof",
    "24401": "Böel",
    "24402": "Esgrus, Schrepperie",
    "24404": "Maasholm, Schleimünde",
    "24405": "Mohrkirch, Rügge",
    "24407": "Rabenkirchen-Faulück",
    "24409": "Stoltebüll",
    "24534": "Neumünster",
    "24536": "Neumünster",
    "24537": "Neumünster",
    "24539": "Neumünster",
    "24558": "Henstedt-Ulzburg",
    "24568": "Kaltenkirchen",
    "24576": "Bad Bramstedt",
    "24582": "Bordesholm",
    "24589": "Nortorf",
    "24594": "Hohenwestedt",
    "24598": "Boostedt",
    "24601": "Wankendorf",
    "24610": "Trappenkamp",
    "24613": "Aukrug, Wiedenborstel",
    "24616": "Brokstedt",
    "24619": "Börnhöved",
    "24620": "Bönebüttel",
    "24622": "Gnutz",
    "24623": "Großenaspe",
    "24625": "Großharrie",
    "24626": "Groß Kummerfeld",
    "24628": "Hartenholm",
    "24629": "Kisdorf",
    "24631": "Langwedel",
    "24632": "Lentföhrden",
    "24634": "Padenstedt",
    "24635": "Rickling",
    "24637": "Schillsdorf",
    "24638": "Schmalensee",
    "24640": "Schmalfeld",
    "24641": "Sievershütten",
    "24643": "Struvenhütten",
    "24644": "Timmaspe",
    "24646": "Warder",
    "24647": "Wasbek",
    "24649": "Wiemersdorf",
    "24768": "Rendsburg",
    "24782": "Büdelsdorf, Rickert",
    "24783": "Osterrönfeld",
    "24784": "Westerrönfeld",
    "24787": "Fockbek",
    "24790": "Schacht-Audorf",
    "24791": "Alt Duvenstedt",
    "24793": "Bargstedt, Brammer, Oldenbüttel",
    "24794": "Borgstedt",
    "24796": "Bredenbek",
    "24797": "Breiholz, Tackesdorf",
    "24799": "Meggerdorf, Friedrichsholm, Friedrichsgraben u.a.",
    "24800": "Elsdorf-Westermühlen",
    "24802": "Emkendorf",
    "24803": "Erfde",
    "24805": "Hamdorf",
    "24806": "Hohn",
    "24808": "Jevenstedt",
    "24809": "Nübbel",
    "24811": "Owschlag u.a.",
    "24813": "Schülp bei Rendsburg",
    "24814": "Sehestedt",
    "24816": "Hamweddel",
    "24817": "Tetenhusen",
    "24819": "Todenbüttel",
    "24837": "Schleswig",
    "24848": "Kropp u.a.",
    "24850": "Schuby",
    "24852": "Eggebek, Langstedt, Sollerup, Süderhackstedt",
    "24855": "Bollingstedt, Jübek",
    "24857": "Fahrdorf",
    "24860": "Böklund u.a.",
    "24861": "Bergenhusen",
    "24863": "Börm",
    "24864": "Brodersby, Goltoft",
    "24866": "Busdorf",
    "24867": "Dannewerk",
    "24869": "Dörpstedt",
    "24870": "Ellingstedt",
    "24872": "Groß Rheide",
    "24873": "Havetoft",
    "24876": "Hollingstedt",
    "24878": "Jagel, Lottorf",
    "24879": "Idstedt",
    "24881": "Nübel",
    "24882": "Schaalby, Geelbek",
    "24884": "Selk, Geltdorf, Hahnekrug",
    "24885": "Sieverstedt",
    "24887": "Silberstedt, Schwittschau",
    "24888": "Steinfeld",
    "24890": "Stolk",
    "24891": "Struxdorf, Schnarup-Thumby",
    "24893": "Taarstedt",
    "24894": "Tolk, Twedt",
    "24896": "Treia, Ahrenviölfeld",
    "24897": "Ulsnis",
    "24899": "Wohlde",
    "24937": "Flensburg",
    "24939": "Flensburg",
    "24941": "Flensburg",
    "24943": "Flensburg, Tastrup",
    "24944": "Flensburg",
    "24955": "Harrislee",
    "24960": "Glücksburg, Munkbrarup",
    "24963": "Tarp",
    "24966": "Sörup",
    "24969": "Großenwiehe, Lindewitt",
    "24972": "Steinberg, Steinbergkirche",
    "24975": "Husby",
    "24976": "Handewitt",
    "24977": "Langballig",
    "24980": "Schafflund, Meyn u.a",
    "24983": "Handewitt",
    "24986": "Mittelangeln",
    "24988": "Oeversee",
    "24989": "Dollerup",
    "24991": "Großsolt",
    "24992": "Jörl",
    "24994": "Medelby",
    "24996": "Sterup",
    "24997": "Wanderup",
    "24999": "Wees",
    "25335": "Elmshorn",
    "25336": "Elmshorn",
    "25337": "Elmshorn",
    "25348": "Glückstadt",
    "25355": "Barmstedt",
    "25358": "Horst",
    "25361": "Krempe, Grevenkop, Süderau, Muchelndorf",
    "25364": "Brande-Hörnerkirchen",
    "25365": "Klein Offenseth-Sparrieshoop",
    "25368": "Kiebitzreihe",
    "25370": "Seester",
    "25371": "Seestermühe",
    "25373": "Ellerhoop",
    "25376": "Borsfleth",
    "25377": "Kollmar, Pagensand",
    "25379": "Herzhorn, Kamerlanderdeich",
    "25421": "Pinneberg",
    "25436": "Uetersen",
    "25451": "Quickborn",
    "25462": "Rellingen",
    "25469": "Halstenbek",
    "25474": "Ellerbek",
    "25479": "Ellerau",
    "25482": "Appen",
    "25485": "Hemdingen",
    "25486": "Alveslohe",
    "25488": "Holm",
    "25489": "Haseldorf",
    "25491": "Hetlingen",
    "25492": "Heist",
    "25494": "Borstel-Hohenraden",
    "25495": "Kummerfeld",
    "25497": "Prisdorf",
    "25499": "Tangstedt",
    "25524": "Itzehoe",
    "25541": "Brunsbüttel",
    "25548": "Kellinghusen",
    "25551": "Hohenlockstedt",
    "25554": "Wilster",
    "25557": "Hanerau-Hademarschen, Seefeld u.a.",
    "25560": "Schenefeld",
    "25563": "Wrist",
    "25566": "Lägerdorf",
    "25569": "Kremperheide",
    "25572": "Sankt Margarethen",
    "25573": "Beidenfleth, Klein Kampen",
    "25575": "Beringstedt",
    "25576": "Brokdorf",
    "25578": "Dägeling, Neuenbrook",
    "25579": "Fitzbek",
    "25581": "Hennstedt",
    "25582": "Hohenaspe",
    "25584": "Holstenniendorf",
    "25585": "Lütjenwestedt, Tackesdorf",
    "25587": "Münsterdorf",
    "25588": "Oldendorf",
    "25590": "Osterstedt",
    "25591": "Ottenbüttel",
    "25593": "Reher",
    "25594": "Vaale",
    "25596": "Wacken",
    "25597": "Westermoor",
    "25599": "Wewelsfleth",
    "25693": "Sankt Michaelisdonn,Gudendorf,Volsemenhusen,Trennewurth",
    "25704": "Meldorf",
    "25709": "Kronprinzenkoog, Marne u.a.",
    "25712": "Burg (Dithmarschen)",
    "25715": "Eddelak, Averlak, Dingen, Ramhusen",
    "25718": "Friedrichskoog",
    "25719": "Barlt, Busenwurth",
    "25721": "Eggstedt",
    "25724": "Neufeld, Schmedeswurth",
    "25725": "Schafstedt, Weidenhof, Bornholt",
    "25727": "Süderhastedt",
    "25729": "Windbergen",
    "25746": "Heide u.a.",
    "25761": "Büsum",
    "25764": "Wesselburen",
    "25767": "Albersdorf",
    "25770": "Hemmingstedt",
    "25774": "Lunden",
    "25776": "Sankt Annen, Rehm-Flehde-Bargen",
    "25779": "Hennstedt",
    "25782": "Tellingstedt",
    "25785": "Nordhastedt",
    "25786": "Dellstedt",
    "25788": "Delve",
    "25791": "Linden, Barkenholm",
    "25792": "Neuenkirchen",
    "25794": "Pahlen",
    "25795": "Weddingstedt",
    "25797": "Wöhrden",
    "25799": "Wrohm",
    "25813": "Husum, Schwesing u.a.",
    "25821": "Bredstedt, Breklum u.a.",
    "25826": "Sankt Peter-Ording",
    "25832": "Tönning",
    "25836": "Garding, Osterhever, Poppenbüll u.a.",
    "25840": "Friedrichstadt, Koldenbüttel u.a.",
    "25842": "Langenhorn, Ockholm u.a.",
    "25845": "Nordstrand, Elisabeth-Sophien-Koog, Südfall",
    "25849": "Süderoog, Pellworm",
    "25850": "Behrendorf, Bondelum",
    "25852": "Bordelum",
    "25853": "Ahrenshöft",
    "25855": "Haselund",
    "25856": "Hattstedt u.a.",
    "25858": "Högel",
    "25859": "Hallig Hooge",
    "25860": "Horstedt",
    "25862": "Joldelund",
    "25863": "Langeneß",
    "25864": "Löwenstedt",
    "25866": "Mildstedt",
    "25867": "Oland",
    "25868": "Norderstapel",
    "25869": "Habel, Gröde",
    "25870": "Oldenswort",
    "25872": "Ostenfeld",
    "25873": "Rantrum",
    "25876": "Schwabstedt",
    "25878": "Drage, Seeth",
    "25879": "Süderstapel",
    "25881": "Tating, Westerhever, Tümlauer Koog",
    "25882": "Tetenbüll",
    "25884": "Viöl",
    "25885": "Wester-Ohrstedt",
    "25887": "Winnert",
    "25889": "Uelvesbüll, Witzwort",
    "25899": "Niebüll",
    "25917": "Leck",
    "25920": "Risum-Lindholm, Stedesand",
    "25923": "Süderlügum, Braderup u.a.",
    "25924": "Rodenäs",
    "25926": "Ladelund",
    "25927": "Neukirchen, Aventoft",
    "25938": "Föhr",
    "25946": "Amrum",
    "25980": "Sylt",
    "25992": "List",
    "25996": "Wenningstedt-Braderup (Sylt)",
    "25997": "Hörnum (Sylt)",
    "25999": "Kampen (Sylt)",
    "26121": "Oldenburg (Oldenburg)",
    "26122": "Oldenburg",
    "26123": "Oldenburg (Oldenburg)",
    "26125": "Oldenburg (Oldenburg)",
    "26127": "Oldenburg (Oldenburg)",
    "26129": "Oldenburg (Oldenburg)",
    "26131": "Oldenburg (Oldenburg)",
    "26133": "Oldenburg (Oldenburg)",
    "26135": "Oldenburg (Oldenburg)",
    "26160": "Bad Zwischenahn",
    "26169": "Friesoythe",
    "26180": "Rastede",
    "26188": "Edewecht",
    "26197": "Großenkneten",
    "26203": "Wardenburg",
    "26209": "Hatten",
    "26215": "Wiefelstede",
    "26219": "Bösel",
    "26316": "Varel",
    "26340": "Zetel",
    "26345": "Bockhorn",
    "26349": "Jade",
    "26382": "Wilhelmshaven",
    "26384": "Wilhelmshaven",
    "26386": "Wilhelmshaven",
    "26388": "Wilhelmshaven",
    "26389": "Wilhelmshaven",
    "26409": "Wittmund",
    "26419": "Schortens",
    "26427": "Esens, Neuharlingersiel u.a.",
    "26434": "Wangerland",
    "26441": "Jever",
    "26446": "Friedeburg",
    "26452": "Sande",
    "26465": "Langeoog",
    "26474": "Spiekeroog",
    "26486": "Wangerooge",
    "26487": "Blomberg, Neuschoo",
    "26489": "Ochtersum",
    "26506": "Norden",
    "26524": "Hage, Halbemond u.a.",
    "26529": "Upgant-Schott, Osteel u.a.",
    "26532": "Großheide",
    "26548": "Norderney",
    "26553": "Dornum",
    "26556": "Westerholt, Schweindorf u.a.",
    "26571": "Juist, Memmert",
    "26579": "Baltrum",
    "26603": "Aurich",
    "26605": "Aurich",
    "26607": "Aurich",
    "26624": "Südbrookmerland",
    "26629": "Großefehn",
    "26632": "Ihlow",
    "26639": "Wiesmoor",
    "26655": "Westerstede",
    "26670": "Uplengen",
    "26676": "Barßel",
    "26683": "Saterland",
    "26689": "Apen",
    "26721": "Emden",
    "26723": "Emden",
    "26725": "Emden",
    "26736": "Krummhörn",
    "26757": "Borkum",
    "26759": "Hinte",
    "26789": "Leer",
    "26802": "Moormerland",
    "26810": "Westoverledingen",
    "26817": "Rhauderfehn",
    "26826": "Weener",
    "26831": "Bunde",
    "26835": "Hesel, Neukamperfehn u.a.",
    "26842": "Ostrhauderfehn",
    "26844": "Jemgum",
    "26845": "Nortmoor",
    "26847": "Detern",
    "26849": "Filsum",
    "26871": "Papenburg",
    "26892": "Dörpen, Lehe, u.a.",
    "26897": "Esterwegen u.a.",
    "26899": "Rhede (Ems)",
    "26901": "Lorup, Rastdorf",
    "26903": "Surwold",
    "26904": "Börger",
    "26906": "Dersum",
    "26907": "Walchum",
    "26909": "Neubörger, Neulehe",
    "26919": "Brake",
    "26931": "Elsfleth",
    "26935": "Stadland",
    "26936": "Stadland",
    "26937": "Stadland",
    "26939": "Ovelgönne",
    "26954": "Nordenham",
    "26969": "Butjadingen",
    "27211": "Bassum",
    "27232": "Sulingen",
    "27239": "Twistringen",
    "27243": "Harpstedt, Groß Ippener, Colnrade u.a.",
    "27245": "Bahrenborstel, Barenburg, Kirchdorf",
    "27246": "Borstel",
    "27248": "Ehrenburg",
    "27249": "Maasen, Mellinghausen",
    "27251": "Neuenkirchen, Scholen",
    "27252": "Schwaförden",
    "27254": "Siedenburg, Staffhorst",
    "27257": "Affinghausen und Sudwalde",
    "27259": "Freistatt, Varrel, Wehrbleck",
    "27283": "Verden",
    "27299": "Langwedel",
    "27305": "Bruchhausen-Vilsen, Süstedt",
    "27308": "Kirchlinteln",
    "27313": "Dörverden",
    "27318": "Hoya, Hoyerhagen, Hilgermissen",
    "27321": "Thedinghausen, Emtinghausen",
    "27324": "Eystrup, Hassel u.a.",
    "27327": "Martfeld, Schwarme",
    "27330": "Asendorf",
    "27333": "Schweringen, Warpe, Bücken",
    "27336": "Rethem (Aller), Häuslingen, Frankenfeld",
    "27337": "Blender",
    "27339": "Riede",
    "27356": "Rotenburg",
    "27367": "Sottrum, Reeßum, Bötersen u.a.",
    "27374": "Visselhövede",
    "27383": "Scheeßel",
    "27386": "Bothel, Kirchwalsede u.a.",
    "27389": "Fintel, Lauenbrück u.a.",
    "27404": "Zeven, Elsdorf",
    "27412": "Tarmstedt, Breddorf u.a.",
    "27419": "Sittensen u.a.",
    "27432": "Bremervörde",
    "27442": "Gnarrenburg",
    "27446": "Selsingen",
    "27449": "Kutenholz",
    "27472": "Cuxhaven",
    "27474": "Cuxhaven",
    "27476": "Cuxhaven",
    "27478": "Cuxhaven",
    "27498": "Helgoland",
    "27499": "Neuwerk",
    "27568": "Bremerhaven",
    "27570": "Bremerhaven",
    "27572": "Bremerhaven",
    "27574": "Bremerhaven",
    "27576": "Bremerhaven",
    "27578": "Bremerhaven",
    "27580": "Bremerhaven",
    "27607": "Geestland",
    "27612": "Loxstedt",
    "27616": "Beverstedt",
    "27619": "Schiffdorf",
    "27624": "Geestland",
    "27628": "Hagen",
    "27639": "Wurster Nordseeküste",
    "27711": "Osterholz-Scharmbeck",
    "27721": "Ritterhude",
    "27726": "Worpswede",
    "27729": "Hambergen, Holste u.a.",
    "27749": "Delmenhorst",
    "27751": "Delmenhorst",
    "27753": "Delmenhorst",
    "27755": "Delmenhorst",
    "27777": "Ganderkesee",
    "27793": "Wildeshausen",
    "27798": "Hude (Oldenburg)",
    "27801": "Dötlingen",
    "27804": "Berne",
    "27809": "Lemwerder",
    "28195": "Bremen",
    "28197": "Bremen",
    "28199": "Bremen",
    "28201": "Bremen",
    "28203": "Bremen",
    "28205": "Bremen",
    "28207": "Bremen",
    "28209": "Bremen",
    "28211": "Bremen",
    "28213": "Bremen",
    "28215": "Bremen",
    "28217": "Bremen",
    "28219": "Bremen",
    "28237": "Bremen",
    "28239": "Bremen",
    "28259": "Bremen",
    "28277": "Bremen",
    "28279": "Bremen",
    "28307": "Bremen",
    "28309": "Bremen",
    "28325": "Bremen",
    "28327": "Bremen",
    "28329": "Bremen",
    "28355": "Bremen",
    "28357": "Bremen",
    "28359": "Bremen",
    "28717": "Bremen",
    "28719": "Bremen",
    "28755": "Bremen",
    "28757": "Bremen",
    "28759": "Bremen",
    "28777": "Bremen",
    "28779": "Bremen",
    "28790": "Schwanewede",
    "28816": "Stuhr",
    "28832": "Achim",
    "28844": "Weyhe",
    "28857": "Syke",
    "28865": "Lilienthal",
    "28870": "Ottersberg",
    "28876": "Oyten",
    "28879": "Grasberg",
    "29221": "Celle",
    "29223": "Celle",
    "29225": "Celle",
    "29227": "Celle",
    "29229": "Celle, Wittbeck",
    "29303": "Bergen, Lohheide u.a.",
    "29308": "Winsen (Aller)",
    "29313": "Hambühren",
    "29320": "Hermannsburg",
    "29323": "Wietze",
    "29328": "Faßberg",
    "29331": "Lachendorf",
    "29336": "Nienhagen",
    "29339": "Wathlingen",
    "29342": "Wienhausen",
    "29345": "Unterlüß",
    "29348": "Eschede",
    "29351": "Eldingen",
    "29352": "Adelheidsdorf",
    "29353": "Ahnsbeck",
    "29355": "Beedenbostel",
    "29356": "Bröckel",
    "29358": "Eicklingen",
    "29359": "Habighorst",
    "29361": "Höfer",
    "29362": "Hohne",
    "29364": "Langlingen",
    "29365": "Sprakensehl",
    "29367": "Steinhorst",
    "29369": "Ummern",
    "29378": "Wittingen",
    "29379": "Wittingen",
    "29386": "Hankensbüttel, Obernholz, Dedelstorf",
    "29389": "Bad Bodenteich",
    "29392": "Wesendorf",
    "29393": "Groß Oesingen",
    "29394": "Lüder",
    "29396": "Schönewörde",
    "29399": "Wahrenholz",
    "29410": "Salzwedel",
    "29413": "Dähre, Diesdorf, Wallstawe",
    "29416": "Kuhfelde",
    "29439": "Lüchow",
    "29451": "Dannenberg",
    "29456": "Hitzacker (Elbe)",
    "29459": "Clenze",
    "29462": "Wustrow",
    "29465": "Schnega",
    "29468": "Bergen",
    "29471": "Gartow",
    "29472": "Damnatz",
    "29473": "Göhrde",
    "29475": "Gorleben",
    "29476": "Gusborn",
    "29478": "Höhbeck",
    "29479": "Jameln",
    "29481": "Karwitz",
    "29482": "Küsten",
    "29484": "Langendorf",
    "29485": "Lemgow",
    "29487": "Luckau (Wendland)",
    "29488": "Lübbow",
    "29490": "Neu Darchau",
    "29491": "Prezelle",
    "29493": "Schnackenburg",
    "29494": "Trebel",
    "29496": "Waddeweitz",
    "29497": "Woltersdorf",
    "29499": "Zernien",
    "29525": "Uelzen",
    "29549": "Bad Bevensen",
    "29553": "Bienenbüttel",
    "29556": "Suderburg",
    "29559": "Wrestedt",
    "29562": "Suhlendorf",
    "29565": "Wriedel",
    "29571": "Rosche",
    "29574": "Ebstorf",
    "29575": "Altenmedingen",
    "29576": "Barum",
    "29578": "Eimke",
    "29579": "Emmendorf",
    "29581": "Gerdau",
    "29582": "Hanstedt",
    "29584": "Himbergen",
    "29585": "Jelmstorf",
    "29587": "Natendorf",
    "29588": "Oetzen",
    "29590": "Rätzlingen",
    "29591": "Römstedt",
    "29593": "Schwienau",
    "29594": "Soltendiek",
    "29597": "Stoetze",
    "29599": "Weste",
    "29614": "Soltau",
    "29633": "Munster",
    "29640": "Schneverdingen, Heimbuch",
    "29643": "Neuenkirchen",
    "29646": "Bispingen",
    "29649": "Wietzendorf",
    "29664": "Walsrode, Ostenholz",
    "29683": "Bad Fallingbostel, Osterheide",
    "29690": "Schwarmstedt u.a.",
    "29693": "Hodenhagen u.a.",
    "29699": "Bomlitz",
    "30159": "Hannover",
    "30161": "Hannover",
    "30163": "Hannover",
    "30165": "Hannover",
    "30167": "Hannover",
    "30169": "Hannover",
    "30171": "Hannover",
    "30173": "Hannover",
    "30175": "Hannover",
    "30177": "Hannover",
    "30179": "Hannover",
    "30419": "Hannover",
    "30449": "Hannover",
    "30451": "Hannover",
    "30453": "Hannover",
    "30455": "Hannover",
    "30457": "Hannover",
    "30459": "Hannover",
    "30519": "Hannover",
    "30521": "Hannover",
    "30539": "Hannover",
    "30559": "Hannover",
    "30625": "Hannover",
    "30627": "Hannover",
    "30629": "Hannover",
    "30655": "Hannover",
    "30657": "Hannover",
    "30659": "Hannover",
    "30669": "Langenhagen (Flughafen)",
    "30823": "Garbsen",
    "30826": "Garbsen",
    "30827": "Garbsen",
    "30851": "Langenhagen",
    "30853": "Langenhagen",
    "30855": "Langenhagen",
    "30880": "Laatzen",
    "30890": "Barsinghausen",
    "30900": "Wedemark",
    "30916": "Isernhagen",
    "30926": "Seelze",
    "30938": "Burgwedel",
    "30952": "Ronnenberg",
    "30966": "Hemmingen",
    "30974": "Wennigsen (Deister)",
    "30982": "Pattensen",
    "30989": "Gehrden",
    "31008": "Elze",
    "31020": "Salzhemmendorf",
    "31028": "Gronau (Leine)",
    "31029": "Banteln",
    "31032": "Betheln",
    "31033": "Brüggen",
    "31035": "Despetal",
    "31036": "Eime",
    "31039": "Rheden",
    "31061": "Alfeld (Leine)",
    "31073": "Grünenplan, Delligsen",
    "31079": "Eberholzen, Sibbesse, Westfeld, Almstedt, Adenstedt",
    "31084": "Freden (Leine)",
    "31085": "Everode",
    "31087": "Landwehr",
    "31088": "Winzenburg",
    "31089": "Duingen",
    "31091": "Coppengrave",
    "31093": "Hoyershausen",
    "31094": "Marienhagen",
    "31096": "Weenzen",
    "31097": "Harbarnsen",
    "31099": "Woltershausen",
    "31134": "Hildesheim",
    "31135": "Hildesheim",
    "31137": "Hildesheim",
    "31139": "Hildesheim",
    "31141": "Hildesheim",
    "31157": "Sarstedt",
    "31162": "Bad Salzdetfurth",
    "31167": "Bockenem",
    "31171": "Nordstemmen",
    "31174": "Schellerten",
    "31177": "Harsum",
    "31180": "Giesen",
    "31185": "Söhlde",
    "31188": "Holle",
    "31191": "Algermissen",
    "31195": "Lamspringe, Neuhof",
    "31196": "Sehlem",
    "31199": "Diekholzen",
    "31224": "Peine",
    "31226": "Peine",
    "31228": "Peine",
    "31234": "Edemissen",
    "31241": "Ilsede",
    "31246": "Lahstedt",
    "31249": "Hohenhameln",
    "31275": "Lehrte",
    "31303": "Burgdorf",
    "31311": "Uetze",
    "31319": "Sehnde",
    "31515": "Wunstorf",
    "31535": "Neustadt am Rübenberge",
    "31542": "Bad Nenndorf",
    "31547": "Rehburg-Loccum",
    "31552": "Apelern, Rodenberg",
    "31553": "Auhagen, Sachsenhagen",
    "31555": "Suthfeld",
    "31556": "Wölpinghausen",
    "31558": "Hagenburg",
    "31559": "Haste, Hohnhorst",
    "31582": "Nienburg/Weser",
    "31592": "Stolzenau",
    "31595": "Steyerberg",
    "31600": "Uchte",
    "31603": "Diepenau",
    "31604": "Raddestorf",
    "31606": "Warmsen",
    "31608": "Marklohe",
    "31609": "Balge",
    "31613": "Wietzen",
    "31618": "Liebenau",
    "31619": "Binnen",
    "31621": "Pennigsehl",
    "31622": "Heemsen",
    "31623": "Drakenburg",
    "31626": "Haßbergen",
    "31627": "Rohrsen",
    "31628": "Landesbergen",
    "31629": "Estorf",
    "31632": "Husum",
    "31633": "Leese",
    "31634": "Steimbke",
    "31636": "Linsburg",
    "31637": "Rodewald",
    "31638": "Stöckse",
    "31655": "Stadthagen",
    "31675": "Bückeburg",
    "31683": "Obernkirchen",
    "31688": "Nienstädt",
    "31691": "Helpsen, Seggebruch",
    "31693": "Hespe",
    "31698": "Lindhorst",
    "31699": "Beckedorf",
    "31700": "Heuerßen",
    "31702": "Lüdersfeld",
    "31707": "Heeßen, Bad Eilsen",
    "31708": "Ahnsen",
    "31710": "Buchholz",
    "31711": "Luhden",
    "31712": "Niederwöhren",
    "31714": "Lauenhagen",
    "31715": "Meerbeck",
    "31717": "Nordsehl",
    "31718": "Pollhagen",
    "31719": "Wiedensahl",
    "31737": "Rinteln",
    "31749": "Auetal",
    "31785": "Hameln",
    "31787": "Hameln",
    "31789": "Hameln",
    "31812": "Bad Pyrmont",
    "31832": "Springe",
    "31840": "Hessisch Oldendorf",
    "31848": "Bad Münder am Deister",
    "31855": "Aerzen",
    "31860": "Emmerthal",
    "31863": "Coppenbrügge",
    "31867": "Pohle, Lauenau, Messenkamp, Hülsede etc",
    "31868": "Ottenstein",
    "32049": "Herford",
    "32051": "Herford",
    "32052": "Herford",
    "32105": "Bad Salzuflen",
    "32107": "Bad Salzuflen",
    "32108": "Bad Salzuflen",
    "32120": "Hiddenhausen",
    "32130": "Enger",
    "32139": "Spenge",
    "32257": "Bünde",
    "32278": "Kirchlengern",
    "32289": "Rödinghausen",
    "32312": "Lübbecke",
    "32339": "Espelkamp",
    "32351": "Stemwede",
    "32361": "Preußisch Oldendorf",
    "32369": "Rahden",
    "32423": "Minden",
    "32425": "Minden",
    "32427": "Minden",
    "32429": "Minden",
    "32457": "Porta Westfalica",
    "32469": "Petershagen",
    "32479": "Hille",
    "32545": "Bad Oeynhausen",
    "32547": "Bad Oeynhausen",
    "32549": "Bad Oeynhausen",
    "32584": "Löhne",
    "32602": "Vlotho",
    "32609": "Hüllhorst",
    "32657": "Lemgo",
    "32676": "Lügde",
    "32683": "Barntrup",
    "32689": "Kalletal",
    "32694": "Dörentrup",
    "32699": "Extertal",
    "32756": "Detmold",
    "32758": "Detmold",
    "32760": "Detmold",
    "32791": "Lage",
    "32805": "Horn-Bad Meinberg",
    "32816": "Schieder-Schwalenberg",
    "32825": "Blomberg",
    "32832": "Augustdorf",
    "32839": "Steinheim",
    "33014": "Bad Driburg",
    "33034": "Brakel",
    "33039": "Nieheim",
    "33098": "Paderborn",
    "33100": "Paderborn",
    "33102": "Paderborn",
    "33104": "Paderborn",
    "33106": "Paderborn",
    "33129": "Delbrück",
    "33142": "Büren",
    "33154": "Salzkotten",
    "33161": "Hövelhof",
    "33165": "Lichtenau",
    "33175": "Bad Lippspringe",
    "33178": "Borchen",
    "33181": "Wünnenberg",
    "33184": "Altenbeken",
    "33189": "Schlangen",
    "33330": "Gütersloh",
    "33332": "Gütersloh",
    "33333": "Bertelsmann",
    "33334": "Gütersloh",
    "33335": "Gütersloh",
    "33378": "Rheda-Wiedenbrück",
    "33397": "Rietberg",
    "33415": "Verl",
    "33428": "Harsewinkel",
    "33442": "Herzebrock-Clarholz",
    "33449": "Langenberg",
    "33602": "Bielefeld",
    "33604": "Bielefeld",
    "33605": "Bielefeld",
    "33607": "Bielefeld",
    "33609": "Bielefeld",
    "33611": "Bielefeld",
    "33613": "Bielefeld",
    "33615": "Bielefeld",
    "33617": "Bielefeld",
    "33619": "Bielefeld",
    "33647": "Bielefeld",
    "33649": "Bielefeld",
    "33659": "Bielefeld",
    "33689": "Bielefeld",
    "33699": "Bielefeld",
    "33719": "Bielefeld",
    "33729": "Bielefeld",
    "33739": "Bielefeld",
    "33758": "Schloß Holte-Stukenbrock",
    "33775": "Versmold",
    "33790": "Halle (Westfalen)",
    "33803": "Steinhagen",
    "33813": "Oerlinghausen",
    "33818": "Leopoldshöhe",
    "33824": "Werther (Westf.)",
    "33829": "Borgholzhausen",
    "34117": "Kassel",
    "34119": "Kassel",
    "34121": "Kassel",
    "34123": "Kassel",
    "34125": "Kassel",
    "34127": "Kassel",
    "34128": "Kassel",
    "34130": "Kassel",
    "34131": "Kassel",
    "34132": "Kassel",
    "34134": "Kassel",
    "34212": "Melsungen",
    "34225": "Baunatal",
    "34233": "Kassel, Fuldatal",
    "34246": "Vellmar",
    "34253": "Lohfelden",
    "34260": "Kaufungen",
    "34266": "Niestetal",
    "34270": "Schauenburg",
    "34277": "Fuldabrück",
    "34281": "Gudensberg",
    "34286": "Spangenberg",
    "34289": "Zierenberg",
    "34292": "Ahnatal",
    "34295": "Edermünde",
    "34298": "Helsa",
    "34302": "Guxhagen",
    "34305": "Niedenstein",
    "34308": "Bad Emstal",
    "34311": "Naumburg",
    "34314": "Espenau",
    "34317": "Habichtswald",
    "34320": "Söhrewald",
    "34323": "Malsfeld",
    "34326": "Morschen",
    "34327": "Körle",
    "34329": "Nieste",
    "34346": "Hann. Münden, Gutsbezirk Reinhardswald",
    "34355": "Staufenberg",
    "34359": "Reinhardshagen",
    "34369": "Hofgeismar",
    "34376": "Immenhausen",
    "34379": "Calden",
    "34385": "Bad Karlshafen",
    "34388": "Trendelburg",
    "34393": "Grebenstein",
    "34396": "Liebenau (Hessen)",
    "34399": "Oberweser",
    "34414": "Warburg",
    "34431": "Marsberg",
    "34434": "Borgentreich",
    "34439": "Willebadessen",
    "34454": "Bad Arolsen",
    "34466": "Wolfhagen",
    "34471": "Volkmarsen",
    "34474": "Diemelstadt",
    "34477": "Twistetal",
    "34479": "Breuna",
    "34497": "Korbach",
    "34508": "Willingen (Upland)",
    "34513": "Waldeck",
    "34516": "Vöhl",
    "34519": "Diemelsee",
    "34537": "Bad Wildungen",
    "34549": "Edertal",
    "34560": "Fritzlar",
    "34576": "Homberg",
    "34582": "Borken",
    "34587": "Felsberg",
    "34590": "Wabern",
    "34593": "Knüllwald",
    "34596": "Bad Zwesten",
    "34599": "Neuental",
    "34613": "Schwalmstadt",
    "34621": "Frielendorf",
    "34626": "Neukirchen (Knüll)",
    "34628": "Willingshausen",
    "34630": "Gilserberg",
    "34632": "Jesberg",
    "34633": "Ottrau",
    "34637": "Schrecksbach",
    "34639": "Schwarzenborn",
    "35037": "Marburg",
    "35039": "Marburg",
    "35041": "Marburg",
    "35043": "Marburg",
    "35066": "Frankenberg",
    "35075": "Gladenbach",
    "35080": "Bad Endbach",
    "35083": "Wetter",
    "35085": "Ebsdorfergrund",
    "35088": "Battenberg",
    "35091": "Cölbe",
    "35094": "Lahntal",
    "35096": "Weimar (Lahn)",
    "35099": "Burgwald",
    "35102": "Lohra",
    "35104": "Lichtenfels",
    "35108": "Allendorf",
    "35110": "Frankenau",
    "35112": "Fronhausen",
    "35114": "Haina",
    "35116": "Hatzfeld",
    "35117": "Münchhausen",
    "35119": "Rosenthal",
    "35216": "Biedenkopf",
    "35232": "Dautphetal",
    "35236": "Breidenbach",
    "35239": "Steffenberg",
    "35260": "Stadtallendorf",
    "35274": "Kirchhain",
    "35279": "Neustadt",
    "35282": "Rauschenberg",
    "35285": "Gemünden",
    "35287": "Amöneburg",
    "35288": "Wohratal",
    "35305": "Grünberg",
    "35315": "Homberg (Ohm)",
    "35321": "Laubach",
    "35325": "Mücke",
    "35327": "Ulrichstein",
    "35329": "Gemünden (Felda)",
    "35390": "Gießen",
    "35392": "Gießen",
    "35394": "Gießen",
    "35396": "Gießen",
    "35398": "Gießen",
    "35410": "Hungen",
    "35415": "Pohlheim",
    "35418": "Buseck",
    "35423": "Lich",
    "35428": "Langgöns",
    "35435": "Wettenberg",
    "35440": "Linden",
    "35444": "Biebertal",
    "35447": "Reiskirchen",
    "35452": "Heuchelheim",
    "35457": "Lollar",
    "35460": "Staufenberg",
    "35463": "Fernwald",
    "35466": "Rabenau",
    "35469": "Allendorf",
    "35510": "Butzbach",
    "35516": "Münzenberg",
    "35519": "Rockenberg",
    "35576": "Wetzlar",
    "35578": "Wetzlar",
    "35579": "Wetzlar",
    "35580": "Wetzlar",
    "35581": "Wetzlar",
    "35582": "Wetzlar",
    "35583": "Wetzlar Garbeinheim",
    "35584": "Wetzlar",
    "35585": "Wetzlar",
    "35586": "Wetzlar",
    "35606": "Solms",
    "35614": "Aßlar",
    "35619": "Braunfels",
    "35625": "Hüttenberg",
    "35630": "Ehringshausen",
    "35633": "Lahnau",
    "35638": "Leun",
    "35641": "Schöffengrund",
    "35644": "Hohenahr",
    "35647": "Waldsolms",
    "35649": "Bischoffen",
    "35683": "Dillenburg",
    "35684": "Dillenburg",
    "35685": "Dillenburg",
    "35686": "Dillenburg",
    "35687": "Dillenburg",
    "35688": "Dillenburg",
    "35689": "Dillenburg",
    "35690": "Dillenburg",
    "35708": "Haiger",
    "35713": "Eschenburg",
    "35716": "Dietzhölztal",
    "35719": "Angelburg",
    "35745": "Herborn",
    "35753": "Greifenstein",
    "35756": "Mittenaar",
    "35759": "Driedorf",
    "35764": "Sinn",
    "35767": "Breitscheid",
    "35768": "Siegbach",
    "35781": "Weilburg",
    "35789": "Weilmünster",
    "35792": "Löhnberg",
    "35794": "Mengerskirchen",
    "35796": "Weinbach",
    "35799": "Merenberg",
    "36037": "Fulda",
    "36039": "Fulda",
    "36041": "Fulda",
    "36043": "Fulda",
    "36088": "Hünfeld",
    "36093": "Künzell",
    "36100": "Petersberg",
    "36103": "Flieden",
    "36110": "Schlitz",
    "36115": "Hilders, Ehrenberg",
    "36119": "Neuhof",
    "36124": "Eichenzell",
    "36129": "Gersfeld",
    "36132": "Eiterfeld",
    "36137": "Großenlüder",
    "36142": "Tann",
    "36145": "Hofbieber",
    "36148": "Kalbach",
    "36151": "Burghaun",
    "36154": "Hosenfeld",
    "36157": "Ebersburg",
    "36160": "Dipperz",
    "36163": "Poppenhausen",
    "36166": "Haunetal",
    "36167": "Nüsttal",
    "36169": "Rasdorf",
    "36179": "Bebra",
    "36199": "Rotenburg an der Fulda",
    "36205": "Sontra",
    "36208": "Wildeck",
    "36211": "Alheim",
    "36214": "Nentershausen",
    "36217": "Ronshausen",
    "36219": "Cornberg",
    "36251": "Bad Hersfeld, Ludwigsau",
    "36266": "Heringen",
    "36269": "Philippsthal",
    "36272": "Niederaula",
    "36275": "Kirchheim (Hessen)",
    "36277": "Schenklengsfeld",
    "36280": "Oberaula",
    "36282": "Hauneck",
    "36284": "Hohenroda",
    "36286": "Neuenstein (Hessen)",
    "36287": "Breitenbach am Herzberg",
    "36289": "Friedewald",
    "36304": "Alsfeld",
    "36318": "Schwalmtal",
    "36320": "Kirtorf",
    "36323": "Grebenau",
    "36325": "Feldatal",
    "36326": "Antrifttal",
    "36329": "Romrod",
    "36341": "Lauterbach",
    "36355": "Grebenhain",
    "36358": "Herbstein",
    "36364": "Bad Salzschlirf",
    "36367": "Wartenberg",
    "36369": "Lautertal",
    "36381": "Schlüchtern",
    "36391": "Sinntal",
    "36396": "Steinau an der Straße",
    "36399": "Freiensteinau",
    "36404": "Vacha, Unterbreizbach",
    "36414": "Unterbreizbach",
    "36419": "Geisa",
    "36433": "Bad Salzungen",
    "36448": "Bad Liebenstein",
    "36452": "Kaltennordheim",
    "36456": "Barchfeld-Immelborn",
    "36457": "Stadtlengsfeld, Weilar, Urnshausen",
    "36460": "Krayenberggemeinde, Frauensee",
    "36466": "Dermbach, Wiesenthal",
    "36469": "Tiefenort",
    "37073": "Göttingen",
    "37075": "Göttingen",
    "37077": "Göttingen",
    "37079": "Göttingen",
    "37081": "Göttingen",
    "37083": "Göttingen",
    "37085": "Göttingen",
    "37115": "Duderstadt",
    "37120": "Bovenden",
    "37124": "Rosdorf",
    "37127": "Dransfeld u.a.",
    "37130": "Gleichen",
    "37133": "Friedland",
    "37136": "Seulingen, Waake u.a.",
    "37139": "Adelebsen",
    "37154": "Northeim",
    "37170": "Uslar",
    "37176": "Nörten-Hardenberg",
    "37181": "Hardegsen",
    "37186": "Moringen",
    "37191": "Katlenburg-Lindau",
    "37194": "Bodenfelde, Wahlsburg",
    "37197": "Hattorf",
    "37199": "Wulften",
    "37213": "Witzenhausen",
    "37214": "Witzenhausen",
    "37215": "Witzenhausen",
    "37216": "Witzenhausen, Gutsbezirk",
    "37217": "Witzenhausen",
    "37218": "Witzenhausen",
    "37235": "Hessisch Lichtenau",
    "37242": "Bad Sooden-Allendorf",
    "37247": "Großalmerode",
    "37249": "Neu-Eichenberg",
    "37269": "Eschwege",
    "37276": "Meinhard",
    "37281": "Wanfried",
    "37284": "Waldkappel",
    "37287": "Wehretal",
    "37290": "Meißner",
    "37293": "Herleshausen",
    "37296": "Ringgau",
    "37297": "Berkatal",
    "37299": "Weißenborn",
    "37308": "Heiligenstadt",
    "37318": "Arenshausen, Uder u.a.",
    "37327": "Leinefelde-Worbis, Wingerode, Hausen",
    "37339": "Worbis",
    "37345": "Am Ohmberg, Sonnenstein",
    "37351": "Dingelstädt",
    "37355": "Niederorschel u.a.",
    "37359": "Küllstedt",
    "37412": "Herzberg, Elbingerode, Hörden",
    "37431": "Bad Lauterberg",
    "37434": "Gieboldehausen, Rhumequelle",
    "37441": "Bad Sachsa",
    "37444": "Braunlage",
    "37445": "Walkenried",
    "37447": "Wieda",
    "37449": "Zorge",
    "37520": "Osterode am Harz",
    "37539": "Bad Grund",
    "37574": "Einbeck, Kreiensen",
    "37581": "Bad Gandersheim",
    "37586": "Dassel",
    "37589": "Kalefeld",
    "37603": "Holzminden",
    "37619": "Bodenwerder, Pegestorf, Kirchbrak, Hehlen",
    "37620": "Halle",
    "37627": "Stadtoldendorf u.a.",
    "37632": "Holzen, Eschershausen, Eimen",
    "37633": "Dielmissen",
    "37635": "Lüerdissen",
    "37639": "Bevern",
    "37640": "Golmbach",
    "37642": "Holenberg",
    "37643": "Negenborn",
    "37647": "Brevörde, Polle, Vahlbruch",
    "37649": "Heinsen",
    "37671": "Höxter",
    "37688": "Beverungen",
    "37691": "Boffzen, Derental",
    "37696": "Marienmünster",
    "37697": "Lauenförde",
    "37699": "Fürstenberg",
    "38100": "Braunschweig",
    "38102": "Braunschweig",
    "38104": "Braunschweig",
    "38106": "Braunschweig",
    "38108": "Braunschweig",
    "38110": "Braunschweig",
    "38112": "Braunschweig",
    "38114": "Braunschweig",
    "38116": "Braunschweig",
    "38118": "Braunschweig",
    "38120": "Braunschweig",
    "38122": "Braunschweig",
    "38124": "Braunschweig",
    "38126": "Braunschweig",
    "38154": "Königslutter am Elm",
    "38159": "Vechelde",
    "38162": "Cremlingen",
    "38165": "Lehre",
    "38170": "Schöppenstedt",
    "38173": "Sickte, Dettum u.a.",
    "38176": "Wendeburg",
    "38179": "Schwülper",
    "38226": "Salzgitter",
    "38228": "Salzgitter",
    "38229": "Salzgitter",
    "38239": "Salzgitter",
    "38259": "Salzgitter",
    "38268": "Lengede",
    "38271": "Baddeckenstedt",
    "38272": "Burgdorf",
    "38274": "Elbe",
    "38275": "Haverlah",
    "38277": "Heere",
    "38279": "Sehlde",
    "38300": "Wolfenbüttel",
    "38302": "Wolfenbüttel",
    "38304": "Wolfenbüttel",
    "38312": "Dorstadt, Flöthe, Börßum u.a.",
    "38315": "Schladen-Werla",
    "38319": "Remlingen",
    "38321": "Denkte",
    "38322": "Hedeper",
    "38324": "Kissenbrück",
    "38325": "Roklum",
    "38327": "Semmenstedt",
    "38329": "Wittmar",
    "38350": "Helmstedt",
    "38364": "Schöningen",
    "38368": "Rennau, Querenhorst, Mariental, Grasleben",
    "38372": "Büddenstedt",
    "38373": "Süpplingen, Frellstedt",
    "38375": "Räbke",
    "38376": "Süpplingenburg",
    "38378": "Warberg",
    "38379": "Wolsdorf",
    "38381": "Jerxheim",
    "38382": "Beierstedt",
    "38384": "Gevensleben",
    "38385": "Ingeleben",
    "38387": "Söllingen",
    "38388": "Twieflingen",
    "38440": "Wolfsburg",
    "38442": "Wolfsburg",
    "38444": "Wolfsburg",
    "38446": "Wolfsburg",
    "38448": "Wolfsburg",
    "38458": "Velpke",
    "38459": "Bahrdorf",
    "38461": "Danndorf",
    "38462": "Grafhorst",
    "38464": "Groß Twülpstedt",
    "38465": "Brome",
    "38467": "Bergfeld",
    "38468": "Ehra-Lessien",
    "38470": "Parsau",
    "38471": "Rühen",
    "38473": "Tiddische",
    "38474": "Tülau",
    "38476": "Barwedel",
    "38477": "Jembke",
    "38479": "Tappenbeck",
    "38486": "Klötze, Apenburg-Winterfeld",
    "38489": "Beetzendorf, Rohrberg, Jübar",
    "38518": "Gifhorn",
    "38524": "Sassenburg",
    "38527": "Meine",
    "38528": "Adenbüttel",
    "38530": "Didderse",
    "38531": "Rötgesbüttel",
    "38533": "Vordorf",
    "38536": "Meinersen",
    "38539": "Müden (Aller)",
    "38542": "Leiferde",
    "38543": "Hillerse",
    "38547": "Calberlah",
    "38550": "Isenbüttel",
    "38551": "Ribbesbüttel",
    "38553": "Wasbüttel",
    "38554": "Weyhausen",
    "38556": "Bokensdorf",
    "38557": "Osloß",
    "38559": "Wagenhoff, Ringelah",
    "38640": "Goslar",
    "38642": "Goslar",
    "38644": "Goslar",
    "38667": "Bad Harzburg, Torfhaus",
    "38678": "Clausthal-Zellerfeld, Oberschulenberg",
    "38685": "Langelsheim",
    "38690": "Goslar",
    "38700": "Braunlage",
    "38704": "Liebenburg",
    "38707": "Altenau, Schulenberg",
    "38709": "Wildemann",
    "38723": "Seesen",
    "38729": "Hahausen, Lutter, Wallmoden",
    "38820": "Halberstadt",
    "38822": "Halberstadt, Groß Quenstedt",
    "38828": "Wegeleben",
    "38829": "Harsleben",
    "38835": "Osterwieck",
    "38836": "Badersleben u.a.",
    "38838": "Huy",
    "38855": "Wernigerode, Nordharz",
    "38871": "Ilsenburg, Nordharz",
    "38875": "Oberharz am Brocken",
    "38877": "Oberharz am Brocken",
    "38879": "Wernigerode",
    "38889": "Blankenburg, Oberharz am Brocken",
    "38895": "Langenstein, Derenburg",
    "38899": "Oberharz am Brocken",
    "39104": "Magdeburg",
    "39106": "Magdeburg",
    "39108": "Magdeburg",
    "39110": "Magdeburg",
    "39112": "Magdeburg",
    "39114": "Magdeburg",
    "39116": "Magdeburg",
    "39118": "Magdeburg",
    "39120": "Magdeburg",
    "39122": "Magdeburg",
    "39124": "Magdeburg",
    "39126": "Magdeburg",
    "39128": "Magdeburg",
    "39130": "Magdeburg",
    "39164": "Wanzleben-Börde",
    "39167": "Eichenbarleben, Ochtmersleben, Irxleben, Schnarsleben, Wellen etc",
    "39171": "Sülzetal",
    "39175": "Gerwisch, Biederitz, Menz, Körbelitz etc",
    "39179": "Barleben",
    "39217": "Schönebeck (Elbe)",
    "39218": "Schönebeck",
    "39221": "Welsleben, Biere, Eickendorf, Eggersdorf, Großmühlingen etc",
    "39240": "Calbe, Rosenburg, Lödderitz etc",
    "39245": "Gommern, Dannigkow",
    "39249": "Barby",
    "39261": "Zerbst/Anhalt",
    "39264": "Güterglück, Lübs, Deetz, Jütrichau etc",
    "39279": "Loburg, Leitzkau",
    "39288": "Burg",
    "39291": "Möckern, Schermen, Nedlitz etc",
    "39307": "Genthin, Hohenseeden, Zabakuck u.a.",
    "39317": "Elbe-Parey",
    "39319": "Jerichow",
    "39326": "Wolmirstedt u.a.",
    "39340": "Haldensleben",
    "39343": "Erxleben, Nordgermersleben u.a.",
    "39345": "Haldensleben, Flechtingen, Bülstringen u.a.",
    "39356": "Weferlingen, Behnsdorf, Belsdorf etc",
    "39359": "Rätzlingen, Wegenstedt, Calvörde, Böddensell etc",
    "39365": "Harbke, Sommersdorf, Wefensleben, Ummendorf, Eilsleben",
    "39387": "Oschersleben (Bode)",
    "39393": "Hötensleben, Völpke, Ottleben, Hamersleben etc",
    "39397": "Schwanebeck, Gröningen, Kroppenstedt",
    "39418": "Staßfurt",
    "39435": "Egeln, Unseburg, Wolmirsleben, Borne etc",
    "39439": "Güsten",
    "39443": "Staßfurt",
    "39444": "Hecklingen",
    "39446": "Staßfurt",
    "39448": "Börde-Hakel",
    "39517": "Tangerhütte u.a.",
    "39524": "Sandau",
    "39539": "Havelberg",
    "39576": "Stendal",
    "39579": "Bismark, Rochau",
    "39590": "Tangermünde",
    "39596": "Goldbeck, Arneburg u.a.",
    "39599": "Bismark",
    "39606": "Osterburg, Altmärkische Höhe",
    "39615": "Seehausen, Werben, Leppin, Wahrenberg etc",
    "39619": "Arendsee",
    "39624": "Kalbe, Bismark",
    "39629": "Bismark",
    "39638": "Gardelegen",
    "39646": "Oebisfelde",
    "39649": "Gardelegen",
    "40210": "Düsseldorf",
    "40211": "Düsseldorf",
    "40212": "Düsseldorf",
    "40213": "Düsseldorf",
    "40215": "Düsseldorf",
    "40217": "Düsseldorf",
    "40219": "Düsseldorf",
    "40221": "Düsseldorf",
    "40223": "Düsseldorf",
    "40225": "Düsseldorf",
    "40227": "Düsseldorf",
    "40229": "Düsseldorf",
    "40231": "Düsseldorf",
    "40233": "Düsseldorf",
    "40235": "Düsseldorf",
    "40237": "Düsseldorf",
    "40239": "Düsseldorf",
    "40468": "Düsseldorf",
    "40470": "Düsseldorf",
    "40472": "Düsseldorf",
    "40474": "Düsseldorf",
    "40476": "Düsseldorf",
    "40477": "Düsseldorf",
    "40479": "Düsseldorf",
    "40489": "Düsseldorf",
    "40545": "Düsseldorf",
    "40547": "Düsseldorf",
    "40549": "Düsseldorf",
    "40589": "Düsseldorf",
    "40591": "Düsseldorf",
    "40593": "Düsseldorf",
    "40595": "Düsseldorf",
    "40597": "Düsseldorf",
    "40599": "Düsseldorf",
    "40625": "Düsseldorf",
    "40627": "Düsseldorf",
    "40629": "Düsseldorf",
    "40667": "Meerbusch",
    "40668": "Meerbusch",
    "40670": "Meerbusch",
    "40699": "Erkrath",
    "40721": "Hilden, Düsseldorf",
    "40723": "Hilden",
    "40724": "Hilden",
    "40764": "Langenfeld",
    "40789": "Monheim am Rhein",
    "40822": "Mettmann",
    "40878": "Ratingen",
    "40880": "Ratingen",
    "40882": "Ratingen",
    "40883": "Ratingen",
    "40885": "Ratingen",
    "41061": "Mönchengladbach",
    "41063": "Mönchengladbach",
    "41065": "Mönchengladbach",
    "41066": "Mönchengladbach",
    "41068": "Mönchengladbach",
    "41069": "Mönchengladbach",
    "41169": "Mönchengladbach",
    "41179": "Mönchengladbach",
    "41189": "Mönchengladbach",
    "41199": "Mönchengladbach",
    "41236": "Mönchengladbach",
    "41238": "Mönchengladbach",
    "41239": "Mönchengladbach",
    "41334": "Nettetal",
    "41352": "Korschenbroich",
    "41363": "Jüchen",
    "41366": "Schwalmtal",
    "41372": "Niederkrüchten",
    "41379": "Brüggen",
    "41460": "Neuss",
    "41462": "Neuss",
    "41464": "Neuss",
    "41466": "Neuss",
    "41468": "Neuss",
    "41469": "Neuss",
    "41470": "Neuss",
    "41472": "Neuss",
    "41515": "Grevenbroich",
    "41516": "Grevenbroich",
    "41517": "Grevenbroich",
    "41539": "Dormagen",
    "41540": "Dormagen",
    "41541": "Dormagen",
    "41542": "Dormagen",
    "41564": "Kaarst",
    "41569": "Rommerskirchen",
    "41747": "Viersen",
    "41748": "Viersen",
    "41749": "Viersen",
    "41751": "Viersen",
    "41812": "Erkelenz",
    "41836": "Hückelhoven",
    "41844": "Wegberg",
    "41849": "Wassenberg",
    "42103": "Wuppertal",
    "42105": "Wuppertal",
    "42107": "Wuppertal",
    "42109": "Wuppertal",
    "42111": "Wuppertal",
    "42113": "Wuppertal",
    "42115": "Wuppertal",
    "42117": "Wuppertal",
    "42119": "Wuppertal",
    "42275": "Wuppertal",
    "42277": "Wuppertal",
    "42279": "Wuppertal",
    "42281": "Wuppertal",
    "42283": "Wuppertal",
    "42285": "Wuppertal",
    "42287": "Wuppertal",
    "42289": "Wuppertal",
    "42327": "Wuppertal",
    "42329": "Wuppertal",
    "42349": "Wuppertal",
    "42369": "Wuppertal",
    "42389": "Wuppertal",
    "42399": "Wuppertal",
    "42477": "Radevormwald",
    "42489": "Wülfrath",
    "42499": "Hückeswagen",
    "42549": "Velbert",
    "42551": "Velbert",
    "42553": "Velbert",
    "42555": "Velbert",
    "42579": "Heiligenhaus",
    "42651": "Solingen",
    "42653": "Solingen",
    "42655": "Solingen",
    "42657": "Solingen",
    "42659": "Solingen",
    "42697": "Solingen",
    "42699": "Solingen",
    "42719": "Solingen",
    "42781": "Haan",
    "42799": "Leichlingen",
    "42853": "Remscheid",
    "42855": "Remscheid",
    "42857": "Remscheid",
    "42859": "Remscheid",
    "42897": "Remscheid",
    "42899": "Remscheid",
    "42929": "Wermelskirchen",
    "44135": "Dortmund",
    "44137": "Dortmund",
    "44139": "Dortmund",
    "44141": "Dortmund",
    "44143": "Dortmund",
    "44145": "Dortmund",
    "44147": "Dortmund",
    "44149": "Dortmund",
    "44225": "Dortmund",
    "44227": "Dortmund",
    "44229": "Dortmund",
    "44263": "Dortmund",
    "44265": "Dortmund",
    "44267": "Dortmund",
    "44269": "Dortmund",
    "44287": "Dortmund",
    "44289": "Dortmund",
    "44309": "Dortmund",
    "44319": "Dortmund",
    "44328": "Dortmund",
    "44329": "Dortmund",
    "44339": "Dortmund",
    "44357": "Dortmund",
    "44359": "Dortmund",
    "44369": "Dortmund",
    "44379": "Dortmund",
    "44388": "Dortmund",
    "44532": "Lünen",
    "44534": "Lünen",
    "44536": "Lünen",
    "44575": "Castrop-Rauxel",
    "44577": "Castrop-Rauxel",
    "44579": "Castrop-Rauxel",
    "44581": "Castrop-Rauxel",
    "44623": "Herne",
    "44625": "Herne",
    "44627": "Herne",
    "44628": "Herne",
    "44629": "Herne",
    "44649": "Herne",
    "44651": "Herne",
    "44652": "Herne",
    "44653": "Herne",
    "44787": "Bochum",
    "44789": "Bochum",
    "44791": "Bochum",
    "44793": "Bochum",
    "44795": "Bochum",
    "44797": "Bochum",
    "44799": "Bochum",
    "44801": "Bochum",
    "44803": "Bochum",
    "44805": "Bochum",
    "44807": "Bochum",
    "44809": "Bochum",
    "44866": "Bochum",
    "44867": "Bochum",
    "44869": "Bochum",
    "44879": "Bochum",
    "44892": "Bochum",
    "44894": "Bochum",
    "45127": "Essen",
    "45128": "Essen",
    "45130": "Essen",
    "45131": "Essen",
    "45133": "Essen",
    "45134": "Essen",
    "45136": "Essen",
    "45138": "Essen",
    "45139": "Essen",
    "45141": "Essen",
    "45143": "Essen",
    "45144": "Essen",
    "45145": "Essen",
    "45147": "Essen",
    "45149": "Essen",
    "45219": "Essen",
    "45239": "Essen",
    "45257": "Essen",
    "45259": "Essen",
    "45276": "Essen",
    "45277": "Essen",
    "45279": "Essen",
    "45289": "Essen",
    "45307": "Essen",
    "45309": "Essen",
    "45326": "Essen",
    "45327": "Essen",
    "45329": "Essen",
    "45355": "Essen",
    "45356": "Essen",
    "45357": "Essen",
    "45359": "Essen",
    "45468": "Mülheim an der Ruhr",
    "45470": "Mülheim an der Ruhr",
    "45472": "Mülheim an der Ruhr",
    "45473": "Mülheim an der Ruhr",
    "45475": "Mülheim an der Ruhr",
    "45476": "Mülheim an der Ruhr",
    "45478": "Mülheim an der Ruhr",
    "45479": "Mülheim an der Ruhr",
    "45481": "Mülheim an der Ruhr",
    "45525": "Hattingen",
    "45527": "Hattingen",
    "45529": "Hattingen",
    "45549": "Sprockhövel",
    "45657": "Recklinghausen",
    "45659": "Recklinghausen",
    "45661": "Recklinghausen",
    "45663": "Recklinghausen",
    "45665": "Recklinghausen",
    "45699": "Herten",
    "45701": "Herten",
    "45711": "Datteln",
    "45721": "Haltern am See",
    "45731": "Waltrop",
    "45739": "Oer-Erkenschwick",
    "45768": "Marl",
    "45770": "Marl",
    "45772": "Marl",
    "45879": "Gelsenkirchen",
    "45881": "Gelsenkirchen",
    "45883": "Gelsenkirchen",
    "45884": "Gelsenkirchen Rotthausen",
    "45886": "Gelsenkirchen",
    "45888": "Gelsenkirchen",
    "45889": "Gelsenkirchen",
    "45891": "Gelsenkirchen",
    "45892": "Gelsenkirchen",
    "45894": "Gelsenkirchen",
    "45896": "Gelsenkirchen",
    "45897": "Gelsenkirchen",
    "45899": "Gelsenkirchen",
    "45964": "Gladbeck",
    "45966": "Gladbeck",
    "45968": "Gladbeck",
    "46045": "Oberhausen",
    "46047": "Oberhausen",
    "46049": "Oberhausen",
    "46117": "Oberhausen",
    "46119": "Oberhausen",
    "46145": "Oberhausen",
    "46147": "Oberhausen",
    "46149": "Oberhausen",
    "46236": "Bottrop",
    "46238": "Bottrop",
    "46240": "Bottrop",
    "46242": "Bottrop",
    "46244": "Bottrop",
    "46282": "Dorsten",
    "46284": "Dorsten",
    "46286": "Dorsten",
    "46325": "Borken",
    "46342": "Velen",
    "46348": "Raesfeld",
    "46354": "Südlohn",
    "46359": "Heiden",
    "46395": "Bocholt",
    "46397": "Bocholt",
    "46399": "Bocholt",
    "46414": "Rhede",
    "46419": "Isselburg",
    "46446": "Emmerich am Rhein",
    "46459": "Rees",
    "46483": "Wesel",
    "46485": "Wesel",
    "46487": "Wesel",
    "46499": "Hamminkeln",
    "46509": "Xanten",
    "46514": "Schermbeck",
    "46519": "Alpen",
    "46535": "Dinslaken",
    "46537": "Dinslaken",
    "46539": "Dinslaken",
    "46562": "Voerde (Niederrhein)",
    "46569": "Hünxe",
    "47051": "Duisburg",
    "47053": "Duisburg",
    "47055": "Duisburg",
    "47057": "Duisburg",
    "47058": "Duisburg",
    "47059": "Duisburg",
    "47119": "Duisburg",
    "47137": "Duisburg",
    "47138": "Duisburg",
    "47139": "Duisburg",
    "47166": "Duisburg",
    "47167": "Duisburg",
    "47169": "Duisburg",
    "47178": "Duisburg",
    "47179": "Duisburg",
    "47198": "Duisburg",
    "47199": "Duisburg",
    "47226": "Duisburg",
    "47228": "Duisburg",
    "47229": "Duisburg",
    "47239": "Duisburg",
    "47249": "Duisburg",
    "47259": "Duisburg",
    "47269": "Duisburg",
    "47279": "Duisburg",
    "47441": "Moers",
    "47443": "Moers",
    "47445": "Moers",
    "47447": "Moers",
    "47475": "Kamp-Lintfort",
    "47495": "Rheinberg",
    "47506": "Neukirchen-Vluyn",
    "47509": "Rheurdt",
    "47533": "Kleve",
    "47546": "Kalkar",
    "47551": "Bedburg-Hau",
    "47559": "Kranenburg",
    "47574": "Goch",
    "47589": "Uedem",
    "47608": "Geldern",
    "47623": "Kevelaer",
    "47624": "Kevelaer",
    "47625": "Kevelaer-Wetten",
    "47626": "Kevelaer-Winnekendonk",
    "47627": "Kevelaer-Kervenheim",
    "47638": "Straelen",
    "47647": "Kerken",
    "47652": "Weeze",
    "47661": "Issum",
    "47665": "Sonsbeck",
    "47669": "Wachtendonk",
    "47798": "Krefeld",
    "47799": "Krefeld",
    "47800": "Krefeld",
    "47802": "Krefeld",
    "47803": "Krefeld",
    "47804": "Krefeld",
    "47805": "Krefeld",
    "47807": "Krefeld",
    "47809": "Krefeld",
    "47829": "Krefeld",
    "47839": "Krefeld",
    "47877": "Willich",
    "47906": "Kempen",
    "47918": "Tönisvorst",
    "47929": "Grefrath",
    "48143": "Münster",
    "48145": "Münster",
    "48147": "Münster",
    "48149": "Münster",
    "48151": "Münster",
    "48153": "Münster",
    "48155": "Münster",
    "48157": "Münster",
    "48159": "Münster",
    "48161": "Münster",
    "48163": "Münster",
    "48165": "Münster",
    "48167": "Münster",
    "48231": "Warendorf",
    "48249": "Dülmen",
    "48268": "Greven",
    "48282": "Emsdetten",
    "48291": "Telgte",
    "48301": "Nottuln",
    "48308": "Senden",
    "48317": "Drensteinfurt",
    "48324": "Sendenhorst",
    "48329": "Havixbeck",
    "48336": "Sassenberg",
    "48341": "Altenberge",
    "48346": "Ostbevern",
    "48351": "Everswinkel",
    "48356": "Nordwalde",
    "48361": "Beelen",
    "48366": "Laer",
    "48369": "Saerbeck",
    "48429": "Rheine",
    "48431": "Rheine",
    "48432": "Rheine",
    "48455": "Bad Bentheim",
    "48465": "Engden, Isterberg, Schüttorf u.a.",
    "48477": "Hörstel",
    "48480": "Lünne, Schapen, Spelle",
    "48485": "Neuenkirchen",
    "48488": "Emsbüren",
    "48493": "Wettringen",
    "48496": "Hopsten",
    "48499": "Salzbergen",
    "48527": "Nordhorn",
    "48529": "Nordhorn",
    "48531": "Nordhorn",
    "48565": "Steinfurt",
    "48599": "Gronau",
    "48607": "Ochtrup",
    "48612": "Horstmar",
    "48619": "Heek",
    "48624": "Schöppingen",
    "48629": "Metelen",
    "48653": "Coesfeld",
    "48683": "Ahaus",
    "48691": "Vreden",
    "48703": "Stadtlohn",
    "48712": "Gescher",
    "48720": "Rosendahl",
    "48727": "Billerbeck",
    "48734": "Reken",
    "48739": "Legden",
    "49074": "Osnabrück",
    "49076": "Osnabrück",
    "49078": "Osnabrück",
    "49080": "Osnabrück",
    "49082": "Osnabrück",
    "49084": "Osnabrück",
    "49086": "Osnabrück",
    "49088": "Osnabrück",
    "49090": "Osnabrück",
    "49124": "Georgsmarienhütte",
    "49134": "Wallenhorst",
    "49143": "Bissendorf",
    "49152": "Bad Essen",
    "49163": "Bohmte",
    "49170": "Hagen am Teutoburger Wald",
    "49176": "Hilter",
    "49179": "Ostercappeln",
    "49186": "Bad Iburg",
    "49191": "Belm",
    "49196": "Bad Laer",
    "49201": "Dissen",
    "49205": "Hasbergen",
    "49214": "Bad Rothenfelde",
    "49219": "Glandorf",
    "49324": "Melle",
    "49326": "Melle",
    "49328": "Melle",
    "49356": "Diepholz",
    "49377": "Vechta",
    "49393": "Lohne",
    "49401": "Damme",
    "49406": "Barnstorf, Eydelstedt, Drentwede",
    "49413": "Dinklage",
    "49419": "Wagenfeld",
    "49424": "Goldenstedt",
    "49429": "Visbek",
    "49434": "Neuenkirchen-Vörden",
    "49439": "Steinfeld",
    "49448": "Lemförde u.a.",
    "49451": "Holdorf",
    "49453": "Barver, Dickel, Hemsloh, Rehden, Wetschen, Wehrblecker Heide",
    "49456": "Bakum",
    "49457": "Drebber",
    "49459": "Lembruch",
    "49477": "Ibbenbüren",
    "49479": "Ibbenbüren",
    "49492": "Westerkappeln",
    "49497": "Mettingen",
    "49504": "Lotte",
    "49509": "Recke",
    "49525": "Lengerich",
    "49536": "Lienen",
    "49545": "Tecklenburg",
    "49549": "Ladbergen",
    "49565": "Bramsche",
    "49577": "Kettenkamp, Eggermühlen, Ankum",
    "49584": "Fürstenau",
    "49586": "Merzen, Neuenkirchen",
    "49593": "Bersenbrück",
    "49594": "Alfhausen",
    "49596": "Gehrde",
    "49597": "Rieste",
    "49599": "Voltlage",
    "49610": "Quakenbrück",
    "49624": "Löningen",
    "49626": "Berge, Bippen",
    "49632": "Essen (Oldenburg)",
    "49635": "Badbergen",
    "49637": "Menslage",
    "49638": "Nortrup",
    "49661": "Cloppenburg",
    "49681": "Garrel",
    "49685": "Emstek",
    "49688": "Lastrup",
    "49692": "Cappeln (Oldenburg)",
    "49696": "Molbergen",
    "49699": "Lindern (Oldenburg)",
    "49716": "Meppen",
    "49733": "Haren",
    "49740": "Haselünne",
    "49744": "Geeste",
    "49751": "Sögel u.a.",
    "49757": "Werlte, Vrees, Lahn",
    "49762": "Sustrum, Lathen u.a.",
    "49767": "Twist",
    "49770": "Herzlake, Dohren",
    "49774": "Lähden",
    "49777": "Stavern",
    "49779": "Oberlangen, Niederlangen",
    "49808": "Lingen",
    "49809": "Lingen",
    "49811": "Lingen",
    "49824": "Ringe, Laar, Emlichheim",
    "49828": "Esche, Georgsdorf, Lage, Neuenhaus, Osterwald",
    "49832": "Andervenne, Beesten, Freren, Messingen, Thuine",
    "49835": "Wietmarschen",
    "49838": "Lengerich u.a.",
    "49843": "Uelsen, Halle, Gölenkamp, Getelo",
    "49844": "Bawinkel",
    "49846": "Hoogstede",
    "49847": "Itterbeck/Wielen",
    "49849": "Wilsum",
    "50126": "Bergheim",
    "50127": "Bergheim",
    "50129": "Bergheim",
    "50169": "Kerpen",
    "50170": "Kerpen",
    "50171": "Kerpen",
    "50181": "Bedburg",
    "50189": "Elsdorf",
    "50226": "Frechen",
    "50259": "Pulheim",
    "50321": "Brühl",
    "50354": "Hürth",
    "50374": "Erftstadt",
    "50389": "Wesseling",
    "50667": "Köln",
    "50668": "Köln",
    "50670": "Köln",
    "50672": "Köln",
    "50674": "Köln",
    "50676": "Köln",
    "50677": "Köln",
    "50678": "Köln",
    "50679": "Köln",
    "50733": "Köln",
    "50735": "Köln",
    "50737": "Köln",
    "50739": "Köln",
    "50765": "Köln",
    "50767": "Köln",
    "50769": "Köln",
    "50823": "Köln",
    "50825": "Köln",
    "50827": "Köln",
    "50829": "Köln",
    "50858": "Köln",
    "50859": "Köln",
    "50931": "Köln",
    "50933": "Köln",
    "50935": "Köln",
    "50937": "Köln",
    "50939": "Köln",
    "50968": "Köln",
    "50969": "Köln",
    "50996": "Köln",
    "50997": "Köln",
    "50999": "Köln",
    "51061": "Köln",
    "51063": "Köln",
    "51065": "Köln",
    "51067": "Köln",
    "51069": "Köln",
    "51103": "Köln",
    "51105": "Köln",
    "51107": "Köln",
    "51109": "Köln",
    "51143": "Köln",
    "51145": "Köln",
    "51147": "Köln",
    "51149": "Köln",
    "51371": "Leverkusen",
    "51373": "Leverkusen",
    "51375": "Leverkusen",
    "51377": "Leverkusen",
    "51379": "Leverkusen",
    "51381": "Leverkusen",
    "51399": "Burscheid",
    "51427": "Bergisch Gladbach",
    "51429": "Bergisch Gladbach",
    "51465": "Bergisch Gladbach",
    "51467": "Bergisch Gladbach",
    "51469": "Bergisch Gladbach",
    "51491": "Overath",
    "51503": "Rösrath",
    "51515": "Kürten",
    "51519": "Odenthal",
    "51545": "Waldbröl",
    "51570": "Windeck",
    "51580": "Reichshof",
    "51588": "Nümbrecht",
    "51597": "Morsbach",
    "51598": "Friesenhagen",
    "51643": "Gummersbach",
    "51645": "Gummersbach",
    "51647": "Gummersbach",
    "51674": "Wiehl",
    "51688": "Wipperfürth",
    "51702": "Bergneustadt",
    "51709": "Marienheide",
    "51766": "Engelskirchen",
    "51789": "Lindlar",
    "52062": "Aachen",
    "52064": "Aachen",
    "52066": "Aachen",
    "52068": "Aachen",
    "52070": "Aachen",
    "52072": "Aachen",
    "52074": "Aachen",
    "52076": "Aachen",
    "52078": "Aachen",
    "52080": "Aachen",
    "52134": "Herzogenrath",
    "52146": "Würselen",
    "52152": "Simmerath",
    "52156": "Monschau",
    "52159": "Roetgen",
    "52222": "Stolberg (Rhld.)",
    "52223": "Stolberg (Rhld.)",
    "52224": "Stolberg",
    "52249": "Eschweiler",
    "52349": "Düren",
    "52351": "Düren",
    "52353": "Düren",
    "52355": "Düren",
    "52372": "Kreuzau",
    "52379": "Langerwehe",
    "52382": "Niederzier",
    "52385": "Nideggen",
    "52388": "Nörvenich",
    "52391": "Vettweiß",
    "52393": "Hürtgenwald",
    "52396": "Heimbach",
    "52399": "Merzenich",
    "52428": "Jülich",
    "52441": "Linnich",
    "52445": "Titz",
    "52457": "Aldenhoven",
    "52459": "Inden",
    "52477": "Alsdorf",
    "52499": "Baesweiler",
    "52511": "Geilenkirchen",
    "52525": "Waldfeucht, Heinsberg",
    "52531": "Übach-Palenberg",
    "52538": "Gangelt, Selfkant",
    "53111": "Bonn",
    "53113": "Bonn",
    "53115": "Bonn",
    "53117": "Bonn",
    "53119": "Bonn",
    "53121": "Bonn",
    "53123": "Bonn",
    "53125": "Bonn",
    "53127": "Bonn",
    "53129": "Bonn",
    "53173": "Bonn",
    "53175": "Bonn",
    "53177": "Bonn",
    "53179": "Bonn",
    "53225": "Bonn",
    "53227": "Bonn",
    "53229": "Bonn",
    "53332": "Bornheim",
    "53340": "Meckenheim",
    "53343": "Wachtberg",
    "53347": "Alfter",
    "53359": "Rheinbach",
    "53424": "Remagen",
    "53426": "Schalkenbach, Königsfeld, Dedenbach",
    "53474": "Bad Neuenahr-Ahrweiler",
    "53489": "Sinzig",
    "53498": "Bad Breisig, Waldorf, Gönnersdorf",
    "53501": "Grafschaft",
    "53505": "Altenahr, Berg, Kalenborn, Kirchsahr",
    "53506": "Ahrbrück, Heckenbach, Hönningen, Kesseling, Rech",
    "53507": "Dernau",
    "53508": "Mayschoß",
    "53518": "Adenau, Kottenborn u.a.",
    "53520": "Reifferscheid, Kaltenborn, Wershofen u.a.",
    "53533": "Antweiler, Aremberg, Dorsel, Eichenbach, Aremberg, Fuchshofen und Müsch",
    "53534": "Barweiler, Bauler, Hoffeld, Pomster, Wiesemscheid, Wirft",
    "53539": "Bodenbach, Kelberg, Kirsbach u.a.",
    "53545": "Linz am Rhein, Ockenfels",
    "53547": "Breitscheid, Dattenberg, Hausen, Hümmerich, Kasbach-Ohlenberg, Roßbach u",
    "53557": "Bad Hönningen",
    "53560": "Vettelschloß, Kretzhaus (Linz am Rhein)",
    "53562": "Sankt Katharinen (Landkreis Neuwied)",
    "53567": "Asbach, Buchholz",
    "53572": "Bruchhausen, Unkel",
    "53577": "Neustadt (Wied)",
    "53578": "Windhagen",
    "53579": "Erpel",
    "53604": "Bad Honnef",
    "53619": "Rheinbreitbach",
    "53639": "Königswinter",
    "53721": "Siegburg",
    "53757": "Sankt Augustin",
    "53773": "Hennef (Sieg)",
    "53783": "Eitorf",
    "53797": "Lohmar",
    "53804": "Much",
    "53809": "Ruppichteroth",
    "53819": "Neunkirchen-Seelscheid",
    "53840": "Troisdorf",
    "53842": "Troisdorf",
    "53844": "Troisdorf",
    "53859": "Niederkassel",
    "53879": "Euskirchen",
    "53881": "Euskirchen",
    "53894": "Mechernich",
    "53902": "Bad Münstereifel",
    "53909": "Zülpich",
    "53913": "Swisttal",
    "53919": "Weilerswist",
    "53925": "Kall",
    "53937": "Schleiden",
    "53940": "Hellenthal",
    "53945": "Blankenheim",
    "53947": "Nettersheim",
    "53949": "Dahlem",
    "54290": "Trier",
    "54292": "Trier",
    "54293": "Trier",
    "54294": "Trier",
    "54295": "Trier",
    "54296": "Trier",
    "54298": "Welschbillig, Igel, Aach",
    "54306": "Kordel",
    "54308": "Langsur",
    "54309": "Newel",
    "54310": "Ralingen",
    "54311": "Trierweiler",
    "54313": "Zemmer",
    "54314": "Zerf",
    "54316": "Pluwig",
    "54317": "Osburg, Gusterath, Farschweiler, Kasel u.a.",
    "54318": "Mertesdorf",
    "54320": "Waldrach",
    "54329": "Konz",
    "54331": "Pellingen",
    "54332": "Wasserliesch",
    "54338": "Schweich",
    "54340": "Leiwen u.a.",
    "54341": "Fell",
    "54343": "Föhren",
    "54344": "Kenn",
    "54346": "Mehring",
    "54347": "Neumagen-Dhron",
    "54349": "Trittenheim",
    "54411": "Deuselbach, Hermeskeil, Rorodt",
    "54413": "Gusenburg",
    "54421": "Reinsfeld",
    "54422": "Neuhütten",
    "54424": "Thalfang",
    "54426": "Malborn",
    "54427": "Kell am See",
    "54429": "Schillingen",
    "54439": "Saarburg",
    "54441": "Ayl, Trassem u.a.",
    "54450": "Freudenburg",
    "54451": "Irsch",
    "54453": "Nittel",
    "54455": "Serrig",
    "54456": "Tawern",
    "54457": "Wincheringen",
    "54459": "Wiltingen",
    "54470": "Bernkastel-Kues u.a.",
    "54472": "Monzelfeld, Hochscheid u.a.",
    "54483": "Kleinich",
    "54484": "Maring-Noviand",
    "54486": "Mülheim (Mosel)",
    "54487": "Wintrich",
    "54492": "Zeltingen-Rachtig, Erden, Lösnich u.a.",
    "54497": "Morbach",
    "54498": "Piesport",
    "54516": "Wittlich",
    "54518": "Binsfeld, Heckenmünster",
    "54523": "Hetzerath, Dierscheid, Heckenmünster",
    "54524": "Klausen",
    "54526": "Landscheid",
    "54528": "Salmtal",
    "54529": "Spangdahlem",
    "54531": "Manderscheid",
    "54533": "Bettenfeld, Niederöfflingen u.a.",
    "54534": "Großlittgen",
    "54536": "Kröv",
    "54538": "Bausendorf",
    "54539": "Ürzig",
    "54550": "Daun",
    "54552": "Mehren u.a.",
    "54558": "Gillenfeld",
    "54568": "Gerolstein",
    "54570": "Pelm, Neroth u.a.",
    "54574": "Birresborn",
    "54576": "Hillesheim",
    "54578": "Walsdorf, Nohn u.a.",
    "54579": "Üxheim",
    "54584": "Jünkerath",
    "54585": "Esch",
    "54586": "Schüller",
    "54587": "Lissendorf",
    "54589": "Stadtkyll",
    "54595": "Prüm",
    "54597": "Pronsfeld",
    "54608": "Bleialf",
    "54610": "Büdesheim",
    "54611": "Hallschlag",
    "54612": "Lasel",
    "54614": "Schönecken",
    "54616": "Winterspelt",
    "54617": "Lützkampen",
    "54619": "Üttfeld",
    "54634": "Bitburg",
    "54636": "Rittersdorf u.a.",
    "54646": "Bettingen",
    "54647": "Dudeldorf",
    "54649": "Waxweiler",
    "54655": "Kyllburg",
    "54657": "Badem, Gindorf, Neidenbach",
    "54662": "Speicher",
    "54664": "Preist",
    "54666": "Irrel",
    "54668": "Ferschweiler",
    "54669": "Bollendorf",
    "54673": "Neuerburg u.a.",
    "54675": "Körperich u.a.",
    "54687": "Arzfeld",
    "54689": "Daleiden, Preischeid u.a.",
    "55116": "Mainz",
    "55118": "Mainz",
    "55120": "Mainz",
    "55122": "Mainz",
    "55124": "Mainz",
    "55126": "Mainz",
    "55127": "Mainz",
    "55128": "Mainz",
    "55129": "Mainz Ebersheim, Hechtsheim",
    "55130": "Mainz",
    "55131": "Mainz",
    "55218": "Ingelheim am Rhein",
    "55232": "Alzey",
    "55234": "Albig",
    "55237": "Flonheim",
    "55239": "Gau-Odernheim",
    "55246": "Wiesbaden",
    "55252": "Mainz-Kastel",
    "55257": "Budenheim",
    "55262": "Heidesheim am Rhein",
    "55263": "Wackernheim",
    "55268": "Nieder-Olm",
    "55270": "Ober-Olm",
    "55271": "Stadecken-Elsheim",
    "55276": "Oppenheim",
    "55278": "Mommenheim",
    "55283": "Nierstein",
    "55286": "Wörrstadt",
    "55288": "Armsheim",
    "55291": "Saulheim",
    "55294": "Bodenheim",
    "55296": "Harxheim",
    "55299": "Nackenheim",
    "55411": "Bingen am Rhein",
    "55413": "Weiler bei Bingen",
    "55422": "Bacharach, Breitscheid",
    "55424": "Münster-Sarmsheim",
    "55425": "Waldalgesheim",
    "55430": "Oberwesel",
    "55432": "Niederburg",
    "55435": "Gau-Algesheim",
    "55437": "Ockenheim",
    "55442": "Stromberg",
    "55444": "Seibersbach",
    "55450": "Langenlonsheim",
    "55452": "Guldental",
    "55457": "Gensingen",
    "55459": "Aspisheim, Grolsheim",
    "55469": "Simmern/Hunsrück u.a.",
    "55471": "Tiefenbach u.a.",
    "55481": "Kirchberg u.a.",
    "55483": "Dickenschied u.a.",
    "55487": "Sohren",
    "55490": "Gemünden",
    "55491": "Büchenbeuren",
    "55494": "Rheinböllen",
    "55496": "Argenthal",
    "55497": "Ellern (Hunsrück), Schnorbach",
    "55499": "Riesweiler",
    "55543": "Bad Kreuznach",
    "55545": "Bad Kreuznach",
    "55546": "Hackenheim",
    "55559": "Bretzenheim",
    "55566": "Sobernheim",
    "55568": "Staudernheim",
    "55569": "Monzingen",
    "55571": "Odernheim am Glan",
    "55576": "Sprendlingen",
    "55578": "Wallertheim",
    "55583": "Bad Kreuznach",
    "55585": "Norheim u.a.",
    "55590": "Meisenheim",
    "55592": "Rehborn",
    "55593": "Rüdesheim",
    "55595": "Hargesheim",
    "55596": "Waldböckelheim",
    "55597": "Wöllstein",
    "55599": "Gau-Bickelheim",
    "55606": "Kirn",
    "55608": "Bergen",
    "55618": "Simmertal",
    "55619": "Hennweiler",
    "55621": "Hundsbach",
    "55624": "Rhaunen",
    "55626": "Bundenbach",
    "55627": "Merxheim",
    "55629": "Seesbach",
    "55743": "Idar-Oberstein",
    "55756": "Herrstein",
    "55758": "Niederwörresbach",
    "55765": "Birkenfeld u.a.",
    "55767": "Brücken, Oberbrombach u.a.",
    "55768": "Hoppstädten-Weiersbach",
    "55774": "Baumholder",
    "55776": "Berglangenbach, Ruschberg u.a.",
    "55777": "Berschweiler bei Baumholder",
    "55779": "Heimbach",
    "56068": "Koblenz",
    "56070": "Koblenz",
    "56072": "Koblenz",
    "56073": "Koblenz",
    "56075": "Koblenz",
    "56076": "Koblenz",
    "56077": "Koblenz",
    "56112": "Lahnstein (ohne Exklave)",
    "56130": "Bad Ems",
    "56132": "Dausenau, Nievern",
    "56133": "Fachbach, Exklave Lahnstein",
    "56154": "Boppard",
    "56170": "Bendorf",
    "56179": "Vallendar",
    "56182": "Urbar (bei Koblenz)",
    "56191": "Weitersburg",
    "56203": "Höhr-Grenzhausen",
    "56204": "Hillscheid",
    "56206": "Hilgert",
    "56218": "Mülheim-Kärlich",
    "56220": "Urmitz",
    "56235": "Ransbach-Baumbach",
    "56237": "Nauort",
    "56242": "Selters (Westerwald)",
    "56244": "Freilingen, Freirachdorf u.a.",
    "56249": "Herschbach",
    "56253": "Treis-Karden",
    "56254": "Müden",
    "56269": "Dierdorf",
    "56271": "Kleinmaischeid",
    "56276": "Großmaischeid",
    "56281": "Emmelshausen",
    "56283": "Wildenbungert, Gondershausen, Nörtershausen u.a.",
    "56288": "Kastellaun",
    "56290": "Beltheim",
    "56291": "Leiningen",
    "56294": "Münstermaifeld",
    "56295": "Lonnig",
    "56299": "Ochtendung",
    "56305": "Puderbach",
    "56307": "Dürrholz",
    "56316": "Raubach",
    "56317": "Urbach",
    "56321": "Rhens",
    "56322": "Spay",
    "56323": "Waldesch, Hünenfeld",
    "56329": "Sankt Goar",
    "56330": "Kobern-Gondorf",
    "56332": "Lehmen, Niederfell, Oberfell, Wolken u.a.",
    "56333": "Winningen",
    "56335": "Neuhäusel",
    "56337": "Eitelborn",
    "56338": "Braubach",
    "56340": "Osterspai",
    "56341": "Kamp-Bornhofen-Filsen",
    "56346": "Sankt Goarshausen",
    "56348": "Bornich, Patersberg",
    "56349": "Kaub",
    "56355": "Nastätten u.a.",
    "56357": "Miehlen u.a.",
    "56368": "Katzenelnbogen",
    "56370": "Schönborn",
    "56377": "Nassau",
    "56379": "Singhofen",
    "56410": "Montabaur",
    "56412": "Nentershausen, Hübingen, Niederelbert u.a.",
    "56414": "Meudt, Molsberg, Hundsangen, Niederahr u.a.",
    "56422": "Wirges, Stadt",
    "56424": "Mogendorf, Ebernhahn, Staudt u.a.",
    "56427": "Siershahn",
    "56428": "Dernbach (Westerwald)",
    "56457": "Westerburg",
    "56459": "Bellingen, Kölbingen, Gemünden etc",
    "56462": "Höhn",
    "56470": "Bad Marienberg (Westerwald)",
    "56472": "Nisterau u.a.",
    "56477": "Rennerod, Zehnhausen, Nister-Möhrendorf, Waigandshain",
    "56479": "Oberrod u.a.",
    "56564": "Neuwied",
    "56566": "Neuwied",
    "56567": "Neuwied",
    "56575": "Weißenthurm",
    "56579": "Rengsdorf",
    "56581": "Melsbach",
    "56584": "Anhausen",
    "56587": "Straßenhaus",
    "56588": "Waldbreitbach, Hasuen",
    "56589": "Niederbreitbach",
    "56593": "Horhausen (Westerwald)",
    "56594": "Willroth",
    "56598": "Rheinbrohl",
    "56599": "Leutesdorf",
    "56626": "Andernach",
    "56630": "Kretz",
    "56637": "Plaidt",
    "56642": "Kruft",
    "56645": "Nickenich",
    "56648": "Saffig",
    "56651": "Niederzissen",
    "56653": "Wehr",
    "56656": "Brohl-Lützing",
    "56659": "Burgbrohl",
    "56727": "Mayen",
    "56729": "Ettringen",
    "56736": "Kottenheim",
    "56743": "Mendig",
    "56745": "Bell",
    "56746": "Kempenich",
    "56751": "Polch",
    "56753": "Mertloch, Welling u.a.",
    "56754": "Binningen",
    "56759": "Kaisersesch",
    "56761": "Düngenheim",
    "56766": "Ulmen",
    "56767": "Uersfeld",
    "56769": "Retterath",
    "56812": "Cochem",
    "56814": "Ediger-Eller",
    "56818": "Klotten",
    "56820": "Senheim",
    "56821": "Ellenz-Poltersdorf",
    "56823": "Büchel",
    "56825": "Gevenich",
    "56826": "Lutzerath",
    "56828": "Alflen",
    "56829": "Pommern",
    "56841": "Traben-Trarbach",
    "56843": "Irmenach",
    "56850": "Enkirch u.a.",
    "56856": "Zell (Mosel)",
    "56858": "Peterswald-Löffelscheid u.a.",
    "56859": "Bullay, Alf, Zell",
    "56861": "Reil",
    "56862": "Pünderich",
    "56864": "Bad Bertrich",
    "56865": "Blankenrath u.a.",
    "56867": "Briedel",
    "56869": "Mastershausen",
    "57072": "Siegen",
    "57074": "Siegen",
    "57076": "Siegen",
    "57078": "Siegen",
    "57080": "Siegen",
    "57223": "Kreuztal",
    "57234": "Wilnsdorf",
    "57250": "Netphen",
    "57258": "Freudenberg",
    "57271": "Hilchenbach",
    "57290": "Neunkirchen",
    "57299": "Burbach",
    "57319": "Bad Berleburg",
    "57334": "Bad Laasphe",
    "57339": "Erndtebrück",
    "57368": "Lennestadt",
    "57392": "Schmallenberg",
    "57399": "Kirchhundem",
    "57413": "Finnentrop",
    "57439": "Attendorn",
    "57462": "Olpe",
    "57482": "Wenden",
    "57489": "Drolshagen",
    "57518": "Betzdorf",
    "57520": "Emmerzhausen, Niederdreisbach, Steinebach",
    "57537": "Wissen, Hövels u.a.",
    "57539": "Fürthen",
    "57548": "Kirchen (Sieg)",
    "57555": "Mudersbach",
    "57562": "Herdorf",
    "57567": "Daaden",
    "57572": "Niederfischbach",
    "57577": "Hamm (Sieg)",
    "57578": "Elkenroth",
    "57580": "Gebhardshain",
    "57581": "Katzwinkel (Sieg)",
    "57583": "Nauroth",
    "57584": "Scheuerfeld",
    "57586": "Weitefeld",
    "57587": "Birken-Honigsessen",
    "57589": "Pracht",
    "57610": "Altenkirchen (Westerwald)",
    "57612": "Birnbach",
    "57614": "Steimel",
    "57627": "Hachenburg",
    "57629": "Malberg, Norken, Höchstenbach u.a.",
    "57632": "Flammersfeld",
    "57635": "Weyerbusch",
    "57636": "Mammelzen",
    "57638": "Neitersen",
    "57639": "Oberdreis",
    "57641": "Oberlahr",
    "57642": "Alpenrod",
    "57644": "Hattert",
    "57645": "Nister",
    "57647": "Nistertal, Enspel",
    "57648": "Unnau",
    "58089": "Hagen",
    "58091": "Hagen",
    "58093": "Hagen",
    "58095": "Hagen",
    "58097": "Hagen",
    "58099": "Hagen",
    "58119": "Hagen",
    "58135": "Hagen",
    "58239": "Schwerte",
    "58256": "Ennepetal",
    "58285": "Gevelsberg",
    "58300": "Wetter (Ruhr)",
    "58313": "Herdecke",
    "58332": "Schwelm",
    "58339": "Breckerfeld",
    "58452": "Witten",
    "58453": "Witten",
    "58454": "Witten",
    "58455": "Witten",
    "58456": "Witten",
    "58507": "Lüdenscheid",
    "58509": "Lüdenscheid",
    "58511": "Lüdenscheid",
    "58513": "Lüdenscheid",
    "58515": "Lüdenscheid",
    "58540": "Meinerzhagen",
    "58553": "Halver",
    "58566": "Kierspe",
    "58579": "Schalksmühle",
    "58636": "Iserlohn",
    "58638": "Iserlohn",
    "58640": "Iserlohn",
    "58642": "Iserlohn",
    "58644": "Iserlohn",
    "58675": "Hemer",
    "58706": "Menden",
    "58708": "Menden",
    "58710": "Menden",
    "58730": "Fröndenberg/Ruhr",
    "58739": "Wickede (Ruhr)",
    "58762": "Altena",
    "58769": "Nachrodt-Wiblingwerde",
    "58791": "Werdohl",
    "58802": "Balve",
    "58809": "Neuenrade",
    "58840": "Plettenberg",
    "58849": "Herscheid",
    "59063": "Hamm",
    "59065": "Hamm",
    "59067": "Hamm",
    "59069": "Hamm",
    "59071": "Hamm",
    "59073": "Hamm",
    "59075": "Hamm",
    "59077": "Hamm",
    "59174": "Kamen",
    "59192": "Bergkamen",
    "59199": "Bönen",
    "59227": "Ahlen",
    "59229": "Ahlen",
    "59269": "Beckum",
    "59302": "Oelde",
    "59320": "Ennigerloh",
    "59329": "Wadersloh",
    "59348": "Lüdinghausen",
    "59368": "Werne",
    "59379": "Selm",
    "59387": "Ascheberg",
    "59394": "Nordkirchen",
    "59399": "Olfen",
    "59423": "Unna",
    "59425": "Unna",
    "59427": "Unna",
    "59439": "Holzwickede",
    "59457": "Werl",
    "59469": "Ense",
    "59494": "Soest",
    "59505": "Bad Sassendorf",
    "59510": "Lippetal",
    "59514": "Welver",
    "59519": "Möhnesee",
    "59555": "Lippstadt",
    "59556": "Lippstadt",
    "59557": "Lippstadt",
    "59558": "Lippstadt",
    "59581": "Warstein",
    "59590": "Geseke",
    "59597": "Erwitte",
    "59602": "Rüthen",
    "59609": "Anröchte",
    "59755": "Arnsberg",
    "59757": "Arnsberg",
    "59759": "Arnsberg",
    "59821": "Arnsberg",
    "59823": "Arnsberg",
    "59846": "Sundern",
    "59872": "Meschede",
    "59889": "Eslohe",
    "59909": "Bestwig",
    "59929": "Brilon",
    "59939": "Olsberg",
    "59955": "Winterberg",
    "59964": "Medebach",
    "59969": "Bromskirchen, Hallenberg",
    "60306": "Frankfurt am Main, Opernturm",
    "60308": "Frankfurt",
    "60310": "Frankfurt am Main (Taunusturm)",
    "60311": "Frankfurt am Main",
    "60313": "Frankfurt am Main",
    "60314": "Frankfurt am Main",
    "60316": "Frankfurt am Main",
    "60318": "Frankfurt am Main",
    "60320": "Frankfurt am Main",
    "60322": "Frankfurt am Main",
    "60323": "Frankfurt am Main",
    "60325": "Frankfurt am Main",
    "60326": "Frankfurt am Main",
    "60327": "Frankfurt am Main",
    "60329": "Frankfurt am Main",
    "60385": "Frankfurt am Main",
    "60386": "Frankfurt am Main",
    "60388": "Frankfurt am Main",
    "60389": "Frankfurt am Main",
    "60431": "Frankfurt am Main",
    "60433": "Frankfurt am Main",
    "60435": "Frankfurt am Main",
    "60437": "Frankfurt am Main",
    "60438": "Frankfurt am Main",
    "60439": "Frankfurt am Main",
    "60486": "Frankfurt am Main",
    "60487": "Frankfurt am Main",
    "60488": "Frankfurt am Main",
    "60489": "Frankfurt am Main",
    "60528": "Frankfurt am Main",
    "60529": "Frankfurt am Main",
    "60549": "Frankfurt am Main",
    "60594": "Frankfurt am Main",
    "60596": "Frankfurt am Main",
    "60598": "Frankfurt am Main",
    "60599": "Frankfurt am Main",
    "61118": "Bad Vilbel",
    "61130": "Nidderau",
    "61137": "Schöneck",
    "61138": "Niederdorfelden",
    "61169": "Friedberg (Hessen)",
    "61184": "Karben",
    "61191": "Rosbach v.d. Höhe",
    "61194": "Niddatal",
    "61197": "Florstadt",
    "61200": "Wölfersheim",
    "61203": "Reichelsheim (Wetterau)",
    "61206": "Wöllstadt",
    "61209": "Echzell",
    "61231": "Bad Nauheim",
    "61239": "Ober-Mörlen",
    "61250": "Usingen",
    "61267": "Neu-Anspach",
    "61273": "Wehrheim",
    "61276": "Weilrod",
    "61279": "Grävenwiesbach",
    "61348": "Bad Homburg v.d. Höhe",
    "61350": "Bad Homburg v.d. Höhe",
    "61352": "Bad Homburg v.d. Höhe",
    "61381": "Friedrichsdorf",
    "61389": "Schmitten",
    "61440": "Oberursel (Taunus)",
    "61449": "Steinbach (Taunus)",
    "61462": "Königstein im Taunus",
    "61476": "Kronberg im Taunus",
    "61479": "Glashütten",
    "63065": "Offenbach am Main",
    "63067": "Offenbach am Main",
    "63069": "Offenbach am Main",
    "63071": "Offenbach am Main",
    "63073": "Offenbach am Main",
    "63075": "Offenbach am Main",
    "63110": "Rodgau",
    "63128": "Dietzenbach",
    "63150": "Heusenstamm",
    "63165": "Mühlheim am Main",
    "63179": "Obertshausen",
    "63225": "Langen",
    "63263": "Neu-Isenburg",
    "63303": "Dreieich",
    "63322": "Rödermark",
    "63329": "Egelsbach",
    "63450": "Hanau",
    "63452": "Hanau",
    "63454": "Hanau",
    "63456": "Hanau",
    "63457": "Hanau",
    "63477": "Maintal",
    "63486": "Bruchköbel",
    "63500": "Seligenstadt",
    "63505": "Langenselbold",
    "63512": "Hainburg",
    "63517": "Rodenbach",
    "63526": "Erlensee",
    "63533": "Mainhausen",
    "63538": "Großkrotzenburg",
    "63543": "Neuberg",
    "63546": "Hammersbach",
    "63549": "Ronneburg",
    "63571": "Gelnhausen",
    "63579": "Freigericht",
    "63584": "Gründau",
    "63589": "Linsengericht",
    "63594": "Hasselroth",
    "63599": "Biebergemünd",
    "63607": "Wächtersbach",
    "63619": "Bad Orb",
    "63628": "Bad Soden-Salmünster",
    "63633": "Birstein",
    "63636": "Brachttal",
    "63637": "Jossgrund",
    "63639": "Flörsbachtal",
    "63654": "Büdingen",
    "63667": "Nidda",
    "63674": "Altenstadt",
    "63679": "Schotten",
    "63683": "Ortenberg",
    "63688": "Gedern",
    "63691": "Ranstadt",
    "63694": "Limeshain",
    "63695": "Glauburg",
    "63697": "Hirzenhain",
    "63699": "Kefenrod",
    "63739": "Aschaffenburg",
    "63741": "Aschaffenburg",
    "63743": "Aschaffenburg",
    "63755": "Alzenau",
    "63762": "Großostheim",
    "63768": "Hösbach",
    "63773": "Goldbach",
    "63776": "Mömbris",
    "63785": "Obernburg a.Main",
    "63791": "Karlstein am Main",
    "63796": "Kahl am Main",
    "63801": "Kleinostheim",
    "63808": "Haibach",
    "63811": "Stockstadt am Main",
    "63814": "Mainaschaff",
    "63820": "Elsenfeld",
    "63825": "Schöllkrippen, Blankenbach",
    "63826": "Geiselbach",
    "63828": "Kleinkahl",
    "63829": "Krombach",
    "63831": "Wiesen, Wiesener Forst",
    "63834": "Sulzbach am Main",
    "63839": "Kleinwallstadt",
    "63840": "Hausen",
    "63843": "Niedernberg",
    "63846": "Laufach",
    "63849": "Leidersbach",
    "63853": "Mömlingen",
    "63856": "Bessenbach",
    "63857": "Waldaschaff, Waldaschaffer Forst",
    "63860": "Rothenbuch, Rothenbucher Forst",
    "63863": "Eschau",
    "63864": "Glattbach",
    "63867": "Johannesberg",
    "63868": "Großwallstadt",
    "63869": "Heigenbrücken",
    "63871": "Heinrichsthal",
    "63872": "Heimbuchenthal",
    "63874": "Dammbach",
    "63875": "Mespelbrunn",
    "63877": "Sailauf",
    "63879": "Weibersbrunn, Rohrbrunner Forst",
    "63897": "Miltenberg",
    "63906": "Erlenbach a.Main",
    "63911": "Klingenberg a. Main",
    "63916": "Amorbach",
    "63920": "Großheubach",
    "63924": "Kleinheubach, Rüdenau",
    "63925": "Laudenbach",
    "63927": "Bürgstadt",
    "63928": "Eichenbühl",
    "63930": "Neunkirchen",
    "63931": "Kirchzell",
    "63933": "Mönchberg",
    "63934": "Röllbach",
    "63936": "Schneeberg",
    "63937": "Weilbach",
    "63939": "Wörth a.Main",
    "64283": "Darmstadt",
    "64285": "Darmstadt",
    "64287": "Darmstadt",
    "64289": "Darmstadt",
    "64291": "Darmstadt",
    "64293": "Darmstadt",
    "64295": "Darmstadt",
    "64297": "Darmstadt",
    "64319": "Pfungstadt",
    "64331": "Weiterstadt",
    "64342": "Seeheim-Jugenheim",
    "64347": "Griesheim",
    "64354": "Reinheim",
    "64367": "Mühltal",
    "64372": "Ober-Ramstadt",
    "64380": "Roßdorf",
    "64385": "Reichelsheim (Odenwald)",
    "64390": "Erzhausen",
    "64395": "Brensbach",
    "64397": "Modautal",
    "64401": "Groß-Bieberau",
    "64404": "Bickenbach",
    "64405": "Fischbachtal",
    "64407": "Fränkisch-Crumbach",
    "64409": "Messel",
    "64521": "Groß-Gerau",
    "64546": "Mörfelden-Walldorf",
    "64560": "Riedstadt",
    "64569": "Nauheim",
    "64572": "Büttelborn",
    "64579": "Gernsheim",
    "64584": "Biebesheim am Rhein",
    "64589": "Stockstadt am Rhein",
    "64625": "Bensheim",
    "64646": "Heppenheim (Bergstraße)",
    "64653": "Lorsch",
    "64658": "Fürth",
    "64665": "Alsbach-Hähnlein",
    "64668": "Rimbach",
    "64673": "Zwingenberg",
    "64678": "Lindenfels",
    "64683": "Einhausen",
    "64686": "Lautertal (Odenwald)",
    "64689": "Grasellenbach",
    "64711": "Erbach",
    "64720": "Michelstadt",
    "64732": "Bad König",
    "64739": "Höchst i. Odw.",
    "64743": "Beerfelden",
    "64747": "Breuberg",
    "64750": "Lützelbach",
    "64753": "Brombachtal",
    "64754": "Hesseneck",
    "64756": "Mossautal",
    "64757": "Rothenberg",
    "64759": "Sensbachtal",
    "64807": "Dieburg",
    "64823": "Groß-Umstadt",
    "64832": "Babenhausen",
    "64839": "Münster",
    "64846": "Groß-Zimmern",
    "64850": "Schaafheim",
    "64853": "Otzberg",
    "64859": "Eppertshausen",
    "65183": "Wiesbaden",
    "65185": "Wiesbaden",
    "65187": "Wiesbaden",
    "65189": "Wiesbaden",
    "65191": "Wiesbaden",
    "65193": "Wiesbaden",
    "65195": "Wiesbaden",
    "65197": "Wiesbaden",
    "65199": "Wiesbaden",
    "65201": "Wiesbaden",
    "65203": "Wiesbaden",
    "65205": "Wiesbaden",
    "65207": "Wiesbaden",
    "65232": "Taunusstein",
    "65239": "Hochheim am Main",
    "65307": "Bad Schwalbach",
    "65321": "Heidenrod",
    "65326": "Aarbergen",
    "65329": "Hohenstein",
    "65343": "Eltville am Rhein",
    "65344": "Eltville am Rhein",
    "65345": "Eltville am Rhein",
    "65346": "Eltville am Rhein",
    "65347": "Eltville am Rhein",
    "65366": "Geisenheim",
    "65375": "Oestrich-Winkel",
    "65385": "Rüdesheim am Rhein",
    "65388": "Schlangenbad",
    "65391": "Lorch",
    "65396": "Walluf",
    "65399": "Kiedrich",
    "65428": "Rüsselsheim",
    "65439": "Flörsheim am Main",
    "65451": "Kelsterbach",
    "65462": "Ginsheim-Gustavsburg",
    "65468": "Trebur",
    "65474": "Bischofsheim",
    "65479": "Raunheim",
    "65510": "Hünstetten, Idstein",
    "65520": "Bad Camberg",
    "65527": "Niedernhausen",
    "65529": "Waldems",
    "65549": "Limburg",
    "65550": "Limburg",
    "65551": "Limburg",
    "65552": "Limburg",
    "65553": "Limburg",
    "65554": "Limburg",
    "65555": "Limburg",
    "65556": "Limburg",
    "65558": "Burgschwalbach",
    "65582": "Diez, Hambach, Aull",
    "65589": "Hadamar",
    "65594": "Runkel",
    "65597": "Hünfelden",
    "65599": "Dornburg",
    "65604": "Elz",
    "65606": "Villmar",
    "65611": "Brechen",
    "65614": "Beselich",
    "65618": "Selters",
    "65620": "Waldbrunn",
    "65623": "Hahnstätten u.a.",
    "65624": "Altendiez",
    "65626": "Birlenbach",
    "65627": "Elbtal",
    "65629": "Niederneisen",
    "65719": "Hofheim am Taunus",
    "65760": "Eschborn",
    "65779": "Kelkheim",
    "65795": "Hattersheim",
    "65812": "Bad Soden am Taunus",
    "65817": "Eppstein",
    "65824": "Schwalbach am Taunus",
    "65830": "Kriftel",
    "65835": "Liederbach am Taunus",
    "65843": "Sulzbach (Taunus)",
    "65929": "Frankfurt am Main",
    "65931": "Frankfurt am Main",
    "65933": "Frankfurt am Main",
    "65934": "Frankfurt am Main",
    "65936": "Frankfurt am Main",
    "66111": "Saarbrücken",
    "66113": "Saarbrücken",
    "66115": "Saarbrücken",
    "66117": "Saarbrücken",
    "66119": "Saarbrücken",
    "66121": "Saarbrücken",
    "66123": "Saarbrücken",
    "66125": "Saarbrücken",
    "66126": "Saarbrücken",
    "66127": "Saarbrücken",
    "66128": "Saarbrücken",
    "66129": "Saarbrücken",
    "66130": "Saarbrücken",
    "66131": "Saarbrücken",
    "66132": "Saarbrücken",
    "66133": "Saarbrücken",
    "66265": "Heusweiler",
    "66271": "Kleinblittersdorf",
    "66280": "Sulzbach/Saar",
    "66287": "Quierschied",
    "66292": "Riegelsberg",
    "66299": "Friedrichsthal",
    "66333": "Völklingen",
    "66346": "Püttlingen",
    "66352": "Großrosseln",
    "66359": "Bous",
    "66386": "Sankt Ingbert",
    "66399": "Mandelbachtal",
    "66424": "Homburg",
    "66440": "Blieskastel",
    "66450": "Bexbach",
    "66453": "Gersheim",
    "66459": "Kirkel",
    "66482": "Zweibrücken",
    "66484": "Battweiler u.a.",
    "66497": "Contwig",
    "66500": "Hornbach",
    "66501": "Kleinbundenbach",
    "66503": "Dellfeld",
    "66504": "Bottenbach",
    "66506": "Maßweiler",
    "66507": "Reifenberg",
    "66509": "Rieschweiler-Mühlbach",
    "66538": "Neunkirchen",
    "66539": "Neunkirchen",
    "66540": "Neunkirchen",
    "66557": "Illingen",
    "66564": "Ottweiler",
    "66571": "Eppelborn",
    "66578": "Schiffweiler",
    "66583": "Spiesen-Elversberg",
    "66589": "Merchweiler",
    "66606": "Sankt Wendel",
    "66620": "Nonnweiler",
    "66625": "Nohfelden",
    "66629": "Freisen",
    "66636": "Tholey",
    "66640": "Namborn",
    "66646": "Marpingen",
    "66649": "Oberthal",
    "66663": "Merzig",
    "66679": "Losheim",
    "66687": "Wadern",
    "66693": "Mettlach",
    "66701": "Beckingen",
    "66706": "Perl",
    "66709": "Weiskirchen",
    "66740": "Saarlouis",
    "66763": "Dillingen/Saar",
    "66773": "Schwalbach",
    "66780": "Rehlingen-Siersburg",
    "66787": "Wadgassen",
    "66793": "Saarwellingen",
    "66798": "Wallerfangen",
    "66802": "Überherrn",
    "66806": "Ensdorf",
    "66809": "Nalbach",
    "66822": "Lebach",
    "66839": "Schmelz",
    "66849": "Landstuhl",
    "66851": "Mittelbrunn, Queidersbach u.a.",
    "66862": "Kindsbach",
    "66869": "Kusel",
    "66871": "Pfeffelbach",
    "66877": "Ramstein-Miesenbach",
    "66879": "Steinwenden u.a.",
    "66882": "Hütschenhausen",
    "66885": "Altenglan",
    "66887": "Rammelsbach u.a.",
    "66892": "Bruchmühlbach-Miesau",
    "66894": "Bechhofen",
    "66901": "Schönenberg-Kübelberg",
    "66903": "Altenkirchen",
    "66904": "Brücken (Pfalz)",
    "66907": "Glan-Münchweiler",
    "66909": "Herschweiler-Pettersheim",
    "66914": "Waldmohr",
    "66916": "Breitenbach",
    "66917": "Wallhalben u.a.",
    "66919": "Obernheim-Kirchenarnbach u.a.",
    "66953": "Pirmasens",
    "66954": "Pirmasens",
    "66955": "Pirmasens",
    "66957": "Vinningen, Trulben, Ruppertsweiler u.a.",
    "66969": "Lemberg",
    "66976": "Rodalben",
    "66978": "Clausen",
    "66981": "Münchweiler an der Rodalb",
    "66987": "Thaleischweiler-Fröschen",
    "66989": "Höheinöd, Petersberg u.a.",
    "66994": "Dahn",
    "66996": "Fischbach, Erfweiler u.a.",
    "66999": "Hinterweidenthal",
    "67059": "Ludwigshafen am Rhein",
    "67061": "Ludwigshafen am Rhein",
    "67063": "Ludwigshafen am Rhein",
    "67065": "Ludwigshafen am Rhein",
    "67067": "Ludwigshafen am Rhein",
    "67069": "Ludwigshafen am Rhein",
    "67071": "Ludwigshafen am Rhein",
    "67098": "Bad Dürkheim",
    "67105": "Schifferstadt",
    "67112": "Mutterstadt",
    "67117": "Limburgerhof",
    "67122": "Altrip",
    "67125": "Dannstadt-Schauernheim",
    "67126": "Hochdorf-Assenheim",
    "67127": "Rödersheim-Gronau",
    "67133": "Maxdorf",
    "67134": "Birkenheide",
    "67136": "Fußgönheim",
    "67141": "Neuhofen",
    "67146": "Deidesheim",
    "67147": "Forst an der Weinstraße",
    "67149": "Meckenheim",
    "67150": "Niederkirchen bei Deidesheim",
    "67152": "Ruppertsberg",
    "67157": "Wachenheim an der Weinstraße",
    "67158": "Ellerstadt",
    "67159": "Friedelsheim",
    "67161": "Gönnheim",
    "67165": "Waldsee",
    "67166": "Otterstadt",
    "67167": "Erpolzheim",
    "67169": "Kallstadt",
    "67227": "Frankenthal (Pfalz)",
    "67229": "Gerolsheim",
    "67240": "Bobenheim-Roxheim",
    "67245": "Lambsheim",
    "67246": "Dirmstein",
    "67251": "Freinsheim",
    "67256": "Weisenheim am Sand",
    "67258": "Heßheim",
    "67259": "Beindersheim",
    "67269": "Grünstadt",
    "67271": "Kindenheim",
    "67273": "Weisenheim am Berg",
    "67278": "Bockenheim an der Weinstraße",
    "67280": "Ebertsheim",
    "67281": "Kirchheim an der Weinstraße",
    "67283": "Obrigheim (Pfalz)",
    "67292": "Kirchheimbolanden",
    "67294": "Bischheim u.a.",
    "67295": "Bolanden",
    "67297": "Marnheim",
    "67304": "Eisenberg (Pfalz)",
    "67305": "Ramsen",
    "67307": "Göllheim",
    "67308": "Albisheim (Pfrimm)",
    "67310": "Hettenleidelheim",
    "67311": "Tiefenthal",
    "67316": "Carlsberg",
    "67317": "Altleiningen",
    "67319": "Wattenheim",
    "67346": "Speyer",
    "67354": "Römerberg",
    "67360": "Lingenfeld",
    "67361": "Freisbach",
    "67363": "Lustadt",
    "67365": "Schwegenheim",
    "67366": "Weingarten (Pfalz)",
    "67368": "Westheim (Pfalz)",
    "67373": "Dudenhofen",
    "67374": "Hanhofen",
    "67376": "Harthausen",
    "67377": "Gommersheim",
    "67378": "Zeiskam",
    "67433": "Neustadt an der Weinstraße",
    "67434": "Neustadt an der Weinstraße",
    "67435": "Neustadt an der Weinstraße",
    "67454": "Haßloch",
    "67459": "Böhl-Iggelheim",
    "67466": "Lambrecht (Pfalz)",
    "67468": "Frankenstein, Neidenfels, Frankeneck",
    "67471": "Elmstein",
    "67472": "Esthal",
    "67473": "Lindenberg",
    "67475": "Weidenthal",
    "67480": "Edenkoben",
    "67482": "Venningen",
    "67483": "Edesheim",
    "67487": "Maikammer",
    "67489": "Kirrweiler (Pfalz)",
    "67547": "Worms",
    "67549": "Worms",
    "67550": "Worms",
    "67551": "Worms",
    "67574": "Osthofen",
    "67575": "Eich",
    "67577": "Alsheim",
    "67578": "Gimbsheim",
    "67580": "Hamm",
    "67582": "Mettenheim",
    "67583": "Guntersblum",
    "67585": "Dorn-Dürkheim",
    "67586": "Hillesheim",
    "67587": "Wintersheim",
    "67590": "Monsheim",
    "67591": "Offstein",
    "67592": "Flörsheim-Dalsheim",
    "67593": "Westhofen, Bermersheim",
    "67595": "Bechtheim",
    "67596": "Dittelsheim-Heßloch",
    "67598": "Gundersheim",
    "67599": "Gundheim",
    "67655": "Kaiserslautern",
    "67657": "Kaiserslautern",
    "67659": "Kaiserslautern",
    "67661": "Kaiserslautern",
    "67663": "Kaiserslautern",
    "67677": "Enkenbach-Alsenborn",
    "67678": "Mehlingen",
    "67680": "Neuhemsbach",
    "67681": "Sembach",
    "67685": "Weilerbach u.a.",
    "67686": "Mackenbach",
    "67688": "Rodenbach",
    "67691": "Hochspeyer",
    "67693": "Waldleiningen, Fischbach",
    "67697": "Otterberg",
    "67699": "Schneckenhausen",
    "67700": "Niederkirchen",
    "67701": "Schallodenbach",
    "67705": "Trippstadt u.a.",
    "67706": "Krickenbach",
    "67707": "Schopp",
    "67714": "Waldfischbach-Burgalben",
    "67715": "Geiselberg",
    "67716": "Heltersberg",
    "67718": "Schmalenberg",
    "67722": "Winnweiler",
    "67724": "Gundersweiler, Gonbach u.a.",
    "67725": "Börrstadt",
    "67727": "Lohnsfeld",
    "67728": "Münchweiler an der Alsenz",
    "67729": "Sippersfeld",
    "67731": "Otterbach, Otterberg",
    "67732": "Hirschhorn",
    "67734": "Katzweiler",
    "67735": "Mehlbach",
    "67737": "Olsbrücken",
    "67742": "Lauterecken u.a.",
    "67744": "Medard, Rathskirchen u.a.",
    "67745": "Grumbach",
    "67746": "Langweiler",
    "67748": "Odenbach",
    "67749": "Offenbach-Hundheim",
    "67752": "Wolfstein",
    "67753": "Rothselberg u.a.",
    "67754": "Eßweiler",
    "67756": "Hinzweiler",
    "67757": "Kreimbach-Kaulbach",
    "67759": "Nußbach",
    "67806": "Rockenhausen, Bisterschied u.a.",
    "67808": "Steinbach, Weitersweiler, Bennhausen, Mörsfeld, Würzweiler, Ruppertsecke",
    "67811": "Dielkirchen",
    "67813": "Schwarzengraben, St. Alban, Gerbach",
    "67814": "Dannenfels",
    "67816": "Dreisen, Standenbühl",
    "67817": "Imsbach",
    "67819": "Kriegsfeld",
    "67821": "Alsenz",
    "67822": "Winterborn, Waldgrehweiler, Niedermoschel, u.a.",
    "67823": "Obermoschel, Schiersfeld",
    "67824": "Feilbingert",
    "67826": "Hallgarten",
    "67827": "Becherbach",
    "67829": "Callbach",
    "68159": "Mannheim",
    "68161": "Mannheim",
    "68163": "Mannheim",
    "68165": "Mannheim",
    "68167": "Mannheim",
    "68169": "Mannheim",
    "68199": "Mannheim",
    "68219": "Mannheim",
    "68229": "Mannheim",
    "68239": "Mannheim",
    "68259": "Mannheim",
    "68305": "Mannheim",
    "68307": "Mannheim",
    "68309": "Mannheim",
    "68519": "Viernheim",
    "68526": "Ladenburg",
    "68535": "Edingen-Neckarhausen",
    "68542": "Heddesheim",
    "68549": "Ilvesheim",
    "68623": "Lampertheim",
    "68642": "Bürstadt",
    "68647": "Biblis",
    "68649": "Groß-Rohrheim",
    "68723": "Schwetzingen",
    "68753": "Waghäusel",
    "68766": "Hockenheim",
    "68775": "Ketsch",
    "68782": "Brühl",
    "68789": "Sankt Leon-Rot",
    "68794": "Oberhausen-Rheinhausen",
    "68799": "Reilingen",
    "68804": "Altlußheim",
    "68809": "Neulußheim",
    "69115": "Heidelberg",
    "69117": "Heidelberg",
    "69118": "Heidelberg",
    "69120": "Heidelberg",
    "69121": "Heidelberg",
    "69123": "Heidelberg",
    "69124": "Heidelberg",
    "69126": "Heidelberg",
    "69151": "Neckargemünd",
    "69168": "Wiesloch",
    "69181": "Leimen",
    "69190": "Walldorf",
    "69198": "Schriesheim",
    "69207": "Sandhausen",
    "69214": "Eppelheim",
    "69221": "Dossenheim",
    "69226": "Nußloch",
    "69231": "Rauenberg",
    "69234": "Dielheim",
    "69239": "Neckarsteinach",
    "69242": "Mühlhausen",
    "69245": "Bammental",
    "69250": "Schönau",
    "69251": "Gaiberg",
    "69253": "Heiligkreuzsteinach",
    "69254": "Malsch",
    "69256": "Mauer",
    "69257": "Wiesenbach",
    "69259": "Wilhelmsfeld",
    "69412": "Eberbach",
    "69427": "Mudau",
    "69429": "Waldbrunn",
    "69434": "Hirschhorn, Brombach, Heddesbach",
    "69436": "Schönbrunn",
    "69437": "Neckargerach",
    "69439": "Zwingenberg",
    "69469": "Weinheim",
    "69483": "Wald-Michelbach",
    "69488": "Birkenau",
    "69493": "Hirschberg an der Bergstraße",
    "69502": "Hemsbach",
    "69509": "Mörlenbach",
    "69514": "Laudenbach",
    "69517": "Gorxheimertal",
    "69518": "Abtsteinach",
    "70173": "Stuttgart",
    "70174": "Stuttgart",
    "70176": "Stuttgart",
    "70178": "Stuttgart",
    "70180": "Stuttgart",
    "70182": "Stuttgart",
    "70184": "Stuttgart",
    "70186": "Stuttgart",
    "70188": "Stuttgart",
    "70190": "Stuttgart",
    "70191": "Stuttgart",
    "70192": "Stuttgart",
    "70193": "Stuttgart",
    "70195": "Stuttgart",
    "70197": "Stuttgart",
    "70199": "Stuttgart",
    "70327": "Stuttgart",
    "70329": "Stuttgart",
    "70372": "Stuttgart",
    "70374": "Stuttgart",
    "70376": "Stuttgart",
    "70378": "Stuttgart",
    "70435": "Stuttgart",
    "70437": "Stuttgart",
    "70439": "Stuttgart",
    "70469": "Stuttgart",
    "70499": "Stuttgart",
    "70563": "Stuttgart",
    "70565": "Stuttgart",
    "70567": "Stuttgart",
    "70569": "Stuttgart",
    "70597": "Stuttgart",
    "70599": "Stuttgart",
    "70619": "Stuttgart",
    "70629": "Stuttgart/Leinfelden-Echterdingen",
    "70734": "Fellbach",
    "70736": "Fellbach",
    "70771": "Leinfelden-Echterdingen",
    "70794": "Filderstadt",
    "70806": "Kornwestheim",
    "70825": "Korntal-Münchingen",
    "70839": "Gerlingen",
    "71032": "Böblingen",
    "71034": "Böblingen",
    "71063": "Sindelfingen",
    "71065": "Sindelfingen",
    "71067": "Sindelfingen",
    "71069": "Sindelfingen",
    "71083": "Herrenberg",
    "71088": "Holzgerlingen",
    "71093": "Weil im Schönbuch",
    "71101": "Schönaich",
    "71106": "Magstadt",
    "71111": "Waldenbuch",
    "71116": "Gärtringen",
    "71120": "Grafenau",
    "71126": "Gäufelden",
    "71131": "Jettingen",
    "71134": "Aidlingen",
    "71139": "Ehningen",
    "71144": "Steinenbronn",
    "71149": "Bondorf",
    "71154": "Nufringen",
    "71155": "Altdorf",
    "71157": "Hildrizhausen",
    "71159": "Mötzingen",
    "71229": "Leonberg",
    "71254": "Ditzingen",
    "71263": "Weil der Stadt",
    "71272": "Renningen",
    "71277": "Rutesheim",
    "71282": "Hemmingen",
    "71287": "Weissach",
    "71292": "Friolzheim",
    "71296": "Heimsheim",
    "71297": "Mönsheim",
    "71299": "Wimsheim",
    "71332": "Waiblingen",
    "71334": "Waiblingen",
    "71336": "Waiblingen",
    "71364": "Winnenden",
    "71384": "Weinstadt",
    "71394": "Kernen im Remstal",
    "71397": "Leutenbach",
    "71404": "Korb",
    "71409": "Schwaikheim",
    "71522": "Backnang",
    "71540": "Murrhardt",
    "71543": "Wüstenrot, Beilstein-Stocksberg",
    "71546": "Aspach",
    "71549": "Auenwald",
    "71554": "Weissach im Tal",
    "71560": "Sulzbach an der Murr",
    "71563": "Affalterbach",
    "71566": "Althütte",
    "71570": "Oppenweiler",
    "71573": "Allmersbach im Tal",
    "71576": "Burgstetten",
    "71577": "Großerlach",
    "71579": "Spiegelberg",
    "71634": "Ludwigsburg",
    "71636": "Ludwigsburg",
    "71638": "Ludwigsburg",
    "71640": "Ludwigsburg",
    "71642": "Ludwigsburg",
    "71665": "Vaihingen an der Enz",
    "71672": "Marbach am Neckar",
    "71679": "Asperg",
    "71686": "Remseck am Neckar",
    "71691": "Freiberg am Neckar",
    "71696": "Möglingen",
    "71701": "Schwieberdingen",
    "71706": "Markgröningen",
    "71711": "Steinheim, Murr",
    "71717": "Beilstein",
    "71720": "Oberstenfeld",
    "71723": "Großbottwar",
    "71726": "Benningen am Neckar",
    "71729": "Erdmannhausen",
    "71732": "Tamm",
    "71735": "Eberdingen",
    "71737": "Kirchberg",
    "71739": "Oberriexingen",
    "72070": "Tübingen",
    "72072": "Tübingen",
    "72074": "Tübingen",
    "72076": "Tübingen",
    "72108": "Rottenburg am Neckar",
    "72116": "Mössingen",
    "72119": "Ammerbuch",
    "72124": "Pliezhausen",
    "72127": "Kusterdingen",
    "72131": "Ofterdingen",
    "72135": "Dettenhausen",
    "72138": "Kirchentellinsfurt",
    "72141": "Walddorfhäslach",
    "72144": "Dußlingen",
    "72145": "Hirrlingen",
    "72147": "Nehren",
    "72149": "Neustetten",
    "72160": "Horb am Neckar",
    "72172": "Sulz am Neckar",
    "72175": "Dornhan",
    "72178": "Waldachtal",
    "72181": "Starzach",
    "72184": "Eutingen im Gäu",
    "72186": "Empfingen",
    "72189": "Vöhringen",
    "72202": "Nagold",
    "72213": "Altensteig",
    "72218": "Wildberg",
    "72221": "Haiterbach",
    "72224": "Ebhausen",
    "72226": "Simmersfeld",
    "72227": "Egenhausen",
    "72229": "Rohrdorf",
    "72250": "Freudenstadt",
    "72270": "Baiersbronn",
    "72275": "Alpirsbach",
    "72280": "Dornstetten",
    "72285": "Pfalzgrafenweiler",
    "72290": "Loßburg",
    "72293": "Glatten",
    "72294": "Grömbach",
    "72296": "Schopfloch",
    "72297": "Seewald",
    "72299": "Wörnersberg",
    "72336": "Balingen",
    "72348": "Rosenfeld",
    "72351": "Geislingen",
    "72355": "Schömberg",
    "72356": "Dautmergen",
    "72358": "Dormettingen",
    "72359": "Dotternhausen",
    "72361": "Hausen am Tann",
    "72362": "Nusplingen",
    "72364": "Obernheim",
    "72365": "Ratshausen",
    "72367": "Weilen unter den Rinnen",
    "72369": "Zimmern unter der Burg",
    "72379": "Hechingen",
    "72393": "Burladingen",
    "72401": "Haigerloch",
    "72406": "Bisingen",
    "72411": "Bodelshausen",
    "72414": "Rangendingen",
    "72415": "Grosselfingen",
    "72417": "Jungingen",
    "72419": "Neufra",
    "72458": "Albstadt",
    "72459": "Albstadt",
    "72461": "Albstadt",
    "72469": "Meßstetten",
    "72474": "Winterlingen",
    "72475": "Bitz",
    "72477": "Schwenningen",
    "72479": "Straßberg",
    "72488": "Sigmaringen",
    "72501": "Gammertingen",
    "72505": "Krauchenwies",
    "72510": "Stetten am kalten Markt",
    "72511": "Bingen",
    "72513": "Hettingen",
    "72514": "Inzigkofen",
    "72516": "Scheer",
    "72517": "Sigmaringendorf",
    "72519": "Veringenstadt",
    "72525": "Münsingen",
    "72531": "Hohenstein",
    "72532": "Gomadingen",
    "72534": "Hayingen",
    "72535": "Heroldstatt",
    "72537": "Mehrstetten",
    "72539": "Pfronstetten",
    "72555": "Metzingen",
    "72574": "Bad Urach",
    "72581": "Dettingen an der Erms",
    "72582": "Grabenstetten",
    "72584": "Hülben",
    "72585": "Riederich",
    "72587": "Römerstein",
    "72589": "Westerheim",
    "72622": "Nürtingen",
    "72631": "Aichtal",
    "72636": "Frickenhausen",
    "72639": "Neuffen",
    "72644": "Oberboihingen",
    "72649": "Wolfschlugen",
    "72654": "Neckartenzlingen",
    "72655": "Altdorf",
    "72657": "Altenriet",
    "72658": "Bempflingen",
    "72660": "Beuren",
    "72661": "Grafenberg",
    "72663": "Großbettlingen",
    "72664": "Kohlberg",
    "72666": "Neckartailfingen",
    "72667": "Schlaitdorf",
    "72669": "Unterensingen",
    "72760": "Reutlingen",
    "72762": "Reutlingen",
    "72764": "Reutlingen",
    "72766": "Reutlingen",
    "72768": "Reutlingen",
    "72770": "Reutlingen",
    "72793": "Pfullingen",
    "72800": "Eningen",
    "72805": "Lichtenstein",
    "72810": "Gomaringen",
    "72813": "St. Johann",
    "72818": "Trochtelfingen",
    "72820": "Sonnenbühl",
    "72827": "Wannweil",
    "72829": "Engstingen",
    "73033": "Göppingen",
    "73035": "Göppingen",
    "73037": "Göppingen",
    "73054": "Eislingen/Fils",
    "73061": "Ebersbach an der Fils",
    "73066": "Uhingen",
    "73072": "Donzdorf",
    "73079": "Süßen",
    "73084": "Salach",
    "73087": "Bad Boll",
    "73092": "Heiningen",
    "73095": "Albershausen",
    "73098": "Rechberghausen",
    "73099": "Adelberg",
    "73101": "Aichelberg",
    "73102": "Birenbach",
    "73104": "Börtlingen",
    "73105": "Dürnau",
    "73107": "Eschenbach",
    "73108": "Gammelshausen",
    "73110": "Hattenhofen",
    "73111": "Lauterstein",
    "73113": "Ottenbach",
    "73114": "Schlat",
    "73116": "Wäschenbeuren",
    "73117": "Wangen",
    "73119": "Zell unter Aichelberg",
    "73207": "Plochingen",
    "73230": "Kirchheim unter Teck",
    "73235": "Weilheim an der Teck",
    "73240": "Wendlingen am Neckar",
    "73249": "Wernau (Neckar)",
    "73252": "Lenningen",
    "73257": "Köngen",
    "73262": "Reichenbach an der Fils",
    "73265": "Dettingen unter Teck",
    "73266": "Bissingen an der Teck",
    "73268": "Erkenbrechtsweiler",
    "73269": "Hochdorf",
    "73271": "Holzmaden",
    "73272": "Neidlingen",
    "73274": "Notzingen",
    "73275": "Ohmden",
    "73277": "Owen",
    "73278": "Schlierbach",
    "73312": "Geislingen an der Steige",
    "73326": "Deggingen",
    "73329": "Kuchen",
    "73333": "Gingen an der Fils",
    "73337": "Bad Überkingen",
    "73340": "Amstetten",
    "73342": "Bad Ditzenbach",
    "73344": "Gruibingen",
    "73345": "Hohenstadt/Drackenstein",
    "73347": "Mühlhausen im Täle",
    "73349": "Wiesensteig",
    "73430": "Aalen",
    "73431": "Aalen",
    "73432": "Aalen",
    "73433": "Aalen",
    "73434": "Aalen",
    "73441": "Bopfingen",
    "73447": "Oberkochen",
    "73450": "Neresheim",
    "73453": "Abtsgmünd",
    "73457": "Essingen",
    "73460": "Hüttlingen",
    "73463": "Westhausen",
    "73466": "Lauchheim",
    "73467": "Kirchheim am Ries",
    "73469": "Riesbürg",
    "73479": "Ellwangen (Jagst)",
    "73485": "Unterschneidheim",
    "73486": "Adelmannsfelden",
    "73488": "Ellenberg",
    "73489": "Jagstzell",
    "73491": "Neuler",
    "73492": "Rainau",
    "73494": "Rosenberg",
    "73495": "Stödtlen",
    "73497": "Tannhausen",
    "73499": "Wört",
    "73525": "Stadt Schwäbisch Gmünd",
    "73527": "Schwäbisch Gmünd, Täferrot",
    "73529": "Stadt Schwäbisch Gmünd",
    "73540": "Heubach",
    "73547": "Lorch",
    "73550": "Waldstetten",
    "73553": "Alfdorf, Schillinghof",
    "73557": "Mutlangen",
    "73560": "Böbingen an der Rems",
    "73563": "Mögglingen",
    "73565": "Spraitbach",
    "73566": "Bartholomä",
    "73568": "Durlangen, Weggen-Ziegelhütte, Leinhäusle",
    "73569": "Eschach, Obergröningen",
    "73571": "Göggingen",
    "73572": "Heuchlingen",
    "73574": "Iggingen",
    "73575": "Leinzell",
    "73577": "Ruppertshofen",
    "73579": "Schechingen",
    "73614": "Schorndorf",
    "73630": "Remshalden",
    "73635": "Rudersberg",
    "73642": "Welzheim",
    "73650": "Winterbach",
    "73655": "Plüderhausen",
    "73660": "Urbach",
    "73663": "Berglen",
    "73666": "Baltmannsweiler",
    "73667": "Kaisersbach",
    "73669": "Lichtenwald",
    "73728": "Esslingen am Neckar",
    "73730": "Esslingen am Neckar",
    "73732": "Esslingen am Neckar",
    "73733": "Esslingen am Neckar",
    "73734": "Esslingen am Neckar",
    "73760": "Ostfildern",
    "73765": "Neuhausen auf den Fildern",
    "73770": "Denkendorf",
    "73773": "Aichwald",
    "73776": "Altbach",
    "73779": "Deizisau",
    "74072": "Heilbronn",
    "74074": "Heilbronn",
    "74076": "Heilbronn",
    "74078": "Heilbronn",
    "74080": "Heilbronn",
    "74081": "Heilbronn",
    "74172": "Neckarsulm",
    "74177": "Bad Friedrichshall",
    "74182": "Obersulm",
    "74189": "Weinsberg",
    "74193": "Schwaigern",
    "74196": "Neuenstadt am Kocher",
    "74199": "Untergruppenbach",
    "74206": "Bad Wimpfen",
    "74211": "Leingarten",
    "74214": "Schöntal",
    "74219": "Möckmühl",
    "74223": "Flein",
    "74226": "Nordheim",
    "74229": "Oedheim",
    "74232": "Abstatt",
    "74235": "Erlenbach",
    "74238": "Krautheim",
    "74239": "Hardthausen am Kocher",
    "74243": "Langenbrettach",
    "74245": "Löwenstein",
    "74246": "Eberstadt",
    "74248": "Ellhofen",
    "74249": "Jagsthausen",
    "74251": "Lehrensteinsfeld",
    "74252": "Massenbachhausen",
    "74254": "Offenau",
    "74255": "Roigheim",
    "74257": "Untereisesheim",
    "74259": "Widdern",
    "74321": "Bietigheim-Bissingen",
    "74336": "Brackenheim",
    "74343": "Sachsenheim",
    "74348": "Lauffen am Neckar",
    "74354": "Besigheim",
    "74357": "Bönnigheim",
    "74360": "Ilsfeld",
    "74363": "Güglingen",
    "74366": "Kirchheim am Neckar",
    "74369": "Löchgau",
    "74372": "Sersheim",
    "74374": "Zaberfeld",
    "74376": "Gemmrigheim",
    "74379": "Ingersheim",
    "74382": "Neckarwestheim",
    "74385": "Pleidelsheim",
    "74388": "Talheim",
    "74389": "Cleebronn",
    "74391": "Erligheim",
    "74392": "Freudental",
    "74394": "Hessigheim",
    "74395": "Mundelsheim",
    "74397": "Pfaffenhofen",
    "74399": "Walheim",
    "74405": "Gaildorf",
    "74417": "Gschwend",
    "74420": "Oberrot",
    "74423": "Obersontheim",
    "74424": "Bühlertann",
    "74426": "Bühlerzell",
    "74427": "Fichtenberg",
    "74429": "Sulzbach-Laufen",
    "74523": "Schwäbisch Hall",
    "74532": "Ilshofen",
    "74535": "Mainhardt",
    "74538": "Rosengarten",
    "74541": "Vellberg",
    "74542": "Braunsbach",
    "74544": "Michelbach an der Bilz",
    "74545": "Michelfeld",
    "74547": "Untermünkheim",
    "74549": "Wolpertshausen",
    "74564": "Crailsheim",
    "74572": "Blaufelden",
    "74575": "Schrozberg",
    "74579": "Fichtenau",
    "74582": "Gerabronn",
    "74585": "Rot am See",
    "74586": "Frankenhardt",
    "74589": "Satteldorf",
    "74592": "Kirchberg an der Jagst",
    "74594": "Kreßberg",
    "74595": "Langenburg",
    "74597": "Stimpfach",
    "74599": "Wallhausen",
    "74613": "Öhringen",
    "74626": "Bretzfeld",
    "74629": "Pfedelbach",
    "74632": "Neuenstein",
    "74635": "Kupferzell",
    "74638": "Waldenburg",
    "74639": "Zweiflingen",
    "74653": "Künzelsau, Ingelfingen",
    "74670": "Forchtenberg",
    "74673": "Mulfingen",
    "74676": "Niedernhall",
    "74677": "Dörzbach",
    "74679": "Weißbach",
    "74706": "Osterburken",
    "74722": "Buchen",
    "74731": "Walldürn",
    "74736": "Hardheim",
    "74740": "Adelsheim",
    "74743": "Seckach",
    "74744": "Ahorn",
    "74746": "Höpfingen",
    "74747": "Ravenstein",
    "74749": "Rosenberg",
    "74821": "Mosbach",
    "74831": "Gundelsheim",
    "74834": "Elztal",
    "74838": "Limbach",
    "74842": "Billigheim",
    "74847": "Obrigheim",
    "74850": "Schefflenz",
    "74855": "Haßmersheim",
    "74858": "Aglasterhausen",
    "74861": "Neudenau",
    "74862": "Binau",
    "74864": "Fahrenbach",
    "74865": "Neckarzimmern",
    "74867": "Neunkirchen",
    "74869": "Schwarzach",
    "74889": "Sinsheim",
    "74906": "Bad Rappenau",
    "74909": "Meckesheim",
    "74912": "Kirchardt",
    "74915": "Waibstadt",
    "74918": "Angelbachtal",
    "74921": "Helmstadt-Bargen",
    "74924": "Neckarbischofsheim",
    "74925": "Epfenbach",
    "74927": "Eschelbronn",
    "74928": "Hüffenhardt",
    "74930": "Ittlingen",
    "74931": "Lobbach",
    "74933": "Neidenstein",
    "74934": "Reichartshausen",
    "74936": "Siegelsbach",
    "74937": "Spechbach",
    "74939": "Zuzenhausen",
    "75015": "Bretten",
    "75031": "Eppingen",
    "75038": "Oberderdingen",
    "75045": "Walzbachtal",
    "75050": "Gemmingen",
    "75053": "Gondelsheim",
    "75056": "Sulzfeld",
    "75057": "Kürnbach",
    "75059": "Zaisenhausen",
    "75172": "Pforzheim",
    "75173": "Pforzheim",
    "75175": "Pforzheim",
    "75177": "Pforzheim",
    "75179": "Pforzheim",
    "75180": "Pforzheim",
    "75181": "Pforzheim",
    "75196": "Remchingen",
    "75203": "Königsbach-Stein",
    "75210": "Keltern",
    "75217": "Birkenfeld",
    "75223": "Niefern-Öschelbronn",
    "75228": "Ispringen",
    "75233": "Tiefenbronn",
    "75236": "Kämpfelbach",
    "75239": "Eisingen",
    "75242": "Neuhausen",
    "75245": "Neulingen",
    "75248": "Ölbronn-Dürrn",
    "75249": "Kieselbronn",
    "75305": "Neuenbürg",
    "75323": "Bad Wildbad",
    "75328": "Schömberg",
    "75331": "Engelsbrand",
    "75334": "Straubenhardt",
    "75335": "Dobel",
    "75337": "Enzklösterle",
    "75339": "Höfen an der Enz",
    "75365": "Calw",
    "75378": "Bad Liebenzell",
    "75382": "Althengstett",
    "75385": "Bad Teinach-Zavelstein",
    "75387": "Neubulach",
    "75389": "Neuweiler",
    "75391": "Gechingen",
    "75392": "Deckenpfronn",
    "75394": "Oberreichenbach",
    "75395": "Ostelsheim",
    "75397": "Simmozheim",
    "75399": "Unterreichenbach",
    "75417": "Mühlacker",
    "75428": "Illingen",
    "75433": "Maulbronn",
    "75438": "Knittlingen",
    "75443": "Ötisheim",
    "75446": "Wiernsheim",
    "75447": "Sternenfels",
    "75449": "Wurmberg",
    "76131": "Karlsruhe",
    "76133": "Karlsruhe",
    "76135": "Karlsruhe",
    "76137": "Karlsruhe",
    "76139": "Karlsruhe",
    "76149": "Karlsruhe",
    "76185": "Karlsruhe",
    "76187": "Karlsruhe",
    "76189": "Karlsruhe",
    "76199": "Karlsruhe",
    "76227": "Karlsruhe",
    "76228": "Karlsruhe",
    "76229": "Karlsruhe",
    "76275": "Ettlingen",
    "76287": "Rheinstetten",
    "76297": "Stutensee",
    "76307": "Karlsbad",
    "76316": "Malsch",
    "76327": "Pfinztal",
    "76332": "Bad Herrenalb",
    "76337": "Waldbronn",
    "76344": "Eggenstein-Leopoldshafen",
    "76351": "Linkenheim-Hochstetten",
    "76356": "Weingarten",
    "76359": "Marxzell",
    "76437": "Rastatt",
    "76448": "Durmersheim",
    "76456": "Kuppenheim",
    "76461": "Muggensturm",
    "76467": "Bietigheim",
    "76470": "Ötigheim",
    "76473": "Iffezheim",
    "76474": "Au am Rhein",
    "76476": "Bischweier",
    "76477": "Elchesheim-Illingen",
    "76479": "Steinmauern",
    "76530": "Baden-Baden",
    "76532": "Baden-Baden",
    "76534": "Baden-Baden",
    "76547": "Sinzheim",
    "76549": "Hügelsheim",
    "76571": "Gaggenau",
    "76593": "Gernsbach",
    "76596": "Forbach",
    "76597": "Loffenau",
    "76599": "Weisenbach",
    "76646": "Bruchsal",
    "76661": "Philippsburg",
    "76669": "Bad Schönborn",
    "76676": "Graben-Neudorf",
    "76684": "Östringen",
    "76689": "Karlsdorf-Neuthard",
    "76694": "Forst",
    "76698": "Ubstadt-Weiher",
    "76703": "Kraichtal",
    "76706": "Dettenheim",
    "76707": "Hambrücken",
    "76709": "Kronau",
    "76726": "Germersheim",
    "76744": "Wörth",
    "76751": "Jockgrim",
    "76756": "Bellheim",
    "76761": "Rülzheim",
    "76764": "Rheinzabern",
    "76767": "Hagenbach",
    "76768": "Berg",
    "76770": "Hatzenbühl",
    "76771": "Hördt",
    "76773": "Kuhardt",
    "76774": "Leimersheim",
    "76776": "Neuburg am Rhein",
    "76777": "Neupotz",
    "76779": "Scheibenhardt",
    "76829": "Landau in der Pfalz",
    "76831": "Billigheim-Ingenheim, Birkweiler",
    "76833": "Frankweiler",
    "76835": "Rhodt u.a.",
    "76846": "Hauenstein",
    "76848": "Wilgartswiesen",
    "76855": "Annweiler am Trifels",
    "76857": "Albersweiler, Silz u.a.",
    "76863": "Herxheim",
    "76865": "Insheim",
    "76870": "Kandel",
    "76872": "Steinweiler",
    "76877": "Offenbach an der Queich",
    "76879": "Hochstadt",
    "76887": "Bad Bergzabern u.a.",
    "76889": "Klingenmünster u.a.",
    "76891": "Bruchweiler-Bärenbach u.a.",
    "77652": "Offenburg",
    "77654": "Offenburg",
    "77656": "Offenburg",
    "77694": "Kehl",
    "77704": "Oberkirch",
    "77709": "Wolfach, Oberwolfach",
    "77716": "Fischerbach, Haslach, Hofstetten",
    "77723": "Gengenbach",
    "77728": "Oppenau",
    "77731": "Willstätt",
    "77736": "Zell am Harmersbach",
    "77740": "Bad Peterstal-Griesbach",
    "77743": "Neuried",
    "77746": "Schutterwald",
    "77749": "Hohberg",
    "77756": "Hausach",
    "77761": "Schiltach",
    "77767": "Appenweier",
    "77770": "Durbach",
    "77773": "Schenkenzell",
    "77776": "Bad Rippoldsau-Schapbach",
    "77781": "Biberach",
    "77784": "Oberharmersbach",
    "77787": "Nordrach",
    "77790": "Steinach",
    "77791": "Berghaupten",
    "77793": "Gutach (Schwarzwaldbahn)",
    "77794": "Lautenbach",
    "77796": "Mühlenbach",
    "77797": "Ohlsbach",
    "77799": "Ortenberg",
    "77815": "Bühl",
    "77830": "Bühlertal",
    "77833": "Ottersweier",
    "77836": "Rheinmünster",
    "77839": "Lichtenau",
    "77855": "Achern",
    "77866": "Rheinau",
    "77871": "Renchen",
    "77876": "Kappelrodeck",
    "77880": "Sasbach",
    "77883": "Ottenhöfen im Schwarzwald",
    "77886": "Lauf",
    "77887": "Sasbachwalden",
    "77889": "Seebach",
    "77933": "Lahr/Schwarzwald",
    "77948": "Friesenheim",
    "77955": "Ettenheim",
    "77960": "Seelbach",
    "77963": "Schwanau",
    "77966": "Kappel-Grafenhausen",
    "77971": "Kippenheim",
    "77972": "Mahlberg",
    "77974": "Meißenheim",
    "77975": "Ringsheim",
    "77977": "Rust",
    "77978": "Schuttertal",
    "78048": "Villingen-Schwenningen",
    "78050": "Villingen-Schwenningen",
    "78052": "Villingen-Schwenningen",
    "78054": "Villingen-Schwenningen",
    "78056": "Villingen-Schwenningen",
    "78073": "Bad Dürrheim",
    "78078": "Niedereschach",
    "78083": "Dauchingen",
    "78086": "Brigachtal",
    "78087": "Mönchweiler",
    "78089": "Unterkirnach",
    "78098": "Triberg im Schwarzwald",
    "78112": "Sankt Georgen im Schwarzwald",
    "78120": "Furtwangen im Schwarzwald",
    "78126": "Königsfeld im Schwarzwald",
    "78132": "Hornberg",
    "78136": "Schonach im Schwarzwald",
    "78141": "Schönwald im Schwarzwald",
    "78144": "Schramberg",
    "78147": "Vöhrenbach",
    "78148": "Gütenbach",
    "78166": "Donaueschingen",
    "78176": "Blumberg",
    "78183": "Hüfingen",
    "78187": "Geisingen",
    "78194": "Immendingen",
    "78199": "Bräunlingen",
    "78224": "Singen (Hohentwiel)",
    "78234": "Engen",
    "78239": "Rielasingen-Worblingen",
    "78244": "Gottmadingen",
    "78247": "Hilzingen",
    "78250": "Tengen",
    "78253": "Eigeltingen",
    "78256": "Steißlingen",
    "78259": "Mühlhausen-Ehingen",
    "78262": "Gailingen am Hochrhein",
    "78266": "Büsingen am Hochrhein",
    "78267": "Aach",
    "78269": "Volkertshausen",
    "78315": "Radolfzell am Bodensee",
    "78333": "Stockach",
    "78337": "Öhningen",
    "78343": "Gaienhofen",
    "78345": "Moos",
    "78351": "Bodman-Ludwigshafen",
    "78354": "Sipplingen",
    "78355": "Hohenfels",
    "78357": "Mühlingen",
    "78359": "Orsingen-Nenzingen",
    "78462": "Konstanz",
    "78464": "Konstanz",
    "78465": "Konstanz",
    "78467": "Konstanz",
    "78476": "Allensbach",
    "78479": "Reichenau",
    "78532": "Tuttlingen",
    "78549": "Spaichingen",
    "78554": "Aldingen",
    "78559": "Gosheim",
    "78564": "Wehingen, Reichenbach",
    "78567": "Fridingen an der Donau",
    "78570": "Mühlheim an der Donau",
    "78573": "Wurmlingen",
    "78576": "Emmingen-Liptingen",
    "78579": "Neuhausen ob Eck",
    "78580": "Bärenthal",
    "78582": "Balgheim",
    "78583": "Böttingen",
    "78585": "Bubsheim",
    "78586": "Deilingen",
    "78588": "Denkingen",
    "78589": "Dürbheim",
    "78591": "Durchhausen",
    "78592": "Egesheim",
    "78594": "Gunningen",
    "78595": "Hausen ob Verena",
    "78597": "Irndorf",
    "78598": "Königsheim",
    "78600": "Kolbingen",
    "78601": "Mahlstetten",
    "78603": "Renquishausen",
    "78604": "Rietheim-Weilheim",
    "78606": "Seitingen-Oberflacht",
    "78607": "Talheim",
    "78609": "Tuningen",
    "78628": "Rottweil",
    "78647": "Trossingen",
    "78652": "Deißlingen",
    "78655": "Dunningen",
    "78658": "Zimmern ob Rottweil",
    "78661": "Dietingen",
    "78662": "Bösingen",
    "78664": "Eschbronn",
    "78665": "Frittlingen",
    "78667": "Villingendorf",
    "78669": "Wellendingen",
    "78713": "Schramberg",
    "78727": "Oberndorf am Neckar",
    "78730": "Lauterbach",
    "78733": "Aichhalden",
    "78736": "Epfendorf",
    "78737": "Fluorn-Winzeln",
    "78739": "Hardt",
    "79098": "Freiburg im Breisgau",
    "79100": "Freiburg im Breisgau",
    "79102": "Freiburg im Breisgau",
    "79104": "Freiburg im Breisgau",
    "79106": "Freiburg im Breisgau",
    "79108": "Freiburg im Breisgau",
    "79110": "Freiburg im Breisgau",
    "79111": "Freiburg im Breisgau",
    "79112": "Freiburg im Breisgau",
    "79114": "Freiburg im Breisgau",
    "79115": "Freiburg im Breisgau",
    "79117": "Freiburg im Breisgau",
    "79183": "Waldkirch",
    "79189": "Bad Krozingen",
    "79194": "Gundelfingen, Heuweiler",
    "79199": "Kirchzarten",
    "79206": "Breisach am Rhein",
    "79211": "Denzlingen",
    "79215": "Elzach, Biederbach",
    "79219": "Staufen im Breisgau",
    "79224": "Umkirch",
    "79227": "Schallstadt",
    "79232": "March",
    "79235": "Vogtsburg im Kaiserstuhl",
    "79238": "Ehrenkirchen",
    "79241": "Ihringen",
    "79244": "Münstertal",
    "79249": "Merzhausen",
    "79252": "Stegen",
    "79254": "Oberried",
    "79256": "Buchenbach",
    "79258": "Hartheim",
    "79261": "Gutach im Breisgau",
    "79263": "Simonswald",
    "79268": "Bötzingen",
    "79271": "Sankt Peter",
    "79274": "St. Märgen",
    "79276": "Reute",
    "79279": "Vörstetten",
    "79280": "Au (Breisgau)",
    "79282": "Ballrechten-Dottingen",
    "79283": "Bollschweil",
    "79285": "Ebringen",
    "79286": "Glottertal",
    "79288": "Gottenheim",
    "79289": "Horben",
    "79291": "Merdingen",
    "79292": "Pfaffenweiler",
    "79294": "Sölden",
    "79295": "Sulzburg",
    "79297": "Winden im Elztal",
    "79299": "Wittnau",
    "79312": "Emmendingen",
    "79331": "Teningen",
    "79336": "Herbolzheim",
    "79341": "Kenzingen",
    "79346": "Endingen am Kaiserstuhl",
    "79348": "Freiamt",
    "79350": "Sexau",
    "79353": "Bahlingen",
    "79356": "Eichstetten",
    "79359": "Riegel",
    "79361": "Sasbach am Kaiserstuhl",
    "79362": "Forchheim",
    "79364": "Malterdingen",
    "79365": "Rheinhausen",
    "79367": "Weisweil",
    "79369": "Wyhl",
    "79379": "Müllheim",
    "79395": "Neuenburg am Rhein",
    "79400": "Kandern",
    "79410": "Badenweiler",
    "79415": "Bad Bellingen",
    "79418": "Schliengen",
    "79423": "Heitersheim",
    "79424": "Auggen",
    "79426": "Buggingen",
    "79427": "Eschbach",
    "79429": "Malsburg-Marzell",
    "79539": "Lörrach",
    "79540": "Lörrach",
    "79541": "Lörrach",
    "79576": "Weil am Rhein",
    "79585": "Steinen",
    "79588": "Efringen-Kirchen",
    "79589": "Binzen",
    "79591": "Eimeldingen",
    "79592": "Fischingen",
    "79594": "Inzlingen",
    "79595": "Rümmingen",
    "79597": "Schallbach",
    "79599": "Wittlingen",
    "79618": "Rheinfelden (Baden)",
    "79639": "Grenzach-Wyhlen",
    "79650": "Schopfheim",
    "79664": "Wehr",
    "79669": "Zell im Wiesental",
    "79674": "Todtnau",
    "79677": "Schönau im Schwarzwald",
    "79682": "Todtmoos",
    "79685": "Häg-Ehrsberg",
    "79686": "Hasel",
    "79688": "Hausen im Wiesental",
    "79689": "Maulburg",
    "79692": "Kleines Wiesental",
    "79694": "Utzenfeld",
    "79695": "Wieden",
    "79713": "Bad Säckingen",
    "79725": "Laufenburg (Baden)",
    "79730": "Murg",
    "79733": "Görwihl",
    "79736": "Rickenbach",
    "79737": "Herrischried",
    "79739": "Schwörstadt",
    "79761": "Waldshut-Tiengen",
    "79771": "Klettgau",
    "79774": "Albbruck",
    "79777": "Ühlingen-Birkendorf",
    "79780": "Stühlingen",
    "79787": "Lauchringen",
    "79790": "Küssaberg",
    "79793": "Wutöschingen",
    "79798": "Jestetten",
    "79801": "Hohentengen am Hochrhein",
    "79802": "Dettighofen",
    "79804": "Dogern",
    "79805": "Eggingen",
    "79807": "Lottstetten",
    "79809": "Weilheim",
    "79822": "Titisee-Neustadt",
    "79837": "St. Blasien, Ibach",
    "79843": "Löffingen",
    "79848": "Bonndorf im Schwarzwald",
    "79853": "Lenzkirch",
    "79856": "Hinterzarten",
    "79859": "Schluchsee",
    "79862": "Höchenschwand",
    "79865": "Grafenhausen",
    "79868": "Feldberg",
    "79871": "Eisenbach",
    "79872": "Bernau im Schwarzwald",
    "79874": "Breitnau",
    "79875": "Dachsberg",
    "79877": "Friedenweiler",
    "79879": "Wutach",
    "80331": "München",
    "80333": "München",
    "80335": "München",
    "80336": "München",
    "80337": "München",
    "80339": "München",
    "80469": "München",
    "80538": "München",
    "80539": "München",
    "80634": "München",
    "80636": "München",
    "80637": "München",
    "80638": "München",
    "80639": "München",
    "80686": "München",
    "80687": "München",
    "80689": "München",
    "80796": "München",
    "80797": "München",
    "80798": "München",
    "80799": "München",
    "80801": "München",
    "80802": "München",
    "80803": "München",
    "80804": "München",
    "80805": "München",
    "80807": "München",
    "80809": "München",
    "80933": "München",
    "80935": "München",
    "80937": "München",
    "80939": "München",
    "80992": "München",
    "80993": "München",
    "80995": "München",
    "80997": "München",
    "80999": "München",
    "81241": "München",
    "81243": "München",
    "81245": "München",
    "81247": "München",
    "81249": "München",
    "81369": "München",
    "81371": "München",
    "81373": "München",
    "81375": "München",
    "81377": "München",
    "81379": "München",
    "81475": "München",
    "81476": "München",
    "81477": "München",
    "81479": "München",
    "81539": "München",
    "81541": "München",
    "81543": "München",
    "81545": "München",
    "81547": "München",
    "81549": "München",
    "81667": "München",
    "81669": "München",
    "81671": "München",
    "81673": "München",
    "81675": "München",
    "81677": "München",
    "81679": "München",
    "81735": "München",
    "81737": "München",
    "81739": "München",
    "81825": "München",
    "81827": "München",
    "81829": "München",
    "81925": "München",
    "81927": "München",
    "81929": "München",
    "82008": "Unterhaching",
    "82024": "Taufkirchen",
    "82031": "Grünwald",
    "82041": "Oberhaching",
    "82049": "Pullach i. Isartal",
    "82054": "Sauerlach",
    "82057": "Icking",
    "82061": "Neuried",
    "82064": "Straßlach-Dingharting",
    "82065": "Baierbrunn",
    "82067": "Schäftlarn",
    "82069": "Schäftlarn",
    "82110": "Germering",
    "82131": "Gauting",
    "82140": "Olching",
    "82152": "Planegg/Krailling",
    "82166": "Gräfelfing",
    "82178": "Puchheim",
    "82194": "Gröbenzell",
    "82205": "Gilching",
    "82211": "Herrsching a. Ammersee",
    "82216": "Maisach",
    "82223": "Eichenau",
    "82229": "Seefeld",
    "82234": "Weßling",
    "82237": "Wörthsee",
    "82239": "Alling",
    "82256": "Fürstenfeldbruck",
    "82266": "Inning a. Ammersee",
    "82269": "Geltendorf",
    "82272": "Moorenweis",
    "82275": "Emmering",
    "82276": "Adelshofen",
    "82278": "Althegnenberg",
    "82279": "Eching a. Ammersee",
    "82281": "Egenhofen",
    "82284": "Grafrath",
    "82285": "Hattenhofen",
    "82287": "Jesenwang",
    "82288": "Kottgeisering",
    "82290": "Landsberied",
    "82291": "Mammendorf",
    "82293": "Mittelstetten",
    "82294": "Oberschweinbach",
    "82296": "Schöngeising",
    "82297": "Steindorf",
    "82299": "Türkenfeld",
    "82319": "Starnberg",
    "82327": "Tutzing",
    "82335": "Berg",
    "82340": "Feldafing",
    "82343": "Pöcking",
    "82346": "Andechs",
    "82347": "Bernried",
    "82349": "Pentenried",
    "82362": "Weilheim i. OB",
    "82377": "Penzberg",
    "82380": "Peißenberg",
    "82383": "Hohenpeißenberg",
    "82386": "Huglfing",
    "82387": "Antdorf",
    "82389": "Böbing",
    "82390": "Eberfing",
    "82392": "Habach",
    "82393": "Iffeldorf",
    "82395": "Obersöchering",
    "82396": "Pähl",
    "82398": "Polling",
    "82399": "Raisting",
    "82401": "Rottenbuch",
    "82402": "Seeshaupt",
    "82404": "Sindelsdorf",
    "82405": "Wessobrunn",
    "82407": "Wielenbach",
    "82409": "Wildsteig",
    "82418": "Murnau a. Staffelsee",
    "82431": "Kochel a. See",
    "82432": "Walchensee",
    "82433": "Bad Kohlgrub",
    "82435": "Bayersoien",
    "82436": "Eglfing",
    "82438": "Eschenlohe",
    "82439": "Großweil",
    "82441": "Ohlstadt",
    "82442": "Saulgrub",
    "82444": "Schlehdorf",
    "82445": "Schwaigen",
    "82447": "Spatzenhausen",
    "82449": "Uffing a. Staffelsee",
    "82467": "Garmisch-Partenkirchen",
    "82475": "Garmisch-Partenkirchen (Schneefernerhaus)",
    "82481": "Mittenwald",
    "82487": "Oberammergau",
    "82488": "Ettal",
    "82490": "Farchant",
    "82491": "Grainau",
    "82493": "Krün",
    "82494": "Krün",
    "82496": "Oberau",
    "82497": "Unterammergau",
    "82499": "Wallgau",
    "82515": "Wolfratshausen",
    "82538": "Geretsried",
    "82541": "Münsing",
    "82544": "Egling",
    "82547": "Eurasburg",
    "82549": "Königsdorf",
    "83022": "Rosenheim",
    "83024": "Rosenheim",
    "83026": "Rosenheim",
    "83043": "Bad Aibling",
    "83052": "Bruckmühl",
    "83059": "Kolbermoor",
    "83064": "Raubling",
    "83071": "Stephanskirchen",
    "83075": "Bad Feilnbach",
    "83080": "Oberaudorf",
    "83083": "Riedering",
    "83088": "Kiefersfelden",
    "83093": "Bad Endorf",
    "83098": "Brannenburg",
    "83101": "Rohrdorf",
    "83104": "Tuntenhausen",
    "83109": "Großkarolinenfeld",
    "83112": "Frasdorf",
    "83115": "Neubeuern",
    "83119": "Obing",
    "83122": "Samerberg",
    "83123": "Amerang",
    "83125": "Eggstätt",
    "83126": "Flintsbach a. Inn",
    "83128": "Halfing",
    "83129": "Höslwang",
    "83131": "Nußdorf a. Inn",
    "83132": "Pittenhart",
    "83134": "Prutting",
    "83135": "Schechen",
    "83137": "Schonstett",
    "83139": "Söchtenau",
    "83209": "Prien a. Chiemsee, Herrenchiemssee",
    "83224": "Grassau",
    "83229": "Aschau i. Chiemgau",
    "83233": "Bernau a. Chiemsee",
    "83236": "Übersee",
    "83242": "Reit im Winkl",
    "83246": "Unterwössen",
    "83250": "Marquartstein",
    "83253": "Rimsting",
    "83254": "Breitbrunn a. Chiemsee",
    "83256": "Chiemsee",
    "83257": "Gstadt a. Chiemsee",
    "83259": "Schleching",
    "83278": "Traunstein",
    "83301": "Traunreut",
    "83308": "Trostberg",
    "83313": "Siegsdorf",
    "83317": "Teisendorf",
    "83324": "Ruhpolding",
    "83329": "Waging a. See",
    "83334": "Inzell",
    "83339": "Chieming",
    "83342": "Tacherting",
    "83346": "Bergen",
    "83349": "Palling",
    "83352": "Altenmarkt a.d. Alz",
    "83355": "Grabenstätt",
    "83358": "Seeon-Seebruck",
    "83361": "Kienberg",
    "83362": "Surberg",
    "83364": "Neukirchen am Teisenberg",
    "83365": "Nußdorf",
    "83367": "Petting",
    "83368": "St. Georgen",
    "83370": "Seeon-Seebruck",
    "83371": "Stein a.d. Traun",
    "83373": "Taching a. See",
    "83374": "Traunreut",
    "83376": "Seeon-Seebruck",
    "83377": "Vachendorf",
    "83379": "Wonneberg",
    "83395": "Freilassing",
    "83404": "Ainring",
    "83410": "Laufen",
    "83413": "Fridolfing",
    "83416": "Saaldorf",
    "83417": "Kirchanschöring",
    "83435": "Bad Reichenhall",
    "83451": "Piding",
    "83454": "Anger",
    "83457": "Bayerisch Gmain",
    "83458": "Schneizlreuth",
    "83471": "Berchtesgaden & Schönau",
    "83483": "Bischofswiesen",
    "83486": "Ramsau b. Berchtesgaden",
    "83487": "Marktschellenberg",
    "83512": "Wasserburg a. Inn",
    "83527": "Haag i. OB",
    "83530": "Schnaitsee",
    "83533": "Edling",
    "83536": "Gars a. Inn",
    "83539": "Pfaffing",
    "83543": "Rott a. Inn",
    "83544": "Albaching",
    "83546": "Gars am Inn",
    "83547": "Babensham",
    "83549": "Eiselfing",
    "83550": "Emmering",
    "83553": "Frauenneuharting",
    "83555": "Gars a. Inn",
    "83556": "Griesstätt",
    "83558": "Maitenbeth",
    "83559": "Gars a. Inn",
    "83561": "Ramerberg",
    "83562": "Rechtmehring",
    "83564": "Soyen",
    "83565": "in der Gde Frauenneuharting",
    "83567": "Unterreit",
    "83569": "Vogtareuth",
    "83607": "Holzkirchen",
    "83620": "Feldkirchen-Westerham",
    "83623": "Dietramszell",
    "83624": "Otterfing",
    "83626": "Valley",
    "83627": "Warngau",
    "83629": "Weyarn",
    "83646": "Bad Tölz, Wackersberg",
    "83661": "Lenggries",
    "83666": "Waakirchen",
    "83670": "Bad Heilbrunn",
    "83671": "Benediktbeuern",
    "83673": "Bichl",
    "83674": "Gaißach",
    "83676": "Jachenau",
    "83677": "Reichersbeuern",
    "83679": "Sachsenkam",
    "83684": "Tegernsee",
    "83700": "Rottach-Egern",
    "83703": "Gmund a. Tegernsee",
    "83707": "Bad Wiessee",
    "83708": "Kreuth",
    "83714": "Miesbach",
    "83727": "Schliersee",
    "83730": "Fischbachau",
    "83734": "Hausham",
    "83735": "Bayrischzell",
    "83737": "Irschenberg",
    "84028": "Landshut",
    "84030": "Ergolding, Landshut",
    "84032": "Landshut, Linden, Altdorf",
    "84034": "Landshut",
    "84036": "Landshut",
    "84048": "Mainburg",
    "84051": "Essenbach",
    "84056": "Rottenburg a.d. Laaber",
    "84061": "Ergoldsbach",
    "84066": "Mallersdorf-Pfaffenberg",
    "84069": "Schierling",
    "84072": "Au in der Hallertau",
    "84076": "Pfeffenhausen",
    "84079": "Bruckberg",
    "84082": "Laberweinting",
    "84085": "Langquaid",
    "84088": "Neufahrn i. NB",
    "84089": "Aiglsbach",
    "84091": "Attenhofen",
    "84092": "Bayerbach bei Ergoldsbach",
    "84094": "Elsendorf",
    "84095": "Furth",
    "84097": "Herrngiersdorf",
    "84098": "Hohenthann",
    "84100": "Niederaichbach",
    "84101": "Obersüßbach",
    "84103": "Postau",
    "84104": "Rudelzhausen",
    "84106": "Volkenschwand",
    "84107": "Weihmichl",
    "84109": "Wörth a.d. Isar",
    "84130": "Dingolfing",
    "84137": "Vilsbiburg",
    "84140": "Gangkofen",
    "84144": "Geisenhausen",
    "84149": "Velden",
    "84152": "Mengkofen",
    "84155": "Bodenkirchen",
    "84160": "Frontenhausen",
    "84163": "Marklkofen",
    "84164": "Moosthenning",
    "84166": "Adlkofen",
    "84168": "Aham",
    "84169": "Altfraunhofen",
    "84171": "Baierbach",
    "84172": "Buch a. Erlbach",
    "84174": "Eching",
    "84175": "Gerzen",
    "84177": "Gottfrieding",
    "84178": "Kröning",
    "84180": "Loiching",
    "84181": "Neufraunhofen",
    "84183": "Niederviehbach",
    "84184": "Tiefenbach",
    "84186": "Vilsheim",
    "84187": "Weng",
    "84189": "Wurmsham",
    "84307": "Eggenfelden",
    "84323": "Massing",
    "84326": "Falkenberg",
    "84329": "Wurmannsquick",
    "84332": "Hebertsfelden",
    "84333": "Malgersdorf",
    "84335": "Mitterskirchen",
    "84337": "Schönau",
    "84339": "Unterdietfurt",
    "84347": "Pfarrkirchen",
    "84359": "Simbach a. Inn",
    "84364": "Bad Birnbach",
    "84367": "Tann",
    "84371": "Triftern",
    "84375": "Kirchdorf a. Inn",
    "84378": "Dietersburg",
    "84381": "Johanniskirchen",
    "84384": "Wittibreut",
    "84385": "Egglham",
    "84387": "Julbach",
    "84389": "Postmünster",
    "84405": "Dorfen",
    "84416": "Taufkirchen (Vils)",
    "84419": "Schwindegg",
    "84424": "Isen",
    "84427": "Sankt Wolfgang",
    "84428": "Buchbach",
    "84431": "Heldenstein",
    "84432": "Hohenpolding",
    "84434": "Kirchberg",
    "84435": "Lengdorf",
    "84437": "Reichertsheim",
    "84439": "Steinkirchen",
    "84453": "Mühldorf a. Inn",
    "84478": "Waldkraiburg",
    "84489": "Burghausen",
    "84494": "Neumarkt-Sankt Veit",
    "84503": "Altötting",
    "84508": "Burgkirchen an der Alz",
    "84513": "Töging a. Inn",
    "84518": "Garching a.d. Alz",
    "84524": "Neuötting",
    "84529": "Tittmoning",
    "84533": "Marktl",
    "84539": "Ampfing",
    "84543": "Winhöring",
    "84544": "Aschau a. Inn",
    "84546": "Egglkofen",
    "84547": "Emmerting",
    "84549": "Engelsberg",
    "84550": "Feichten a.d. Alz",
    "84552": "Geratskirchen",
    "84553": "Halsbach",
    "84555": "Jettenbach",
    "84556": "Kastl",
    "84558": "Kirchweidach",
    "84559": "Kraiburg a. Inn",
    "84561": "Mehring",
    "84562": "Mettenheim",
    "84564": "Oberbergkirchen",
    "84565": "Oberneukirchen",
    "84567": "Erlbach",
    "84568": "Pleiskirchen",
    "84570": "Polling",
    "84571": "Reischach",
    "84573": "Schönberg",
    "84574": "Taufkirchen",
    "84576": "Teising",
    "84577": "Tüßling",
    "84579": "Unterneukirchen",
    "85049": "Ingolstadt",
    "85051": "Ingolstadt",
    "85053": "Ingolstadt",
    "85055": "Ingolstadt",
    "85057": "Ingolstadt",
    "85072": "Eichstätt",
    "85077": "Manching",
    "85080": "Gaimersheim",
    "85084": "Reichertshofen",
    "85088": "Vohburg a.d. Donau",
    "85092": "Kösching",
    "85095": "Denkendorf",
    "85098": "Großmehring",
    "85101": "Lenting",
    "85104": "Pförring",
    "85107": "Baar-Ebenhausen",
    "85110": "Kipfenberg",
    "85111": "Adelschlag",
    "85113": "Böhmfeld",
    "85114": "Buxheim",
    "85116": "Egweil",
    "85117": "Eitensheim",
    "85119": "Ernsgaden",
    "85120": "Hepberg",
    "85122": "Hitzhofen",
    "85123": "Karlskron",
    "85125": "Kinding",
    "85126": "Münchsmünster",
    "85128": "Nassenfels",
    "85129": "Oberdolling",
    "85131": "Pollenfeld",
    "85132": "Schernfeld",
    "85134": "Stammham",
    "85135": "Titting",
    "85137": "Walting",
    "85139": "Wettstetten",
    "85221": "Dachau",
    "85229": "Markt Indersdorf",
    "85232": "Bergkirchen",
    "85235": "Odelzhausen",
    "85238": "Petershausen",
    "85241": "Hebertshausen",
    "85244": "Röhrmoos",
    "85247": "Schwabhausen",
    "85250": "Altomünster",
    "85253": "Erdweg",
    "85254": "Sulzemoos",
    "85256": "Vierkirchen",
    "85258": "Weichs",
    "85259": "Sulzemoos",
    "85276": "Pfaffenhofen a.d.Ilm",
    "85283": "Wolnzach",
    "85290": "Geisenfeld",
    "85293": "Reichertshausen",
    "85296": "Rohrbach",
    "85298": "Scheyern",
    "85301": "Schweitenkirchen",
    "85302": "Gerolsbach",
    "85304": "Ilmmünster",
    "85305": "Jetzendorf",
    "85307": "Paunzhausen",
    "85309": "Pörnbach",
    "85354": "Freising",
    "85356": "Freising",
    "85368": "Moosburg a.d. Isar",
    "85375": "Neufahrn b. Freising",
    "85376": "Neufahrn b. Freising",
    "85386": "Eching",
    "85391": "Allershausen",
    "85395": "Attenkirchen",
    "85399": "Hallbergmoos",
    "85402": "Kranzberg",
    "85405": "Nandlstadt",
    "85406": "Zolling",
    "85408": "Gammelsdorf",
    "85410": "Haag a.d. Amper",
    "85411": "Hohenkammer",
    "85413": "Hörgertshausen",
    "85414": "Kirchdorf a.d. Amper",
    "85416": "Langenbach",
    "85417": "Marzling",
    "85419": "Mauern",
    "85435": "Erding",
    "85445": "Oberding",
    "85447": "Fraunberg",
    "85452": "Moosinning",
    "85456": "Wartenberg",
    "85457": "Wörth",
    "85459": "Berglern",
    "85461": "Bockhorn",
    "85462": "Eitting",
    "85464": "Finsing",
    "85465": "Langenpreising",
    "85467": "Neuching",
    "85469": "Walpertskirchen",
    "85521": "Ottobrunn/Riemerling",
    "85540": "Haar",
    "85551": "Kirchheim b. München",
    "85560": "Ebersberg",
    "85567": "Grafing b. München",
    "85570": "Markt Schwaben",
    "85579": "Neubiberg",
    "85586": "Poing",
    "85591": "Vaterstetten",
    "85598": "Baldham",
    "85599": "Parsdorf/Hergolding",
    "85604": "Zorneding",
    "85609": "Aschheim",
    "85614": "Kirchseeon",
    "85617": "Aßling",
    "85622": "Feldkirchen",
    "85625": "Glonn",
    "85630": "Grasbrunn",
    "85635": "Höhenkirchen-Siegertsbrunn",
    "85640": "Putzbrunn",
    "85643": "Steinhöring",
    "85646": "Anzing",
    "85649": "Brunnthal",
    "85652": "Pliening",
    "85653": "Aying",
    "85656": "Buch a. Buchrain",
    "85658": "Egmating",
    "85659": "Forstern",
    "85661": "Forstinning",
    "85662": "Hohenbrunn",
    "85664": "Hohenlinden",
    "85665": "Moosach",
    "85667": "Oberpframmern",
    "85669": "Pastetten",
    "85716": "Unterschleißheim",
    "85737": "Ismaning",
    "85748": "Garching b. München",
    "85757": "Karlsfeld",
    "85764": "Oberschleißheim",
    "85774": "Unterföhring",
    "85777": "Fahrenzhausen",
    "85778": "Haimhausen",
    "86150": "Augsburg",
    "86152": "Augsburg",
    "86153": "Augsburg",
    "86154": "Augsburg",
    "86156": "Augsburg",
    "86157": "Augsburg",
    "86159": "Augsburg",
    "86161": "Augsburg",
    "86163": "Augsburg",
    "86165": "Augsburg",
    "86167": "Augsburg",
    "86169": "Augsburg",
    "86179": "Augsburg",
    "86199": "Augsburg",
    "86316": "Friedberg",
    "86343": "Königsbrunn",
    "86356": "Neusäß",
    "86368": "Gersthofen",
    "86381": "Krumbach (Schwaben)",
    "86391": "Stadtbergen",
    "86399": "Bobingen",
    "86405": "Meitingen",
    "86415": "Mering",
    "86420": "Diedorf",
    "86424": "Dinkelscherben",
    "86438": "Kissing",
    "86441": "Zusmarshausen",
    "86444": "Affing",
    "86447": "Aindling",
    "86450": "Altenmünster",
    "86453": "Dasing",
    "86456": "Gablingen",
    "86459": "Gessertshausen",
    "86462": "Langweid a. Lech",
    "86465": "Welden",
    "86470": "Thannhausen",
    "86473": "Ziemetshausen",
    "86476": "Neuburg a.d. Kammel",
    "86477": "Adelsried",
    "86479": "Aichen",
    "86480": "Aletshausen",
    "86482": "Aystetten",
    "86483": "Balzhausen",
    "86485": "Biberbach",
    "86486": "Bonstetten",
    "86488": "Breitenthal",
    "86489": "Deisenhausen",
    "86491": "Ebershausen",
    "86492": "Egling a.d. Paar",
    "86494": "Emersacker",
    "86495": "Eurasburg",
    "86497": "Horgau",
    "86498": "Kettershausen",
    "86500": "Kutzenhausen",
    "86502": "Laugna",
    "86504": "Merching",
    "86505": "Münsterhausen",
    "86507": "Oberottmarshausen",
    "86508": "Rehling",
    "86510": "Ried",
    "86511": "Schmiechen",
    "86513": "Ursberg",
    "86514": "Ustersbach",
    "86517": "Wehringen",
    "86519": "Wiesenbach",
    "86529": "Schrobenhausen",
    "86551": "Aichach",
    "86554": "Pöttmes",
    "86556": "Kühbach",
    "86558": "Hohenwart",
    "86559": "Adelzhausen",
    "86561": "Aresing",
    "86562": "Berg im Gau",
    "86564": "Brunnen",
    "86565": "Gachenbach",
    "86567": "Hilgertshausen-Tandern",
    "86568": "Hollenbach",
    "86570": "Inchenhofen",
    "86571": "Langenmosen",
    "86573": "Obergriesbach",
    "86574": "Petersdorf",
    "86576": "Schiltberg",
    "86577": "Sielenbach",
    "86579": "Waidhofen",
    "86609": "Donauwörth",
    "86633": "Neuburg an der Donau",
    "86637": "Wertingen",
    "86641": "Rain",
    "86643": "Rennertshofen",
    "86647": "Buttenwiesen",
    "86650": "Wemding",
    "86653": "Monheim",
    "86655": "Harburg",
    "86657": "Bissingen",
    "86660": "Tapfheim",
    "86663": "Asbach-Bäumenheim",
    "86666": "Burgheim",
    "86668": "Karlshuld",
    "86669": "Königsmoos",
    "86672": "Thierhaupten",
    "86673": "Bergheim",
    "86674": "Baar",
    "86675": "Buchdorf",
    "86676": "Ehekirchen",
    "86678": "Ehingen",
    "86679": "Ellgau",
    "86681": "Fünfstetten",
    "86682": "Genderkingen",
    "86684": "Holzheim",
    "86685": "Huisheim",
    "86687": "Kaisheim",
    "86688": "Marxheim",
    "86690": "Mertingen",
    "86692": "Münster",
    "86694": "Niederschönenfeld",
    "86695": "Nordendorf",
    "86697": "Oberhausen",
    "86698": "Oberndorf a.Lech",
    "86700": "Otting",
    "86701": "Rohrenfels",
    "86703": "Rögling",
    "86704": "Tagmersheim",
    "86706": "Weichering",
    "86707": "Westendorf, Kühlenthal",
    "86709": "Wolferstadt",
    "86720": "Nördlingen",
    "86732": "Oettingen i. Bay.",
    "86733": "Alerheim",
    "86735": "Amerdingen",
    "86736": "Auhausen",
    "86738": "Deiningen",
    "86739": "Ederheim",
    "86741": "Ehingen a. Ries",
    "86742": "Fremdingen",
    "86744": "Hainsfarth",
    "86745": "Hohenaltheim",
    "86747": "Maihingen",
    "86748": "Marktoffingen",
    "86750": "Megesheim",
    "86751": "Mönchsdeggingen",
    "86753": "Möttingen",
    "86754": "Munningen",
    "86756": "Reimlingen",
    "86757": "Wallerstein",
    "86759": "Wechingen",
    "86807": "Buchloe",
    "86825": "Bad Wörishofen",
    "86830": "Schwabmünchen",
    "86833": "Ettringen",
    "86836": "Untermeitingen",
    "86842": "Türkheim",
    "86845": "Großaitingen",
    "86850": "Fischach",
    "86853": "Langerringen",
    "86854": "Amberg",
    "86856": "Hiltenfingen",
    "86857": "Hurlach",
    "86859": "Igling",
    "86860": "Jengen",
    "86862": "Lamerdingen",
    "86863": "Langenneufnach",
    "86865": "Markt Wald",
    "86866": "Mickhausen",
    "86868": "Mittelneufnach",
    "86869": "Oberostendorf",
    "86871": "Rammingen",
    "86872": "Scherstetten",
    "86874": "Tussenhausen",
    "86875": "Waal",
    "86877": "Walkertshofen",
    "86879": "Wiedergeltingen",
    "86899": "Landsberg a. Lech",
    "86911": "Dießen a. Ammersee",
    "86916": "Kaufering",
    "86919": "Utting a. Ammersee",
    "86920": "Denklingen",
    "86922": "Eresing",
    "86923": "Finning",
    "86925": "Fuchstal",
    "86926": "Greifenberg",
    "86928": "Hofstetten",
    "86929": "Penzing",
    "86931": "Prittriching",
    "86932": "Pürgen",
    "86934": "Reichling",
    "86935": "Rott",
    "86937": "Scheuring",
    "86938": "Schondorf a. Ammersee",
    "86940": "Schwifting",
    "86941": "Eresing",
    "86943": "Thaining",
    "86944": "Unterdießen",
    "86946": "Vilgertshofen",
    "86947": "Weil",
    "86949": "Windach",
    "86956": "Schongau",
    "86971": "Peiting",
    "86972": "Altenstadt",
    "86974": "Apfeldorf",
    "86975": "Bernbeuren",
    "86977": "Burggen",
    "86978": "Hohenfurch",
    "86980": "Ingenried",
    "86981": "Kinsau",
    "86983": "Lechbruck",
    "86984": "Prem",
    "86986": "Schwabbruck",
    "86987": "Schwabsoien",
    "86989": "Steingaden",
    "87435": "Kempten (Allgäu)",
    "87437": "Kempten (Allgäu)",
    "87439": "Kempten (Allgäu)",
    "87448": "Waltenhofen",
    "87452": "Altusried",
    "87459": "Pfronten",
    "87463": "Dietmannsried",
    "87466": "Oy-Mittelberg",
    "87471": "Durach",
    "87474": "Buchenberg",
    "87477": "Sulzberg",
    "87480": "Weitnau",
    "87484": "Nesselwang",
    "87487": "Wiggensbach",
    "87488": "Betzigau",
    "87490": "Haldenwang",
    "87493": "Lauben",
    "87494": "Rückholz",
    "87496": "Untrasried",
    "87497": "Wertach",
    "87499": "Wildpoldsried",
    "87509": "Immenstadt im Allgäu",
    "87527": "Sonthofen",
    "87534": "Oberstaufen",
    "87538": "Fischen im Allgäu",
    "87541": "Bad Hindelang",
    "87544": "Blaichach",
    "87545": "Burgberg im Allgäu",
    "87547": "Missen-Wilhams",
    "87549": "Rettenberg",
    "87561": "Oberstdorf",
    "87600": "Kaufbeuren",
    "87616": "Marktoberdorf",
    "87629": "Füssen",
    "87634": "Obergünzburg",
    "87637": "Seeg",
    "87640": "Biessenhofen",
    "87642": "Halblech",
    "87645": "Schwangau",
    "87647": "Unterthingau",
    "87648": "Aitrang",
    "87650": "Baisweil",
    "87651": "Bidingen",
    "87653": "Eggenthal",
    "87654": "Friesenried",
    "87656": "Germaringen",
    "87657": "Görisried",
    "87659": "Hopferau",
    "87660": "Irsee",
    "87662": "Kaltental",
    "87663": "Lengenwang",
    "87665": "Mauerstetten",
    "87666": "Pforzen",
    "87668": "Rieden",
    "87669": "Rieden am Forggensee",
    "87671": "Ronsberg",
    "87672": "Roßhaupten",
    "87674": "Ruderatshofen",
    "87675": "Stötten am Auerberg",
    "87677": "Stöttwang",
    "87679": "Westendorf",
    "87700": "Memmingen",
    "87719": "Mindelheim",
    "87724": "Ottobeuren",
    "87727": "Babenhausen",
    "87730": "Bad Grönenbach",
    "87733": "Markt Rettenbach",
    "87734": "Benningen",
    "87736": "Böhen",
    "87737": "Boos",
    "87739": "Breitenbrunn",
    "87740": "Buxheim",
    "87742": "Apfeltrach",
    "87743": "Egg an der Günz",
    "87745": "Eppishausen",
    "87746": "Erkheim",
    "87748": "Fellheim",
    "87749": "Hawangen",
    "87751": "Heimertingen",
    "87752": "Holzgünz",
    "87754": "Kammlach",
    "87755": "Kirchhaslach",
    "87757": "Kirchheim in Schwaben",
    "87758": "Kronburg",
    "87760": "Lachen",
    "87761": "Lauben",
    "87763": "Lautrach",
    "87764": "Legau",
    "87766": "Memmingerberg",
    "87767": "Niederrieden",
    "87769": "Oberrieden",
    "87770": "Oberschönegg",
    "87772": "Pfaffenhausen",
    "87773": "Pleß",
    "87775": "Salgen",
    "87776": "Sontheim",
    "87778": "Stetten",
    "87779": "Trunkelsberg",
    "87781": "Ungerhausen",
    "87782": "Unteregg",
    "87784": "Westerheim",
    "87785": "Winterrieden",
    "87787": "Wolfertschwenden",
    "87789": "Woringen",
    "88045": "Friedrichshafen",
    "88046": "Friedrichshafen",
    "88048": "Friedrichshafen",
    "88069": "Tettnang",
    "88074": "Meckenbeuren",
    "88079": "Kressbronn am Bodensee",
    "88085": "Langenargen",
    "88090": "Immenstaad am Bodensee",
    "88094": "Oberteuringen",
    "88097": "Eriskirch",
    "88099": "Neukirch",
    "88131": "Lindau (Bodensee)",
    "88138": "Sigmarszell",
    "88142": "Wasserburg (Bodensee)",
    "88145": "Hergatz",
    "88147": "Achberg",
    "88149": "Nonnenhorn",
    "88161": "Lindenberg im Allgäu",
    "88167": "Röthenbach (Allgäu)",
    "88171": "Weiler-Simmerberg",
    "88175": "Scheidegg",
    "88178": "Heimenkirch",
    "88179": "Oberreute",
    "88212": "Ravensburg",
    "88213": "Ravensburg",
    "88214": "Ravensburg",
    "88239": "Wangen im Allgäu",
    "88250": "Weingarten",
    "88255": "Baienfurt",
    "88260": "Argenbühl",
    "88263": "Horgenzell",
    "88267": "Vogt",
    "88271": "Wilhelmsdorf",
    "88273": "Fronreute",
    "88276": "Berg",
    "88279": "Amtzell",
    "88281": "Schlier",
    "88284": "Wolpertswende",
    "88285": "Bodnegg",
    "88287": "Grünkraut",
    "88289": "Waldburg",
    "88299": "Leutkirch im Allgäu",
    "88316": "Isny im Allgäu",
    "88317": "Aichstetten",
    "88319": "Aitrach",
    "88326": "Aulendorf",
    "88339": "Bad Waldsee",
    "88348": "Bad Saulgau, Allmannsweiler",
    "88353": "Kißlegg",
    "88356": "Ostrach",
    "88361": "Altshausen",
    "88364": "Wolfegg",
    "88367": "Hohentengen",
    "88368": "Bergatreute",
    "88370": "Ebenweiler",
    "88371": "Ebersbach-Musbach",
    "88373": "Fleischwangen",
    "88374": "Hoßkirch",
    "88376": "Königseggwald",
    "88377": "Riedhausen",
    "88379": "Unterwaldhausen",
    "88400": "Biberach an der Riß",
    "88410": "Bad Wurzach",
    "88416": "Ochsenhausen",
    "88422": "Bad Buchau",
    "88427": "Bad Schussenried",
    "88430": "Rot an der Rot",
    "88433": "Schemmerhofen",
    "88436": "Eberhardzell",
    "88437": "Maselheim",
    "88441": "Mittelbiberach",
    "88444": "Ummendorf",
    "88447": "Warthausen",
    "88448": "Attenweiler",
    "88450": "Berkheim",
    "88451": "Dettingen an der Iller",
    "88453": "Erolzheim",
    "88454": "Hochdorf",
    "88456": "Ingoldingen",
    "88457": "Kirchdorf an der Iller",
    "88459": "Tannheim",
    "88471": "Laupheim",
    "88477": "Schwendi",
    "88480": "Achstetten",
    "88481": "Balzheim",
    "88483": "Burgrieden",
    "88484": "Gutenzell-Hürbel",
    "88486": "Kirchberg an der Iller",
    "88487": "Mietingen",
    "88489": "Wain",
    "88499": "Riedlingen",
    "88512": "Mengen",
    "88515": "Langenenslingen",
    "88518": "Herbertingen",
    "88521": "Ertingen",
    "88524": "Uttenweiler",
    "88525": "Dürmentingen",
    "88527": "Unlingen",
    "88529": "Zwiefalten",
    "88605": "Meßkirch, Sauldorf",
    "88630": "Pfullendorf",
    "88631": "Beuron",
    "88633": "Heiligenberg",
    "88634": "Herdwangen-Schönach",
    "88636": "Illmensee",
    "88637": "Leibertingen, Buchheim",
    "88639": "Wald",
    "88662": "Überlingen",
    "88677": "Markdorf",
    "88682": "Salem",
    "88690": "Uhldingen-Mühlhofen",
    "88693": "Deggenhausertal",
    "88696": "Owingen",
    "88697": "Bermatingen",
    "88699": "Frickingen",
    "88709": "Meersburg",
    "88718": "Daisendorf",
    "88719": "Stetten",
    "89073": "Ulm",
    "89075": "Ulm",
    "89077": "Ulm",
    "89079": "Ulm",
    "89081": "Ulm",
    "89129": "Langenau",
    "89134": "Blaustein",
    "89143": "Blaubeuren",
    "89150": "Laichingen",
    "89155": "Erbach",
    "89160": "Dornstadt",
    "89165": "Dietenheim",
    "89168": "Niederstotzingen",
    "89171": "Illerkirchberg",
    "89173": "Lonsee",
    "89174": "Altheim (Alb)",
    "89176": "Asselfingen",
    "89177": "Ballendorf",
    "89179": "Beimerstetten",
    "89180": "Berghülen",
    "89182": "Bernstadt",
    "89183": "Breitingen",
    "89185": "Hüttisheim",
    "89186": "Illerrieden",
    "89188": "Merklingen",
    "89189": "Neenstetten",
    "89191": "Nellingen",
    "89192": "Rammingen",
    "89194": "Schnürpflingen",
    "89195": "Staig",
    "89197": "Weidenstetten",
    "89198": "Westerstetten",
    "89231": "Neu-Ulm",
    "89233": "Neu-Ulm",
    "89250": "Senden",
    "89257": "Illertissen",
    "89264": "Weißenhorn",
    "89269": "Vöhringen",
    "89275": "Elchingen",
    "89278": "Nersingen",
    "89281": "Altenstadt",
    "89284": "Pfaffenhofen a.d. Roth",
    "89287": "Bellenberg",
    "89290": "Buch",
    "89291": "Holzheim",
    "89293": "Kellmünz a.d. Iller",
    "89294": "Oberroth",
    "89296": "Osterberg",
    "89297": "Roggenburg",
    "89299": "Unterroth",
    "89312": "Günzburg",
    "89331": "Burgau",
    "89335": "Ichenhausen",
    "89340": "Leipheim",
    "89343": "Jettingen-Scheppach",
    "89344": "Aislingen",
    "89346": "Bibertal",
    "89347": "Bubesheim",
    "89349": "Burtenbach",
    "89350": "Dürrlauingen",
    "89352": "Ellzee",
    "89353": "Glött",
    "89355": "Gundremmingen",
    "89356": "Haldenwang",
    "89358": "Kammeltal",
    "89359": "Kötz",
    "89361": "Landensberg",
    "89362": "Offingen",
    "89364": "Rettenbach",
    "89365": "Röfingen",
    "89367": "Waldstetten",
    "89368": "Winterbach",
    "89407": "Dillingen a.d. Donau",
    "89415": "Lauingen (Donau)",
    "89420": "Höchstädt a.d. Donau",
    "89423": "Gundelfingen a.d. Donau",
    "89426": "Wittislingen",
    "89428": "Syrgenstein",
    "89429": "Bachhagel",
    "89431": "Bächingen a.d. Brenz",
    "89434": "Blindheim",
    "89435": "Finningen",
    "89437": "Haunsheim",
    "89438": "Holzheim",
    "89440": "Lutzingen",
    "89441": "Medlingen",
    "89443": "Schwenningen",
    "89446": "Ziertheim",
    "89447": "Zöschingen",
    "89518": "Heidenheim an der Brenz",
    "89520": "Heidenheim an der Brenz",
    "89522": "Heidenheim an der Brenz",
    "89537": "Giengen an der Brenz",
    "89542": "Herbrechtingen",
    "89547": "Gerstetten",
    "89551": "Königsbronn",
    "89555": "Steinheim am Albuch",
    "89558": "Böhmenkirch",
    "89561": "Dischingen",
    "89564": "Nattheim",
    "89567": "Sontheim an der Brenz",
    "89568": "Hermaringen",
    "89584": "Ehingen (Donau), Lauterach",
    "89597": "Munderkingen",
    "89601": "Schelklingen",
    "89604": "Allmendingen",
    "89605": "Altheim",
    "89607": "Emerkingen",
    "89608": "Griesingen",
    "89610": "Oberdischingen",
    "89611": "Obermarchtal",
    "89613": "Oberstadion",
    "89614": "Öpfingen",
    "89616": "Rottenacker",
    "89617": "Untermarchtal",
    "89619": "Unterstadion",
    "90402": "Nürnberg",
    "90403": "Nürnberg",
    "90408": "Nürnberg",
    "90409": "Nürnberg",
    "90411": "Nürnberg",
    "90419": "Nürnberg",
    "90425": "Nürnberg",
    "90427": "Nürnberg",
    "90429": "Nürnberg",
    "90431": "Nürnberg",
    "90439": "Nürnberg",
    "90441": "Nürnberg",
    "90443": "Nürnberg",
    "90449": "Nürnberg",
    "90451": "Nürnberg",
    "90453": "Nürnberg",
    "90455": "Nürnberg",
    "90459": "Nürnberg",
    "90461": "Nürnberg",
    "90469": "Nürnberg",
    "90471": "Nürnberg",
    "90473": "Nürnberg",
    "90475": "Nürnberg",
    "90478": "Nürnberg",
    "90480": "Nürnberg",
    "90482": "Nürnberg",
    "90489": "Nürnberg",
    "90491": "Nürnberg",
    "90513": "Zirndorf",
    "90518": "Altdorf bei Nürnberg",
    "90522": "Oberasbach",
    "90530": "Wendelstein",
    "90537": "Nürnberg-Feucht, Feuchter Forst",
    "90542": "Eckental",
    "90547": "Stein",
    "90552": "Röthenbach an der Pegnitz",
    "90556": "Cadolzburg",
    "90559": "Burgthann",
    "90562": "Heroldsberg",
    "90571": "Schwaig b. Nürnberg, Behringersdorfer Forst",
    "90574": "Roßtal",
    "90579": "Langenzenn",
    "90584": "Allersberg",
    "90587": "Veitsbronn",
    "90592": "Schwarzenbruck",
    "90596": "Schwanstetten",
    "90599": "Dietenhofen",
    "90602": "Pyrbaum",
    "90607": "Rückersdorf",
    "90610": "Winkelhaid",
    "90613": "Großhabersdorf",
    "90614": "Ammerndorf",
    "90616": "Neuhof a.d.Zenn",
    "90617": "Puschendorf",
    "90619": "Trautskirchen",
    "90762": "Fürth",
    "90763": "Fürth",
    "90765": "Fürth",
    "90766": "Fürth",
    "90768": "Fürth",
    "91052": "Erlangen",
    "91054": "Erlangen",
    "91056": "Erlangen",
    "91058": "Erlangen",
    "91074": "Herzogenaurach",
    "91077": "Neunkirchen a. Brand",
    "91080": "Uttenreuth, Marloffstein",
    "91083": "Baiersdorf",
    "91085": "Weisendorf",
    "91086": "Aurachtal",
    "91088": "Bubenreuth",
    "91090": "Effeltrich",
    "91091": "Großenseebach",
    "91093": "Heßdorf",
    "91094": "Langensendelbach",
    "91096": "Möhrendorf/Mark",
    "91097": "Oberreichenbach",
    "91099": "Poxdorf",
    "91126": "Schwabach",
    "91154": "Roth",
    "91161": "Hilpoltstein",
    "91166": "Georgensgmünd",
    "91171": "Greding",
    "91174": "Spalt",
    "91177": "Thalmässing",
    "91180": "Heideck",
    "91183": "Abenberg",
    "91186": "Büchenbach",
    "91187": "Röttenbach",
    "91189": "Rohr",
    "91207": "Lauf an der Pegnitz",
    "91217": "Hersbruck",
    "91220": "Schnaittach",
    "91224": "Pommelsbrunn",
    "91227": "Leinburg",
    "91230": "Happurg",
    "91233": "Neunkirchen am Sand",
    "91235": "Velden/Hartenstein",
    "91236": "Alfeld",
    "91238": "Engelthal/Offenhausen",
    "91239": "Henfenfeld",
    "91241": "Kirchensittenbach",
    "91242": "Ottensoos",
    "91244": "Reichenschwand",
    "91245": "Simmelsdorf",
    "91247": "Vorra",
    "91249": "Weigendorf",
    "91257": "Pegnitz",
    "91275": "Auerbach i.d. OPf.",
    "91278": "Pottenstein",
    "91281": "Kirchenthumbach",
    "91282": "Betzenstein",
    "91284": "Neuhaus a.d.Pegnitz",
    "91286": "Obertrubach",
    "91287": "Plech",
    "91289": "Schnabelwaid",
    "91301": "Forchheim",
    "91315": "Höchstadt a.d.Aisch",
    "91320": "Ebermannstadt",
    "91322": "Gräfenberg",
    "91325": "Adelsdorf",
    "91327": "Gößweinstein",
    "91330": "Eggolsheim",
    "91332": "Heiligenstadt i. OFr.",
    "91334": "Hemhofen",
    "91336": "Heroldsbach",
    "91338": "Igensdorf",
    "91341": "Röttenbach",
    "91344": "Waischenfeld",
    "91346": "Wiesenttal",
    "91347": "Aufseß",
    "91349": "Egloffstein",
    "91350": "Gremsdorf",
    "91352": "Hallerndorf",
    "91353": "Hausen",
    "91355": "Hiltpoltstein",
    "91356": "Kirchehrenbach",
    "91358": "Kunreuth",
    "91359": "Leutenbach",
    "91361": "Pinzberg",
    "91362": "Pretzfeld",
    "91364": "Unterleinleiter",
    "91365": "Weilersbach",
    "91367": "Weißenohe",
    "91369": "Wiesenthau",
    "91413": "Neustadt a.d. Aisch",
    "91438": "Bad Windsheim",
    "91443": "Scheinfeld",
    "91448": "Emskirchen",
    "91452": "Wilhermsdorf",
    "91456": "Diespeck",
    "91459": "Markt Erlbach",
    "91460": "Baudenbach",
    "91462": "Dachsbach",
    "91463": "Dietersheim",
    "91465": "Ergersheim",
    "91466": "Gerhardshofen",
    "91468": "Gutenstetten",
    "91469": "Hagenbüchach",
    "91471": "Illesheim",
    "91472": "Ipsheim",
    "91474": "Langenfeld",
    "91475": "Lonnerstadt",
    "91477": "Markt Bibart",
    "91478": "Markt Nordheim",
    "91480": "Markt Taschendorf",
    "91481": "Münchsteinach",
    "91483": "Oberscheinfeld",
    "91484": "Sugenheim",
    "91486": "Uehlfeld",
    "91487": "Vestenbergsgreuth",
    "91489": "Wilhelmsdorf",
    "91522": "Ansbach",
    "91541": "Rothenburg ob der Tauber",
    "91550": "Dinkelsbühl",
    "91555": "Feuchtwangen",
    "91560": "Heilsbronn",
    "91564": "Neuendettelsau",
    "91567": "Herrieden",
    "91572": "Bechhofen",
    "91575": "Windsbach",
    "91578": "Leutershausen",
    "91580": "Petersaurach",
    "91583": "Schillingsfürst",
    "91586": "Lichtenau",
    "91587": "Adelshofen",
    "91589": "Aurach",
    "91590": "Bruckberg",
    "91592": "Buch a. Wald",
    "91593": "Burgbernheim",
    "91595": "Burgoberbach",
    "91596": "Burk",
    "91598": "Colmberg",
    "91599": "Dentlein a. Forst",
    "91601": "Dombühl",
    "91602": "Dürrwangen",
    "91604": "Flachslanden",
    "91605": "Gallmersgarten",
    "91607": "Gebsattel",
    "91608": "Geslau",
    "91610": "Insingen",
    "91611": "Lehrberg",
    "91613": "Marktbergel",
    "91614": "Mönchsroth",
    "91616": "Neusitz",
    "91617": "Oberdachstetten",
    "91619": "Obernzenn",
    "91620": "Ohrenbach",
    "91622": "Rügland",
    "91623": "Sachsen b. Ansbach",
    "91625": "Schnelldorf",
    "91626": "Schopfloch",
    "91628": "Steinsfeld",
    "91629": "Weihenzell",
    "91631": "Wettringen",
    "91632": "Wieseth",
    "91634": "Wilburgstetten",
    "91635": "Windelsbach",
    "91637": "Wörnitz",
    "91639": "Wolframs-Eschenbach",
    "91710": "Gunzenhausen",
    "91717": "Wassertrüdingen",
    "91719": "Heidenheim",
    "91720": "Absberg",
    "91722": "Arberg",
    "91723": "Dittenheim",
    "91725": "Ehingen",
    "91726": "Gerolfingen",
    "91728": "Gnotzheim",
    "91729": "Haundorf",
    "91731": "Langfurth",
    "91732": "Merkendorf",
    "91734": "Mitteleschenbach",
    "91735": "Muhr a. See",
    "91737": "Ornbau",
    "91738": "Pfofeld",
    "91740": "Röckingen",
    "91741": "Theilenhofen",
    "91743": "Unterschwaningen",
    "91744": "Weiltingen",
    "91746": "Weidenbach",
    "91747": "Westheim",
    "91749": "Wittelshofen",
    "91757": "Treuchtlingen",
    "91781": "Weißenburg i. Bay.",
    "91785": "Pleinfeld",
    "91788": "Pappenheim",
    "91790": "Nennslingen",
    "91792": "Ellingen",
    "91793": "Alesheim",
    "91795": "Dollnstein",
    "91796": "Ettenstatt",
    "91798": "Höttingen",
    "91799": "Langenaltheim",
    "91801": "Markt Berolzheim",
    "91802": "Meinheim",
    "91804": "Mörnsheim",
    "91805": "Polsingen",
    "91807": "Solnhofen",
    "91809": "Wellheim",
    "92224": "Amberg",
    "92237": "Sulzbach-Rosenberg",
    "92242": "Hirschau",
    "92245": "Kümmersbruck",
    "92249": "Vilseck",
    "92253": "Schnaittenbach",
    "92256": "Hahnbach",
    "92259": "Neukirchen b. Sulzbach-Rosen",
    "92260": "Ammerthal",
    "92262": "Birgland",
    "92263": "Ebermannsdorf",
    "92265": "Edelsfeld",
    "92266": "Ensdorf",
    "92268": "Etzelwang",
    "92269": "Fensterbach",
    "92271": "Freihung",
    "92272": "Freudenberg",
    "92274": "Gebenbach",
    "92275": "Hirschbach",
    "92277": "Hohenburg",
    "92278": "Illschwang",
    "92280": "Kastl",
    "92281": "Königstein",
    "92283": "Lauterhofen",
    "92284": "Poppenricht",
    "92286": "Rieden",
    "92287": "Schmidmühlen",
    "92289": "Ursensollen",
    "92318": "Neumarkt i.d. OPf.",
    "92331": "Parsberg",
    "92334": "Berching",
    "92339": "Beilngries",
    "92342": "Freystadt",
    "92345": "Dietfurt a.d. Altmühl",
    "92348": "Berg b. Neumarkt i.d. OPf.",
    "92353": "Postbauer-Heng",
    "92355": "Velburg",
    "92358": "Seubersdorf i.d. OPf.",
    "92360": "Mühlhausen",
    "92361": "Berngau",
    "92363": "Breitenbrunn",
    "92364": "Deining",
    "92366": "Hohenfels",
    "92367": "Pilsach",
    "92369": "Sengenthal",
    "92421": "Schwandorf",
    "92431": "Neunburg vorm Wald",
    "92436": "Bruck i.d. OPf.",
    "92439": "Bodenwöhr",
    "92442": "Wackersdorf",
    "92444": "Rötz",
    "92445": "Neukirchen-Balbini",
    "92447": "Schwarzhofen",
    "92449": "Steinberg",
    "92507": "Nabburg",
    "92521": "Schwarzenfeld",
    "92526": "Oberviechtach",
    "92533": "Wernberg-Köblitz",
    "92536": "Pfreimd",
    "92539": "Schönsee",
    "92540": "Altendorf",
    "92542": "Dieterskirchen",
    "92543": "Guteneck",
    "92545": "Niedermurach",
    "92546": "Schmidgaden",
    "92548": "Schwarzach b. Nabburg",
    "92549": "Stadlern",
    "92551": "Stulln",
    "92552": "Teunz",
    "92554": "Thanstein",
    "92555": "Trausnitz",
    "92557": "Weiding",
    "92559": "Winklarn",
    "92637": "Weiden in der OPf., Theisseil",
    "92648": "Vohenstrauß",
    "92655": "Grafenwöhr",
    "92660": "Neustadt a.d. Waldnaab",
    "92665": "Altenstadt a.d. Waldnaab",
    "92670": "Windischeschenbach",
    "92676": "Eschenbach i.d. OPf.",
    "92681": "Erbendorf",
    "92685": "Floß",
    "92690": "Pressath",
    "92693": "Eslarn",
    "92694": "Etzenricht",
    "92696": "Flossenbürg",
    "92697": "Georgenberg",
    "92699": "Bechtsried",
    "92700": "Kaltenbrunn",
    "92702": "Kohlberg",
    "92703": "Krummennaab",
    "92705": "Leuchtenberg",
    "92706": "Luhe-Wildenau",
    "92708": "Mantel",
    "92709": "Moosbach",
    "92711": "Parkstein",
    "92712": "Pirk",
    "92714": "Pleystein",
    "92715": "Püchersreuth",
    "92717": "Reuth b. Erbendorf",
    "92718": "Schirmitz",
    "92720": "Schwarzenbach",
    "92721": "Störnstein",
    "92723": "Tännesberg",
    "92724": "Trabitz",
    "92726": "Waidhaus",
    "92727": "Waldthurn",
    "92729": "Weiherhammer",
    "93047": "Regensburg",
    "93049": "Regensburg",
    "93051": "Regensburg",
    "93053": "Regensburg",
    "93055": "Regensburg",
    "93057": "Regensburg",
    "93059": "Regensburg",
    "93073": "Neutraubling",
    "93077": "Bad Abbach",
    "93080": "Pentling",
    "93083": "Obertraubling",
    "93086": "Wörth an der Donau",
    "93087": "Alteglofsheim",
    "93089": "Aufhausen",
    "93090": "Bach an der Donau",
    "93092": "Barbing",
    "93093": "Donaustauf",
    "93095": "Hagelstadt",
    "93096": "Köfering",
    "93098": "Mintraching",
    "93099": "Mötzing",
    "93101": "Pfakofen",
    "93102": "Pfatter",
    "93104": "Sünching",
    "93105": "Tegernheim",
    "93107": "Thalmassing",
    "93109": "Wiesent",
    "93128": "Regenstauf",
    "93133": "Burglengenfeld",
    "93138": "Lappersdorf",
    "93142": "Maxhütte-Haidhof",
    "93149": "Nittenau",
    "93152": "Nittendorf",
    "93155": "Hemau",
    "93158": "Teublitz",
    "93161": "Sinzing",
    "93164": "Laaber, Brunn",
    "93167": "Falkenstein",
    "93170": "Bernhardswald",
    "93173": "Wenzenbach",
    "93176": "Beratzhausen",
    "93177": "Altenthann",
    "93179": "Brennberg",
    "93180": "Deuerling",
    "93182": "Duggendorf",
    "93183": "Kallmünz",
    "93185": "Michelsneukirchen",
    "93186": "Pettendorf",
    "93188": "Pielenhofen",
    "93189": "Reichenbach",
    "93191": "Rettenbach",
    "93192": "Wald",
    "93194": "Walderbach",
    "93195": "Wolfsegg",
    "93197": "Zeitlarn",
    "93199": "Zell",
    "93309": "Kelheim",
    "93326": "Abensberg",
    "93333": "Neustadt a.d. Donau",
    "93336": "Altmannstein",
    "93339": "Riedenburg",
    "93342": "Saal a.d. Donau",
    "93343": "Essing",
    "93345": "Hausen",
    "93346": "Ihrlerstein",
    "93348": "Kirchdorf",
    "93349": "Mindelstetten",
    "93351": "Painten",
    "93352": "Rohr i. NB",
    "93354": "Siegenburg",
    "93356": "Teugn",
    "93358": "Train",
    "93359": "Wildenberg",
    "93413": "Cham",
    "93426": "Roding",
    "93437": "Furth i. Wald",
    "93444": "Kötzting",
    "93449": "Waldmünchen",
    "93453": "Neukirchen b. Hl. Blut",
    "93455": "Traitsching",
    "93458": "Eschlkam",
    "93462": "Lam",
    "93464": "Tiefenbach",
    "93466": "Chamerau",
    "93468": "Miltach",
    "93470": "Lohberg",
    "93471": "Arnbruck",
    "93473": "Arnschwang",
    "93474": "Arrach",
    "93476": "Blaibach",
    "93477": "Gleißenberg",
    "93479": "Grafenwiesen",
    "93480": "Hohenwarth",
    "93482": "Pemfling",
    "93483": "Pösing",
    "93485": "Rimbach",
    "93486": "Runding",
    "93488": "Schönthal",
    "93489": "Schorndorf",
    "93491": "Stamsried",
    "93492": "Treffelstein",
    "93494": "Waffenbrunn",
    "93495": "Weiding",
    "93497": "Willmering",
    "93499": "Zandt",
    "94032": "Passau",
    "94034": "Passau",
    "94036": "Passau",
    "94051": "Hauzenberg",
    "94060": "Pocking",
    "94065": "Waldkirchen",
    "94072": "Bad Füssing",
    "94078": "Freyung",
    "94081": "Fürstenzell",
    "94086": "Griesbach i. Rottal",
    "94089": "Neureichenau",
    "94094": "Rotthalmünster",
    "94099": "Ruhstorf a.d. Rott",
    "94104": "Tittling",
    "94107": "Untergriesbach",
    "94110": "Wegscheid",
    "94113": "Tiefenbach",
    "94116": "Hutthurm",
    "94118": "Jandelsbrunn",
    "94121": "Salzweg",
    "94124": "Büchlberg",
    "94127": "Neuburg a. Inn",
    "94130": "Obernzell",
    "94133": "Röhrnbach",
    "94136": "Thyrnau",
    "94137": "Bayerbach",
    "94139": "Breitenberg",
    "94140": "Ering",
    "94142": "Fürsteneck",
    "94143": "Grainet",
    "94145": "Haidmühle",
    "94146": "Hinterschmiding",
    "94148": "Kirchham",
    "94149": "Kößlarn",
    "94151": "Mauth",
    "94152": "Neuhaus a. Inn",
    "94154": "Neukirchen vorm Wald",
    "94157": "Perlesreut",
    "94158": "Philippsreut",
    "94160": "Ringelai",
    "94161": "Ruderting",
    "94163": "Saldenburg",
    "94164": "Sonnen",
    "94166": "Stubenberg",
    "94167": "Tettenweis",
    "94169": "Thurmansbang",
    "94209": "Regen",
    "94227": "Zwiesel",
    "94234": "Viechtach",
    "94239": "Zachenberg",
    "94244": "Teisnach",
    "94249": "Bodenmais",
    "94250": "Achslach",
    "94252": "Bayerisch Eisenstein",
    "94253": "Bischofsmais",
    "94255": "Böbrach",
    "94256": "Drachselsried",
    "94258": "Frauenau",
    "94259": "Kirchberg",
    "94261": "Kirchdorf i. Wald",
    "94262": "Kollnburg",
    "94264": "Langdorf",
    "94265": "Patersdorf",
    "94267": "Prackenbach",
    "94269": "Rinchnach",
    "94315": "Straubing",
    "94327": "Bogen",
    "94330": "Aiterhofen",
    "94333": "Geiselhöring",
    "94336": "Hunderdorf",
    "94339": "Leiblfing",
    "94342": "Straßkirchen",
    "94344": "Wiesenfelden",
    "94345": "Aholfing",
    "94347": "Ascha",
    "94348": "Atting",
    "94350": "Falkenfels",
    "94351": "Feldkirchen",
    "94353": "Haibach",
    "94354": "Haselbach",
    "94356": "Kirchroth",
    "94357": "Konzell",
    "94359": "Loitzendorf",
    "94360": "Mitterfels",
    "94362": "Neukirchen",
    "94363": "Oberschneiding",
    "94365": "Parkstetten",
    "94366": "Perasdorf",
    "94368": "Perkam",
    "94369": "Rain",
    "94371": "Rattenberg",
    "94372": "Rattiszell",
    "94374": "Schwarzach",
    "94375": "Stallwang",
    "94377": "Steinach",
    "94379": "Sankt Englmar",
    "94405": "Landau a.d. Isar",
    "94419": "Reisbach",
    "94424": "Arnstorf",
    "94428": "Eichendorf",
    "94431": "Pilsting",
    "94436": "Simbach",
    "94437": "Mamming",
    "94439": "Roßbach",
    "94447": "Plattling",
    "94469": "Deggendorf",
    "94474": "Vilshofen an der Donau",
    "94481": "Grafenau",
    "94486": "Osterhofen",
    "94491": "Hengersberg",
    "94496": "Ortenburg",
    "94501": "Aldersbach",
    "94505": "Bernried",
    "94508": "Schöllnach",
    "94513": "Schönberg",
    "94518": "Spiegelau",
    "94522": "Wallersdorf",
    "94526": "Metten",
    "94527": "Aholming",
    "94529": "Aicha vorm Wald",
    "94530": "Auerbach",
    "94532": "Außernzell",
    "94533": "Buchhofen",
    "94535": "Eging a. See",
    "94536": "Eppenschlag",
    "94538": "Fürstenstein",
    "94539": "Grafling",
    "94541": "Grattersdorf",
    "94542": "Haarbach",
    "94544": "Hofkirchen",
    "94545": "Hohenau",
    "94547": "Iggensbach",
    "94548": "Innernzell",
    "94550": "Künzing",
    "94551": "Lalling",
    "94553": "Mariaposching",
    "94554": "Moos",
    "94556": "Neuschönau",
    "94557": "Niederalteich",
    "94559": "Niederwinkling",
    "94560": "Offenberg",
    "94562": "Oberpöring",
    "94563": "Otzing",
    "94566": "Riedlhütte",
    "94568": "Sankt Oswald",
    "94569": "Stephansposching",
    "94571": "Schaufling",
    "94572": "Schöfweg",
    "94574": "Wallerfing",
    "94575": "Windorf",
    "94577": "Winzer",
    "94579": "Zenting",
    "95028": "Hof",
    "95030": "Hof",
    "95032": "Hof",
    "95100": "Selb",
    "95111": "Rehau",
    "95119": "Naila",
    "95126": "Schwarzenbach a.d. Saale",
    "95131": "Schwarzenbach a. Wald",
    "95138": "Bad Steben",
    "95145": "Oberkotzau",
    "95152": "Selbitz",
    "95158": "Kirchenlamitz",
    "95163": "Weißenstadt",
    "95168": "Marktleuthen",
    "95173": "Schönwald",
    "95176": "Konradsreuth",
    "95179": "Geroldsgrün",
    "95180": "Berg",
    "95182": "Döhlau",
    "95183": "Feilitzsch",
    "95185": "Gattendorf",
    "95186": "Höchstädt",
    "95188": "Issigau",
    "95189": "Köditz",
    "95191": "Leupoldsgrün",
    "95192": "Lichtenberg",
    "95194": "Regnitzlosau",
    "95195": "Röslau",
    "95197": "Schauenstein",
    "95199": "Thierstein",
    "95213": "Münchberg",
    "95233": "Helmbrechts",
    "95234": "Sparneck",
    "95236": "Stammbach",
    "95237": "Weißdorf",
    "95239": "Zell",
    "95326": "Kulmbach",
    "95336": "Mainleus",
    "95339": "Neuenmarkt",
    "95346": "Stadtsteinach",
    "95349": "Thurnau",
    "95352": "Marktleugast",
    "95355": "Presseck",
    "95356": "Grafengehaig",
    "95358": "Guttenberg",
    "95359": "Kasendorf",
    "95361": "Ködnitz",
    "95362": "Kupferberg",
    "95364": "Ludwigschorgast",
    "95365": "Rugendorf",
    "95367": "Trebgast",
    "95369": "Untersteinach",
    "95444": "Bayreuth",
    "95445": "Bayreuth",
    "95447": "Bayreuth",
    "95448": "Bayreuth",
    "95460": "Bad Berneck im Fichtelgebirge",
    "95463": "Bindlach",
    "95466": "Weidenberg, Kirchenpingarten",
    "95469": "Speichersdorf",
    "95473": "Creußen",
    "95478": "Kemnath",
    "95482": "Gefrees",
    "95485": "Warmensteinach",
    "95488": "Eckersdorf",
    "95490": "Mistelgau",
    "95491": "Ahorntal",
    "95493": "Bischofsgrün",
    "95494": "Gesees",
    "95496": "Glashütten",
    "95497": "Goldkronach",
    "95499": "Harsdorf",
    "95500": "Heinersreuth",
    "95502": "Himmelkron",
    "95503": "Hummeltal",
    "95505": "Immenreuth",
    "95506": "Kastl",
    "95508": "Kulmain",
    "95509": "Marktschorgast",
    "95511": "Mistelbach",
    "95512": "Neudrossenfeld",
    "95514": "Neustadt a. Kulm",
    "95515": "Plankenfels",
    "95517": "Emtmannsberg",
    "95519": "Vorbach",
    "95615": "Marktredwitz",
    "95632": "Wunsiedel",
    "95643": "Tirschenreuth",
    "95652": "Waldsassen",
    "95659": "Arzberg",
    "95666": "Mitterteich",
    "95671": "Bärnau",
    "95676": "Wiesau",
    "95679": "Waldershof",
    "95680": "Bad Alexandersbad",
    "95682": "Brand",
    "95683": "Ebnath",
    "95685": "Falkenberg",
    "95686": "Fichtelberg",
    "95688": "Friedenfels",
    "95689": "Fuchsmühl",
    "95691": "Hohenberg a.d. Eger",
    "95692": "Konnersreuth",
    "95694": "Mehlmeisel",
    "95695": "Mähring",
    "95697": "Nagel",
    "95698": "Neualbenreuth",
    "95700": "Neusorg",
    "95701": "Pechbrunn",
    "95703": "Plößberg",
    "95704": "Pullenreuth",
    "95706": "Schirnding",
    "95707": "Thiersheim",
    "95709": "Tröstau",
    "96047": "Bamberg",
    "96049": "Bamberg",
    "96050": "Bamberg",
    "96052": "Bamberg",
    "96103": "Hallstadt",
    "96106": "Ebern",
    "96110": "Scheßlitz",
    "96114": "Hirschaid",
    "96117": "Memmelsdorf",
    "96120": "Bischberg",
    "96123": "Litzendorf",
    "96126": "Maroldsweisach",
    "96129": "Strullendorf",
    "96132": "Schlüsselfeld",
    "96135": "Stegaurach",
    "96138": "Burgebrach",
    "96142": "Hollfeld",
    "96145": "Seßlach",
    "96146": "Altendorf",
    "96148": "Baunach",
    "96149": "Breitengüßbach",
    "96151": "Breitbrunn",
    "96152": "Burghaslach",
    "96154": "Burgwindheim",
    "96155": "Buttenheim",
    "96157": "Ebrach",
    "96158": "Frensdorf",
    "96160": "Geiselwind",
    "96161": "Gerach",
    "96163": "Gundelsheim",
    "96164": "Kemmern",
    "96166": "Kirchlauter",
    "96167": "Königsfeld",
    "96169": "Lauter",
    "96170": "Lisberg",
    "96172": "Mühlhausen",
    "96173": "Oberhaid",
    "96175": "Pettstadt",
    "96176": "Pfarrweisach",
    "96178": "Pommersfelden",
    "96179": "Rattelsdorf",
    "96181": "Rauhenebrach",
    "96182": "Reckendorf",
    "96184": "Rentweinsdorf",
    "96185": "Schönbrunn i. Steigerwald",
    "96187": "Stadelhofen",
    "96188": "Stettfeld",
    "96190": "Untermerzbach",
    "96191": "Viereth-Trunstadt",
    "96193": "Wachenroth",
    "96194": "Walsdorf",
    "96196": "Wattendorf",
    "96197": "Wonsees",
    "96199": "Zapfendorf",
    "96215": "Lichtenfels",
    "96224": "Burgkunstadt",
    "96231": "Staffelstein",
    "96237": "Ebersdorf b. Coburg",
    "96242": "Sonnefeld",
    "96247": "Michelau i. OFr.",
    "96250": "Ebensfeld",
    "96253": "Untersiemau",
    "96257": "Redwitz a.d. Rodach",
    "96260": "Weismain",
    "96264": "Altenkunstadt",
    "96268": "Mitwitz",
    "96269": "Großheirath",
    "96271": "Grub a. Forst",
    "96272": "Hochstadt a. Main",
    "96274": "Itzgrund",
    "96275": "Marktzeuln",
    "96277": "Schneckenlohe",
    "96279": "Weidhausen b. Coburg",
    "96317": "Kronach",
    "96328": "Küps",
    "96332": "Pressig",
    "96337": "Ludwigsstadt",
    "96342": "Stockheim",
    "96346": "Wallenfels",
    "96349": "Steinwiesen",
    "96352": "Wilhelmsthal",
    "96355": "Tettau",
    "96358": "Teuschnitz",
    "96361": "Steinbach a. Wald",
    "96364": "Marktrodach",
    "96365": "Nordhalben",
    "96367": "Tschirn",
    "96369": "Weißenbrunn",
    "96450": "Coburg",
    "96465": "Neustadt b. Coburg",
    "96472": "Rödental",
    "96476": "Rodach b. Coburg",
    "96479": "Weitramsdorf",
    "96482": "Ahorn",
    "96484": "Meeder",
    "96486": "Lautertal",
    "96487": "Dörfles-Esbach",
    "96489": "Niederfüllbach",
    "96515": "Sonneberg, Judenbach",
    "96523": "Steinach",
    "96524": "Neuhaus-Schierschnitz",
    "96528": "Frankenblick, Schalkau, Bachfeld",
    "97070": "Würzburg",
    "97072": "Würzburg",
    "97074": "Würzburg",
    "97076": "Würzburg",
    "97078": "Würzburg",
    "97080": "Würzburg",
    "97082": "Würzburg",
    "97084": "Würzburg",
    "97199": "Ochsenfurt",
    "97204": "Höchberg",
    "97209": "Veitshöchheim",
    "97215": "Uffenheim",
    "97218": "Gerbrunn",
    "97222": "Rimpar",
    "97225": "Zellingen",
    "97228": "Rottendorf",
    "97230": "Estenfeld",
    "97232": "Giebelstadt",
    "97234": "Reichenberg, Guttenberger Wald",
    "97236": "Randersacker",
    "97237": "Altertheim",
    "97239": "Aub",
    "97241": "Bergtheim, Oberpleichfeld",
    "97243": "Bieberehren",
    "97244": "Bütthard",
    "97246": "Eibelstadt",
    "97247": "Eisenheim",
    "97249": "Eisingen",
    "97250": "Erlabrunn",
    "97252": "Frickenhausen",
    "97253": "Gaukönigshofen",
    "97255": "Gelchsheim, Sonderhofen",
    "97256": "Geroldshausen",
    "97258": "Ippesheim",
    "97259": "Greußenheim",
    "97261": "Güntersleben",
    "97262": "Hausen b. Würzburg",
    "97264": "Helmstadt",
    "97265": "Hettstadt",
    "97267": "Himmelstadt",
    "97268": "Kirchheim",
    "97270": "Kist, Irtenberger Wald",
    "97271": "Kleinrinderfeld",
    "97273": "Kürnach",
    "97274": "Leinach",
    "97276": "Margetshöchheim",
    "97277": "Neubrunn",
    "97279": "Prosselsheim",
    "97280": "Remlingen",
    "97282": "Retzstadt",
    "97283": "Riedenheim",
    "97285": "Röttingen, Tauberrettersheim",
    "97286": "Winterhausen",
    "97288": "Theilheim",
    "97289": "Thüngen",
    "97291": "Thüngershem",
    "97292": "Üttingen, Holzkirchen",
    "97294": "Unterpleichfeld",
    "97295": "Waldbrunn, Irtenberger Wald",
    "97297": "Waldbüttelbrunn",
    "97299": "Zell a. Main",
    "97318": "Kitzingen",
    "97320": "Albertshofen",
    "97332": "Volkach",
    "97334": "Sommerach",
    "97337": "Dettelbach",
    "97340": "Marktbreit",
    "97342": "Obernbreit",
    "97346": "Iphofen",
    "97348": "Rödelsee",
    "97350": "Mainbernheim",
    "97353": "Wiesentheid",
    "97355": "Kleinlangheim",
    "97357": "Prichsenstadt",
    "97359": "Schwarzach a. Main",
    "97421": "Schweinfurt",
    "97422": "Schweinfurt",
    "97424": "Schweinfurt",
    "97437": "Haßfurt",
    "97440": "Werneck",
    "97447": "Gerolzhofen",
    "97450": "Arnstein",
    "97453": "Schonungen",
    "97456": "Dittelbrunn",
    "97461": "Hofheim i. UFr.",
    "97464": "Niederwerrn",
    "97469": "Gochsheim",
    "97475": "Zeil a. Main",
    "97478": "Knetzgau",
    "97483": "Eltmann",
    "97486": "Königsberg i. Bay.",
    "97488": "Stadtlauringen",
    "97490": "Poppenhausen",
    "97491": "Aidhausen",
    "97493": "Bergrheinfeld",
    "97494": "Bundorf",
    "97496": "Burgpreppach",
    "97497": "Dingolshausen",
    "97499": "Donnersdorf",
    "97500": "Ebelsbach",
    "97502": "Euerbach",
    "97503": "Gädheim",
    "97505": "Geldersheim",
    "97506": "Grafenrheinfeld",
    "97508": "Grettstadt",
    "97509": "Kolitzheim",
    "97511": "Lülsfeld",
    "97513": "Michelau i. Steigerwald, Hundelshausen",
    "97514": "Oberaurach",
    "97516": "Oberschwarzach",
    "97517": "Rannungen",
    "97519": "Riedbach",
    "97520": "Röthlein",
    "97522": "Sand a. Main",
    "97523": "Schwanfeld",
    "97525": "Schwebheim",
    "97526": "Sennfeld",
    "97528": "Sulzdorf a.d. Lederhecke",
    "97529": "Sulzheim",
    "97531": "Theres",
    "97532": "Üchtelhausen",
    "97534": "Waigolshausen",
    "97535": "Wasserlosen",
    "97537": "Wipfeld",
    "97539": "Wonfurt",
    "97616": "Bad Neustadt an der Saale",
    "97618": "Hohenroth",
    "97631": "Bad Königshofen i. Grabfeld",
    "97633": "Sulzfeld",
    "97638": "Mellrichstadt",
    "97640": "Oberstreu",
    "97645": "Ostheim v.d. Rhön",
    "97647": "Nordheim v.d. Rhön",
    "97650": "Fladungen",
    "97653": "Bischofsheim a.d. Rhön",
    "97654": "Bastheim",
    "97656": "Oberelsbach",
    "97657": "Sandberg",
    "97659": "Schönau a.d. Brend",
    "97688": "Bad Kissingen",
    "97702": "Münnerstadt",
    "97705": "Burkardroth",
    "97708": "Bad Bocklet",
    "97711": "Maßbach",
    "97714": "Oerlenbach",
    "97717": "Euerdorf",
    "97720": "Nüdlingen",
    "97723": "Oberthulba",
    "97724": "Burglauer",
    "97725": "Elfershausen",
    "97727": "Fuchsstadt",
    "97729": "Ramsthal",
    "97737": "Gemünden a. Main",
    "97753": "Karlstadt",
    "97762": "Hammelburg",
    "97769": "Bad Brückenau",
    "97772": "Wildflecken",
    "97773": "Aura i. Sinngrund",
    "97775": "Burgsinn",
    "97776": "Eußenheim",
    "97778": "Fellen",
    "97779": "Geroda",
    "97780": "Gössenheim",
    "97782": "Gräfendorf",
    "97783": "Karsbach",
    "97785": "Mittelsinn",
    "97786": "Motten",
    "97788": "Neuendorf",
    "97789": "Oberleichtersbach",
    "97791": "Obersinn",
    "97792": "Riedenberg",
    "97794": "Rieneck",
    "97795": "Schondra",
    "97797": "Wartmannsroth",
    "97799": "Zeitlofs",
    "97816": "Lohr a. Main",
    "97828": "Marktheidenfeld",
    "97833": "Frammersbach",
    "97834": "Birkenfeld",
    "97836": "Bischbrunn",
    "97837": "Erlenbach b. Marktheidenfeld",
    "97839": "Esselbach",
    "97840": "Hafenlohr, Rothenbuch",
    "97842": "Karbach",
    "97843": "Neuhütten",
    "97845": "Neustadt a. Main",
    "97846": "Partenstein",
    "97848": "Rechtenbach",
    "97849": "Roden",
    "97851": "Rothenfels",
    "97852": "Schollbrunn",
    "97854": "Steinfeld",
    "97855": "Triefenstein",
    "97857": "Urspringen",
    "97859": "Wiesthal",
    "97877": "Wertheim",
    "97892": "Kreuzwertheim",
    "97896": "Freudenberg, Collenberg",
    "97900": "Külsheim",
    "97901": "Altenbuch",
    "97903": "Collenberg",
    "97904": "Dorfprozelten",
    "97906": "Faulbach",
    "97907": "Hasloch",
    "97909": "Stadtprozelten",
    "97922": "Lauda-Königshofen",
    "97941": "Tauberbischofsheim",
    "97944": "Boxberg",
    "97947": "Grünsfeld",
    "97950": "Großrinderfeld",
    "97953": "Königheim",
    "97956": "Werbach",
    "97957": "Wittighausen",
    "97959": "Assamstadt",
    "97980": "Bad Mergentheim",
    "97990": "Weikersheim",
    "97993": "Creglingen",
    "97996": "Niederstetten",
    "97999": "Igersheim",
    "98527": "Suhl",
    "98528": "Suhl",
    "98529": "Suhl",
    "98530": "Suhl, Marisfeld, Rohr u.a.",
    "98544": "Zella-Mehlis",
    "98547": "Viernau u.a.",
    "98553": "Schleusingen u.a.",
    "98554": "Benshausen",
    "98559": "Oberhof",
    "98574": "Schmalkalden",
    "98587": "Steinbach-Hallenberg",
    "98590": "Schwallungen",
    "98593": "Floh-Seligenthal",
    "98596": "Brotterode-Trusetal",
    "98597": "Breitungen/Werra",
    "98617": "Meiningen",
    "98630": "Römhild",
    "98631": "Grabfeld",
    "98634": "Wasungen",
    "98639": "Walldorf",
    "98646": "Hildburghausen",
    "98660": "Themar",
    "98663": "Bad Colberg-Heldburg",
    "98666": "Biberau, Masserberg",
    "98667": "Schleusegrund",
    "98669": "Veilsdorf",
    "98673": "Eisfeld, Auengrund",
    "98678": "Sachsenbrunn",
    "98693": "Ilmenau",
    "98701": "Großbreitenbach",
    "98704": "Langewiesen",
    "98708": "Gehren",
    "98711": "Schmiedefeld, Frauenwald, Suhl",
    "98714": "Stützerbach",
    "98716": "Geschwenda",
    "98724": "Neuhaus am Rennweg, Lauscha",
    "98739": "Lichte",
    "98743": "Gräfenthal",
    "98744": "Oberweißbach u.a.",
    "98746": "Katzhütte",
    "99084": "Erfurt",
    "99085": "Erfurt",
    "99086": "Erfurt",
    "99087": "Erfurt",
    "99089": "Erfurt",
    "99090": "Erfurt",
    "99091": "Erfurt",
    "99092": "Erfurt",
    "99094": "Erfurt",
    "99095": "Erfurt",
    "99096": "Erfurt",
    "99097": "Erfurt",
    "99098": "Erfurt",
    "99099": "Erfurt",
    "99100": "Großfahner, Dachwig u.a.",
    "99102": "Rockhausen, Klettbach",
    "99189": "Gebesee",
    "99192": "Nesse-Apfelstädt, Nottleben",
    "99195": "Großrudestedt, Schloßvippach u.a.",
    "99198": "Udestedt, Mönchenholzhausen u.a.",
    "99310": "Arnstadt",
    "99326": "Stadtilm, Ilmtal",
    "99330": "Gräfenroda",
    "99334": "Elleben, Wachsenburg",
    "99338": "Arnstadt",
    "99423": "Weimar",
    "99425": "Weimar",
    "99427": "Weimar",
    "99428": "Weimar",
    "99438": "Bad Berka u.a.",
    "99439": "Berlstedt",
    "99441": "Lehnstedt u.a.",
    "99444": "Blankenhain",
    "99448": "Kranichfeld u.a.",
    "99510": "Apolda",
    "99518": "Bad Sulza",
    "99610": "Sömmerda",
    "99625": "Kölleda",
    "99628": "Buttstädt",
    "99631": "Weißensee",
    "99634": "Straußfurt",
    "99636": "Rastenberg",
    "99638": "Kindelbrück",
    "99706": "Sondershausen",
    "99707": "Kyffhäuserland",
    "99713": "Ebeleben",
    "99718": "Greußen Clingen Großenehrich",
    "99734": "Nordhausen",
    "99735": "Werther Hohenstein Wolkramshausen",
    "99752": "Bleicherode",
    "99755": "Ellrich",
    "99759": "Sollstedt",
    "99762": "Neustadt/Harz u.a.",
    "99765": "Heringen/ Helme",
    "99768": "Harztor",
    "99817": "Eisenach",
    "99819": "Marksuhl, Krauthausen u.a.",
    "99820": "Hörselberg-Hainich",
    "99826": "Mihla",
    "99830": "Treffurt",
    "99831": "Creuzburg, Ifta",
    "99834": "Gerstungen",
    "99837": "Berka/ Werra",
    "99842": "Ruhla",
    "99846": "Seebach",
    "99848": "Wutha-Farnroda",
    "99867": "Gotha",
    "99869": "Drei Gleichen",
    "99880": "Waltershausen",
    "99885": "Luisenthal, Ohrdruf, Wolfis",
    "99887": "Georgenthal/ Thür. Wald",
    "99891": "Tabarz/ Thür. Wald",
    "99894": "Leinatal",
    "99897": "Tambach-Dietharz/ Thür.",
    "99947": "Bad Langensalza",
    "99955": "Bad Tennstedt",
    "99958": "Großvargula, Tonna",
    "99974": "Mühlhausen, Unstruttal",
    "99976": "Rodeberg, Dünwald u.a.",
    "99986": "Vogtei, Kammerforst u.a.",
    "99988": "Südeichsfeld",
    "99991": "Großengottern, Heroldishausen",
    "99994": "Schlotheim",
    "99996": "Menteroda, Obermehler",
    "99998": "Körner, Weinbergen"
}