import React from 'react';
import { Select, MenuItem, TextField, InputLabel, FormControl, Typography } from '@material-ui/core';
import { StepContainer, Text, Image, CustomizedButton, ButtonContainer, useFormStyles } from '../shared';
import { getContent } from '../utils';
import { trackStepChange, trackInputFieldDataChange } from '../tracker';
import mapImage from '../assets/map-image.png';
import { getCity } from '../assets/germanPostalCodes';
import Helper from '../../Helper';

export const LocationStep = ({ formData, setFormData, setCurrentStep }) => {
  const formStyles = useFormStyles();

  const hasAgeCurrentHeatingSystemError =
    !!formData.ageCurrentHeatingSystem && (formData.ageCurrentHeatingSystem < 1 || formData.ageCurrentHeatingSystem > 99);

  const disabledNextStep = !formData?.address?.city || !formData?.address?.postalCode?.trim() || !(formData?.address?.postalCode > 1000 && formData?.address?.postalCode < 100000);

  return (
    <StepContainer>
      <FormControl className={formStyles.formControl}>
        <Typography> {getContent('countryLabel')}<Helper header="Land" text="Das Land in dem Ihr Gebäude für eine Wärmepumpe steht." /></Typography>
        <Select
          labelId="select-label"
          value={formData.address.country}
          onChange={(event) => {
            trackInputFieldDataChange('address.country', {
              previousValue: formData.address.country,
              newValue: event.target.value,
            });

            formData.address.country = event.target.value;
            setFormData({ ...formData });
          }}>
          <MenuItem value='DEU'>{getContent('enumTranslation')?.country?.DEU}</MenuItem>
          {/*        <MenuItem value='AUT'>{getContent('enumTranslation')?.country?.AUT}</MenuItem> */}
        </Select>
      </FormControl>
      <FormControl className={formStyles.formControl}>
        <Typography> {getContent('zipCodeLabel')}<Helper header="Postleitzahl" text="Die Postleitzahl in dem Ihr Gebäude für eine Wärmepumpe steht." /></Typography>
        <TextField
          variant="outlined"
          placeholder="z.B.: 50670"

          value={formData.address.postalCode}
          autoFocus
          required
          onChange={(event) => {
            trackInputFieldDataChange('postalCode', {
              previousValue: formData.address.postalCode,
              newValue: event.target.value,
            });
            formData.address.postalCode = event.target.value;
            formData.address.city = getCity[String(formData.address.postalCode)]
            setFormData({ ...formData });
          }}
        />
      </FormControl>
      <Text>{getContent('locationStepText')}</Text>
      <Image src={mapImage} alt="Map Image" width={'45%'} />
      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(1, 0, formData);
            setCurrentStep(0);
          }}>
          {getContent('backButtonLabel')}
        </CustomizedButton>
        <CustomizedButton
          onClick={() => {
            trackStepChange(1, 2, formData);
            setCurrentStep(2);
          }}
          disabled={disabledNextStep}
        >
          {getContent('nextButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
