import React from 'react';
import { Typography, Radio, RadioGroup, FormLabel, Grid, FormControlLabel, Switch, TextField, FormControl, Select, InputLabel, Link, MenuItem } from '@material-ui/core';
import { useFormStyles, StepContainer, CustomizedButton, ButtonContainer } from '../shared';
import { getContent } from '../utils';
import { trackStepChange, trackInputFieldDataChange } from '../tracker';



export const HpLocationStep = ({ formData, setFormData, setCurrentStep, onSubmit }) => {
  const disabledNextStep = !formData.heaterLocationOld;
  const formStyles = useFormStyles();

  return (
    <StepContainer>
      <FormControl className={formStyles.formControl}>
        <FormLabel>{getContent('hpLocationRule')}</FormLabel>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>{getContent('no')}</Grid>
          <Grid item>
            <Switch
              checked={formData.distanceRuleIsRespected}
              onChange={(event) => {
                trackInputFieldDataChange('distanceRuleIsRespected', {
                  previousValue: formData.distanceRuleIsRespected,
                  newValue: event.target.checked,
                });
                setFormData({ ...formData, distanceRuleIsRespected: event.target.checked });
              }}
            />
          </Grid>
          <Grid item>{getContent('yes')}</Grid>
        </Grid>
      </FormControl>

      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('hpLocationLabel')}</Typography>

        {/*    <InputLabel required id="select-label" shrink={true}>
          {getContent('hpLocationLabel')}
        </InputLabel> */}
        <Select
          autoFocus
          labelId="select-label"
          value={formData.heaterLocationOld}
          onChange={(event) => {
            trackInputFieldDataChange('heaterLocationOld', {
              previousValue: formData.heaterLocationOld,
              newValue: event.target.value,
            });
            setFormData({ ...formData, heaterLocationOld: event.target.value });
          }}>
          <MenuItem value="cellar">{getContent('locationCellar')}</MenuItem>
          <MenuItem value="groundFloor">{getContent('locationGroundFloor')}</MenuItem>
          <MenuItem value="upperFloorBelowRoof">{getContent('locationUpperFloorBelowRoof')}</MenuItem>
          <MenuItem value="adjoiningBuilding">{getContent('locationAdjoiningBuilding')}</MenuItem>
          <MenuItem value="elsewhere">{getContent('locationElsewhere')}</MenuItem>
          <MenuItem value="unknown">{getContent('locationUnknown')}</MenuItem>
        </Select>
      </FormControl>

      <FormControl component="fieldset" className={formStyles.formControl}>
        <FormLabel component="legend">{getContent("locationPhotovoltaikLabel")}</FormLabel>
        <RadioGroup
          aria-label="pv"
          name="controlled-radio-buttons-group"
          value={formData.pvSettings}
          onChange={(event) => {
            trackInputFieldDataChange('pvSettings', {
              previousValue: formData.pvSettings,
              newValue: event.target.value,
            });
            setFormData({ ...formData, pvSettings: event.target.value });
          }}
        >
          <FormControlLabel value="available" control={<Radio />} label={getContent("locationPhotovoltaikAlreadyHave")} />
          <FormControlLabel value="interested" control={<Radio />} label={getContent("locationPhotovoltaikInterested")} />
          <FormControlLabel value="notAvailableNotInterested" control={<Radio />} label={getContent("locationPhotovoltaikNotAvailableNotInterested")} />

        </RadioGroup>
      </FormControl>

      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(8, 7, formData);
            setCurrentStep(7);
          }}>
          {getContent('backButtonLabel')}
        </CustomizedButton>
        <CustomizedButton
          onClick={() => {
            trackStepChange(8, 9, formData);
            setCurrentStep(9);
          }}
          disabled={disabledNextStep}>
          {getContent('nextButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer >
  );
};
