import * as React from 'react';
import { Link, Button } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { baseApiUrl } from 'mapbox-gl';
import API, { graphqlOperation } from '@aws-amplify/api';
import * as mutations from '../graphql/mutations';
import { trackEvent } from './ConfiguratorForm/tracker';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

    const termsAndConditionsUrl = "https://www.heatly.de/allgemeine-geschäftsbedingungen";
    const leadId = props.leadId
    const [open, setOpen] = React.useState(false);
    const onCloseEventCancel = props.onCloseEventCancel
    const onCloseEventBuy = props.onCloseEventBuy

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event) => {
        console.log(event);
        setOpen(false);
        onCloseEventCancel()
    };

    const buyLead = async (event) => {
        console.log(event)
        var buyLeadRequest = await API.graphql({
            query: mutations.buyLead,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {
                leadId: leadId
            }
        })
        console.log(buyLeadRequest)
        trackEvent("buyLead", { area: "wpguru", leadId: leadId });
        setOpen(false);
        onCloseEventBuy()
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Kaufen
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Zustimmung AGB?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Wir befinden uns noch in der Testphase. Mit dem Klick auf "Ja" stimmen Sie unseren Allgemeinen Geschäftsbedingungen zu und verpflichten sich den Kunden innerhalb der nächsten Tage zu kontaktieren. Preise wie abgesprochen.
                        <br /><Link href={termsAndConditionsUrl} target="_blank" rel="noreferrer">
                            Allgemeine Geschäftsbedingungen
                        </Link>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button onClick={buyLead}>Ja</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
