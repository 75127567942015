export const exploreOpenLeads = /* GraphQL */ `
  query ExploreOpenLeads {
    exploreOpenLeads {
      id
      status
      notesPublic
      type
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        bivaColdDayBooster
        powerBivaSource
        isOwner
        address {
            city
            country
            postalCode
            street
            streetNo
            type
            location {
              lat
              long
        }
            }
            evaluation {
              area {
                name
                text
                value
              }
              result {
                name
                text
                value
              }
              house {
                text
                name
                value
              }
              spaceHeatSys {
                name
                text
                value
              }
              specificHeatDemand
    heatingDemand
    heatLoad
    heaterConstructionYear
    designExternalTemp
    consumptionYearBiva
    consumptionYear
            }
      }
    }
  }
`;

export const listPartnerExtended = /* GraphQL */ `
  query ListPartnerExtended(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
      companyName
      address {
        city
        postalCode
        street
        streetNo
        type
        location {
          lat
          long
        }
      }
      leads {
        items {
          _lastChangedAt
          id
          notesPublic
          type
          requestId
          notesPartner
          buyDate
      partnerStatus
    contractPrice
          request {
            
            address {
            city
            country
            postalCode
            street
            streetNo
            type
            location {
              lat
              long
        }
            }
            evaluation {
              area {
                name
                text
                value
              }
              result {
                name
                text
                value
              }
              house {
                text
                name
                value
              }
              spaceHeatSys {
                name
                text
                value
              }
              specificHeatDemand
    heatingDemand
    heatLoad
    heaterConstructionYear
    designExternalTemp
    consumptionYearBiva
    consumptionYear
            }
            customer {
              checkFoerderung
              checkNutzungsbedingungen
              email
              firstName
              lastName
              phone
              address {
        city
        postalCode
        street
        streetNo
        type
        country
        location {
          lat
          long
        }
              }
              id
            }
            id
            type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        customerId
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        isOwner
        bivaColdDayBooster
        powerBivaSource

          }
          createdAt
          _lastChangedAt
          _version
          _deleted
          status
        }
      }
      id
    }
  }
}

`;

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      firstName
      lastName
      email
      phone
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      checkFoerderung
      checkNutzungsbedingungen
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;

export const listPartnersWithLeads = /* GraphQL */ `
  query ListPartnersWithLeads(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {

      leads {

          id
          notesPublic
          _lastChangedAt
          _version
          _deleted
          requestId
          status
          type
          updatedAt
        }
      }
}`;
export const getRequest = /* GraphQL */ `
query GetRequest($id: ID!) {
  getRequest(id: $id) {
    id
    status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        customerId
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        isOwner
        bivaColdDayBooster
        powerBivaSource
        evaluation {
              area {
                name
                text
                value
              }
              result {
                name
                text
                value
              }
              house {
                text
                name
                value
              }
              spaceHeatSys {
                name
                text
                value
              }
              specificHeatDemand
              heatingDemand
              heatLoad
              heaterConstructionYear
              designExternalTemp
              consumptionYearBiva
              consumptionYear
            }
            address {
        city
        postalCode
        street
        streetNo
        type
        country
        location {
          lat
          long
        }
            }
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    customerId
  }
}
`;

export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      status
      type
      ageCurrentHeatingSystem
      energySource
      heaterConstructionYear
      accountingPeriodDays
      volumeConsumptionAccountingPeriod
      consumptionAccountingPeriod
      consumptionYear
      energySubSource
      accountingPeriodStartDate
      accountingPeriodEndDate
      spaceHeatSystem
      tempPreFlow
      buildingType
      buildingUsefulArea
      buildingConstructionYear
      hotDomesticWater
      hotDomesticWaterFutur
      inhabs
      bivalentMode
      energySubSourceBiva
      consumptionAccountingPeriodBiva
      energySourceBiva
      volumeConsumptionAccountingPeriodBiva
      accountingPeriodStartDateBiva
      accountingPeriodEndDateBiva
      heatingSystemChangePlannedMonths
      hpFeatures
      pvSettings
      heaterLocationOld
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      distanceRuleIsRespected
      acceptedDataProtection
      acceptedTerms
      isOwner
      contactPerson {
        name
        phone
        email
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      checkFoerderung
      checkNutzungsbedingungen
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
 
    }
  }
`;

export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      status
      type
      ageCurrentHeatingSystem
      energySource
      heaterConstructionYear
      accountingPeriodDays
      volumeConsumptionAccountingPeriod
      consumptionAccountingPeriod
      consumptionYear
      energySubSource
      accountingPeriodStartDate
      accountingPeriodEndDate
      spaceHeatSystem
      tempPreFlow
      buildingType
      buildingUsefulArea
      buildingConstructionYear
      hotDomesticWater
      hotDomesticWaterFutur
      inhabs
      bivalentMode
      energySubSourceBiva
      consumptionAccountingPeriodBiva
      energySourceBiva
      volumeConsumptionAccountingPeriodBiva
      accountingPeriodStartDateBiva
      accountingPeriodEndDateBiva
      heatingSystemChangePlannedMonths
      hpFeatures
      pvSettings
      heaterLocationOld
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      distanceRuleIsRespected
      acceptedDataProtection
      acceptedTerms
      isOwner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      leadPreference
    }
  }
`;