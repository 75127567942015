import { translations } from "../ConfiguratorForm/assets/content"
export default function buildRequest(request) {

  console.log(request)

  return {
    /** General */
    groups: [
      {
        headline: "Persönliche Daten zum Auftragsstandort",
        elements: [
          { left: `Land: ${translations.enumTranslation.country[request?.address?.country]}`, right: `Postleitzahl: ${request?.address?.postalCode}` }, //Print Country and postalCode to the console
        ]
      },
      {
        headline: "Gebäudedaten",
        elements: [
          { left: `Gebäudetyp: ${translations?.enumTranslation?.buildingType[request?.buildingType]}`, right: `Baujahr: ${request.buildingConstructionYear}` }, //Print buildingType & ConstructionYear
          { left: `Wohnfläche: ${request?.buildingUsefulArea} qm`, right: `Anzahl der Personen im Haushalt: ${request.inhabs}` }, //Print buildingUsefulArea & inhabs
          { left: `Normaußentemperatur am Gebäudestandort: ${request?.evaluation?.designExternalTemp} °C`, right: `Mindestabstand Wärmepumpe zum Nachbargrundstück kann eingehalten werden: ${request.distanceRuleIsRespected ? "Ja" : "Nein"}` }, //Prints designExternalTemp & distanceRuleIsRespected
        ]
      },
      {
        headline: "Daten zum aktuellen Heizsystem",
        elements: [
          { left: `Energieträger: ${translations?.enumTranslation?.energySource[request?.energySource]}`, right: `Alter der aktuellen Heizanlage: ${request.ageCurrentHeatingSystem} Jahre` }, //Prints energySource & ageCurrentHeatingSystem
          { left: `Raumheizungstyp: ${translations?.enumTranslation?.spaceHeatSystem[request?.spaceHeatSystem]}`, right: `Vorlauftemperatur: ${request.tempPreFlow} °C` }, //Prints spaceHeatSystem & tempPreFlow
          { left: `Aufstellungsort der alten Heizanlage: ${translations?.enumTranslation?.heaterLocation[request?.heaterLocationOld]}`, right: `Warmwasser wird über Heizanlage bereitgestellt: ${request.hotDomesticWater ? "Ja" : "Nein"}` }, //Prints systemEfficiency & hotDomesticWater
          { left: `Eine zweite Heizquelle ist vorhanden: ${request.bivalentMode ? "Ja" : "Nein"}`, right: `` }, //Prints heaterLocationOld & bivalentMode
        ]
      },
      {
        headline: "Verbrauchswerte (primäres) Heizsystem",
        elements: [
          { left: `Abrechnungszeitraum: ${request.accountingPeriodStartDate} - ${request.accountingPeriodEndDate}`, right: `Energiebedarf der letzten Heizperiode: ${request.consumptionAccountingPeriod}` }, //Prints accountigPeriod Start & End Date + consumptionAccountingPeriod
        ]
      },
      {
        headline: "Vebrauchswerte bivalentes Heizsystem",
        hidden: !request.bivalentMode,
        elements: [
          { left: `Abrechnungszeitraum bivalente Heizquelle: ${request.accountingPeriodStartDateBiva} - ${request.accountingPeriodEndDateBiva}`, right: `Energiebedarf bivalente Heizquelle in der letzten Heizperiode: ${request.consumptionAccountingPeriodBiva}` }, //Prints accountingPeriodBiva Start & End + consumption Accounting PeriodBiva
        ]
      },
      {
        headline: "Kalkulierte Prognosen",
        elements: [
          { left: `Geschätzte Heizlast (nach Schweizerformel): ${Math.round(request?.evaluation?.heatLoad)} kW`, right: `Überschlägiger, spezifischer Heizwärmebedarf: ${Math.round(request?.evaluation?.specificHeatDemand)} W/qm` }, //Prints heatLoad & specificHeatDemand
          { left: `Tatsächlicher Heizwärmebedarf inkl. Warmwasser: ${Math.round(request?.evaluation?.heatingDemand)} kWh/Jahr`, right: `Brennstoffeinsatz bivalente Heizquelle: ${Math.round(request?.evaluation?.consumptionYearBiva)} kWh/Jahr` },
        ]
      },
      {
        headline: "Kundenwünsche",
        elements: [
          { left: `Wann soll der Heizungswechsel stattfinden: ${request.heatingSystemChangePlannedMonths}`, right: `Diese Eigenschaften sollen bei der neuen Heizungsanlage berücksichtigt werden: ${translations?.enumTranslation?.hpFeatures[request?.hpFeatures]}` },
          { left: `Gibt es eine Photovoltaikanlage oder ist in Zukunft eine gewünscht: ${translations?.enumTranslation?.pvSettings[request.pvSettings]}`, right: `Soll das Brauchwarmwasser zukünftig über die Wärmepumpe bereitgestellt werden: ${request.hotDomesticWaterFutur ? "Ja" : "Nein"}` },
        ]
      },

    ]
  }
}


/*
console.log(JSON.stringify(build(
  {
    "address": {
      "country": "DEU",
      "postalCode": "12112"
    },
    "ageCurrentHeatingSystem": 12,
    "energySource": "oil",
    "volumeConsumptionAccountingPeriod": 123,
    "consumptionAccountingPeriod": 0,
    "accountingPeriodStartDate": "2020-11-03",
    "accountingPeriodEndDate": "2021-11-03",
    "spaceHeatSystem": "underfloorHeating",
    "tempPreFlow": 0,
    "buildingType": "singleFamilyHouse",
    "buildingUsefulArea": 22,
    "buildingConstructionYear": 1989,
    "hotDomesticWater": true,
    "inhabs": 2,
    "hotDomesticWaterFutur": false,
    "bivalentMode": false,
    "energySourceBiva": "oil",
    "volumeConsumptionAccountingPeriodBiva": 0,
    "consumptionAccountingPeriodBiva": 0,
    "energySubSource": "",
    "energySubSourceBiva": "",
    "accountingPeriodStartDateBiva": "2020-11-03",
    "accountingPeriodEndDateBiva": "2021-11-03",
    "distanceRuleIsRespected": false,
    "heaterLocationOld": "",
    "pvSettings": "notAvailableNotInterested",
    "heatingSystemChangePlannedMonths": "12",
    "designExternalTemp": "",
    "systemEfficiency": "",
    "heatLoad": "",
    "specificHeatDemand": "",
    "heatingDemand": "",
    "consumptionYearBiva": "",
    "pvCombination": "",
    "hpFeatures": [
      "highQualityProduct",
      "coolingPossible"
    ],
    "acceptedTerms": true,
    "acceptedDataProtection": true
  }), null, " ")) */