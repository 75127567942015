import React from "react";
import {
  FormControl,
  FormLabel,
  Grid,
  Switch,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import {
  StepContainer,
  CustomizedButton,
  ButtonContainer,
  useFormStyles,
} from "../shared";
import { getContent } from "../utils";
import { trackStepChange, trackInputFieldDataChange } from "../tracker";



const getEnergyConsumptionVolumeHelperText = (energySource) => {
  switch (energySource) {
    case "oil":
      return getContent('energyConsumptionVolumeOilHelperText');
    case "wood":
      return getContent('energyConsumptionVolumeHolzHelperText');
    case "coal":
      return getContent('energyConsumptionVolumeKohleHelperText');
    case "pellets":
      return getContent('energyConsumptionVolumePelletsHelperText');
    default:
      return '';
  }
};

export const SecondHeatingStep = ({
  formData,
  setFormData,
  setCurrentStep,
}) => {
  const formStyles = useFormStyles();

  const showEnergyVolume =
    formData.energySourceBiva === 'pellets' || formData.energySourceBiva === 'oil' || formData.energySourceBiva === 'wood' || formData.energySourceBiva === 'coal';

  const showEnergyConsumption =
    formData.energySourceBiva === 'naturalGas' ||
    formData.energySourceBiva === 'liquidGas' ||
    formData.energySourceBiva === 'districtHeat' ||
    formData.energySourceBiva === 'nightPower';

  const disabledNextStep = formData.bivalentMode && (
    showEnergyConsumption && !formData.consumptionAccountingPeriodBiva || showEnergyVolume && !formData.volumeConsumptionAccountingPeriodBiva || formData.energySourceBiva == "wood" && !formData.energySubSourceBiva)

  const hasConsumptionAccountingPeriodError = showEnergyConsumption && !formData.consumptionAccountingPeriod;


  formData.accountingPeriodStartDateBiva = formData.accountingPeriodStartDate
  formData.accountingPeriodEndDateBiva = formData.accountingPeriodEndDate

  const hasDatesError = formData.accountingPeriodStartDateBiva.localeCompare(formData.accountingPeriodEndDateBiva) >= 0;

  return (
    <StepContainer>
      <FormControl className={formStyles.formControl}>
        <FormLabel>{getContent("hasAdditionalHeatingLabel")}</FormLabel>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>{getContent("no")}</Grid>
          <Grid item>
            <Switch
              checked={formData.bivalentMode}
              onChange={(event) => {
                trackInputFieldDataChange("bivalentMode", {
                  previousValue: formData.bivalentMode,
                  newValue: event.target.checked,
                });
                setFormData({
                  ...formData,
                  bivalentMode: event.target.checked,
                });
              }}
            />
          </Grid>
          <Grid item>{getContent("yes")}</Grid>
        </Grid>
      </FormControl>
      {formData.bivalentMode && (
        <>
          <FormControl className={formStyles.formControl}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={getContent('startDateLabel')}
                  type="date"
                  required
                  disabled={true}
                  value={formData.accountingPeriodStartDateBiva}
                  onChange={(event) => {
                    trackInputFieldDataChange('accountingPeriodStartDateBiva', {
                      previousValue: formData.accountingPeriodStartDateBiva,
                      newValue: event.target.value,
                    });
                    setFormData({ ...formData, accountingPeriodStartDateBiva: event.target.value });
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={getContent('endDateLabel')}
                  type="date"
                  required
                  disabled={true}
                  error={hasDatesError}
                  helperText={hasDatesError && getContent('datesErrorText')}
                  value={formData.accountingPeriodEndDateBiva}
                  onChange={(event) => {
                    trackInputFieldDataChange('accountingPeriodEndDateBiva', {
                      previousValue: formData.accountingPeriodEndDateBiva,
                      newValue: event.target.value,
                    });
                    setFormData({ ...formData, accountingPeriodEndDateBiva: event.target.value });
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>

          <FormControl className={formStyles.formControl}>
            <Typography>{getContent('energyLabel')}</Typography>
            <Select
              labelId="select-label"
              value={formData.energySourceBiva}
              onChange={(event) => {
                trackInputFieldDataChange('energySourceBiva', {
                  previousValue: formData.energySourceBiva,
                  newValue: event.target.value,
                });
                setFormData({ ...formData, energySourceBiva: event.target.value });
              }}>
              <MenuItem value="oil">{getContent('enumTranslation')?.energySource?.oil}</MenuItem>
              <MenuItem value="naturalGas">{getContent('enumTranslation')?.energySource?.naturalGas}</MenuItem>
              {/*   <MenuItem value="liquidGas">{getContent('energyLiquidGas')}</MenuItem> */}
              <MenuItem value="wood">{getContent('enumTranslation')?.energySource?.wood}</MenuItem>
              <MenuItem value="pellets">{getContent('enumTranslation')?.energySource?.pellets}</MenuItem>
              <MenuItem value="districtHeat">{getContent('enumTranslation')?.energySource?.districtHeat}</MenuItem>
              <MenuItem value="nightPower">{getContent('enumTranslation')?.energySource?.nightPower}</MenuItem>
              <MenuItem value="coal">{getContent('enumTranslation')?.energySource?.coal}</MenuItem>
            </Select>
          </FormControl>
          {formData.energySourceBiva === "wood" && (
            <FormControl className={formStyles.formControl}>
              <Typography>{getContent('woodSelectLabel')}</Typography>
              <Select
                labelId="select-label"
                value={formData.energySubSourceBiva}
                onChange={(event) => {
                  trackInputFieldDataChange("energySubSourceBiva", {
                    previousValue: formData.energySubSourceBiva,
                    newValue: event.target.value,
                  });
                  setFormData({
                    ...formData,
                    energySubSourceBiva: event.target.value,
                  });
                }}
              >
                <MenuItem value="birch">{getContent('woodBirke')}</MenuItem>
                <MenuItem value="beech">{getContent('woodBuche')}</MenuItem>
                <MenuItem value="oak">{getContent('woodEiche')}</MenuItem>
                <MenuItem value="ash">{getContent('woodEsche')}</MenuItem>
                <MenuItem value="pine">{getContent('woodKiefer')}</MenuItem>
                <MenuItem value="larch">{getContent('woodLarche')}</MenuItem>
                <MenuItem value="hardwoodMix">{getContent('woodHartholz')}</MenuItem>
              </Select>
            </FormControl>
          )}
          {showEnergyVolume && (
            <FormControl className={formStyles.formControl}>
              <Typography>{getContent('energyConsumptionVolumeLabel')}</Typography>

              <TextField
                variant="outlined"
                value={formData.volumeConsumptionAccountingPeriodBiva ? formData.volumeConsumptionAccountingPeriodBiva : ''}
                onChange={(event) => {
                  trackInputFieldDataChange('volumeConsumptionAccountingPeriodBiva', {
                    previousValue: formData.volumeConsumptionAccountingPeriodBiva,
                    newValue: parseInt(event.target.value) || 0,
                  });
                  setFormData({ ...formData, volumeConsumptionAccountingPeriodBiva: parseInt(event.target.value) || 0 });
                }}

                placeholder={getContent('energyConsumptionVolumeHelperPlaceholder')}
                helperText={getEnergyConsumptionVolumeHelperText(formData.energySourceBiva)}
              />
            </FormControl>
          )}

          {showEnergyConsumption && (
            <FormControl className={formStyles.formControl}>
              <Typography>{getContent('consumptionAccountingPeriodLabel')}</Typography>

              <TextField
                variant="outlined"
                value={formData.consumptionAccountingPeriodBiva ? formData.consumptionAccountingPeriodBiva : ''}
                onChange={(event) => {
                  trackInputFieldDataChange('consumptionAccountingPeriodBiva', {
                    previousValue: formData.consumptionAccountingPeriodBiva,
                    newValue: parseInt(event.target.value) || 0,
                  });
                  setFormData({ ...formData, consumptionAccountingPeriodBiva: parseInt(event.target.value) || 0 });
                }}
                placeholder={getContent('consumptionAccountingPeriodHelperPlaceholder')}
                helperText={
                  !!formData.consumptionAccountingPeriod && hasConsumptionAccountingPeriodError
                    ? getContent('consumptionAccountingPeriodErrorText')
                    : getContent('consumptionAccountingPeriodHelperText')
                }
                required
                error={!!formData.consumptionAccountingPeriodBiva && hasConsumptionAccountingPeriodError}
              />
            </FormControl>
          )}


          {/*  <FormControl className={formStyles.formControl}>
            <InputLabel required id="select-label" shrink={true}>
              {getContent("currentEnergyLabel")}
            </InputLabel>
            <Select
              labelId="select-label"
              value={formData.energySourceBiva}
              onChange={(event) => {
                trackInputFieldDataChange("energySourceBiva", {
                  previousValue: formData.energySourceBiva,
                  newValue: event.target.value,
                });
                setFormData({
                  ...formData,
                  energySourceBiva: event.target.value,
                });
              }}
            >
              <MenuItem value={getContent("energyOil")}>
                {getContent("energyOil")}
              </MenuItem>
              <MenuItem value={getContent("energyGas")}>
                {getContent("energyGas")}
              </MenuItem>
              <MenuItem value={getContent("energyHolz")}>
                {getContent("energyHolz")}
              </MenuItem>
              <MenuItem value={getContent("energyPellets")}>
                {getContent("energyPellets")}
              </MenuItem>
              <MenuItem value={getContent("energyFernwarme")}>
                {getContent("energyFernwarme")}
              </MenuItem>
              <MenuItem value={getContent("energyNachstorm")}>
                {getContent("energyNachstorm")}
              </MenuItem>
            </Select>
          </FormControl>
    */}
          <FormControl className={formStyles.formControl}>
            <FormLabel>{getContent("bivaColdDayBoosterLabel")}</FormLabel>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>{getContent("no")}</Grid>
              <Grid item>
                <Switch
                  checked={formData.bivaColdDayBooster}
                  onChange={(event) => {
                    trackInputFieldDataChange("bivaColdDayBooster", {
                      previousValue: formData.bivaColdDayBooster,
                      newValue: event.target.checked,
                    });
                    setFormData({
                      ...formData,
                      bivaColdDayBooster: event.target.checked,
                    });
                  }}
                />
              </Grid>
              <Grid item>{getContent("yes")}</Grid>
            </Grid>
          </FormControl>
          <FormControl className={formStyles.formControl}>
            <TextField
              variant="outlined"
              value={
                formData.powerBivaSource
              }
              onChange={(event) => {
                trackInputFieldDataChange("powerBivaSource", {
                  previousValue: formData.powerBivaSource,
                  newValue: parseInt(event.target.value) || 0,
                });
                setFormData({
                  ...formData,
                  powerBivaSource: parseInt(event.target.value) || 0,
                });
              }}
              label={getContent("powerBivaSourceLabel")}
              placeholder={getContent("powerBivaSourceHelperPlaceholder")}
              helperText={getContent("powerBivaSourceHelperText")}
            />
          </FormControl>


        </>
      )}

      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(7, 6, formData);
            setCurrentStep(6);
          }}
        >
          {getContent("backButtonLabel")}
        </CustomizedButton>
        <CustomizedButton
          autoFocus
          onClick={() => {
            trackStepChange(7, 8, formData);
            setCurrentStep(8);
          }}
          disabled={disabledNextStep}
        >
          {getContent("nextButtonLabel")}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
