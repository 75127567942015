/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://4zskjgwfvfd25oqag2r3nu5akq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-esnppqkn3ndbhjz3belwqf7tuu",
    "aws_cognito_identity_pool_id": "eu-central-1:5acee82d-94f7-4439-8ed9-035edc57ff4c",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_PQOFV4hlq",
    "aws_user_pools_web_client_id": "6ojr60h0tkuj56o5q3q18nk5u8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "WEBSITE",
        "GIVEN_NAME",
        "FAMILY_NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "eu-central-1",
            "maps": {
                "items": {
                    "wpguruDisplayMap-prod": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "wpguruDisplayMap-prod"
            }
        }
    }
};


export default awsmobile;
