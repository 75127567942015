/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLeadNoModel = /* GraphQL */ `
  mutation CreateLeadNoModel(
    $input: CreateLeadNoModelInput!
    $condition: ModelLeadNoModelConditionInput
  ) {
    createLeadNoModel(input: $input, condition: $condition) {
      id
      status
      type
      notes
      notesPublic
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        bivaColdDayBooster
        powerBivaSource
        isOwner
        leadPreference
      }
      partnerStatus
      contractPrice
      notesPartner
      buyDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLeadNoModel = /* GraphQL */ `
  mutation UpdateLeadNoModel(
    $input: UpdateLeadNoModelInput!
    $condition: ModelLeadNoModelConditionInput
  ) {
    updateLeadNoModel(input: $input, condition: $condition) {
      id
      status
      type
      notes
      notesPublic
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        bivaColdDayBooster
        powerBivaSource
        isOwner
        leadPreference
      }
      partnerStatus
      contractPrice
      notesPartner
      buyDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLeadNoModel = /* GraphQL */ `
  mutation DeleteLeadNoModel(
    $input: DeleteLeadNoModelInput!
    $condition: ModelLeadNoModelConditionInput
  ) {
    deleteLeadNoModel(input: $input, condition: $condition) {
      id
      status
      type
      notes
      notesPublic
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        bivaColdDayBooster
        powerBivaSource
        isOwner
        leadPreference
      }
      partnerStatus
      contractPrice
      notesPartner
      buyDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const buyLead = /* GraphQL */ `
  mutation BuyLead($leadId: String!, $partnerId: String) {
    buyLead(leadId: $leadId, partnerId: $partnerId)
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      checkFoerderung
      checkNutzungsbedingungen
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      request {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      checkFoerderung
      checkNutzungsbedingungen
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      request {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      checkFoerderung
      checkNutzungsbedingungen
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      request {
        nextToken
        startedAt
      }
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      status
      type
      ageCurrentHeatingSystem
      energySource
      heaterConstructionYear
      accountingPeriodDays
      volumeConsumptionAccountingPeriod
      consumptionAccountingPeriod
      consumptionYear
      energySubSource
      accountingPeriodStartDate
      accountingPeriodEndDate
      spaceHeatSystem
      tempPreFlow
      buildingType
      buildingUsefulArea
      buildingConstructionYear
      hotDomesticWater
      hotDomesticWaterFutur
      inhabs
      bivalentMode
      energySubSourceBiva
      consumptionAccountingPeriodBiva
      energySourceBiva
      volumeConsumptionAccountingPeriodBiva
      accountingPeriodStartDateBiva
      accountingPeriodEndDateBiva
      heatingSystemChangePlannedMonths
      hpFeatures
      pvSettings
      heaterLocationOld
      customerId
      evaluation {
        specificHeatDemand
        heatingDemand
        heatLoad
        heaterConstructionYear
        designExternalTemp
        consumptionYearBiva
        consumptionYear
        resultVideoURL
      }
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      distanceRuleIsRespected
      acceptedDataProtection
      acceptedTerms
      isOwner
      bivaColdDayBooster
      powerBivaSource
      contactPerson {
        name
        phone
        email
      }
      leadPreference
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        checkFoerderung
        checkNutzungsbedingungen
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      lead {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      status
      type
      ageCurrentHeatingSystem
      energySource
      heaterConstructionYear
      accountingPeriodDays
      volumeConsumptionAccountingPeriod
      consumptionAccountingPeriod
      consumptionYear
      energySubSource
      accountingPeriodStartDate
      accountingPeriodEndDate
      spaceHeatSystem
      tempPreFlow
      buildingType
      buildingUsefulArea
      buildingConstructionYear
      hotDomesticWater
      hotDomesticWaterFutur
      inhabs
      bivalentMode
      energySubSourceBiva
      consumptionAccountingPeriodBiva
      energySourceBiva
      volumeConsumptionAccountingPeriodBiva
      accountingPeriodStartDateBiva
      accountingPeriodEndDateBiva
      heatingSystemChangePlannedMonths
      hpFeatures
      pvSettings
      heaterLocationOld
      customerId
      evaluation {
        specificHeatDemand
        heatingDemand
        heatLoad
        heaterConstructionYear
        designExternalTemp
        consumptionYearBiva
        consumptionYear
        resultVideoURL
      }
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      distanceRuleIsRespected
      acceptedDataProtection
      acceptedTerms
      isOwner
      bivaColdDayBooster
      powerBivaSource
      contactPerson {
        name
        phone
        email
      }
      leadPreference
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        checkFoerderung
        checkNutzungsbedingungen
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      lead {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      status
      type
      ageCurrentHeatingSystem
      energySource
      heaterConstructionYear
      accountingPeriodDays
      volumeConsumptionAccountingPeriod
      consumptionAccountingPeriod
      consumptionYear
      energySubSource
      accountingPeriodStartDate
      accountingPeriodEndDate
      spaceHeatSystem
      tempPreFlow
      buildingType
      buildingUsefulArea
      buildingConstructionYear
      hotDomesticWater
      hotDomesticWaterFutur
      inhabs
      bivalentMode
      energySubSourceBiva
      consumptionAccountingPeriodBiva
      energySourceBiva
      volumeConsumptionAccountingPeriodBiva
      accountingPeriodStartDateBiva
      accountingPeriodEndDateBiva
      heatingSystemChangePlannedMonths
      hpFeatures
      pvSettings
      heaterLocationOld
      customerId
      evaluation {
        specificHeatDemand
        heatingDemand
        heatLoad
        heaterConstructionYear
        designExternalTemp
        consumptionYearBiva
        consumptionYear
        resultVideoURL
      }
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      distanceRuleIsRespected
      acceptedDataProtection
      acceptedTerms
      isOwner
      bivaColdDayBooster
      powerBivaSource
      contactPerson {
        name
        phone
        email
      }
      leadPreference
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        checkFoerderung
        checkNutzungsbedingungen
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      lead {
        nextToken
        startedAt
      }
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      type
      status
      partnerStatus
      contractPrice
      notes
      notesPublic
      partnerId
      notesPartner
      buyDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        customerId
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        isOwner
        bivaColdDayBooster
        powerBivaSource
        leadPreference
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      partner {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      type
      status
      partnerStatus
      contractPrice
      notes
      notesPublic
      partnerId
      notesPartner
      buyDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        customerId
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        isOwner
        bivaColdDayBooster
        powerBivaSource
        leadPreference
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      partner {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      type
      status
      partnerStatus
      contractPrice
      notes
      notesPublic
      partnerId
      notesPartner
      buyDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        customerId
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        isOwner
        bivaColdDayBooster
        powerBivaSource
        leadPreference
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      partner {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      companyName
      email
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      staus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      leads {
        nextToken
        startedAt
      }
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      companyName
      email
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      staus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      leads {
        nextToken
        startedAt
      }
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      id
      companyName
      email
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      staus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      leads {
        nextToken
        startedAt
      }
    }
  }
`;
export const createPartnerUser = /* GraphQL */ `
  mutation CreatePartnerUser(
    $input: CreatePartnerUserInput!
    $condition: ModelPartnerUserConditionInput
  ) {
    createPartnerUser(input: $input, condition: $condition) {
      id
      partnerId
      firstName
      lastName
      rechte
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      partner {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePartnerUser = /* GraphQL */ `
  mutation UpdatePartnerUser(
    $input: UpdatePartnerUserInput!
    $condition: ModelPartnerUserConditionInput
  ) {
    updatePartnerUser(input: $input, condition: $condition) {
      id
      partnerId
      firstName
      lastName
      rechte
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      partner {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePartnerUser = /* GraphQL */ `
  mutation DeletePartnerUser(
    $input: DeletePartnerUserInput!
    $condition: ModelPartnerUserConditionInput
  ) {
    deletePartnerUser(input: $input, condition: $condition) {
      id
      partnerId
      firstName
      lastName
      rechte
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      partner {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
