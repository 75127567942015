import { translations } from "../ConfiguratorForm/assets/content"
export default function buildCustomer(request) {

  return {
    /** General */
    groups: [
      {
        headline: "Persönliche Daten des Kunden",
        elements: [
          { left: `Vorname: ${request.firstName}`, right: `Nachname: ${request.lastName}` }, //Print frist & last name
          { left: `E-Mail: ${request.email}`, right: `Telefonnummer: ${request.phone}` }, //Print email & phone number
          { left: `Anschrift: ${request.address.street}`, right: `Hausnummer: ${request.address.streetNo}` }, //Print street & streetNo
          { left: `Postleitzahl: ${request.address.postalCode}`, right: `Stadt: ${request.address.city}` }, //Print postalcode & city
        ]
      },

    ]
  }
}
/*
console.log(JSON.stringify(build(
  {
    "firstName": "Dominic",
    "lastName": "Beckbauer",
    "email": "test@beckbauer.de",
    "phone": "+49123123123123",
    "checkFoerderung": true,
    "checkNutzungsbedingungen": true,
    "address": {
      "type": "main",
      "street": "Brüsseler Str.",
      "streetNo": "44A",
      "postalCode": "12341",
      "city": "Köln",
      "country": "DEU",
    },

  }
), null, " ")) */