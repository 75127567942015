import React from 'react';
import { StepContainer, Text, CustomizedButton, ButtonContainer } from '../shared';
import { getContent } from '../utils';
import { trackStepChange } from '../tracker';

export const StartStep = ({ setCurrentStep }) => {
  return (
    <StepContainer>
      <Text>{getContent('startStepText1')}</Text>
      <Text>{getContent('startStepText2')}</Text>
      <ButtonContainer>
        <CustomizedButton
          autoFocus
          onClick={() => {
            trackStepChange(0, 1, {});
            setCurrentStep(1);
          }}>
          {getContent('startButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
