import React, { Component } from 'react';

import { Typography, Box, LinearProgress, Button } from '@material-ui/core/';
import waitingForResult from '../media/dialogs/waitingForResult.gif';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import CheckCircle from '@material-ui/icons/CheckCircle';

import BeforeUnloadComponent from 'react-beforeunload-component';
import FeaturesSection from '../components/FeaturesSection';
import JumpButton from '../components/JumpButton';

import AnimatedModal from '../components/AnimatedModal';
import HeroSection2 from '../components/HeroSection2';
import CostCalculatorPage from './costcalculator';


import { getContent } from '../components/ConfiguratorForm/utils';
import { theme } from '../components/ConfiguratorForm/ConfiguratorForm';

// operations
import API, { graphqlOperation } from '@aws-amplify/api';
import * as customqueries from '../graphql/custom-queries';

/** Configure Amplify */
import config from '../aws-exports';
import CloseWarning from '../components/CloseWarning';
import { Beforeunload } from 'react-beforeunload';
import styled from 'styled-components';
import Section from '../components/Section';
import { trackEvent } from '../components/ConfiguratorForm/tracker';

const Image = styled.img`
  padding-top: 24px;
  padding-bottom: 24px;
  margin: auto;
  min-width: 200px;
  min-width: 300px;
  width: 30%;
`;

API.configure(config);

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      alertButtonClosed: false,
      formResultId: props.match.params.id,
      formResultData: {},
      heatPumpData: {},
      userContactData: {},
      userContactDataForm: {},
      isUserContactDataReady: false,
      hasFormResultError: false,
      hasHeatPumpResultError: false,
      openedModal: false,
      leaveingWarning: false
    };
  }

  getFormResultData = () => {
    //this.setState({ ...this.state, isLoading: true });



    console.log("getformresultdata")

    return API.graphql(
      graphqlOperation(customqueries.getRequest, {
        id: this.state.formResultId,
      }),
    )
      .then((result) => {

        const formResultData = result.data.getRequest;
        this.setState({ ...this.state, formResultData });

        /** Get existing customer data if there is already a `customerId`. */
        if (formResultData.customerId) {
          this.getCustomerData(formResultData.customerId);
        }
      })
      .catch((e) => this.setState({ ...this.state, hasFormResultError: true }))
      .finally(() => {
        this.setState({
          ...this.state,
          isLoading: false,
          alertButtonClosed: false,
        });
        trackEvent("viewResults", { area: "wpguru", requestId: this.state.formResultId, result: this.state.formResultData?.evaluation?.result?.value });
      });
  };

  getCustomerData = (customerId) => {
    this.setState({ ...this.state, isLoading: true });

    return API.graphql(
      graphqlOperation(customqueries.getCustomer, {
        id: customerId,
      }),
    )
      .then((result) => {
        this.setState({ ...this.state, userContactData: result.data.getCustomer, isUserContactDataReady: true });
      })
      .catch(() => this.setState({ ...this.state, hasHeatPumpResultError: true }))
      .finally(() =>
        this.setState({
          ...this.state,
          isLoading: false,
          alertButtonClosed: false,
        }),
      );
  };

  componentDidMount() {
    setTimeout(this.getFormResultData, 5000);



    // this.checkHeatPumpCompatibility();
  }

  setUserContactData = (userContact) => {
    this.setState({
      ...this.state,
      userContactDataForm: userContact,
    });
  };

  setIsUserContactDataReady = () => {
    this.setState({
      ...this.state,
      isUserContactDataReady: true,
    });
  };

  shouldDisplayErrorNotification = () =>
    (this.state.hasFormResultError || this.state.hasHeatPumpResultError) && !this.state.alertButtonClosed;
  shouldDisplayResultsSection = () => { return !this.state.isLoading && this.state?.formResultData?.evaluation && Object.keys(this.state.formResultData.evaluation).length };
  shouldDisplayThankYouMessage = () =>
    !this.state.isLoading &&
    this.state.isUserContactDataReady &&
    (!!Object.keys(this.state.userContactData).length || !!Object.keys(this.state.userContactDataForm).length);

  render() {
    return (
      <>
        {this.state.isLoading && (
          <>
            <MuiThemeProvider theme={theme}>
              <LinearProgress />
            </MuiThemeProvider>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
              {/* <Typography variant="h4" component="h5">
                {getContent('loadingFormResultsDescription')}
              </Typography> */}
              <Image src={waitingForResult} alt="Please wait!" />

            </Box>
          </>
        )}
        {this.shouldDisplayErrorNotification() && (
          <Alert
            severity="error"
            onClose={() => {
              this.setState({ ...this.state, alertButtonClosed: true });
            }}>
            {getContent('formResultsLoadingErrorDescription')}
          </Alert>
        )}
        {this.shouldDisplayResultsSection() && (
          <>
            <CloseWarning disabled={this.state.isUserContactDataReady} />

            <HeroSection2
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title={this.state.formResultData?.evaluation?.result?.name}
              subtitle={this.state.formResultData?.evaluation?.result?.text}
              value={this.state.formResultData?.evaluation?.result?.value}
            />
            {this.shouldDisplayThankYouMessage() ? (
              <>
                <HeroSection2
                  bgColor="default"
                  size="medium"
                  bgImage=""
                  bgImageOpacity={1}
                  title={
                    <>
                      <Box color={'#91c148'}>
                        <CheckCircle fontSize={'large'} />
                      </Box>
                      {getContent('thankYouLabel')}
                    </>
                  }
                  subtitle={`Wir haben Ihre Daten erhalten. Wir werden uns bei Ihnen, ${this.state.userContactData.firstName || this.state.userContactDataForm.firstName
                    } ${this.state.userContactData.lastName || this.state.userContactDataForm.lastName}, schnellstmöglichst melden.`}
                />
              </>
            ) : (
              <>
                {this.state.formResultData?.evaluation?.result?.value > 0 && (
                  <>
                    <HeroSection2
                      bgColor="default"
                      size="medium"
                      bgImage=""
                      bgImageOpacity={1}
                      title="Die Wärmepumpe im Vergleich mit anderen Heizungen"
                      subtitle="Hier gehts zu unserem Kostenvergleichsrechner."
                      image={getContent('offerImageUrl')}
                    /><JumpButton jumpToId="costcalculator">Zum Kostenvergleichsrechner</JumpButton>
                    <div id="contact"><HeroSection2
                      bgColor="default"
                      size="medium"
                      bgImage=""
                      bgImageOpacity={1}
                      title={getContent('offerTitle')}
                      subtitle={getContent('offerSubtitle')}
                      image={getContent('offerImageUrl')}
                    /></div>
                    <AnimatedModal
                      myId={this.state.formResultData.id}
                      userContactData={this.state.userContactDataForm}
                      setUserContactData={this.setUserContactData}
                      setIsUserContactDataReady={this.setIsUserContactDataReady}
                      heatPump={this.state.formResultData?.evaluation?.result?.value >= 3}
                      energyConsultant={true}
                    />
                  </>
                )}
              </>
            )}
            <FeaturesSection
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              heatPumpData={this.state.formResultData.evaluation}
            />
            {this.state.formResultData?.evaluation?.result?.value > 0 && (
              <div id="costcalculator" style={{ width: "80%", minWidth: "400px", border: "0px solid #FFFF00", textAlign: "center", margin: "auto" }}>

                <Section>
                  <CostCalculatorPage />
                </Section>
              </div>

            )}

          </>
        )}
      </>
    );
  }
}

export default Results;
