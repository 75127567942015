import React from 'react';
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Grid,
  Switch,
  Typography
} from '@material-ui/core';
import { StepContainer, CustomizedButton, ButtonContainer, Image, useFormStyles, Text } from '../shared';
import { getContent } from '../utils';
import { trackStepChange, trackInputFieldDataChange } from '../tracker';
import familyImage from '../assets/family-image.png';


export const HotWaterStep = ({ formData, setFormData, setCurrentStep }) => {
  const formStyles = useFormStyles();

  const hasHouseholdNumberError =
    !!formData.inhabs && (formData.inhabs < 1 || formData.inhabs > 25);

  const disableNextStep = !formData.inhabs || hasHouseholdNumberError;

  return (
    <StepContainer>
      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('heatingHotWaterLabel')}</Typography>

        {/*    <FormLabel>{getContent('heatingHotWaterLabel')}</FormLabel> */}
        <RadioGroup
          aria-label="gender"
          value={formData.hotDomesticWater ? getContent('yesOption') : getContent('noOption')}
          onChange={(event) => {
            trackInputFieldDataChange('hotDomesticWater', {
              previousValue: formData.hotDomesticWater,
              newValue: getContent('yesOption') === event.target.value,
            });
            setFormData({
              ...formData,
              hotDomesticWater: getContent('yesOption') === event.target.value,
            });
          }}>
          <FormControlLabel value={getContent('yesOption')} control={<Radio />} label={getContent('yesOption')} />
          <FormControlLabel value={getContent('noOption')} control={<Radio />} label={getContent('noOption')} />
        </RadioGroup>
      </FormControl>
      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('householdNumberLabel')}</Typography>

        <TextField
          autoFocus
          variant="outlined"
          //  label={getContent('householdNumberLabel')}
          value={formData.inhabs || ''}
          error={!!formData.inhabs && hasHouseholdNumberError}
          helperText={
            !!formData.inhabs && hasHouseholdNumberError
              ? getContent('householdNumberErrorText')
              : getContent('householdNumberHelperText')
          }
          required
          onChange={(event) => {
            trackInputFieldDataChange('inhabs', {
              previousValue: formData.inhabs,
              newValue: parseInt(event.target.value),
            });
            setFormData({ ...formData, inhabs: parseInt(event.target.value) });
          }}
        />
      </FormControl>
      {!formData.hotDomesticWater && (
        <>
          <FormControl className={formStyles.formControl}>
            <FormLabel>{getContent('preferCentralHeatingLabel')}</FormLabel>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>{getContent('no')}</Grid>
              <Grid item>
                <Switch
                  checked={formData.hotDomesticWaterFutur}
                  onChange={(event) => {
                    trackInputFieldDataChange('hotDomesticWaterFutur', {
                      previousValue: formData.hotDomesticWaterFutur,
                      newValue: event.target.checked,
                    });
                    setFormData({ ...formData, hotDomesticWaterFutur: event.target.checked });
                  }}
                />
              </Grid>
              <Grid item>{getContent('yes')}</Grid>
            </Grid>
          </FormControl>
          <Text>{getContent('preferCentralHeatingDescription')}</Text>
        </>
      )}
      <Image src={familyImage} alt="Family Image" width={'40%'} />
      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(6, 5, formData);
            setCurrentStep(5);
          }}>
          {getContent('backButtonLabel')}
        </CustomizedButton>
        <CustomizedButton
          onClick={() => {
            trackStepChange(6, 7, formData);
            setCurrentStep(7);
          }}
          disabled={disableNextStep}>
          {getContent('nextButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
