import React from 'react';
import styled from 'styled-components';
import { CustomizedButton } from '../components/ConfiguratorForm/shared';
import { trackEvent } from './ConfiguratorForm/tracker';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  min-width: 200px;
`;

const SubmitButton = styled(CustomizedButton)`
  margin-top: -24px;
  min-width: 200px;
`;

export default function JumpButton(props) {

    return (
        <>
            <ButtonContainer>
                <SubmitButton onClick={test => {
                    var element = document.getElementById(props.jumpToId);
                    if (element)
                        element.scrollIntoView();
                    trackEvent("useJumpButton", { area: "wpguru", jumpTo: props.jumpToId });
                }
                }>{props.children}</SubmitButton>
            </ButtonContainer>
        </>
    )
}