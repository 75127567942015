import React, { useCallback, useContext, useEffect, useState } from "react";

import { Typography } from "@material-ui/core/";

//Cost Caculater Setting
import { costCalculatorBaseSettings } from "../components/CostCalculator/content";

// operations
import API, { graphqlOperation } from "@aws-amplify/api";
import * as customqueries from "../graphql/custom-queries";

import { useParams } from "react-router-dom";

/** Configure Amplify */
import config from "../aws-exports";

//cost caculater components
import { useCostCalculator } from "../util/costCalculator";
import CostCalculatorResult from "../components/CostCalculator/CostCalculatorResult";
import CostCalcuaterTable from "../components/CostCalculator/CostCalcuaterTable";
import CostCalculatorGraph from "../components/CostCalculator/CostCalculatorGraph";
import CostCalculaterEditable from "../components/CostCalculator/CostCalculaterEditable";

import { Box } from "@mui/material";

API.configure(config);

const CostCalculatorPage = (props) => {
  const { id } = useParams();

  const {
    requestResultState,
    costCalculatorResultState,
    costCalculaterEditableState,
    costCaculaterByEnergySource,
  } = useCostCalculator();

  const { requestResult, setRequestResult } = requestResultState;
  const { costCalculaterEditable } = costCalculaterEditableState;
  const { costCalculatorResult } = costCalculatorResultState;

  const [isLoaded, setIsLoaded] = useState(false);

  const getData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(customqueries.getRequest, {
          id,
        })
      );

      setRequestResult(result.data.getRequest);
      setIsLoaded(true);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   // if (!requestResult || !costCalculatorResult._3thCol.energySource ||
  //   //   !costCalculatorResult._2thCol.energySource ||
  //   //   !costCalculatorResult._1thCol.energySource ||
  //   //   !costCalculaterEditable.mainEnergySource ||
  //   //   !costCalculaterEditable.secondEnergySource ||
  //   //   !costCalculaterEditable.thirdEnergySource) return;
  //   // if (
  //   //   costCalculatorResult._3thCol.energySource &&
  //   //   costCalculatorResult._2thCol.energySource &&
  //   //   costCalculatorResult._1thCol.energySource &&
  //   //   costCalculaterEditable.mainEnergySource &&
  //   //   costCalculaterEditable.secondEnergySource &&
  //   //   costCalculaterEditable.thirdEnergySource && !isLoaded &&
  //   //   costCalculatorResult._1thCol.acquisitionCostsThirdEnergySource ===
  //   //     costCalculaterEditable.acquisitionCostsThirdEnergySource
  //   // ){
  //   //   costCaculaterByEnergySource(3);
  //   //   costCaculaterByEnergySource(2, "naturalGas");
  //   //   costCaculaterByEnergySource(1, "HPair");
  //   //     setIsLoaded(true);
  //   // }
  // }, [costCalculaterEditable, costCalculatorResult, requestResult]);

  return isLoaded ? (
    <>
      <Box width="100%">
        <CostCalculatorResult />
        <CostCalcuaterTable />
        <CostCalculatorGraph />
        <CostCalculaterEditable />
      </Box>
    </>
  ) : (
    <Typography>Loading</Typography>
  );
};

export default CostCalculatorPage;
