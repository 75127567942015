import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} enterTouchDelay={0} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f3f6f4',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,

    border: '1px solid #dadde9',
  },
})); 

export default function Helper(props) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <>

      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">{props.header}</Typography>
            {props.text}
          </React.Fragment>
        }
      >
        <IconButton>
          <HelpIcon color='primary' fontSize="small" />
        </IconButton>
      </HtmlTooltip>
    </>
  );
}