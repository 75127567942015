import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Input,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  objectKeySearchtranslationsHeaterDE,
  useCostCalculator,
} from "../../util/costCalculator";
import { costCalculatorBaseSettings } from "./content";

const DropStyleButton = ({ type }) => {
  const {
    loadedState,
    consideredPeriodState,
    costCalculaterEditableState,
    costCalculatorResultState,
    energySourceChangeState,
  } = useCostCalculator();
  const { loaded, setLoaded } = loadedState;
  const { consideredPeriod, setConsideredPeriod } = consideredPeriodState;
  const { costCalculaterEditable, setCostCalculaterEditable } =
    costCalculaterEditableState;
  const { costCalculatorResult } = costCalculatorResultState;
  const { energySourceChange, setEnergySourceChange } = energySourceChangeState;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let menuData;
  if (type === "consideredPeriod") {
    menuData = [5, 10, 15, 20, 25, 30, 35];
  } else if (type === "fundingRatiosHP") {
    menuData = [0.35, 0.4, 0.45, 0.5];
  } else if (type === "fundingRatiosSecoundEnergySource") {
    menuData = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55];
  } else if (type === "fundingRatiosMainEnergySource") {
    menuData = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55];
  }

  const handleSelect = (e) => {
    if (type === "consideredPeriod") {
      setConsideredPeriod(Number(e));
    } else if (type === "fundingRatiosHP") {
      setCostCalculaterEditable({
        ...costCalculaterEditable,
        fundingRatiosHP: e,
      });
      setEnergySourceChange({
        ...energySourceChange,
        energySource: objectKeySearchtranslationsHeaterDE(
          costCalculatorResult._1thCol.energySource
        ),
        colNumber: 1,
      });
    } else if (type === "fundingRatiosSecoundEnergySource") {
      setCostCalculaterEditable({
        ...costCalculaterEditable,
        fundingRatiosSecoundEnergySource: e,
      });
      setEnergySourceChange({
        ...energySourceChange,
        energySource: objectKeySearchtranslationsHeaterDE(
          costCalculatorResult._2thCol.energySource
        ),
        colNumber: 2,
      });
    } else if (type === "fundingRatiosMainEnergySource") {
      setCostCalculaterEditable({
        ...costCalculaterEditable,
        fundingRatiosMainEnergySource: e,
      });
      setEnergySourceChange({
        ...energySourceChange,
        energySource: objectKeySearchtranslationsHeaterDE(
          costCalculatorResult._3thCol.energySource
        ),
        colNumber: 3,
      });
    }
    setLoaded(!loaded);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Box
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleClick}
        border="1px solid #000"
        padding="16px"
        borderRadius="15px"
        color="#FF4D00"
        sx={{ boxShadow: 4 }}
        boxShadow={4}
        maxWidth="250px"
        minWidth="150px"
        width="fit-content"
        height="auto"
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
      >
        <Typography>
          {type === "consideredPeriod"
            ? consideredPeriod
            : type === "fundingRatiosHP"
            ? `${costCalculatorResult._1thCol.fundingRatiosHP * 100} %`
            : type === "fundingRatiosSecoundEnergySource"
            ? `${
                costCalculatorResult._2thCol.fundingRatiosEnergySource * 100
              } %`
            : type === "fundingRatiosMainEnergySource"
            ? `${
                costCalculatorResult._3thCol.fundingRatiosEnergySource * 100
              } %`
            : null}
        </Typography>
        <KeyboardArrowDownIcon backgroundColor="#000" />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuData.map(
          (element, index) =>
            element !== consideredPeriod && (
              <MenuItem onClick={() => handleSelect(element)} key={index}>
                {type === "fundingRatiosHP" ||
                type === "fundingRatiosSecoundEnergySource" ||
                type === "fundingRatiosMainEnergySource"
                  ? `${(element * 100).toFixed()} %`
                  : element}
              </MenuItem>
            )
        )}
      </Menu>
    </Fragment>
  );
};

const StyleButton = ({ children, name, ...props }) => {
  return (
    <Box
      border="1px solid #000"
      padding="16px"
      borderRadius="15px"
      color="#FF4D00"
      sx={{ boxShadow: 4 }}
      boxShadow={4}
      width="250px"
      height="auto"
      {...props}
    >
      <Typography>{name}</Typography>
    </Box>
  );
};

const InputStyleButton = ({ placeHolder, value, name, onChange, id }) => {
  return (
    <Box
      border="1px solid #000"
      padding="16px"
      borderRadius="15px"
      color="#FF4D00"
      sx={{ boxShadow: 4 }}
      boxShadow={4}
      // width="250px"
      // maxWidth="250px"
      width="150px"
      // width="fit-content"
      height="auto"
    >
      <input
        placeholder={placeHolder}
        style={{
          outline: "none",
          border: "none",
          color: "#FF4D00",
          fontSize: "1rem",
          fontWeight: 400,
          overflow: "hidden",
          width: "130px",
        }}
        // value={value ? value : ""}
        value={value}
        type="number"
        id={id}
        name={name}
        onChange={(e) => onChange(e)}
      />
    </Box>
  );
};

const IncDecButton = ({ value, name, decIncFunc, id }) => {
  return (
    <div style={{ flexDirection: "row" }}>
      <Box
        border="1px solid #000"
        padding="10px 0"
        borderRadius="15px"
        color="#FF4D00"
        sx={{ boxShadow: 4 }}
        boxShadow={4}
        // width="190px"
        maxWidth="250px"
        minWidth="190px"
        width="fit-content"
        height="auto"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button onClick={() => decIncFunc(value, id, name, "dec")}>-</Button>
        <Typography>{(Number(value) * 100).toFixed(2)} %</Typography>
        <Button onClick={() => decIncFunc(value, id, name, "inc")}>+</Button>
      </Box>
    </div>
  );
};

const CostCalculaterEditable = () => {
  const matches = useMediaQuery("(max-width:959px)");
  const classes = useStyles();

  const {
    costCalculatorResultState,
    costCalculaterEditableState,
    loadedState,
    energySourceChangeState,
    costCaculaterByEnergySource,
  } = useCostCalculator();
  const { costCalculaterEditable, setCostCalculaterEditable } =
    costCalculaterEditableState;
  const { costCalculatorResult } = costCalculatorResultState;
  const { loaded, setLoaded } = loadedState;
  const { energySourceChange, setEnergySourceChange } = energySourceChangeState;

  const [hpElectricityTariff, setHpElectricityTariff] = useState(
    costCalculatorResult._1thCol.hpElectricityTariff
  );
  const [expanded, setExpanded] = useState(false);
  // const [updateEnergySource, setUpdateEnergySource] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  // const [specificFuleCostsEnergySource, setSpecificFuleCostsEnergySource] =
  //   useState(null);

  const changeHpElectricityTariff = () => {
    const HPpower = !hpElectricityTariff ? "HPonlie" : "heatPump";
    setCostCalculaterEditable({
      ...costCalculaterEditable,
      HPpower: HPpower,
    });
    setEnergySourceChange({
      ...energySourceChange,
      energySource: objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._1thCol.energySource
      ),
      colNumber: 1,
    });
    setHpElectricityTariff(!hpElectricityTariff);
  };

  const onChangeTextInput = (e) => {
    const { name, value, id } = e.target;
    let energySourceName;
    if (Number(id) === 1) {
      energySourceName = objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._1thCol.energySource
      );
    } else if (Number(id) === 2) {
      energySourceName = objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._2thCol.energySource
      );
    } else {
      energySourceName = objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._3thCol.energySource
      );
    }
    setCostCalculaterEditable({
      ...costCalculaterEditable,
      [name]: value,
    });
    setEnergySourceChange({
      ...energySourceChange,
      energySource: energySourceName,
      colNumber: Number(id),
    });
    setLoaded(!loaded);
  };

  const headLineHandler = (energySourceDE) => {
    const energySource = objectKeySearchtranslationsHeaterDE(energySourceDE);
    if (energySource === "oil") {
      return {
        headline: "Heizöl in €/Liter",
        value: 12.5,
      };
    } else if (energySource === "wood") {
      return {
        headline: "Holz in €/RM",
        value: 1800,
      };
    } else if (energySource === "pellets") {
      return {
        headline: "Pellets in €/kg",
        value: 4.8,
      };
    } else if (energySource === "coal") {
      return {
        headline: "Kohle in €/kg",
        value: 7,
      };
    } else if (energySource === "naturalGas") {
      return {
        headline: "Erdgas in €/kWh",
        value: 1,
      };
    } else if (
      energySource === "HPair" ||
      energySource === "HPwater" ||
      energySource === "HPdeppEarth" ||
      energySource === "HPgroundEarth"
    ) {
      return {
        headline: "Wärmepumpen-Strom in €/kWh",
        value: 1,
      };
    } else {
      return {
        headline: "Energiepreis in €/kWh",
        value: 1,
      };
    }
  };

  const decIncFunc = (value, id, name, incDec) => {
    let energySourceName;
    if (Number(id) === 1) {
      energySourceName = objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._1thCol.energySource
      );
    } else if (Number(id) === 2) {
      energySourceName = objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._2thCol.energySource
      );
    } else {
      energySourceName = objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._3thCol.energySource
      );
    }
    setCostCalculaterEditable({
      ...costCalculaterEditable,
      [name]: incDec === "inc" ? value + 0.001 : value - 0.001,
    });
    setEnergySourceChange({
      ...energySourceChange,
      energySource: energySourceName,
      colNumber: Number(id),
    });
    setLoaded(!loaded);
  };

  const onChangeSpecificFuleCostsEnergySource = (e) => {
    const { name, value, id } = e.target;
    let energySourceName;
    if (Number(id) === 1) {
      energySourceName = objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._1thCol.energySource
      );
      setCostCalculaterEditable({
        ...costCalculaterEditable,
        [name]: value,
      });
    } else if (Number(id) === 2) {
      energySourceName = objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._2thCol.energySource
      );
      setCostCalculaterEditable({
        ...costCalculaterEditable,
        [name]: value,
      });
    } else {
      energySourceName = objectKeySearchtranslationsHeaterDE(
        costCalculatorResult._3thCol.energySource
      );
      setCostCalculaterEditable({
        ...costCalculaterEditable,
        [name]: value,
      });
    }
    setEnergySourceChange({
      ...energySourceChange,
      energySource: energySourceName,
      colNumber: Number(id),
    });
    // setLoaded(!loaded);
  };

  // useEffect(() => {
  //   setSpecificFuleCostsEnergySource({
  //     ...specificFuleCostsEnergySource,
  //     main: (
  //       costCalculaterEditable.specificFuleCostsMainEnergySource *
  //       headLineHandler(costCalculatorResult._3thCol.energySource).value
  //     ).toFixed(3),
  //     second: (
  //       costCalculaterEditable.specificFuleCostsSecondEnergySource *
  //       headLineHandler(costCalculatorResult._2thCol.energySource).value
  //     ).toFixed(3),
  //     third: (
  //       costCalculaterEditable.specificFuleCostsThirdEnergySource *
  //       headLineHandler(costCalculatorResult._1thCol.energySource).value
  //     ).toFixed(3),
  //   });
  // }, []);

  // useEffect(() => {
  //   if (
  //     costCalculaterEditable.mainEnergySource !==
  //     costCalculatorResult._3thCol.energySource
  //   )
  //     setUpdateEnergySource(!updateEnergySource);
  // }, [costCalculatorResult, costCalculaterEditable]);

  // useEffect(() => {
  //   if (
  //     costCalculaterEditable.mainEnergySource ===
  //     costCalculatorResult._3thCol.energySource
  //   )
  //     setSpecificFuleCostsEnergySource({
  //       ...specificFuleCostsEnergySource,
  //       main: (
  //         costCalculaterEditable.specificFuleCostsMainEnergySource *
  //         headLineHandler(costCalculatorResult._3thCol.energySource).value
  //       ).toFixed(3),
  //       second: (
  //         costCalculaterEditable.specificFuleCostsSecondEnergySource *
  //         headLineHandler(costCalculatorResult._2thCol.energySource).value
  //       ).toFixed(3),
  //       third: (
  //         costCalculaterEditable.specificFuleCostsThirdEnergySource *
  //         headLineHandler(costCalculatorResult._1thCol.energySource).value
  //       ).toFixed(3),
  //     });
  // }, [updateEnergySource]);

  const resetButtonHandler = () => {
    setCostCalculaterEditable({
      mainEnergySource: null,
      secondEnergySource: null,
      thirdEnergySource: null,

      HPpower: null,
      utilizationRateThirdEnergySource: null,

      fundingRatiosHP: null,
      fundingRatiosSecoundEnergySource: null,
      fundingRatiosMainEnergySource: null,

      acquisitionCostsThirdEnergySource: null,
      acquisitionCostsSecondEnergySource: null,
      acquisitionCostsMainEnergySource: null,

      specificFuleCostsThirdEnergySource: null,
      specificFuleCostsSecondEnergySource: null,
      specificFuleCostsMainEnergySource: null,

      priceIncreaseThirdEnergySource: null,
      priceIncreaseSecondEnergySource: null,
      priceIncreaseMainEnergySource: null,

      deliveryCostsThirdEnergySource: null,
      deliveryCostsSecondEnergySource: null,
      deliveryCostsMainEnergySource: null,
    });
    setEnergySourceChange({
      ...energySourceChange,
      energySource: [
        costCalculatorResult._1thCol.energySource,
        costCalculatorResult._2thCol.energySource,
        costCalculatorResult._3thCol.energySource,
      ],
      colNumber: [1, 2, 3],
    });
    setHpElectricityTariff(costCalculatorResult._1thCol.hpElectricityTariff);
    setLoaded(!loaded);
  };

  return (
    <Box className={classes.container}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary>
          <Box margin="auto" flexDirection="row" display="flex">
            <Typography align="center" variant="h5">
              Parameter anpassen
            </Typography>
            <Box className={classes.centerFlex}>
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.accordionContainer}>
            {/* row */}
            <Box className={classes.accordionRow}>
              <Box className={classes._0thCol}>
                <Typography variant="h6">Allgemein:</Typography>
              </Box>
              <Box className={classes.column}>
                <Typography variant="h6" align="center">
                  Lebensdauer
                </Typography>
                <DropStyleButton type="consideredPeriod" />
              </Box>
              <Box className={classes.column}>
                <Typography variant="h6" align="center">
                  Wärmepumpen-Stromtarif
                </Typography>
                <Checkbox
                  checked={hpElectricityTariff}
                  onClick={changeHpElectricityTariff}
                />
              </Box>
              <Box className={classes.column}>
                <Typography variant="h6" align="center">
                  JAZ
                </Typography>
                <InputStyleButton
                  name="utilizationRateThirdEnergySource"
                  value={
                    costCalculatorResult._1thCol
                      .utilizationRateThirdEnergySource
                  }
                  id={1}
                  onChange={onChangeTextInput}
                />
              </Box>
            </Box>
            {/* row */}
            <Box className={classes.accordionRow}>
              <Box className={classes._0thCol}>
                <Typography variant="h6">Förderungen:</Typography>
              </Box>
              <Box className={classes.column}>
                <Typography variant="h6" align="center">
                  {costCalculatorResult._1thCol.energySource}
                </Typography>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._1thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <DropStyleButton type="fundingRatiosHP" />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Typography variant="h6" align="center">
                  {costCalculatorResult._2thCol.energySource}
                </Typography>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._2thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <DropStyleButton type="fundingRatiosSecoundEnergySource" />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Typography variant="h6" align="center">
                  {costCalculatorResult._3thCol.energySource}
                </Typography>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._3thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <DropStyleButton type="fundingRatiosMainEnergySource" />
                </Box>
              </Box>
            </Box>
            {/* row */}
            <Box className={classes.accordionRow}>
              <Box className={classes._0thCol}>
                <Typography variant="h6" fontSize="14px">
                  Anschaffungskosten in €:
                </Typography>
              </Box>
              <Box className={classes.column}>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._1thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <InputStyleButton
                    name="acquisitionCostsThirdEnergySource"
                    value={
                      costCalculatorResult._1thCol
                        .acquisitionCostsThirdEnergySourceOrginal
                    }
                    id={1}
                    onChange={onChangeTextInput}
                  />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._2thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <InputStyleButton
                    // placeHolder="Enter Acquisition Costs Second Energy Source"
                    name="acquisitionCostsSecondEnergySource"
                    value={
                      costCalculatorResult._2thCol
                        .acquisitionCostsMainEnergySourceOrginal
                    }
                    id={2}
                    onChange={onChangeTextInput}
                  />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._3thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <InputStyleButton
                    name="acquisitionCostsMainEnergySource"
                    value={
                      costCalculatorResult._3thCol
                        .acquisitionCostsMainEnergySourceOrginal
                    }
                    id={3}
                    onChange={onChangeTextInput}
                  />
                </Box>
              </Box>
            </Box>
            {/* row */}
            <Box className={classes.accordionRow}>
              <Box className={classes._0thCol}>
                <Typography variant="h6" fontSize="14px">
                  Energiekosten:
                </Typography>
              </Box>
              <Box className={classes.column}>
                <Typography variant="h6" align="center">
                  {
                    headLineHandler(costCalculatorResult._1thCol.energySource)
                      .headline
                  }
                </Typography>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._1thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <InputStyleButton
                    name="specificFuleCostsThirdEnergySource"
                    value={
                      costCalculatorResult._1thCol
                        .specificFuleCostsThirdEnergySource
                    }
                    id={1}
                    onChange={onChangeSpecificFuleCostsEnergySource}
                  />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Typography variant="h6" align="center">
                  {
                    headLineHandler(costCalculatorResult._2thCol.energySource)
                      .headline
                  }
                </Typography>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._2thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <InputStyleButton
                    name="specificFuleCostsSecondEnergySource"
                    value={
                      costCalculatorResult._2thCol
                        .specificFuleCostsMainEnergySource
                    }
                    id={2}
                    onChange={onChangeSpecificFuleCostsEnergySource}
                  />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Typography variant="h6" align="center">
                  {
                    headLineHandler(costCalculatorResult._3thCol.energySource)
                      .headline
                  }
                </Typography>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._3thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <InputStyleButton
                    name="specificFuleCostsMainEnergySource"
                    value={
                      costCalculatorResult._3thCol
                        .specificFuleCostsMainEnergySource
                    }
                    id={3}
                    onChange={onChangeSpecificFuleCostsEnergySource}
                  />
                </Box>
              </Box>
            </Box>
            {/* row */}
            <Box className={classes.accordionRow}>
              <Box className={classes._0thCol}>
                <Typography variant="h6" fontSize="14px">
                  Preisanstieg Energie pro Jahr:
                </Typography>
              </Box>
              <Box className={classes.column}>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._1thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <IncDecButton
                    name="priceIncreaseThirdEnergySource"
                    value={
                      costCalculatorResult._1thCol
                        .priceIncreasesThirdEnergySource
                    }
                    id={1}
                    decIncFunc={decIncFunc}
                  />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._2thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <IncDecButton
                    decIncFunc={decIncFunc}
                    name="priceIncreaseSecondEnergySource"
                    value={
                      costCalculatorResult._2thCol
                        .priceIncreasesMainEnergySource
                    }
                    id={2}
                  />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._3thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <IncDecButton
                    name="priceIncreaseMainEnergySource"
                    value={
                      costCalculatorResult._3thCol
                        .priceIncreasesMainEnergySource
                    }
                    id={3}
                    decIncFunc={decIncFunc}
                  />
                </Box>
              </Box>
            </Box>
            {/* row */}
            <Box className={classes.accordionRow}>
              <Box className={classes._0thCol}>
                <Typography variant="h6" fontSize="14px">
                  Zähler-/Lieferkosten in €:
                </Typography>
              </Box>
              <Box className={classes.column}>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._1thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <InputStyleButton
                    name="deliveryCostsThirdEnergySource"
                    value={
                      costCalculatorResult._1thCol
                        .provisionCostsThirdeEnergySource
                    }
                    id={1}
                    onChange={onChangeTextInput}
                  />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._2thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <InputStyleButton
                    name="deliveryCostsSecondEnergySource"
                    value={
                      costCalculatorResult._2thCol
                        .provisionCostsMainEnergySource
                    }
                    id={2}
                    onChange={onChangeTextInput}
                  />
                </Box>
              </Box>
              <Box className={classes.column}>
                <Box className={classes.mobileFlex}>
                  {matches && (
                    <img
                      src={costCalculatorResult._3thCol.energySourceLogo}
                      alt="Logo"
                      className={classes._0rowLogoStyle}
                    />
                  )}
                  <InputStyleButton
                    name="deliveryCostsMainEnergySource"
                    value={
                      costCalculatorResult._3thCol
                        .provisionCostsMainEnergySource
                    }
                    id={3}
                    onChange={onChangeTextInput}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
        <Box className={classes.resetButtonContainer}>
          <Button className={classes.resetButton} onClick={resetButtonHandler}>
            reset
          </Button>
        </Box>
      </Accordion>
    </Box>
  );
};

export default CostCalculaterEditable;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1100px",
    margin: "auto",
    marginTop: "50px",
    flexWrap: "wrap",
  },
  accordionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  accordionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "30px",
    // flexWrap: 'wrap',
    [theme.breakpoints.down("sm")]: {
      // paddingBottom: "10px",
      flexDirection: "column",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "10px auto",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",
  },
  mobileFlex: {
    display: "flex",
    flexDirection: "row",
  },
  _0thCol: {
    maxWidth: "150px",
    // minWidth: "150px",
    // width: 'auto',
    width: "190px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    wordBreak: "break-all",
  },
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxStyle: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textInput: {
    border: "1px solid #000",
    padding: "16px",
    borderRadius: "15px",
    color: "#FF4D00",
    boxShadow: 4,
    width: "250px",
    height: "auto",
  },
  resetButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  resetButton: {
    border: "1px solid #000",
  },
  _0rowLogoStyle: {
    width: 40,
    marginRight: 20,
  },
}));
