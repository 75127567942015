import React from 'react';
import { v4 } from 'uuid';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import styled from 'styled-components';

import ContactForm from './ContactForm';
import { theme } from '../components/ConfiguratorForm/ConfiguratorForm';
import { CustomizedButton } from '../components/ConfiguratorForm/shared';
import { getContent } from '../components/ConfiguratorForm/utils';

// operations
import API, { graphqlOperation } from '@aws-amplify/api';
import * as mutations from '../graphql/custom-queries';

/** Configure Amplify */
import config from '../aws-exports';
import { trackEvent } from './ConfiguratorForm/tracker';
API.configure(config);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 1000,
    overflow: 'scroll',
    maxHeight: "95%"
  },
}));

const ModalContainer = styled.div`
  max-width: 80%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  min-width: 200px;
`;

const SubmitButton = styled(CustomizedButton)`
  margin-top: -24px;
  min-width: 200px;
`;

export default function AnimatedModal({ myId, userContactData, setUserContactData, setIsUserContactDataReady, heatPump = true, energyConsultant = true }) {

  console.log(heatPump, energyConsultant);
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const handleOpen = () => { setModalOpen(true); trackEvent('openContactForm', {}); };

  const handleClose = (userContactData) => {
    setUserContactData(userContactData);
    setHasError(false);
    setModalOpen(false);
  };

  const handleSubmit = (userContactData) => {
    setIsLoading(true);

    setUserContactData(userContactData);
    saveUserContactData(userContactData)
      .then(() => {
        setIsUserContactDataReady();
        trackEvent("customer-form-submitted", { userContactData })
        setModalOpen(false);
      })
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  };

  const saveUserContactData = async (formData) => {
    const customerId = v4();

    var leadPreference = [];

    if (formData.heatPump) {
      leadPreference.push("heatPump");
    }

    if (formData.energyConsultant) {

      leadPreference.push("energyConsultant");
    }

    delete formData.heatPump;
    delete formData.energyConsultant;

    const { data } = await API.graphql(
      graphqlOperation(mutations.createCustomer, {
        input: { ...formData, id: customerId },
      }),
    );
    console.log("updateRequest")
    await API.graphql(

      graphqlOperation(mutations.updateRequest, {
        input: { id: myId, customerId, leadPreference },
      }),
    ).catch(() => {
      throw new Error('Error happened while mapping request with customer.');
    });

    return data.createCustomer;
  };

  return (
    <>
      <ButtonContainer>
        <SubmitButton onClick={handleOpen}>{getContent('userContactDataButtonLabel')}</SubmitButton>
      </ButtonContainer>
      <ModalContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={modalOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={modalOpen}>
            <div className={classes.paper}>
              <MuiThemeProvider theme={theme}>
                <h2>{getContent('contactDataModalTitle')}</h2>
                <ContactForm
                  userContactData={userContactData}
                  onSubmit={handleSubmit}
                  onClose={handleClose}
                  isLoading={isLoading}
                  hasError={hasError}
                  heatPump={heatPump}
                  energyConsultant={energyConsultant}
                />
              </MuiThemeProvider>
            </div>
          </Fade>
        </Modal>
      </ModalContainer>
    </>
  );
}
