
import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button, CardActions } from '@material-ui/core';
import { translations } from "./ConfiguratorForm/assets/content"


export default function LeadCard(props) {

    const translationMap = { "energyConsultant": "Energieberater", "heatPump": "Luft/Wasser Wärmepumpe" }

    const lead = props.lead
    var leadTypeText = translationMap[lead?.type]
    if (!leadTypeText) { leadTypeText = "Luft/Wasser Wärmepumpe" }



    const head = lead ? `${leadTypeText} in ${lead?.request?.address?.postalCode} ${lead?.request?.address?.city}` : "Keine Aufträge vorhanden"
    const bulletPoints = []
    if (lead?.request?.customer?.firstName) {
        bulletPoints.push(`${lead?.request?.customer?.firstName} ${lead?.request?.customer?.lastName}`)
    }
    if (lead?.buyDate) {
        bulletPoints.push(`Gekauft am: ${lead?.buyDate}`)
    }
    bulletPoints.push(`${translations?.enumTranslation?.buildingType[lead?.request?.buildingType]} mit ${lead?.request?.buildingUsefulArea} m² aus ${lead?.request?.buildingConstructionYear}`)
    bulletPoints.push(`Früher: ${translations?.enumTranslation?.energySource[lead?.request?.energySource]}`, `Verbrauchswert im letzten Abrechnungszeitraum: ${Math.round(lead?.request?.evaluation?.consumptionYear)} kWh`)

    const handleOpen = () => {
        props.onOpen(lead)
    };
    const handleClose = () => {
    };


    var bulletPointsUI = []

    for (var element of bulletPoints) {
        bulletPointsUI.push(<Typography variant="body2" color="text.secondary">{element}</Typography>)
    }

    return (
        <>
            <Card sx={{ maxWidth: 345 }}>

                {/*      <CardMedia
                    component="img"
                    height="140"
                    image="/static/images/cards/contemplative-reptile.jpg"
                    alt="green iguana"
                /> */}
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {head}
                    </Typography>
                    {lead ? (bulletPointsUI) : (<></>)}
                </CardContent>

                <CardActions>
                    {lead ? (<Button size="small" color="primary" onClick={handleOpen}>
                        Details
                    </Button>) : (<></>)}
                </CardActions>
            </Card>
        </>
    );
}