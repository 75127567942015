import React, { Component } from 'react';
import { LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Section from '../components/Section';
import { ConfiguratorForm, theme } from '../components/ConfiguratorForm/ConfiguratorForm';
import { getContent } from '../components/ConfiguratorForm/utils';

// operations
import API, { graphqlOperation } from '@aws-amplify/api';
import * as mutations from '../graphql/custom-queries';

const prepareApiFormData = (formData) => {
  const {
    buildingSpaceSquareMeters,
    buildingSpaceSquareMetersGreater,
    additionalTypeOfWood,
    householdNumber,
    ...rest
  } = formData;

  return {
    buildingSpaceSquareMeterGreater: buildingSpaceSquareMetersGreater,
    houseHoldNumber: householdNumber,
    ...rest,
  };
};

class Hpform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingFormResult: false,
      hasFormResultError: false,
      alertButtonClosed: false,
      cognitoId: undefined,
    };
  }

  handleFormSubmit = async (formData) => {
    console.log("submitting form data")
    this.setState({ ...this.state, isLoadingFormResult: true });

    const result = await API.graphql(
      graphqlOperation(mutations.createRequest, {
        input: prepareApiFormData(formData),
      }),
    )
      .catch(() => this.setState({ ...this.state, hasFormResultError: true }))
      .finally(() =>
        this.setState({
          ...this.state,
          isLoadingFormResult: false,
          alertButtonClosed: false,
        }),
      );

    this.setState({
      ...this.state,
      cognitoId: result?.data?.createRequest.id,
    });
  };

  render() {
    return (
      <>
        {this.state.hasFormResultError && !this.state.alertButtonClosed && (
          <Alert
            severity="error"
            onClose={() => {
              this.setState({ ...this.state, alertButtonClosed: true });
            }}>
            {getContent('formSubmittingErrorDescription')}
          </Alert>
        )}
        {this.state.isLoadingFormResult && (
          <MuiThemeProvider theme={theme}>
            <LinearProgress />
          </MuiThemeProvider>
        )}
        <>
          {this.state.cognitoId ? (
            <Redirect to={`/results/${this.state.cognitoId}`} />
          ) : (
            <Section
              style={{
                // display: 'block',
                paddingLeft: '2em',
                paddingRight: '2em',
              }}>
              <ConfiguratorForm onSubmit={this.handleFormSubmit} />
            </Section>
          )}
        </>
      </>
    );
  }
}

export default Hpform;
