import OilLogo from "../../media/costCalculator/oil.svg"
import NaturalGas from "../../media/costCalculator/water-heater.svg"
import LiquidGas from "../../media/costCalculator/gas.svg"
import Wood from "../../media/costCalculator/wood.svg"
import Pellets from "../../media/costCalculator/pellets.svg"
import NightPower from "../../media/costCalculator/nightPower.svg"
import Coal from "../../media/costCalculator/coal.svg"
import DistrictHeat from "../../media/costCalculator/districtHeat.svg"
import HPair from "../../media/costCalculator/HPair.svg"
import HPwater from "../../media/costCalculator/HPwater.svg"
import HPdeppEarth from "../../media/costCalculator/HPdeppEarth.svg"
import HPgroundEarth from "../../media/costCalculator/HPgroundEarth.svg"


export const costCalculatorBaseSettings = {

    //todo: everywhere same enums and all enums everywhere 

    reviewPeriodYears: 20,

    utilizationRateList: { "oil": 0.8, "naturalGas": 0.96, "liquidGas": 0.98, "wood": 0.6, "pellets": 0.89, "nightPower": 0.95, "coal": 0.8, "districtHeat": 0.88, "HPair": 3.5, "HPwater": 5, "HPdeppEarth": 4.5, "HPgroundEarth": 4.2 }, //Estimated efficiency of the heat generators
    
    co2fee: [30, 35, 45, 55, 58, 61, 64, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65], //CO2 tax values for the next 30 years
    
    acquisitionCosts: { "oil": 9000, "naturalGas": 8000, "liquidGas": 8000, "wood": 15000, "pellets": 18000, "nightPower": 8000, "coal": 12000, "districtHeat": 6000, "HPair": 18000, "HPwater": 0, "HPdeppEarth": 0, "HPgroundEarth": 0 }, //Acquisition costs for the assets

    fundingRatios: { "oil": 0.35, "naturalGas": 0.35, "liquidGas": 0.35, "wood": 0.25, "pellets": 0.25, "nightPower": 0.35, "coal": 0.35, "districtHeat": 0.25, "heatPump": 0.25, "HPonlie": 0.25 }, //State subsidy on the acquisition costs

    maintenance: { "oil": 250, "naturalGas": 115, "liquidGas": 165, "wood": 200, "pellets": 250, "nightPower": 0, "coal": 200, "districtHeat": 0, "heatPump": 150, "HPonlie": 150 },
    //maintenance Cost

    chimneySweeperCosts: { "oil": 60, "naturalGas": 50, "liquidGas": 50, "wood": 80, "pellets": 70, "nightPower": 0, "coal": 80, "districtHeat": 0, "heatPump": 0 }, //Costs for the chimney sweep (flue gas measurement, cleaning, etc.)

    provisionCosts: {"oil": 60, "naturalGas": 180, "liquidGas": 60, "wood": 25, "pellets": 25, "nightPower": 60, "coal": 25, "districtHeat": 630, "heatPump": 0, "HPonlie": 110}, //Costs for the provision of electricity meters or delivery charges

    priceIncreases: { "oil": 0.014, "naturalGas": 0.014, "liquidGas": 0.014, "wood": 0.005, "pellets": 0.014, "nightPower": 0.001, "coal": 0, "districtHeat": 0.01, "heatPump": 0.001, "HPonlie": 0.001 }, //Annual assumed price increase

    CO2factor: { "oil": 0.266, "naturalGas": 0.202, "liquidGas": 0.234, "wood": 0, "pellets": 0, "nightPower": 0, "coal": 0.35, "districtHeat": 0.2, "heatPump": 0, "HPonlie": 0}, //Physical and mathematical factor CO2 in kg/kWh
    
    specificFuleCosts: { "oil": 0.12, "naturalGas": 0.11, "liquidGas": 0.11, "wood": 0.070, "pellets": 0.092, "nightPower": 0.25, "coal": 0.03, "districtHeat": 0.1, "heatPump": 0.322, "HPonlie": 0.238 }, //Spezifische Specific fuel costs €/kWh #sourceEnergyPrice: https://www.bmwi.de/Redaktion/DE/Binaer/Energiedaten/energiedaten-gesamt-xls.html; https://www.carmen-ev.de/service/marktueberblick/marktpreise-energieholz/marktpreisvergleich/   specificFuleCosts: { "oil": 0.067, "naturalGas": 0.010, "liquidGas": 0.09, "wood": 0.050, "pellets": 0.060, "nightPower": 0.25, "coal": 0.03, "districtHeat": 0.086, "heatPump": 0.322, "HPonlie": 0.238 }, //Spezifische Specific fuel costs €/kWh #sourceEnergyPrice: https://www.bmwi.de/Redaktion/DE/Binaer/Energiedaten/energiedaten-gesamt-xls.html; https://www.carmen-ev.de/service/marktueberblick/marktpreise-energieholz/marktpreisvergleich/

    specificFoundationCosts: { "HPair": 15000, "HPwater": 0, "HPdeppEarth": 0, "HPgroundEarth": 0 }, //Specific development costs in m (HPwater, HPdeppEart) or m2 (HPgroundEarth)(placeholder, currently not used)

    secoundEnergySoure: ["pellets", "oil", "naturalGas", "liquidGas", "wood", "nightPower", "coal", "districtHeat" ], //Selection of possible heating technologies

    thirdEnergySource: ["HPair", "HPwater", "HPdeppEarth", "HPgroundEarth"], //Heat source selection for the heat pump & specific set-up costs

    localFoundingCosts: {"HPair": 0, "HPwater": 0, "HPdeppEarth": 0, "HPgroundEarth": 0},

    translationsEnergySourceDE: {
        oil: "Öl",
        naturalGas: "",
        "...": "..."

    },
    translationsHeaterDE: {
        oil: "Ölheizung",
        naturalGas : "Erdgas",
        liquidGas : "Flüssiggas",
        wood : "Brennholz",
        pellets : "Pellets",
        nightPower : "Nachtspeicher",
        coal : "Kohle",
        districtHeat : "Fernwärme",
        HPair: "Luft-Wärmepumpe",
        HPwater: "Wasser-Wärmepumpe",
        HPdeppEarth: "Tiefe Geothermie",
        HPgroundEarth: "Flächen Geothermie"
    },
    energySourceLogo: {
        oil: OilLogo,
        naturalGas: NaturalGas,
        liquidGas: LiquidGas,
        wood: Wood,
        pellets: Pellets,
        nightPower: NightPower,
        coal: Coal,
        districtHeat: DistrictHeat,
        HPair: HPair,
        HPwater: HPwater,
        HPdeppEarth: HPdeppEarth,
        HPgroundEarth: HPgroundEarth,
    },

}