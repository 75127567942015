import React, { Component } from "react";

import { Typography, Grid, Paper, Box } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import Section from "../components/Section";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Navigation as NavigationIcon, Call as CallIcon } from '@material-ui/icons';



class Details extends Component {

  constructor(props) {

    super(props);

    this.classes = makeStyles({
      table: {
        minWidth: 650,
      },
    });


    this.state = {

      hpdata: {},
      rowsPerson: [],
      rowsTechnical: [],
      contactdata: {},
      contactready: false,
      id: props.match.params.id
    }
  }



  //  const cla


  getContactData() {
    this.setState({
      contactready: true
    })
  }

  getData() {
    fetch("https://ga4ra2fxaj.execute-api.eu-central-1.amazonaws.com/dev/inbound/" + this.state.id)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          setTimeout(() => { this.getData(); }, 500)
          throw "not yet available"
        }

      })
      .then(result => {
        console.log("setstate")
        var rowsTechnical = this.prepareDataTechnical(result)
        var rowsPerson = this.prepareDataPerson(result)
        this.setState({ hpdata: result, rowsTechnical: rowsTechnical, rowsPerson: rowsPerson })
      }).catch(error => { console.log(error); });
  }


  prepareDataTechnical(data) {
    var rows = []
    rows = [

      { name: "Aktuelles Heizsystem", value: data.EnergySource },
      { name: "Verbrauchswert", value: data.PeriodTime.VolConsumpLastHeatPeriod },
      { name: "Gebäudeart", value: data.BuildingType },
      { name: "Wohnfläche in m²", value: data.Raumfläche },
      { name: "Im Haushalt lebende Personen", value: data.Inhabs },
      { name: "Erwärmt aktuelle Heizung Warmwasser?", value: data.ErwärmtIhreHeizungAuchDasWarmwasser },

    ]
    return rows
  }

  prepareDataPerson(data) {
    var rows = []
    rows = [
      { name: "Vorname", value: data.person.firstName },
      { name: "Nachname", value: data.person.lastName },
      { name: "Adresse", value: `${data.person.street} ${data.person.streetNo}` },
      { name: "PLZ", value: data.person.plz },
      { name: "Ort", value: data.person.city },
      { name: "Telefon", value: data.person.phone },

    ]
    return rows
  }

  componentDidUpdate() {
    console.log("component did update ")
    if (this.state.hpdata && this.state.id) {
      console.log("load event registriert")



    }
  }

  2
  componentDidMount() {

    this.getContactData();
    this.getData();
  }

  handleContactReady = (contact) => {
    console.log(contact)
    this.setState({
      contactready: true,
      contactdata: contact
    })


  }

  render() {




    return !this.state.hpdata.KEY ? (
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >

          <div><CircularProgress /></div>
          <Typography variant="h3" component="h3" > Wir bereiten die Daten auf. Bitte warten....</Typography>
        </Box>
      </>
    ) : (
      <>

        <Section style={{ display: "block", paddingLeft: "100px", paddingRight: "100px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>{this.state.hpdata.person.firstName} {this.state.hpdata.person.lastName}, {this.state.hpdata.person.city}, {this.state.id}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Unsere Einschätzung:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>Hier ist eine Wärmepumpe möglich.</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Lage</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{this.state.hpdata.person.city} hat kaum Werte unter 0 Grad</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Dämmung</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>Die Verbrauchswerte weisen auf eine gute Isolierung hin</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Heizungstyp</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{this.state.hpdata.EnergySource}, {this.state.hpdata.SpaceHeatSystem}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Wohnhaus</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{this.state.hpdata.BuildingType}</Typography>
            </Grid>
            <Grid item xs={2}>
              <a href={"tel:" + this.state.hpdata.person.phone}><CallIcon /></a>
            </Grid>
            <Grid item xs={2}>
              <a href={`https://www.google.com/maps/search/?api=1&query=${this.state.hpdata.person.street}+${this.state.hpdata.person.streetNo}+${this.state.hpdata.person.plz}+${this.state.hpdata.person.city}`} target="_blank"><NavigationIcon /></a>
            </Grid>
          </Grid>


        </Section>
        <Section style={{ display: "block", paddingLeft: "100px", paddingRight: "100px" }}>
          <Typography variant="h5">Details</Typography>
          <TableContainer component={Paper}>
            <Table className={this.classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Wert</TableCell>
                  <TableCell align="left">Weitere Informationen</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rowsTechnical.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.value}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Section>
        <Section style={{ display: "block", paddingLeft: "100px", paddingRight: "100px" }}>
          <Typography variant="h5">Kontakt</Typography>
          <TableContainer component={Paper}>
            <Table className={this.classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Wert</TableCell>
                  <TableCell align="left">Weitere Informationen</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rowsPerson.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.value}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Section>

      </>
    )

  }
}



export default Details