import React from 'react';
import styled from 'styled-components';
import { Typography, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { StepContainer, CustomizedButton, ButtonContainer, Image, Text, useFormStyles } from '../shared';
import { getContent } from '../utils';
import { trackStepChange, trackInputFieldDataChange } from '../tracker';
import wallDistributionImage from '../assets/wall-distribution-image.jpeg';
import radiatorsDistributionImage from '../assets/radiator-distribution-image.jpeg';
import ribbedRadiatorsDistributionImage from '../assets/ribbed-radiator-distribution-image.jpeg';
import convectorDistributionImage from '../assets/konvektor.jpg';

const ImageTitle = styled(Text)`
  text-align: center;
  font-weight: bold;
`;

const renderDistributionDetails = (distributionMethod) => {
  switch (distributionMethod) {
    case 'wallHeating':
      return (
        <>
          <Image src={wallDistributionImage} alt="Energy Distribution Image" width={'50%'} />
          <ImageTitle>{getContent('distributionWallHeatingImageTitle')}</ImageTitle>
          <Text>{getContent('distributionWallHeatingImageDescription')}</Text>
        </>
      );
    case 'panelRadiator':
      return (
        <>
          <Image src={radiatorsDistributionImage} alt="Energy Distribution Image" width={'50%'} />
          <ImageTitle>{getContent('distributionRadiatorsHeatingImageTitle')}</ImageTitle>
          <Text>{getContent('distributionRadiatorsHeatingImageDescription1')}</Text>
          <Text>{getContent('distributionRadiatorsHeatingImageDescription2')}</Text>
        </>
      );
    case 'ribbedRadiator':
      return (
        <>
          <Image src={ribbedRadiatorsDistributionImage} alt="Energy Distribution Image" width={'50%'} />
          <ImageTitle>{getContent('distributionRibbedRadiatorsHeatingImageTitle')}</ImageTitle>
          <Text>{getContent('distributionRibbedRadiatorsHeatingImageDescription')}</Text>
        </>
      );
    case 'convector':
      return (
        <>
          <Image src={convectorDistributionImage} alt="Energy Distribution Image" width={'50%'} />
          <ImageTitle>{getContent('distributionConvectorHeatingImageTitle')}</ImageTitle>
          <Text>{getContent('distributionConvectorHeatingImageDescription')}</Text>
        </>
      );
    default:
      return <></>;
  }
};


export const DistributionStep = ({ formData, setFormData, setCurrentStep }) => {
  const formStyles = useFormStyles();

  return (
    <StepContainer>
      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('distributionLabel')}</Typography>

        {/*         <InputLabel required id="select-label" shrink={true}>
          {getContent('distributionLabel')}
        </InputLabel> */}
        <Select
          labelId="select-label"
          value={formData.spaceHeatSystem}
          onChange={(event) => {
            trackInputFieldDataChange('spaceHeatSystem', {
              previousValue: formData.spaceHeatSystem,
              newValue: event.target.value,
            });
            setFormData({ ...formData, spaceHeatSystem: event.target.value });
          }}>
          <MenuItem value="underfloorHeating">
            {getContent('distributionUnderfloorHeating')}
          </MenuItem>
          <MenuItem value="wallHeating">{getContent('distributionWallHeating')}</MenuItem>

          <MenuItem value="ribbedRadiator">
            {getContent('distributionRibbedRadiatorsHeating')}
          </MenuItem>
          <MenuItem value="panelRadiator">
            {getContent('distributionPanelRadiator')}
          </MenuItem>
          <MenuItem value="convector">
            {getContent('distributedConvectorHeating')}
          </MenuItem>
          <MenuItem value="mixedHeating">
            {getContent('distributedMixedHeating')}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('tempPreFlowLabel')}</Typography>

        <TextField
          variant="outlined"
          value={formData.tempPreFlow}
          onChange={(event) => {
            trackInputFieldDataChange('tempPreFlow', {
              previousValue: formData.tempPreFlow,
              newValue: parseInt(event.target.value) || 0,
            });
            setFormData({ ...formData, tempPreFlow: parseInt(event.target.value) || 0 });
          }}

          placeholder={getContent('tempPreFlowHelperPlaceholder')}
          helperText={getContent('tempPreFlowHelperText')}
        />
      </FormControl>
      {renderDistributionDetails(formData.spaceHeatSystem)}
      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(4, 3, formData);
            setCurrentStep(3);
          }}>
          {getContent('backButtonLabel')}
        </CustomizedButton>
        <CustomizedButton
          autoFocus
          onClick={() => {
            trackStepChange(4, 5, formData);
            setCurrentStep(5);
          }}>
          {getContent('nextButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
