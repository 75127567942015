import React, { Fragment, useState } from "react";
import { Box, makeStyles, Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { costCalculatorBaseSettings } from "../content";
import {
  useCostCalculator,
  objectKeySearchtranslationsHeaterDE,
} from "../../../util/costCalculator";

const TableMenu = ({ energySource, column }) => {
  const {
    costCaculaterByEnergySource,
    loadedState,
    energySourceChangeState,
    costCalculaterEditableState,
  } = useCostCalculator();
  const { loaded, setLoaded } = loadedState;
  const { energySourceChange, setEnergySourceChange } = energySourceChangeState;
  const { costCalculaterEditable, setCostCalculaterEditable } =
    costCalculaterEditableState;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (e) => {
    if (Number(column) === 1) {
      setCostCalculaterEditable({
        ...costCalculaterEditable,
        thirdEnergySource: null,
        HPpower: null,
        utilizationRateThirdEnergySource: null,
        fundingRatiosHP: null,
        acquisitionCostsThirdEnergySource: null,
        specificFuleCostsThirdEnergySource: null,
        priceIncreaseThirdEnergySource: null,
        deliveryCostsThirdEnergySource: null,
      });
    } else if (Number(column) === 2) {
      setCostCalculaterEditable({
        ...costCalculaterEditable,
        secondEnergySource: null,
        fundingRatiosSecoundEnergySource: null,
        acquisitionCostsSecondEnergySource: null,
        specificFuleCostsSecondEnergySource: null,
        priceIncreaseSecondEnergySource: null,
        deliveryCostsSecondEnergySource: null,
      });
    } else {
      setCostCalculaterEditable({
        ...costCalculaterEditable,
        mainEnergySource: null,
        fundingRatiosMainEnergySource: null,
        acquisitionCostsMainEnergySource: null,
        specificFuleCostsMainEnergySource: null,
        priceIncreaseMainEnergySource: null,
        deliveryCostsMainEnergySource: null,
      });
    }
    setEnergySourceChange({
      ...energySourceChange,
      energySource: e,
      colNumber: Number(column),
    });
    setLoaded(!loaded);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Box
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleClick}
        className={classes.boxStyle}
      >
        <KeyboardArrowDownIcon className={classes.arrowColor} />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {column !== 1
          ? costCalculatorBaseSettings.secoundEnergySoure.map(
              (e, i) =>
                objectKeySearchtranslationsHeaterDE(energySource) !== e && (
                  <MenuItem onClick={() => handleSelect(e)} key={i}>
                    {costCalculatorBaseSettings.translationsHeaterDE[e]}
                  </MenuItem>
                )
            )
          : costCalculatorBaseSettings.thirdEnergySource.map(
              (e, i) =>
                objectKeySearchtranslationsHeaterDE(energySource) !== e && (
                  <MenuItem onClick={() => handleSelect(e)} key={i}>
                    {costCalculatorBaseSettings.translationsHeaterDE[e]}
                  </MenuItem>
                )
            )}
      </Menu>
    </Fragment>
  );
};

export default TableMenu;

const useStyles = makeStyles({
  boxStyle: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowColor: {
    color: "white",
  },
});
