import React from 'react';
import { TextField, Grid, Checkbox, FormControlLabel, Link, LinearProgress, FormGroup } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import styled from 'styled-components';

import { CustomizedButton } from './ConfiguratorForm/shared';
import { getContent } from './ConfiguratorForm/utils';
import { Typography } from '@mui/material';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 16px;
  }
`;

export const AlertContainer = styled.div`
  padding-bottom: 12px;
`;

const privacyNoticeUrl = 'https://www.heatenergysolutions.com/privacy';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: {
    street: '',
    streetNo: '',
    postalCode: '',
    city: '',
    country: 'DEU'
  },
  checkNutzungsbedingungen: false,
  checkFoerderung: true,
  heatPump: false,
  energyConsultant: false,
};

const ContactForm = ({ userContactData, onSubmit, onClose, isLoading, hasError, heatPump, energyConsultant }) => {
  const [formData, setFormData] = React.useState({ ...defaultValues, heatPump, energyConsultant, ...userContactData });

  const handleTextFieldChange = ({ target }) => {
    const { name, value } = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTextFieldChangeAddress = ({ target }) => {
    const { name, value } = target;
    formData.address[name] = value;
    console.log(formData);
    setFormData({
      ...formData,
    });
  };

  const handleCheckboxChange = ({ target }) => {
    const { name, checked } = target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };



  const buttonDisabled =
    isLoading ||
    !formData.firstName ||
    !formData.lastName ||
    !formData.email ||
    !formData.phone ||
    !formData.address.street ||
    !formData.address.streetNo ||
    !formData.address.postalCode ||
    !formData.address.city ||
    //  !formData.address.country ||
    !formData.checkNutzungsbedingungen;

  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      {isLoading && <LinearProgress />}
      {!isLoading && hasError && (
        <AlertContainer>
          <Alert severity="error">{getContent('savingUserContactErrorDescription')}</Alert>
        </AlertContainer>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography>Wobei dürfen wir Sie beraten?</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={formData.energyConsultant} onChange={handleCheckboxChange} name="energyConsultant" disabled={!energyConsultant} />
              }
              label="Energieberatung"
            />
            <FormControlLabel
              control={
                <Checkbox checked={formData.heatPump} onChange={handleCheckboxChange} name="heatPump" disabled={!heatPump} />
              }
              label="Wärmepumpe"
            />
            {!heatPump && (<Typography>Ihr Haus scheint nicht für eine Wärmepumpe geeignet zu sein. Sie müssen hier zunächst eine Energieberatung machen</Typography>)}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            key="firstName"
            name="firstName"
            label={getContent('firstName')}
            fullWidth
            required
            value={formData.firstName}
            autoComplete="none"
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            key="lastName"
            name="lastName"
            label={getContent('lastName')}
            fullWidth
            value={formData.lastName}
            autoComplete="none"
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            required
            key="street"
            name="street"
            label={getContent('street')}
            fullWidth
            value={formData.address.street}
            autoComplete="none"
            onChange={handleTextFieldChangeAddress}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            key="streetNo"
            name="streetNo"
            label={getContent('houseNumber')}
            fullWidth
            value={formData.address.streetNo}
            autoComplete="none"
            onChange={handleTextFieldChangeAddress}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            key="postalCode"
            name="postalCode"
            label={getContent('zipCode')}
            fullWidth
            value={formData.address.postalCode}
            autoComplete="none"
            onChange={handleTextFieldChangeAddress}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            required
            key="city"
            name="city"
            label={getContent('city')}
            fullWidth
            value={formData.address.city}
            autoComplete="none"
            onChange={handleTextFieldChangeAddress}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            key="email"
            name="email"
            label={getContent('email')}
            fullWidth
            value={formData.email}
            autoComplete="none"
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            key="phone"
            required
            name="phone"
            label={getContent('telephoneNumber')}
            fullWidth
            value={formData.phone}
            autoComplete="none"
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="checkNutzungsbedingungen"
                checked={formData.checkNutzungsbedingungen}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label={getContent('termsAndConditions')}
          />
          <Link href={privacyNoticeUrl} target="_blank" rel="noreferrer">
            {getContent('termsAndConditionsLink')}
          </Link>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            label={getContent('govermentFunding')}
            control={
              <Checkbox checked={formData.checkFoerderung} onChange={handleCheckboxChange} name="checkFoerderung" />
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ButtonContainer>
            <CustomizedButton disabled={isLoading} variant="outlined" onClick={() => onClose(formData)}>
              {getContent('closeButton')}
            </CustomizedButton>
            <CustomizedButton disabled={buttonDisabled} onClick={() => onSubmit(formData)}>
              {getContent('submitButton')}
            </CustomizedButton>
          </ButtonContainer>
        </Grid>
      </Grid>
    </form>
  );
};
export default ContactForm;
