export const translations = {

  enumTranslation: {
    country: {
      'DEU': 'Deutschland',
      'AUT': 'Österreich',
    },
    heaterLocation: {
      'cellar': 'Keller',
      'groundFloor': 'Erdgeschoss',
      'upperFloorBelowRoof': 'Obergeschoss unterhalb des Daches',
      'adjoiningBuilding': 'Nebenstehendes Gebäude',
      'elsewhere': 'Sonstiges',
      'unknown': 'Unbekannt'
    },
    pvSettings: {
      'available': '(Bereits) Vorhanden',
      'interested': 'Interessiert',
      'notAvailableNotInterested': 'Nicht vorhanden, nicht interessiert',
    },
    hpFeatures: {
      'highQualityProduct': 'Hohe Qualität',
      'coolingPossible': 'Klimatisieren des Gebäudes möglich',
      'premiumOptic': 'Angenehmens Aussehen',
      'highEfficiency': 'Hohe Effizienz',
      'lowInitialPrice': 'Niedriger Anschaffungspreis',
    },
    buildingType: {
      'office': 'Bürogebäude',
      'singleFamilyHouse': 'Einfamilienhaus',
      'apartmentHouse': 'Mehrfamilienhaus',
      'school': 'Schule',
      'other': 'Sonstiges',
    },
    spaceHeatSystem: {
      'convector': 'Konvektor',
      'ribbedRadiator': 'Rippenheizkörper',
      'panelRadiator': 'Plattenheizkörper',
      'underfloorHeating': 'Fußbodenheizung',
      'wallHeating': 'Wandheizung',
      'mixedHeating': 'Gemischtes Heizsystem',
    },
    energySource: {
      'oil': 'Heizöl',
      'naturalGas': 'Erdgas',
      'pellets': 'Pellets',
      'wood': 'Holz',
      'districtHeat': 'Fernwärme',
      'nightPower': 'Nachtstrom',
      'coal': 'Kohle',
    },
    energySubSource: {
      'birch': 'Birke',
      'beech': 'Buche',
      'oak': 'Eiche',
      'ash': 'Esche',
      'pine': 'Kiefer',
      'larch': 'Lärche',
      'hardwoodMix': 'Hartholz-Mix',
    },
    OperationMode: {
      'monovalent': 'Monovalent',
      'monovalentPowerControlled ': 'Monovalent Stromgeführt',
      'monoenergetic': 'Monoenergetisch',
      'bivalentAlternative': '',
      'bivalentPartialParallel': '',
    }
  },


  /** General */
  pageHeader: 'Kostenloser Wärmepumpen-Check für Ihr Gebäude',
  backButtonLabel: 'Zurück',
  nextButtonLabel: 'Weiter',

  /** Start Step */
  startStepLabel: 'Start',
  startStepText1: 'Ihre alte Heizanlage muss getauscht werden?',
  startStepText2: 'Wir prüfen kostenfrei für Sie, ob eine nachhaltige Heizanlage für Ihr Gebäude in Frage kommt und finden für Sie den geeigneten Fachpartner in Ihrer Region. ',
  startButtonLabel: 'Start',

  /** Location Step */
  locationStepLabel: 'Standort',
  zipCodeLabel: 'Postleitzahl',
  countryLabel: 'Land',
  countryGermany: 'Deutschland',
  countryAustria: 'Österreich',
  locationStepText: 'Ihr Standort ist entscheidend damit wir die Effizienz der Wärmepumpe in Ihrer Klimazone bestimmen können.',

  /** Energy Step */
  energySourceStepLabel: 'Energieträger',
  energyLabel: 'Mit welchem Energieträger (Öl, Gas, Holz etc.) heizen Sie aktuell?',
  energyOil: 'Heizöl',
  energyNaturalGas: 'Natur Gas',
  energyLiquidGas: 'Flüssiggas',
  energyWood: 'Holz',
  energyPellets: 'Pellets',
  energyDistrictHeat: 'Fernwärme',
  energyNightPower: 'Nachtstrom',
  energyCoal: "Kohle",

  ageCurrentHeatingSystemLabel: 'Wie alt ist Ihre Heizung in Jahren in etwa?',
  ageCurrentHeatingSystemHelperText:
    'Wir benötigen das (geschätzte) Alter Ihrer Heizung um den Effizienzwert Ihrer alten Anlage zu bestimmen.',

  /** Consumption Step */
  consumptionStepLabel: 'Verbrauch',
  consumptionAccountingPeriodErrorVolumeLabel: 'Energieträger nach Volumen',
  energyConsumptionVolumeLabel: 'Wie hoch ist Ihr Energieverbrauch?*',
  energyConsumptionVolumeHolzHelperText:
    'Tragen Sie die im Abrechnungszeitraum verbrauchte Menge an Holz in Raummetern ein.',
  energyConsumptionVolumeOilHelperText: 'Tragen Sie Ihren Heizölverbrauch in Litern ein.',
  energyConsumptionVolumePelletsHelperText: 'Tragen Sie den Pelletsverbrauch Ihres angegebenen Abrechnungszeitraums in Kilogramm (kg) ein.',
  energyConsumptionVolumeKohleHelperText: 'Tragen Sie den Kohleverbrauch Ihres angegebenen Abrechnungszeitraums in Kilogramm (kg) ein.',
  energyConsumptionVolumeHelperPlaceholder: 'Energieverbrauch Ihrer letzen Abrechnung',
  consumptionAccountingPeriodLabel: 'Wie hoch ist Ihr Energieverbrauch?',
  consumptionAccountingPeriodHelperText: 'Tragen Sie den Energieverbrauch Ihrer letzten Abrechnung in Kilowattstunden (kWh) ein.',
  consumptionAccountingPeriodHelperPlaceholder: 'Energieverbrauch Ihrer letzen Abrechnung',
  startDateLabel: 'Beginn Abrechnungszeitraum',
  endDateLabel: 'Ende Abrechnungszeitraum',
  woodSelectLabel: 'Holzart',
  woodBirke: 'Birke',
  woodBuche: 'Buche',
  woodEiche: 'Eiche',
  woodEsche: 'Esche',
  woodKiefer: 'Kiefer',
  woodLarche: 'Lärche',
  woodHartholz: 'Hartholz-Mix',
  consumptionStepText1:
    'In welchem Zeitraum wurde die Energiemenge verbraucht? Die Wintermonate sollten hier eingeschlossen sein.',
  consumptionStepText2: '',
  datesErrorText: 'Das Ende des Abrechnungszeitraums sollte größer sein als der Beginn des Abrechnungszeitraums.',
  consumptionAccountingPeriodErrorText: 'Der Wert muss mindestens 500 betragen.',

  /** Distribution Step */
  distributionStepLabel: 'Verteilung',
  distributionLabel: 'Wie wird die Wärme an Ihren Wohnraum abgegeben?',
  distributionUnderfloorHeating: 'Fußbodenheizung',
  distributionWallHeating: 'Wandheizung oder Deckenheizung',
  distributionWallHeatingImageTitle: 'Abbildung: Wand im Rohbau mit installiertem Wärmeverteilsystem',
  distributionWallHeatingImageDescription:
    'Mit Flächenheizungen (z.B. Wand- oder Deckenheizungen) sind Übertragungssysteme gemeint, die in Wand oder Decke eingelassen sind. Wandheizungen z.B. überzeugen durch eine sehr große Heizfläche und entsprechend geringe Vorlauftemperaturen. Dadurch steigt die Effizienz des Heizsystems.',
  distributionRadiatorsHeating: 'Heizkörper (moderne Plattenheizkörper)',
  distributionRadiatorsHeatingImageTitle:
    'Abbildung: Beispiel für einen an der Wand befestigten Plattenheizkörper.',
  distributionRadiatorsHeatingImageDescription1:
    ' Heutzutage sind moderne Plattenheizkörper effizient mit einer Wärmepumpe kombinierbar. Sie benötigen also nicht notwendigerweise eine Flächenheizung. Etwas größere Heizkörper (von der Fläche) können demnach auch mit geringen Vorlauftemperaturen genügend Wärme an den Raum abgeben.',
  distributionRadiatorsHeatingImageDescription2:
    '',
  distributionConvectorHeatingImageDescription: 'Konvektoren gibt es in verschiedenen Bauformen. Unterflur vor Fenstern, Wandhängend mit Ventilator oder als Raumgebläse. Alle haben eine kompakte Bauform und benötigen eine hohe Vorlauftemperatur, um die nötige Leistung bereitzustellen, da sie fast ausschließlich die Luft erwärmen und nicht über Strahlungswärme arbeiten. Sie sind nicht ideal für eine Wärmepumpe.',
  distributionConvectorHeatingImageTitle: 'Abbildung: Beispiel eines Konvektors',
  distributionRibbedRadiatorsHeating: 'Rippenheizkörper',
  distributionRibbedRadiatorsHeatingImageTitle:
    'Abbildung: Beispiel eines alten Rippenheizkörpers.',
  distributionRibbedRadiatorsHeatingImageDescription:
    'Die Kombination aus Rippenheizkörpern und Wärmepumpen ist nicht empfehlenswert. Rippenheizkörper benötigen sehr hohe Vorlauftemperaturen, um genügend Wärme an den Raum abzugeben. Es gibt vereinzelt Wärmepumpen die Vorlauftemperaturen bis 70 °C erzeugen können, was aber zu Lasten der Effizienz geht. Denken Sie bitte über eine Modernisierung Ihres gesamten Heizsystems nach! Wir unterstützen Sie beim Tausch Ihrer Heizkörper und informieren Sie über die attraktiven Fördermöglichkeiten, die Sie dafür in Anspruch nehmen können.',
  distributionPanelRadiator: 'Plattenheizkörper',
  distributedConvectorHeating: 'Konvektoren',

  distributedMixedHeating: 'Gemischt: Heizkörper und Fußboden-/Flächenheizung',

  tempPreFlowLabel: "Wie hoch ist Ihre Vorlauftemperatur",
  tempPreFlowHelperText: "Die Vorlauftemperatur ist wichtig, um zu bestimmen, ob Ihr Gebäude für eine Wärmepumpe geeignet ist. Oftmals kann man in der Betriebsanleitung der alten Heizanlage nachlesen, wie man die Vorlauftemperatur Ihres Heizsystem herausfindet. Bitte verwechseln Sie die maximal nötige Vorlauftemperatur nicht mit der Speichertemperatur Ihres Warmwassers. Wenn Sie die Antwort nicht wissen, einfach 0 eintragen.",


  /** Building Step */
  buildingStepLabel: 'Gebäude',
  buildingTypeLabel: 'Um was für eine Gebäudenutzung handelt es sich?*',
  buildingEinfamilienhaus: 'Einfamilienhaus',
  buildingMehrfamilienhaus: 'Mehrfamilienhaus',
  buildingBurogebaude: 'Bürogebäude',
  buildingSonstiges: 'Sonstiges',
  buildingSurfaceLabel: 'Bitte geben Sie die zu beheizende Nutzfläche in Quadratmetern an:*',
  buildingSurfacePlaceholder: 'z.B. 100',
  buildingSurface1: '75 - 100',
  buildingSurface2: '100 - 125',
  buildingSurface3: '125 - 150',
  buildingSurface4: '150 - 175',
  buildingSurface5: '175 - 200',
  buildingSurface6: '200 - 225',
  buildingSurface7: '225 - 275',
  buildingSurfaceGreater: 'größer',
  buildingSurfaceGreaterLabel: 'Geben Sie die beheizte Nutzfläche in Quadratmetern an:',
  buildingSurfaceGreaterHelperText: 'Bei größen über 1.500 qm sprechen Sie uns bitte direkt an.',
  buildingConstructionYearLabel: 'Baujahr des Hauses oder letzte energetische Sanierung',
  buildingConstructionYearPlaceholder: 'z.B. 1995',
  greaterSurfaceErrorText: 'Geben Sie die beheizte Wohnfläche in Quadratmetern an: muss zwischen 275 und 1.500 liegen.',
  constructionYearErrorText: 'Das Jahr sollte zwischen 1000 und dem aktuellen Jahr liegen.',

  /** Hot Water Step */
  hotWaterStepLabel: 'Warmwasser',
  heatingHotWaterLabel: 'Erwärmt Ihre Heizung auch das Warmwasser?',
  yesOption: 'Ja',
  noOption: 'Nein, wir nutzen ein Zusatzgerät (z.B. Durchlauferhitzer mit Strom)',
  householdNumberLabel: 'Wie viele Personen leben in Ihrem Haushalt?*',
  householdNumberHelperText:
    'Pro Person werden im Wohnumfeld im Schnitt 1.000 kWh Energiebedarf für Warmwasser pro Jahr angenommen.',
  preferCentralHeatingLabel: 'Möchten Sie ihr Warmwasser zukünftig zentral erwärmen?',
  yes: 'Ja',
  no: 'Nein',
  preferCentralHeatingDescription:
    'Wenn Sie bisher Ihr Brauchwarmwasser, z.B. zum Duschen, zentral erwärmen macht es Sinn dies auch in Zukunft beizubehalten. Nutzen Sie bisher Durchlauferhitzer, z.B. für das Badezimmer, könnte eine zentrale Warmwasserbereitung nicht nur Ihren Energiebedarf senken, sondern auch Ihren Komfort und den Wert Ihrer Immobilie steigern. Allerdings gilt es den Aufwand zum Legen einer zentralen Warmwasserversorgung zu berücksichtigen.',
  householdNumberErrorText: 'Der Wert sollte zwischen 1 und 25 liegen.',

  /** Second Heating Step */
  secondHeatingStepLabel: 'Zweite Heizquelle',
  hasAdditionalHeatingLabel: 'Nutzen Sie zusätzlich zu Ihrer Heizanlage eine weitere Wärmequelle (z.B. einen Kamin)?',
  currentEnergyLabel: 'Welchen Wärmeträger setzen Sie aktuell anstelle von Umweltwärme ein?',
  powerBivaSourceLabel: "Leistung der zweiten Heizquelle.",
  powerBivaSourceHelperText: "Kennen Sie zufällig die Leistung Ihrer zweiten Heizquelle in Kilowatt (kW)? Falls nicht tragen Sie hier einfach 0 ein.",
  powerBivaSourceHelperPlaceholder: 'z.B. 5',

  /** HpLocation Step */
  hpLocationStepLabel: 'Installationsort',
  hpLocationRule: 'Es gibt einen (potenziellen) Aufstellungsort, für die Außeneinheit der Wärmepumpe, auf meinem Grundstück mit 4 Metern Abstand zum Nachbarsgrundstück.',
  hpLocationLabel: "Aufstellungsort der alten Heizung",
  locationCellar: "Keller",
  locationUnknown: "Unbekannt oder noch keine Heizung vorhanden",
  locationGroundFloor: "Erdgeschoss",
  locationUpperFloorBelowRoof: "Obergeschoss unterhalb des Daches",
  locationAdjoiningBuilding: "Nebenstehenden Gebäude (z.B. Garage)",
  locationElsewhere: "Sonstiges",
  locationPhotovoltaikLabel: 'Haben Sie vielleicht schon einmal über die effiziente Kombination von Photovoltaik und Wärmepumpe nachgedacht, um z.B. Ihren eigens erzeugten Strom fürs Heizen zu verwenden?',
  locationPhotovoltaikAlreadyHave: 'Ich habe bereits Photovoltaik',
  locationPhotovoltaikInterested: 'Ich könnte mir Photovoltaik als Unterstützung vorstellen',
  locationPhotovoltaikNotAvailableNotInterested: 'Ich habe keinen Platz für Photovoltaik oder es interessiert mich nicht.',
  bivaColdDayBoosterLabel: "Möchten Sie Ihr zentrales Heizungssystem auch künftig, z.B. durch einen Kamin, unterstützen, um Aufwände bei der Modernisierung einzusparen?",
  /** Wishes Step */
  wishesStepLabel: 'Wünsche',
  wishesLabel: 'Worauf legen Sie bei Ihrem neuen Heizsystem besonderen Wert?',
  wishesHighQualityProduct: 'Hohe Qualität',
  wishesCoolingPossible: 'Klimatisieren des Gebäudes über die Wärmepumpe soll möglich sein',
  wishesPremiumOptic: 'Ein angenehmes Aussehen',
  wishesHighEfficiency: 'Hohe Effizienz',
  wishesLowTotalPrice: 'Einen niedrigen Anschaffungspreis',
  wishesActionDateLabel: 'Bis wann soll der Heizungswechsel erfolgen?*',
  wishesActionDateImmediately: 'So schnell wie möglich.',
  wishesActionDateNextMonth: "In den nächsten Monaten",
  wishesActionNextYear: "Nächstes Jahr",
  wishesActionNotNow: "Ich weiß es nicht. Ich wollte mich z.B. nur einmal informieren.",

  /** Finish Step */
  finishStepLabel: 'Finish',
  finishStepText:
    'Fast geschafft! Akzeptieren Sie noch unsere Datenschutzerklärung und Nutzungsbedingungen, damit wir mit der Analyse Ihres Objekts fortfahren können. Wir würden Sie einmal gerne anrufen um Ihre Daten zu validieren und Sie zu unserem Service befragen. Wir sind als kleines Startup hier sehr auf Ihr Feedback angewiesen. Bitte hinterlassen Sie hierzu Ihren Namen und Ihre Telefonnummer, unter der wir Sie erreichen können.', //Bitte geben Sie uns für etwaige Rückfragen und zur Validierung Ihrer Angaben noch einige Kontaktinformationen, damit wir Ihre Beratung telefonisch ergänzen und Feedback einholen können.',
  termsAndConditionsLabel: 'Zustimmung zu unseren Nutzungsbedingungen',
  termsAndConditionsDescription: 'Hiermit erkläre ich mich mit den Nutzungsbedingungen als einverstanden',
  termsAndConditionsLink: 'Nutzungsbedingungen',
  dataProtectionLabel: 'Zustimmung zu unserer Datenschutzerklärung',
  dataProtectionDescription: 'Hiermit erkläre ich mich mit der Datenschutzerklärung einverstanden',
  dataProtectionLink: 'Datenschutzerklärung',
  isOwnerLabel: 'Ja, ich bin der Eigentümer des Gebäudes von dem die ausgefüllten Daten stammen, oder kann über eine neue Heizung entscheiden (nicht Mieter ).',
  isOwnerDescription: 'Ich bin Eigentümer',

  submitButtonLabel: 'Ergebnis anschauen',
  formSubmittingErrorDescription:
    'Bei der Berechnung Ihrer Ergebnisse ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!',

  /** Form Results */
  formResultsLoadingErrorDescription:
    'Beim Laden Ihrer Ergebnisse ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!',
  loadingFormResultsDescription: 'Wir bereiten Ihre Anfrage vor. Bitte warten...',
  thankYouLabel: 'Vielen Dank!',
  offerTitle: 'Anmeldung zum kostenlosen Beratungsgespräch.',
  offerSubtitle:
    'Werden Sie Teil der Wärmewende und sparen Sie Geld und klimaschädliches CO2 mit einer modernen Wärmepumpe. Fordern Sie jetzt ein unverbindliches Angebote von Fachpartnern oder Energieberatern in Ihrer Region an. Wir werden Sie telefonisch kontaktieren um alles weitere mit Ihnen zu besprechen.',
  offerImageUrl: 'https://www.sutertech-shop.ch/wp-content/uploads/Artikelbilder/PUHZ-SHW80VHA_0-600x600.jpg',
  offerButtonLabel: "Los geht's",
  userContactDataButtonLabel: 'Unverbindlich Anfragen',

  /** User Contact Data Modal */
  contactDataModalTitle: 'Anmeldung zum kostenlosen Beratungsgespräch.',
  firstName: 'Vorname',
  lastName: 'Nachname',
  street: 'Straße',
  houseNumber: 'Hausnummer',
  zipCode: 'Postleitzahl',
  city: 'Stadt',
  email: 'Email',
  telephoneNumber: 'Telefon',
  termsAndConditions: 'Ich bin mit den Nutzungsbedingungen einverstanden',
  govermentFunding: 'Bitte senden Sie mir Infos zur staatlichen Förderung',
  submitButton: 'Unverbindlich anfordern',
  closeButton: 'Schließen',
  savingUserContactErrorDescription:
    'Beim Speichern Ihrer Daten ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut!',
};
