import React from 'react';
import { Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import { StepContainer, CustomizedButton, ButtonContainer, Image, useFormStyles } from '../shared';
import { getContent } from '../utils';
import { trackStepChange, trackInputFieldDataChange } from '../tracker';
import buildingImage from '../assets/building-image.png';

export const BuildingStep = ({ formData, setFormData, setCurrentStep }) => {
  const formStyles = useFormStyles();

  const hasSurfaceError =
    !formData.buildingUsefulArea ||
    formData.buildingUsefulArea > 1500

  const hasBuildingYearError =
    !!formData.buildingConstructionYear &&
    (formData.buildingConstructionYear > new Date().getFullYear() || formData.buildingConstructionYear < 1000);

  const disabledNextStep = !formData.buildingType || hasBuildingYearError || (hasSurfaceError);

  return (
    <StepContainer>
      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('buildingTypeLabel')}</Typography>

        {/*    <InputLabel id="select-label" shrink={true}>
          {getContent('buildingTypeLabel')}
        </InputLabel> */}
        <Select
          autoFocus
          labelId="select-label"
          value={formData.buildingType}
          onChange={(event) => {
            trackInputFieldDataChange('buildingType', {
              previousValue: formData.buildingType,
              newValue: event.target.value,
            });
            setFormData({ ...formData, buildingType: event.target.value });
          }}>
          <MenuItem value="singleFamilyHouse">{getContent('buildingEinfamilienhaus')}</MenuItem>
          <MenuItem value="apartmentHouse">{getContent('buildingMehrfamilienhaus')}</MenuItem>
          <MenuItem value="office">{getContent('buildingBurogebaude')}</MenuItem>
          <MenuItem value="other">{getContent('buildingSonstiges')}</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('buildingSurfaceLabel')}</Typography>

        <TextField
          variant="outlined"
          value={formData.buildingUsefulArea ? formData.buildingUsefulArea : ''}
          onChange={(event) => {
            trackInputFieldDataChange('buildingUsefulArea', {
              previousValue: formData.buildingUsefulArea,
              newValue: parseInt(event.target.value) || 0,
            });
            setFormData({ ...formData, buildingUsefulArea: parseInt(event.target.value) || 0 });
          }}
          // label={getContent('buildingSurfaceLabel')}
          placeholder={getContent('buildingSurfacePlaceholder')}
        //      placeholder={getContent('energyConsumptionVolumeHelperPlaceholder')}
        //      helperText={getEnergyConsumptionVolumeHelperText(formData.energySource)}
        />
      </FormControl>
      {/*       {showGreaterSurfaceTextfield && (
        <FormControl className={formStyles.formControl}>
          <TextField
            variant="outlined"
            label={getContent('buildingSurfaceGreaterLabel')}
            value={formData.buildingSpaceSquareMetersGreater || ''}
            required
            error={!!formData.buildingSpaceSquareMetersGreater && hasGreaterSurfaceError}
            helperText={
              !!formData.buildingSpaceSquareMetersGreater && hasGreaterSurfaceError
                ? getContent('greaterSurfaceErrorText')
                : getContent('buildingSurfaceGreaterHelperText')
            }
            onChange={(event) => {
              trackInputFieldDataChange('buildingSpaceSquareMetersGreater', {
                previousValue: formData.buildingSpaceSquareMetersGreater,
                newValue: parseInt(event.target.value),
              });
              setFormData({ ...formData, buildingSpaceSquareMetersGreater: parseInt(event.target.value) });
            }}
          />
        </FormControl>
      )} */}
      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('buildingConstructionYearLabel')}</Typography>

        <TextField
          variant="outlined"
          //   label={getContent('buildingConstructionYearLabel')}
          value={formData.buildingConstructionYear ? formData.buildingConstructionYear : ''}
          error={!!formData.buildingConstructionYear && hasBuildingYearError}
          helperText={
            !!formData.buildingConstructionYear && hasBuildingYearError && getContent('constructionYearErrorText')
          }
          placeholder={getContent('buildingConstructionYearPlaceholder')}
          onChange={(event) => {
            trackInputFieldDataChange('buildingConstructionYear', {
              previousValue: formData.buildingConstructionYear,
              newValue: parseInt(event.target.value) || 0,
            });
            setFormData({ ...formData, buildingConstructionYear: parseInt(event.target.value) || 0 });
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <Image src={buildingImage} alt="Building Image" width={'80%'} />
      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(5, 4, formData);
            setCurrentStep(4);
          }}>
          {getContent('backButtonLabel')}
        </CustomizedButton>
        <CustomizedButton
          onClick={() => {
            trackStepChange(5, 6, formData);
            setCurrentStep(6);
          }}
          disabled={disabledNextStep}>
          {getContent('nextButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
