import React from "react";
import { AmplifyConfirmSignIn, AmplifyAuthContainer, AmplifySignUp, AmplifySignIn, AmplifyAuthenticator, AmplifyConfirmSignUp, AmplifyForgotPassword } from "@aws-amplify/ui-react";
import { useAuth } from "../util/auth"

function AmplifyAuthComponent(props) {

    return (
        <AmplifyAuthContainer>
            <AmplifyAuthenticator background-color="#123456">

                {props.children}


                <AmplifySignUp
                    slot="sign-up"
                    usernameAlias="email"
                    formFields={[
                        {
                            type: "email",
                            label: "E-Mail Adresse",
                            placeholder: "name@company.de",
                            inputProps: { required: true, autocomplete: "username" },
                        },
                        {
                            type: "password",
                            label: "Passwort",
                            placeholder: "Passwort",
                            inputProps: { required: true, autocomplete: "new-password" },
                        },
                        {
                            type: "family_name",
                            label: "Nachname",
                            placeholder: "Custom FN placeholder",
                        },
                        {
                            type: "given_name",
                            label: "Vorname",
                            placeholder: "Custom GN placeholder",
                        },
                        {
                            type: "website",
                            label: "Webseite",
                            placeholder: "Custom WEbsite placeholder",
                        },
                        {
                            type: "custom:company",
                            label: "Company",
                            placeholder: "Custom Company placeholder",
                        },
                    ]}
                />
                <AmplifySignIn slot="sign-in" usernameAlias="email" headerText="Hier gehts zum Login" submitButtonText="Login" />


                <AmplifyConfirmSignIn
                    headerText="Anmeldung bestätigen."
                    slot="confirm-sign-in"
                ></AmplifyConfirmSignIn>
                <AmplifyConfirmSignUp
                    headerText="My Custom Confirm Sign Up Text"
                    slot="confirm-sign-up"
                ></AmplifyConfirmSignUp>
                <AmplifyForgotPassword
                    headerText="Passwort vergessen? Bitte Code aus E-Mail eintippen."
                    slot="forgot-password"
                    user={useAuth()}
                ></AmplifyForgotPassword>

                {/*        <AmplifyRequireNewPassword
                headerText="My Custom Require New Password Text"
                slot="require-new-password"
                user={useAuth()}
            ></AmplifyRequireNewPassword> */}

            </AmplifyAuthenticator>
        </AmplifyAuthContainer>
    );
}

export default AmplifyAuthComponent;
