import React, { Fragment, useEffect, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import PiggyBank from "../../media/costCalculator/piggybank.svg";
import PlanetEarth from "../../media/costCalculator/planetearth.svg";
import {
  useCostCalculator,
  numberWithCommas,
  objectKeySearchtranslationsHeaterDE,
} from "../../util/costCalculator";
import { costCalculatorBaseSettings } from "./content";

const CostCalculatorResult = () => {
  const { costCalculatorResultState, loadedState, consideredPeriodState } =
    useCostCalculator();
  const { costCalculatorResult } = costCalculatorResultState;
  const { consideredPeriod } = consideredPeriodState;
  const { loaded } = loadedState;

  const [savedMoney, setSavedMoney] = useState(null);
  const [savedCO2, setSavedCO2] = useState(null);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    if (
      !costCalculatorResult._3thCol.energySource ||
      !costCalculatorResult._2thCol.energySource ||
      !costCalculatorResult._1thCol.energySource
    )
      return;
    setLoading(true);
    setSavedMoney(
      numberWithCommas(
        costCalculatorResult._3thCol.totalCostsMainEnergySourceArr[
          consideredPeriod - 1
        ] -
          costCalculatorResult._1thCol.totalCostsMainEnergySourceArr[
            consideredPeriod - 1
          ]
      )
    );
    setSavedCO2(
      numberWithCommas(
        costCalculatorBaseSettings.CO2factor[
          objectKeySearchtranslationsHeaterDE(
            costCalculatorResult._3thCol.energySource
          )
        ] *
          costCalculatorResult._3thCol.energyDemandMainEnergySource *
          consideredPeriod
      )
    );

    setLoading(false);
  }, [consideredPeriod, costCalculatorResult, loaded]);

  return loading ? (
    <div>
      <h1>Loading</h1>
    </div>
  ) : (
    <Fragment>
      <Box className={classes.conatiner}>
        <Typography align="center" className={classes.headerText}>
          Eine Luft-Wasser-Wärmepumpe spart Ihnen:
        </Typography>
        <Box className={classes.calculateSavingOverYearsBox}>
          <Box className={classes.boxRow}>
            <img src={PiggyBank} alt="Logo" style={{ width: 40 }} />
            <Typography
              align="center"
              className={[classes.calculateSavingOverYearsTypography]}
            >
              {savedMoney} € über {consideredPeriod} Jahre
            </Typography>
          </Box>
          <Box width={40} />
          <Box className={classes.boxRow}>
            <img src={PlanetEarth} alt="Logo" style={{ width: 40 }} />
            <Typography
              align="center"
              className={classes.calculateSavingOverYearsTypography}
            >
              {savedCO2} Kg CO<sub>2</sub> über {consideredPeriod} Jahre
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.savingBox}>
        <Typography align="center" className={classes.savingBoxTypography}>
          Einsparungen für Ihr Gebäude: Sehr hoch
        </Typography>
      </Box>
    </Fragment>
  );
};

export default CostCalculatorResult;

const useStyles = makeStyles((theme) => ({
  conatiner: {
    backgroundColor: "#01B13B",
    width: "100%",
    minHeight: 100,
    height: "max-content",
    display: "flex",
    flexDirection: "column",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  calculateSavingOverYearsBox: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  calculateSavingOverYearsTypography: {
    color: "white",
    marginLeft: 10,
  },
  savingBox: {
    backgroundColor: "#00D200",
    width: "100%",
    minHeight: 30,
    height: "max-content",
  },
  savingBoxTypography: {
    color: "white",
    fontSize: 19,
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  boxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "10px",
    },
  },
}));
