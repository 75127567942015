//import './App.css';

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Forms from "./pages/forms";
import Results from "./pages/result";
import Dashboard from "./pages/partner/dashboard";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import NotFoundPage from "./pages/404";
import CookieBot from "react-cookiebot";
import { AuthProvider } from "./util/auth";

import { ThemeProvider } from "./util/theme.js";

import { Switch, Route, Router } from "./util/router";
import Details from "./pages/details";

import Amplify from "aws-amplify";
import config from "./aws-exports";
import Explore from "./pages/partner/explore";

import logo from "./media/logo/logo.png";
import logo_inverted from "./media/logo/logo-inverted.png";
//import "./components/Analytics";

import CostCalculatorPage from "./pages/costcalculator";
import { CostCalculatorProvider } from "./util/costCalculator";

Amplify.configure(config);

export default function App() {


  // const domainGroupId = '39763adf-acf4-4c48-953f-28481052fe49';
  /* const initGA = () => {


  }

  const handleAcceptCookie = () => {
    console.log("Analytics ID " + process.env.REACT_APP_DEV_MODE)
    if (true) {
      ReactGA.initialize("G-RZWTDHFXMZ");
      ReactGA.send({ hitType: "pageview", page: "/my-path" });
      console.log("GA Initialized")
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);
 */

  return (
    <>
      {/*   <CookieBot domainGroupId={domainGroupId} /> */}

      <ThemeProvider>
        <AuthProvider>
          <CostCalculatorProvider>
            <Router>
              <Navbar color="header" logo={logo} logoInverted={logo_inverted} />
              <Switch>
                <Route path="/details/:id" component={Details} />
                <Route path="/results/:id" component={Results} />
                <Route
                  path="/costcalculator/:id"
                  component={CostCalculatorPage}
                />
                <Route path="/dashboard" component={Dashboard} />

                <Route exact path="/partner/dashboard" component={Dashboard} />
                <Route exact path="/partner" component={Dashboard} />
                <Route exact path="/partner/explore" component={Explore} />
                <Route path="/" component={Forms} />
                <Route component={NotFoundPage} />
              </Switch>
              {process.env.NODE_ENV == "development"
                ? "Mode " + process.env.NODE_ENV
                : ""}
              {/*    <CookieConsent
            enableDeclineButton
            onAccept={handleAcceptCookie}
            onDecline={handleDeclineCookie}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent> */}
              <Footer
                bgColor="header"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="A short description of what you do here"
                copyright={`© ${new Date().getFullYear()} Heatly.de`}
                logo={logo}
                logoInverted={logo_inverted}
                sticky={true}
              />
            </Router>
          </CostCalculatorProvider>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
}
