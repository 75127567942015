import React, { useState, useEffect, Fragment } from "react";
import { Box, Button, ButtonGroup, Grid, makeStyles } from "@material-ui/core";

import Chart from "react-google-charts";
import { useCostCalculator, numberWithCommas } from "../../util/costCalculator";
import ClickGesture from "../../media/costCalculator/click.svg";

const CostCalculatorGraph = () => {
  const classes = useStyles();

  const { costCalculatorResultState, loadedState } = useCostCalculator();
  const { costCalculatorResult } = costCalculatorResultState;
  const { loaded } = loadedState;
  const [data, setData] = useState(null);
  const [ticks, setTicks] = useState([]);
  const [isDefaultGraph, setIsDefaultGraph] = useState(false);

  useEffect(() => {
    if (
      !costCalculatorResult._3thCol.energySource ||
      !costCalculatorResult._2thCol.energySource ||
      !costCalculatorResult._1thCol.energySource
    )
      return;
    graphLineDrawerData();
  }, [loaded, isDefaultGraph]);

  const graphLineDrawerData = () => {
    setTicks([])
    const newData = [
      [
        "x",
        costCalculatorResult._1thCol.energySource,
        { role: "tooltip", type: "string", p: { html: true } },
        costCalculatorResult._2thCol.energySource,
        { role: "tooltip", type: "string", p: { html: true } },
        costCalculatorResult._3thCol.energySource,
        { role: "tooltip", type: "string", p: { html: true } },
      ],
    ];
    if (isDefaultGraph) {
      for (
        let i = 1;
        i <= costCalculatorResult._1thCol.totalOperatingCostsYearArr.length + 2;
        i++
      ) {
        setTicks((arr) => [...arr, i - 1]);
        newData.push([
          i,
          costCalculatorResult._1thCol.totalOperatingCostsYearArr[i - 1],
          `${i}. Jahr, ${
            costCalculatorResult._1thCol.energySource
          }: ${numberWithCommas(
            costCalculatorResult._1thCol.totalOperatingCostsYearArr[i - 1]
          )} €`,
          costCalculatorResult._2thCol.totalOperatingCostsYearArr[i - 1],
          `${i}. Jahr, ${
            costCalculatorResult._2thCol.energySource
          }: ${numberWithCommas(
            costCalculatorResult._2thCol.totalOperatingCostsYearArr[i - 1]
          )} €`,
          costCalculatorResult._3thCol.totalOperatingCostsYearArr[i - 1],
          `${i}. Jahr, ${
            costCalculatorResult._3thCol.energySource
          }: ${numberWithCommas(
            costCalculatorResult._3thCol.totalOperatingCostsYearArr[i - 1]
          )} €`,
        ]);
      }
    } else {
      for (
        let i = 1;
        i <=
        costCalculatorResult._1thCol.totalCostsMainEnergySourceArr.length + 2;
        i++
      ) {
        setTicks((arr) => [...arr, i - 1]);
        newData.push([
          i,
          costCalculatorResult._1thCol.totalCostsMainEnergySourceArr[i - 1],
          `${i}. Jahr, ${
            costCalculatorResult._1thCol.energySource
          }: ${numberWithCommas(
            costCalculatorResult._1thCol.totalCostsMainEnergySourceArr[i - 1]
          )} €`,
          costCalculatorResult._2thCol.totalCostsMainEnergySourceArr[i - 1],
          `${i}. Jahr, ${
            costCalculatorResult._2thCol.energySource
          }: ${numberWithCommas(
            costCalculatorResult._2thCol.totalCostsMainEnergySourceArr[i - 1]
          )} €`,
          costCalculatorResult._3thCol.totalCostsMainEnergySourceArr[i - 1],
          `${i}. Jahr, ${
            costCalculatorResult._3thCol.energySource
          }: ${numberWithCommas(
            costCalculatorResult._3thCol.totalCostsMainEnergySourceArr[i - 1]
          )} €`,
        ]);
      }
    }

    setData(newData);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      {data && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => setIsDefaultGraph(!isDefaultGraph)}
            className={classes.button}
          >
            {isDefaultGraph ? "Energiekostenentwicklung" : "Gesamtkosten"}
            <img
              src={ClickGesture}
              alt="Logo"
              style={{
                width: 30,
              }}
            />
          </Button>

          <Chart
            key={"LineChart"}
            style={{
              maxWidth: "1100px",
              width: "100%",
              height: "500px",
            }}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
              hAxis: {
                title: "Jahre",
                showTextEvery: 1,
                ticks: ticks,
                baselineColor: "#fff",
                gridlineColor: "#fff",
              },
              vAxis: {
                title: "Kosten in €",
                format: "#,### €",
              },
              series: {
                0: { curveType: "function", color: "#01B13B", axis: "Temps" },
                1: { curveType: "function", color: "#FFC000", axis: "Temps" },
                2: { curveType: "function", color: "#FF4D00", axis: "Temps" },
              },
              axes: {
                x: {
                  format: "percent",
                },
                y: {
                  format: "percent",
                },
              },
              legend: "bottom",
              is3D: true,
            }}
            rootProps={{ "data-testid": "3" }}
            chartLanguage="de"
          />
        </div>
      )}
    </Grid>
  );
};

export default CostCalculatorGraph;

const useStyles = makeStyles({
  container: {
    marginTop: 40,
  },
  button: {
    backgroundColor: "#FFC000",
    color: "black",
    "&:hover": {
      backgroundColor: "#FFC000",
      color: "black",
    },
    position: "absolute",
    zIndex: 1,
    top: "20px",
  },
});
