import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { trackEvent } from './ConfiguratorForm/tracker';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CloseWarning(props) {
    const [open, setOpen] = React.useState(0);

    const handleClickOpen = () => {
        if (props.disabled != true) {
            setOpen(open + 1);
        }

    };
    const handleClose = () => {

        setOpen(open + 1);
    };

    const handleContact = () => {
        var element = document.getElementById("contact");
        if (element)
            element.scrollIntoView();
        trackEvent("closeWarningJumpTo", { area: "wpguru", jumpTo: "contact" });

        handleClose()
    }

    const handleCost = () => {
        var element = document.getElementById("costcalculator");
        if (element)
            element.scrollIntoView();
        trackEvent("closeWarningJumpTo", { area: "wpguru", jumpTo: "costcalculator" });

        handleClose()

    }

    const wrapper = {
        width: "100%",
        height: "10px",
        backgroundColor: "rgb(0 0 0 / 4%)",
        top: "0px",

        position: "fixed",
        zIndex: 999
    };

    return (
        <div>
            <div onMouseEnter={handleClickOpen} style={wrapper}>

            </div>
            <BootstrapDialog
                onClose={() => { console.log("close"); trackEvent("closeWarningClose", { area: "wpguru" }); handleClose(); }}
                aria-labelledby="customized-dialog-title"
                open={open === 1 ? true : false}
                PaperProps={{ sx: { maxWidth: "650px" } }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { console.log("close"); trackEvent("closeWarningClose", { area: "wpguru" }); handleClose(); }}>


                    <Typography variant="h4" component="div" gutterBottom>
                        Stopp! Wir haben noch mehr...
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography variant="body1" gutterBottom>
                        Finden Sie jetzt Ihren regionalen Fachpartner, Energieberater oder lassen Sie sich von uns beraten. Füllen Sie einfach unser Formular aus. Das ist 100% kostenlos für Sie und wir geben keine Daten weiter ohne Ihr Einverständnis.</Typography>
                    <Typography variant="body1" gutterBottom>
                        Ihre generierte Anfrage können Sie auch jederzeit erreichen unter der Adresse: </Typography>
                    <Typography variant="body1" gutterBottom>{window.location.href}<Button onClick={() => { navigator.clipboard.writeText(window.location.href) }}>Copy</Button></Typography>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCost}>Kostenvergleich anschauen.
                    </Button>
                    <Button
                        onClick={handleContact}>Formular jetzt ausfüllen.
                    </Button>
                    <Button autoFocus onClick={() => { console.log("close"); trackEvent("closeWarningClose", { area: "wpguru" }); handleClose(); }}>
                        Zurück
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div >
    );
}
