import React from 'react';
import { Typography, FormControl, TextField, Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { StepContainer, Text, Image, CustomizedButton, ButtonContainer, useFormStyles } from '../shared';
import { getContent } from '../utils';
import { trackStepChange, trackInputFieldDataChange } from '../tracker';
import electricityEnergyImage from '../assets/electricity-energy-image.png';
import districtEnergyImage from '../assets/district-heating-energy-image.png';
import oilEnergyImage from '../assets/energy-oil-image.png';
import gasEnergyImage from '../assets/gas-energy-image.png';
import pelletsEnergyImage from '../assets/pellets-energy-image.png';
import woodEnergyImage from '../assets/wood-energy-image.png';
import Helper from '../../Helper';

const getEnergyConsumptionImage = (energySource) => {
  switch (energySource) {
    case "oil":
      return <Image src={oilEnergyImage} alt="Energy Consumption Image" width={'40%'} />;
    case "naturalGas":
      return <Image src={gasEnergyImage} alt="Energy Consumption Image" width={'15%'} />;
    case "nightPower":
      return <Image src={electricityEnergyImage} alt="Energy Consumption Image" width={'20%'} />;
    case "districtHeat":
      return <Image src={districtEnergyImage} alt="Energy Consumption Image" width={'30%'} />;
    case "pellets":
      return <Image src={pelletsEnergyImage} alt="Energy Consumption Image" width={'30%'} />;
    case "wood":
      return <Image src={woodEnergyImage} alt="Energy Consumption Image" width={'30%'} />;
    default:
      return <></>;
  }
};

const getEnergyConsumptionVolumeHelperText = (energySource) => {
  switch (energySource) {
    case "oil":
      return getContent('energyConsumptionVolumeOilHelperText');
    case "wood":
      return getContent('energyConsumptionVolumeHolzHelperText');
    case "coal":
      return getContent('energyConsumptionVolumeKohleHelperText');
    case "pellets":
      return getContent('energyConsumptionVolumePelletsHelperText');
    default:
      return '';
  }
};

export const ConsumptionStep = ({ formData, setFormData, setCurrentStep }) => {
  const formStyles = useFormStyles();

  const showEnergyVolume =
    formData.energySource === 'pellets' || formData.energySource === 'oil' || formData.energySource === 'wood' || formData.energySource === 'coal';

  const showEnergyConsumption =
    formData.energySource === 'naturalGas' ||
    formData.energySource === 'liquidGas' ||
    formData.energySource === 'districtHeat' ||
    formData.energySource === 'nightPower';

  const hasConsumptionAccountingPeriodError = showEnergyConsumption && formData.consumptionAccountingPeriod < 500;
  const hasVolumeConsumptionAccountingPeriodError = showEnergyVolume && formData.volumeConsumptionAccountingPeriod < 1;

  const hasDatesError = formData.accountingPeriodStartDate.localeCompare(formData.accountingPeriodEndDate) >= 0;

  const disabledNextStep =
    hasDatesError || hasConsumptionAccountingPeriodError || hasVolumeConsumptionAccountingPeriodError || !formData.accountingPeriodStartDate || !formData.accountingPeriodEndDate;

  return (
    <StepContainer>
      {showEnergyVolume && (
        <FormControl className={formStyles.formControl}>
          <Typography>{getContent('energyConsumptionVolumeLabel')}<Helper header="Energieverbrauch" text="Ihr Energieverbrauch ist Essenziel um die tatsächliche Leistung abzuschätzen, die Sie künftig benötigen. Bitte sein Sie möglichst genau bei der Angabe des Verbrauchs. Jetzt Lohnt es sich nach einer alten Rechnung zu kramen. Achtung! Wenn Sie innerhalb der letzten Heizperiode energetische Sanierungen vorgenommen haben, ist der Abrechnungswert nur vermindert aussage Kräftig. Auch ein Normierter auf den Standort Potsdam bezogen Energieausweis ist an dieser Stelle nicht hilfreich. Für aussagekräftige Werte sprechen Sie mit Ihrem Energieberater oder bestellen Sie einen über uns (fahren Sie hierfür einfach mit einem überhöhten Wert fort." /></Typography>

          <TextField
            autoFocus
            variant="outlined"
            value={formData.volumeConsumptionAccountingPeriod ? formData.volumeConsumptionAccountingPeriod : ''}
            onChange={(event) => {
              trackInputFieldDataChange('volumeConsumptionAccountingPeriod', {
                previousValue: formData.volumeConsumptionAccountingPeriod,
                newValue: parseInt(event.target.value) || 0,
              });
              setFormData({ ...formData, volumeConsumptionAccountingPeriod: parseInt(event.target.value) || 0 });
            }}
            //label={getContent('energyConsumptionVolumeLabel')}
            placeholder={getContent('energyConsumptionVolumeHelperPlaceholder')}
            helperText={getEnergyConsumptionVolumeHelperText(formData.energySource)}
          />
        </FormControl>
      )}

      {showEnergyConsumption && (
        <FormControl className={formStyles.formControl}>
          <Typography>{getContent('consumptionAccountingPeriodLabel')}<Helper header="Energieverbrauch" text="Ihr Energieverbrauch ist Essenziel um die tatsächliche Leistung abzuschätzen, die Sie künftig benötigen. Bitte sein Sie möglichst genau bei der Angabe des Verbrauchs. Jetzt Lohnt es sich nach einer alten Rechnung zu kramen. Achtung! Wenn Sie innerhalb der letzten Heizperiode energetische Sanierungen vorgenommen haben, ist der Abrechnungswert nur vermindert aussage Kräftig. Auch ein Normierter auf den Standort Potsdam bezogen Energieausweis ist an dieser Stelle nicht hilfreich. Für aussagekräftige Werte sprechen Sie mit Ihrem Energieberater oder bestellen Sie einen über uns (fahren Sie hierfür einfach mit einem überhöhten Wert fort." /></Typography>

          <TextField
            autoFocus
            variant="outlined"
            value={formData.consumptionAccountingPeriod ? formData.consumptionAccountingPeriod : ''}
            onChange={(event) => {
              trackInputFieldDataChange('consumptionAccountingPeriod', {
                previousValue: formData.consumptionAccountingPeriod,
                newValue: parseInt(event.target.value) || 0,
              });
              setFormData({ ...formData, consumptionAccountingPeriod: parseInt(event.target.value) || 0 });
            }}
            //    label={getContent('consumptionAccountingPeriodLabel')}
            placeholder={getContent('consumptionAccountingPeriodHelperPlaceholder')}
            helperText={
              !!formData.consumptionAccountingPeriod && hasConsumptionAccountingPeriodError
                ? getContent('consumptionAccountingPeriodErrorText')
                : getContent('consumptionAccountingPeriodHelperText')
            }
            required
            error={!!formData.consumptionAccountingPeriod && hasConsumptionAccountingPeriodError}
          />
        </FormControl>
      )}
      <FormControl className={formStyles.formControl}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Typography>{getContent('startDateLabel')}</Typography>

            <TextField
              // label={getContent('startDateLabel')}
              type="date"
              required
              value={formData.accountingPeriodStartDate}
              onChange={(event) => {
                trackInputFieldDataChange('accountingPeriodStartDate', {
                  previousValue: formData.accountingPeriodStartDate,
                  newValue: event.target.value,
                });
                setFormData({ ...formData, accountingPeriodStartDate: event.target.value });
              }}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{getContent('endDateLabel')} <Helper header="Heizperiode" text="Entnahmen Sie den Abrechnungszeitraum einfach Ihrer letzten Abrechnung. Idealerweise umfasst dieser mindestens 12 Monate, desto länger desto genauer. Achtung! Aufgrund von Wetterbereinigungen ist es nötig den korrekten Zeitraum und nicht nur irgend eine Zeitspanne anzugeben."></Helper></Typography>

            <TextField
              //  label={getContent('endDateLabel')}
              type="date"
              required
              error={hasDatesError}
              helperText={hasDatesError && getContent('datesErrorText')}
              value={formData.accountingPeriodEndDate}
              onChange={(event) => {
                trackInputFieldDataChange('accountingPeriodEndDate', {
                  previousValue: formData.accountingPeriodEndDate,
                  newValue: event.target.value,
                });
                setFormData({ ...formData, accountingPeriodEndDate: event.target.value });
              }}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
      <Text>{getContent('consumptionStepText1')}</Text>
      <Text>{getContent('consumptionStepText2')}</Text>
      {getEnergyConsumptionImage(formData.energySource)}
      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(3, 2, formData);
            setCurrentStep(2);
          }}>
          {getContent('backButtonLabel')}
        </CustomizedButton>
        <CustomizedButton
          onClick={() => {
            trackStepChange(3, 4, formData);
            setCurrentStep(4);
          }}
          disabled={disabledNextStep}>
          {getContent('nextButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
