import React from 'react';
import styled from 'styled-components';
import { Checkbox, FormControlLabel, InputLabel, Link, Typography, TextField, FormControl } from '@material-ui/core';
import { StepContainer, Text, CustomizedButton, ButtonContainer, useFormStyles } from '../shared';
import { getContent } from '../utils';
import { trackEvent, trackStepChange, trackInputFieldDataChange } from '../tracker';

const privacyNoticeUrl = 'https://www.heatly.de/privacy';
const termsAndConditionsUrl = 'https://www.heatly.de/nutzungsbedingungen'

const TermsContainer = styled.div`
  padding-top: 24px;
  padding-left: 16px;
  padding-bottom: 32px;
`;

export const FinishStep = ({ formData, setFormData, setCurrentStep, onSubmit }) => {
  const formStyles = useFormStyles();


  const disabledSubmit = !formData.acceptedTerms || !formData.acceptedDataProtection || !formData.isOwner || !formData.contactPerson.name || !formData.contactPerson.phone;

  return (
    <StepContainer>
      <Text>{getContent('finishStepText')}</Text>

      {/* Workaround Start */}


      <FormControl className={formStyles.formControl}>
        <Typography>Vorname und Nachname*</Typography>

        <TextField
          variant="outlined"
          value={formData.contactPerson.name}
          onChange={(event) => {
            trackInputFieldDataChange('name', {
              previousValue: formData.contactPerson.name,
              newValue: event.target.value
            });
            formData.contactPerson.name = event.target.value
            setFormData({ ...formData });
          }}
          //    label={getContent('consumptionAccountingPeriodLabel')}
          placeholder="Vorname Nachname"


        />
      </FormControl>
      <FormControl className={formStyles.formControl}>
        <Typography>E-Mail</Typography>

        <TextField
          variant="outlined"
          value={formData.contactPerson.email}
          onChange={(event) => {
            trackInputFieldDataChange('email', {
              previousValue: formData.contactPerson.email,
              newValue: event.target.value
            });
            formData.contactPerson.email = event.target.value
            setFormData({ ...formData });
          }}
          //    label={getContent('consumptionAccountingPeriodLabel')}
          placeholder="test@wärmepumpenfan.de"
          helperText="Ihre E-Mail Adresse"


        />
      </FormControl>
      <FormControl className={formStyles.formControl}>
        <Typography>Telefon*</Typography>

        <TextField
          variant="outlined"
          value={formData.contactPerson.phone}
          onChange={(event) => {
            trackInputFieldDataChange('phone', {
              previousValue: formData.contactPerson.phone,
              newValue: event.target.value,
            });
            formData.contactPerson.phone = event.target.value
            setFormData({ ...formData });
          }}
          //    label={getContent('consumptionAccountingPeriodLabel')}
          placeholder="+49 12345 67890234"
          helperText="Ihre Telefonnummer"


        />
      </FormControl>





      <TermsContainer>
        <InputLabel>{getContent('isOwnerLabel')}</InputLabel>
        <FormControlLabel
          control={
            <Checkbox
              autoFocus
              checked={formData.isOwner}
              onChange={(event) => {
                trackInputFieldDataChange('isOwner', {
                  previousValue: formData.isOwner,
                  newValue: event.target.checked,
                });
                setFormData({ ...formData, isOwner: event.target.checked });
              }}
              color="primary"
            />
          }
          label={getContent('isOwnerDescription')}
        />
      </TermsContainer>
      <TermsContainer>
        <InputLabel>{getContent('termsAndConditionsLabel')}</InputLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.acceptedTerms}
              onChange={(event) => {
                trackInputFieldDataChange('acceptedTerms', {
                  previousValue: formData.acceptedTerms,
                  newValue: event.target.checked,
                });
                setFormData({ ...formData, acceptedTerms: event.target.checked });
              }}
              color="primary"
            />
          }
          label={getContent('termsAndConditionsDescription')}
        />
        <Link href={termsAndConditionsUrl} target="_blank" rel="noreferrer">
          {getContent('termsAndConditionsLink')}
        </Link>
      </TermsContainer>
      <TermsContainer>
        <InputLabel>{getContent('dataProtectionLabel')}</InputLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.acceptedDataProtection}
              onChange={(event) => {
                trackInputFieldDataChange('acceptedDataProtection', {
                  previousValue: formData.acceptedDataProtection,
                  newValue: event.target.checked,
                });
                setFormData({ ...formData, acceptedDataProtection: event.target.checked });
              }}
              color="primary"
            />
          }
          label={getContent('dataProtectionDescription')}
        />
        <Link href={privacyNoticeUrl} target="_blank" rel="noreferrer">
          {getContent('dataProtectionLink')}
        </Link>
      </TermsContainer>
      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(10, 9, formData);
            setCurrentStep(9);
          }}>
          {getContent('backButtonLabel')}
        </CustomizedButton>
        <CustomizedButton
          onClick={() => {
            trackEvent('configurator-form-submitted', { formData });
            onSubmit(formData);
          }}
          disabled={disabledSubmit}>
          {getContent('submitButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
