import React, {
    useState,
    useEffect,
    useMemo,
    useContext,
    createContext,
} from "react";

import AmplifyAuthComponent from "../components/AmplifyAuth";
import { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

// Whether to connect analytics session to `user.uid`
const ANALYTICS_IDENTIFY = true;

// Create a `useAuth` hook and `AuthProvider` that enables
// any component to subscribe to auth and re-render when it changes.
const authContext = createContext();
export const useAuth = () => useContext(authContext);

// This should wrap the app in `src/pages/_app.js`
export function AuthProvider({ children }) {

    const [user, setUser] = useState(null);

    const checkUser = async () => {

        try {
            var data = await Auth.currentSession()

            var user = await Auth.currentAuthenticatedUser();
            setUser(user)
        } catch (err) {
            console.log("you are not logged in")
        }
    }

    useEffect(() => {

        onAuthUIStateChange(async (nextAuthState, authData) => {
            if (nextAuthState === AuthState.SignedIn) {
                console.log("user successfully signed in!");

                //let credentials = await Auth.currentUserCredentials()
                //authData.user = "Username"
                //   console.log(authData)
                setUser(authData)
            }
            if (!authData) {
                console.log("user is not signed in...");
                setUser(null)
            }
        });

        checkUser()

    }, []);

    return (<authContext.Provider value={user} > {children}</authContext.Provider >);
}



// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
    return (props) => {
        // Get authenticated user
        const auth = useAuth();

        /*       useEffect(() => {
                // Redirect if not signed in
                if (auth.user === false) {
                  history.replace("/auth/signin");
                }
              }, [auth]); */

        // Show loading indicator
        // We're either loading (user is `null`) or about to redirect from above `useEffect` (user is `false`)




        if (auth != null) {
            return <Component {...props} />;
        }

        return <AmplifyAuthComponent></AmplifyAuthComponent>;

    };
};
