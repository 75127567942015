import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LeadCard from "./LeadCard";
import { Grid, Typography } from "@material-ui/core";
import Auth from '@aws-amplify/auth'
import { createRequestTransformer } from "amazon-location-helpers";
import amplifyConfig from "../aws-exports";
import API from '@aws-amplify/api';
import * as customQueries from '../graphql/custom-queries';
import "./css/maplibre.css"
import "./css/map.css"
import RequestInfo from './RequestInfo/RequestInfo'
import BuyButton from './BuyButton';
import ReactMapGL, { NavigationControl, Source, Layer } from "react-map-gl";
import { trackEvent } from "./ConfiguratorForm/tracker";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth } from "../util/auth";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}
const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    top: '5%',
    left: '5%',
    bottom: '5%',
    maxWidth: '90%',
    display: 'block',
    background: '#FFF',

  },
  header: {
    padding: '12px',
    borderBottom: '1px solid darkgrey'
  },
  content: {
    padding: '12px',
    overflowY: 'scroll',
    maxHeight: "90%"
  },
  footer: {
    padding: '12px',
    borderBottom: '1px solid darkgrey'
  },
}));

function LeadMap(props) {


  const userGroups = useAuth().signInUserSession.idToken.payload["cognito:groups"]

  //Backup if no usergroup is assigned
  //remove after Mai 2022

  if (!userGroups.includes("heatPump") && !userGroups.includes("energyConsultant") && userGroups.includes("partner")) {

    userGroups.push("heatPump")

  }

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  //incoming props
  const [open, setOpen] = React.useState(false);
  const { leadType, showFreeLeads, showOwnLeads, ...otherProps } = props;

  const [map, setMap] = useState({ loadMap: false, transformRequest: null, geojson: null, partnerRequest: null, viewport: null });
  const [popup, setPopup] = useState({ show: false })

  const handleClose = () => {
    setPopup({ show: false });
  };
  useEffect(() => {

    console.log("did mount")
    loadMap()

  }, []);

  const loadMap = async () => {
    let tr = await createRequestTransformer({
      credentials: await Auth.currentUserCredentials(),
      region: amplifyConfig.aws_project_region,
    });

    var freeLeadsGeoJson = null
    var freeLeadsRequest = null
    var ownLeadsGeoJson = null
    var ownLeadsRequest = null

    if (showFreeLeads) {

      freeLeadsRequest = await API.graphql({
        query: customQueries.exploreOpenLeads,
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })

      freeLeadsGeoJson = await createGeoJsonForOpenLeads(freeLeadsRequest)
    }

    if (showOwnLeads) {

      ownLeadsRequest = await API.graphql({
        query: customQueries.listPartnerExtended,
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })

      ownLeadsGeoJson = await createGeoJsonForPartnerLeads(ownLeadsRequest)
    }

    let viewport = {
      height: "100vh",
      width: "100wh",
      longitude: 7.071606,
      latitude: 50.226913,
      zoom: 7
    }

    let mymap = {
      viewport,
      transformRequest: tr,
      loadMap: true,
      ownLeadsGeoJson,
      freeLeadsGeoJson,
      ownLeadsRequest,
      freeLeadsRequest
    }

    setMap(mymap)


  }

  const createGeoJsonForPartnerLeads = async (listPartner) => {

    var geojson = {
      type: 'FeatureCollection',
      features: []
    }

    // console.log(myPartner.data.listPartners.items.length)
    console.log(listPartner)


    for (var partner of listPartner.data.listPartners.items) {

      for (var lead of partner.leads.items) {
        if (lead?.request?.address?.location?.long && lead?.request?.address?.location?.lat) {
          geojson.features.push(
            {
              type: 'Feature', properties: {
                'description': lead.notes,
                'request': lead.request,
                'leadId': lead.id,
                'isOwnerOfLead': true,
                lead,
                'icon': 'theatre-15'
              }, geometry: { type: 'Point', coordinates: [lead?.request?.address?.location?.long, lead?.request?.address?.location?.lat] }
            }
          )
        }

      }


    }

    return geojson

  }

  const createGeoJsonForOpenLeads = async (openLeads) => {

    var geojson = {
      type: 'FeatureCollection',
      features: []
    }

    // console.log(myPartner.data.listPartners.items.length)
    console.log(openLeads)




    for (var lead of openLeads.data.exploreOpenLeads) {
      if (lead?.request?.address?.location?.long && lead?.request?.address?.location?.lat) {

        var push = false;

        if (!lead.type && userGroups.includes("heatPump")) {
          push = true;
        }

        if (lead.type == "heatPump" && userGroups.includes("heatPump")) {
          push = true;
        }

        if (lead.type == "energyConsultant" && userGroups.includes("energyConsultant")) {
          push = true;
        }

        if (push) {


          geojson.features.push(
            {
              type: 'Feature', properties: {
                'description': lead.notes,
                'request': lead.request,
                'leadId': lead.id,
                'isOwnerOfLead': false,
                'lead': lead,

                'icon': 'theatre-15'
              }, geometry: { type: 'Point', coordinates: [lead?.request?.address?.location?.long, lead?.request?.address?.location?.lat] }
            }
          )
        }
      }




    }

    return geojson

  }

  const createLeadCards = (partnerRequest) => {

    var leadCards = []

    if (!partnerRequest || !partnerRequest?.data?.listPartners?.items || partnerRequest?.data?.listPartners?.items?.length == 0) {

      leadCards.push((<LeadCard />))
      return leadCards;
    }

    for (var partner of partnerRequest.data.listPartners.items) {

      const showDetails = (lead) => {
        trackEvent('lead_map_show_details_own_lead', { leadId: lead.id })
        setPopup({
          show: true,
          request: lead.request,
          leadId: lead.id,
          lead: lead,
          isOwnerOfLead: true
        })
      }


      function compare(a, b) {
        if (a.buyDate && b.buyDate && (a.buyDate < b.buyDate)) {
          return 1;
        }

        if (a.buyDate && b.buyDate && (a.buyDate > b.buyDate)) {
          return -1;
        }

        if (a.buyDate && b.buyDate && (a.buyDate = b.buyDate)) {
          return 0;
        }

        if (a.buyDate && !b.buyDate) {
          return -1;
        }

        if (!a.buyDate && b.buyDate) {
          return 1;
        }

        if (a._lastChangedAt < b._lastChangedAt) {
          return 1;
        }
        if (a._lastChangedAt > b._lastChangedAt) {
          return -1;
        }
        return 0;
      }

      var leads = partner.leads.items
      if (leads.length > 0) {

        leads = leads.sort(compare)

      }

      for (var lead of leads) {
        if (!lead.type) {
          leadCards.push((<LeadCard onOpen={showDetails} lead={lead} />))
        }

        if (lead.type == "heatPump") {
          lead.type = "heatPump"
          leadCards.push((<LeadCard onOpen={showDetails} lead={lead} />))
        }

        if (lead.type == "energyConsultant") {
          lead.type = "energyConsultant"
          leadCards.push((<LeadCard onOpen={showDetails} lead={lead} />))
        }


      }


    }

    if (leadCards.length == 0) {
      leadCards.push((<LeadCard />));
    }
    return leadCards;
  }
  const createLeadCardsOpen = (openLeads) => {

    var leadCards = []
    if (!openLeads || !openLeads?.data?.exploreOpenLeads || openLeads.data.exploreOpenLeads?.length == 0) {

      leadCards.push((<LeadCard />))
      return leadCards;
    }



    const showDetails = (lead) => {
      trackEvent('lead_map_show_details_open_lead', { leadId: lead.id })
      setPopup({
        show: true,
        request: lead.request,
        lead: lead,
        leadId: lead.id,
        isOwnerOfLead: false
      })
    }

    if (userGroups)

      for (var lead of openLeads.data.exploreOpenLeads) {

        if (!lead.type && userGroups.includes("heatPump")) {
          leadCards.push((<LeadCard onOpen={showDetails} lead={lead} />))
        }

        if (lead.type == "heatPump" && userGroups.includes("heatPump")) {

          leadCards.push((<LeadCard onOpen={showDetails} lead={lead} />))
        }

        if (lead.type == "energyConsultant" && userGroups.includes("energyConsultant")) {

          leadCards.push((<LeadCard onOpen={showDetails} lead={lead} />))
        }

      }

    if (leadCards.length == 0) {
      leadCards.push((<LeadCard />))
    }


    return leadCards;

  }

  const layerStyleOwnLeads = {

    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 10,
      'circle-color': '#007cbf'
    }
  };

  const layerStyleFreeLeads = {

    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 10,
      'circle-color': '#123456'
    }
  };

  return (
    !map.loadMap ? ("Loading map....") : (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} md={6}>
          <Dialog open={popup.show} onClose={handleClose} scroll="paper">
            <DialogTitle id="scroll-dialog-title">Details zum Kundenauftrag</DialogTitle>
            <DialogContent dividers={true}>



              {popup.show && (<RequestInfo request={popup?.request} lead={popup?.lead} isOwnerOfLead={popup.isOwnerOfLead} />)}


            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {popup?.isOwnerOfLead ? null : (<BuyButton leadId={popup.leadId} onCloseEventCancel={() => {
                setPopup({
                  show: false
                })
              }} onCloseEventBuy={() => {
                setMap({ loadMap: false })
                setPopup({ show: false })
                loadMap()
              }}></BuyButton>)}


            </DialogActions>

          </Dialog>
          <ReactMapGL height="50%" onClick={(e) => {

            if (!e.features[0]?.properties?.request) {
              return null
            }
            // Copy coordinates array.
            try {



              const coordinates = e.features[0].geometry.coordinates.slice();
              const request = e.features[0].properties.request;
              const leadId = e.features[0].properties.leadId;
              const lead = e.features[0].properties.lead;
              const isOwnerOfLead = e.features[0].properties?.isOwnerOfLead;


              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
              setPopup({
                show: true,
                longitude: coordinates[0],
                latitude: coordinates[1],
                leadId,
                lead,
                request,
                isOwnerOfLead,


              })
              //    new mapboxgl.Popup()
              //      .setLngLat(coordinates)
              //      .setHTML(description)
              //      .addTo(map);
            }
            catch (err) {

            }
          }}


            {...map.viewport}
            width="100%"
            height="100vh"
            transformRequest={map.transformRequest}
            mapStyle="heatly"
            onViewportChange={(data) => setMap({ ...map, viewport: data })}
          >
            {/*   {popup.show && <Popup

              latitude={popup.latitude}
              longitude={popup.longitude}
              closeButton={true}
              closeOnClick={false}
              onClose={() => setPopup({ show: false })}
              anchor="top" >

              <div><strong>{popup.head}</strong><p>{popup.description}</p></div>
            </Popup>} */}



            {map?.freeLeadsGeoJson && <Source id="freeLeads" type="geojson" data={map.freeLeadsGeoJson}>
              <Layer {...layerStyleFreeLeads} />
            </Source>
            }
            {map?.ownLeadsGeoJson && <Source id="ownLeads" type="geojson" data={map.ownLeadsGeoJson}>
              <Layer {...layerStyleOwnLeads} />
            </Source>}


            <div style={{ position: "absolute", left: 20, top: 20 }}>
              <NavigationControl showCompass={true} />
            </div>
          </ReactMapGL>
        </Grid>
        <Grid item={true} xs={12} md={6} id="leadlist">
          {showOwnLeads ? createLeadCards(map.ownLeadsRequest) : (<></>)}
          {showFreeLeads ? createLeadCardsOpen(map.freeLeadsRequest) : (<></>)}

        </Grid>
      </Grid>
    )
  )
}

export default LeadMap;
