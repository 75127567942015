import React from 'react';
import { Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import { StepContainer, CustomizedButton, ButtonContainer, useFormStyles } from '../shared';
import { getContent } from '../utils';
import { trackStepChange, trackInputFieldDataChange } from '../tracker';
import Helper from '../../Helper';

export const EnergyStep = ({ formData, setFormData, setCurrentStep }) => {
  const formStyles = useFormStyles();

  const hasAgeCurrentHeatingSystemError =
    !!formData.ageCurrentHeatingSystem && (formData.ageCurrentHeatingSystem < 1 || formData.ageCurrentHeatingSystem > 100);


  const disableNextStep = !formData.ageCurrentHeatingSystem || hasAgeCurrentHeatingSystemError || (formData.energySource == "wood" && !formData.energySubSource);

  return (
    <StepContainer>

      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('energyLabel')}<Helper header="Energieträger" text="Wie wird das Objekt zur Zeit hauptsächlich geheizt. Heizen Sie zusätzlich beispielsweise noch mit Holz? Dazu werden wir Sie später nochmal befragen." /></Typography>

        <Select
          labelId="select-label"
          value={formData.energySource}
          onChange={(event) => {
            trackInputFieldDataChange('energySource', {
              previousValue: formData.energySource,
              newValue: event.target.value,
            });
            setFormData({ ...formData, energySource: event.target.value });
          }}>
          <MenuItem value="oil">{getContent('enumTranslation')?.energySource?.oil}</MenuItem>
          <MenuItem value="naturalGas">{getContent('enumTranslation')?.energySource?.naturalGas}</MenuItem>
          {/*   <MenuItem value="liquidGas">{getContent('energyLiquidGas')}</MenuItem> */}
          <MenuItem value="wood">{getContent('enumTranslation')?.energySource?.wood}</MenuItem>
          <MenuItem value="pellets">{getContent('enumTranslation')?.energySource?.pellets}</MenuItem>
          <MenuItem value="districtHeat">{getContent('enumTranslation')?.energySource?.districtHeat}</MenuItem>
          <MenuItem value="nightPower">{getContent('enumTranslation')?.energySource?.nightPower}</MenuItem>
          <MenuItem value="coal">{getContent('enumTranslation')?.energySource?.coal}</MenuItem>
        </Select>
      </FormControl>
      {formData.energySource === "wood" && (
        <FormControl className={formStyles.formControl}>
          <InputLabel id="select-label">
            {getContent("woodSelectLabel")}
          </InputLabel>
          <Select
            labelId="select-label"
            value={formData.energySubSource}
            onChange={(event) => {
              trackInputFieldDataChange("energySubSource", {
                previousValue: formData.energySubSource,
                newValue: event.target.value,
              });
              setFormData({
                ...formData,
                energySubSource: event.target.value,
              });
            }}
          >
            <MenuItem value="birch">{getContent('woodBirke')}</MenuItem>
            <MenuItem value="beech">{getContent('woodBuche')}</MenuItem>
            <MenuItem value="oak">{getContent('woodEiche')}</MenuItem>
            <MenuItem value="ash">{getContent('woodEsche')}</MenuItem>
            <MenuItem value="pine">{getContent('woodKiefer')}</MenuItem>
            <MenuItem value="larch">{getContent('woodLarche')}</MenuItem>
            <MenuItem value="hardwoodMix">{getContent('woodHartholz')}</MenuItem>
          </Select>
        </FormControl>
      )}


      <FormControl className={formStyles.formControl}>
        <Typography>{getContent('ageCurrentHeatingSystemLabel')}</Typography>
        <TextField
          variant="outlined"

          value={formData.ageCurrentHeatingSystem || ''}
          error={!!formData.ageCurrentHeatingSystem && hasAgeCurrentHeatingSystemError}
          helperText={
            !!formData.ageCurrentHeatingSystem && hasAgeCurrentHeatingSystemError
              ? getContent('ageCurrentHeatingSystemErrorText')
              : getContent('ageCurrentHeatingSystemHelperText')
          }
          required
          onChange={(event) => {
            trackInputFieldDataChange('ageCurrentHeatingSystem', {
              previousValue: formData.ageCurrentHeatingSystem,
              newValue: parseInt(event.target.value),
            });
            setFormData({ ...formData, ageCurrentHeatingSystem: parseInt(event.target.value) });
          }}
        />
      </FormControl>
      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(2, 1, formData);
            setCurrentStep(1);
          }}>
          {getContent('backButtonLabel')}
        </CustomizedButton>
        <CustomizedButton
          autoFocus
          onClick={() => {
            trackStepChange(2, 3, formData);
            setCurrentStep(3);
          }}
          disabled={disableNextStep}>

          {getContent('nextButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
