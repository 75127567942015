import React, { useEffect, useState } from "react";

import { useCostCalculator, numberWithCommas } from "../../util/costCalculator";

import { Box, Button, Collapse, IconButton } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { makeStyles } from "@material-ui/styles";
import TableMenu from "./table/TableMenu";

function createData(name, _1thC, _2thC, _3thC) {
  return { name, _1thC, _2thC, _3thC };
}

const CostCalcuaterTable = () => {
  const classes = useStyles();

  const { costCalculatorResultState, loadedState, consideredPeriodState } =
    useCostCalculator();
  const { loaded } = loadedState;
  const { costCalculatorResult } = costCalculatorResultState;
  const { consideredPeriod } = consideredPeriodState;

  const [rows, setRows] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      !costCalculatorResult._3thCol.energySource ||
      !costCalculatorResult._2thCol.energySource ||
      !costCalculatorResult._1thCol.energySource
    )
      return;
    setRows([
      createData(
        "Einmalige Anlagenkosten",
        numberWithCommas(
          costCalculatorResult._1thCol.acquisitionCostsThirdEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._2thCol.acquisitionCostsMainEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._3thCol.acquisitionCostsMainEnergySource
        )
      ),
      createData(
        "Betriebskosten",
        numberWithCommas(
          costCalculatorResult._1thCol.operationCostsThirdEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._2thCol.operationCostsMainEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._3thCol.operationCostsMainEnergySource
        )
      ),
      createData(
        "Schornsteinfeger",
        numberWithCommas(
          costCalculatorResult._1thCol.chimneySweeperCostsMainEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._2thCol.chimneySweeperCostsMainEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._3thCol.chimneySweeperCostsMainEnergySource
        )
      ),
      createData(
        "Wartung",
        numberWithCommas(
          costCalculatorResult._1thCol.maintenanceThirdEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._2thCol.maintenanceMainEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._3thCol.maintenanceMainEnergySource
        )
      ),
      createData(
        "Energiekosten",
        numberWithCommas(
          costCalculatorResult._1thCol.energyCostsAverageMainEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._2thCol.energyCostsAverageMainEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._3thCol.energyCostsAverageMainEnergySource
        )
      ),
      createData(
        "CO2 - Abgabe",
        numberWithCommas(costCalculatorResult._1thCol.co2CostsMainEnergySource),
        numberWithCommas(costCalculatorResult._2thCol.co2CostsMainEnergySource),
        numberWithCommas(costCalculatorResult._3thCol.co2CostsMainEnergySource)
      ),
      createData(
        "Zähler-/Lieferkosten",
        numberWithCommas(
          costCalculatorResult._1thCol.provisionCostsThirdeEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._2thCol.provisionCostsMainEnergySource
        ),
        numberWithCommas(
          costCalculatorResult._3thCol.provisionCostsMainEnergySource
        )
      ),
    ]);
  }, [costCalculatorResult, loaded]);
  return (
    <>
      {!rows ? (
        <div>
          <p>loading</p>
        </div>
      ) : (
        <Box className={classes.conatiner}>
          <Box m="auto" boxShadow={3}>
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ height: 80 }}>
                    <TableCell
                      className={[
                        classes.tableRightBorder,
                        classes._0thColHeaderStyle,
                      ]}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className={classes._1thColHeaderStyle}
                    >
                      <Box className={classes.colBoxLogoNameContainer}>
                        <img
                          src={costCalculatorResult._1thCol.energySourceLogo}
                          alt="Logo"
                          className={classes._0rowLogoStyle}
                        />
                        <Typography className={classes.thTextColor}>
                          {costCalculatorResult._1thCol.energySource}
                        </Typography>
                        {/* <TableMenu
                          energySource={
                            costCalculatorResult._1thCol.energySource
                          }
                          column={1}
                        /> */}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes._2thColHeaderStyle}
                    >
                      <Box className={classes.colBoxLogoNameContainer}>
                        <img
                          src={costCalculatorResult._2thCol.energySourceLogo}
                          alt="Logo"
                          className={classes._0rowLogoStyle}
                        />
                        <Typography className={classes.thTextColor}>
                          {costCalculatorResult._2thCol.energySource}
                        </Typography>
                        <TableMenu
                          energySource={
                            costCalculatorResult._2thCol.energySource
                          }
                          column={2}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes._3thColHeaderStyle}
                    >
                      <Box className={classes.colBoxLogoNameContainer}>
                        <img
                          src={costCalculatorResult._3thCol.energySourceLogo}
                          alt="Logo"
                          className={classes._0rowLogoStyle}
                        />
                        <Typography className={classes.thTextColor}>
                          {costCalculatorResult._3thCol.energySource}
                        </Typography>
                        <TableMenu
                          energySource={
                            costCalculatorResult._3thCol.energySource
                          }
                          column={3}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <React.Fragment key={index}>
                      {index < 2 && (
                        <TableRow
                          key={row.name}
                          onClick={() => index === 1 && setOpen(!open)}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={[
                              classes.tableRightBorder,
                              classes._0thColBody,
                            ]}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                              }}
                            >
                              <Typography
                                fontWeight={index < 3 && "900"}
                                className={index > 2 && classes.paddingLeft}
                              >
                                {row.name}
                              </Typography>
                              {index === 1 &&
                                (open ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                ))}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableRightBorder}
                          >
                            {row._1thC} €
                          </TableCell>
                          <TableCell className={classes.tableRightBorder}>
                            {row._2thC} €
                          </TableCell>
                          <TableCell align="left">{row._3thC} €</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                          }}
                          colSpan={4}
                        >
                          {index >= 2 && (
                            <Collapse
                              in={open}
                              timeout="auto"
                              style={{
                                borderBottom: "1px solid white",
                                width: "100%",
                              }}
                            >
                              <TableRow
                                className={classes.childTableRow}
                                style={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes._0thChildTableCellRow}
                                >
                                  {row.name}
                                </TableCell>
                                <TableCell
                                  className={classes._1thChildTableCellRow}
                                >
                                  {row._1thC} €
                                </TableCell>
                                <TableCell
                                  className={classes._2thChildTableCellRow}
                                >
                                  <Typography className={classes.childTextRow}>
                                    {row._2thC} €
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes._3thChildTableCellRow}
                                >
                                  {row._3thC} €
                                </TableCell>
                              </TableRow>
                            </Collapse>
                          )}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}

                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.tableRightBorder}
                    >
                      <Typography fontWeight="900">
                        Gesamtkosten {consideredPeriod} Jahre
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={[
                        classes.tableRightBorder,
                        classes._1thColHeaderStyle,
                      ]}
                    >
                      <Typography className={classes.thTextColor}>
                        {numberWithCommas(
                          costCalculatorResult._1thCol
                            .totalCostsMainEnergySourceArr[consideredPeriod - 1]
                        )}{" "}
                        €
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={[
                        classes.tableRightBorder,
                        classes._2thColHeaderStyle,
                      ]}
                    >
                      <Typography className={classes.thTextColor}>
                        {numberWithCommas(
                          costCalculatorResult._2thCol
                            .totalCostsMainEnergySourceArr[consideredPeriod - 1]
                        )}{" "}
                        €
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes._3thColHeaderStyle}
                    >
                      <Typography className={classes.thTextColor}>
                        {numberWithCommas(
                          costCalculatorResult._3thCol
                            .totalCostsMainEnergySourceArr[consideredPeriod - 1]
                        )}{" "}
                        €
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CostCalcuaterTable;

const useStyles = makeStyles((theme) => ({
  conatiner: {
    display: "flex",
    flex: 1,
    width: "100%",
    marginTop: 50,
    overflow: "scroll",
  },
  _0thColHeaderStyle: {
    width: "25%",
  },
  _1thColHeaderStyle: {
    backgroundColor: "#01B13B",
    width: "25%",
  },
  _2thColHeaderStyle: {
    backgroundColor: "#FFC000",
    width: "25%",
  },
  _3thColHeaderStyle: {
    backgroundColor: "#FF4D00",
    width: "25%",
  },
  _0rowLogoStyle: {
    width: 40,
  },
  colBoxLogoNameContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
  },
  _0thColBody: {
    flexDirection: "row",
    height: 80,
  },
  thTextColor: {
    color: "white",
  },
  arrowColor: {
    color: "white",
  },
  tableRightBorder: {
    borderRight: "1px solid black",
  },
  tableLeftBorder: {
    borderLeft: "1px solid black",
  },
  paddingLeft: {
    paddingLeft: 20,
  },
  childTableRow: {
    [theme.breakpoints.down("xs")]: {
      gap: "10px",
    },
  },
  _0thChildTableCellRow: {
    width: "25%",
    minWidth: 150,
    borderBottom: "none",
  },
  _1thChildTableCellRow: {
    minWidth: "27%",
    borderBottom: "none",
  },
  _2thChildTableCellRow: {
    width: "25%",
    borderBottom: "none",
  },
  _3thChildTableCellRow: {
    width: "22%",
    borderBottom: "none",
  },
}));
