import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
  useCallback,
} from "react";

import { costCalculatorBaseSettings } from "../components/CostCalculator/content";

const costCalculatorContext = createContext();

export const useCostCalculator = () => useContext(costCalculatorContext);

export const CostCalculatorProvider = (props) => {
  const [requestResult, setRequestResult] = useState(null);
  const [costCalculatorResult, setCostCalculatorResult] = useState({
    _1thCol: {},
    _2thCol: {},
    _3thCol: {},
  });
  const [loaded, setLoaded] = useState(false);
  const [consideredPeriod, setConsideredPeriod] = useState(25);
  const [costCalculaterEditable, setCostCalculaterEditable] = useState({
    mainEnergySource: null,
    secondEnergySource: null,
    thirdEnergySource: null,

    HPpower: null,
    utilizationRateThirdEnergySource: null,

    fundingRatiosHP: null,
    fundingRatiosSecoundEnergySource: null,
    fundingRatiosMainEnergySource: null,

    acquisitionCostsThirdEnergySource: null,
    acquisitionCostsSecondEnergySource: null,
    acquisitionCostsMainEnergySource: null,

    specificFuleCostsThirdEnergySource: null,
    specificFuleCostsSecondEnergySource: null,
    specificFuleCostsMainEnergySource: null,

    priceIncreaseThirdEnergySource: null,
    priceIncreaseSecondEnergySource: null,
    priceIncreaseMainEnergySource: null,

    deliveryCostsThirdEnergySource: null,
    deliveryCostsSecondEnergySource: null,
    deliveryCostsMainEnergySource: null,
  });
  const [numberColChange, setNumberColChange] = useState(null);
  const [energySourceChange, setEnergySourceChange] = useState({
    energySource: null,
    colNumber: null,
  });
  const numberColChangeState = useMemo(
    () => ({
      numberColChange,
      setNumberColChange,
    }),
    [numberColChange, setNumberColChange]
  );
  const energySourceChangeState = useMemo(
    () => ({
      energySourceChange,
      setEnergySourceChange,
    }),
    [energySourceChange, setEnergySourceChange]
  );

  const costCalculatorResultState = useMemo(
    () => ({
      costCalculatorResult,
      setCostCalculatorResult,
    }),
    [costCalculatorResult, setCostCalculatorResult]
  );
  const requestResultState = useMemo(
    () => ({
      requestResult,
      setRequestResult,
    }),
    [requestResult, setRequestResult]
  );
  const loadedState = useMemo(
    () => ({
      loaded,
      setLoaded,
    }),
    [loaded, setLoaded]
  );
  const consideredPeriodState = useMemo(
    () => ({
      consideredPeriod,
      setConsideredPeriod,
    }),
    [consideredPeriod, setConsideredPeriod]
  );
  const costCalculaterEditableState = useMemo(
    () => ({
      costCalculaterEditable,
      setCostCalculaterEditable,
    }),
    [costCalculaterEditable, setCostCalculaterEditable]
  );

  const costCaculaterByEnergySource = useCallback(
    (colNumber, choosenEnergySource) => {
      setLoaded(!loaded);
      const heatingDemand = requestResult.evaluation.heatingDemand; //Heat demand in kWh per square meter
      const Inhabs = requestResult.inhabs; //#int number of users
      const buildingUsefulArea = requestResult.buildingUsefulArea; //Useful Area of the building in square meter
      const energySource = choosenEnergySource || requestResult.energySource; //list of the selected heating system [“oil”, “naturalGas”, “liquidGas”, “wood”, “pellets”, “nightPower”, “coal”, “districtHeat”] selected by the user
      const hpFeatures = requestResult.hpFeatures; //{“highQualityProduct”, “coolingPossible”, “premiumOptic”, “highEfficiency”, “lowInitialPrice”} #list of selected features
      const hotDomesticWater = requestResult.hotDomesticWater; //true or false
      const hotDomesticWaterFuture = requestResult.hotDomesticWater; //true or false

      let heatDemandDomesticHotWater;
      if (hotDomesticWater || hotDomesticWaterFuture) {
        heatDemandDomesticHotWater = Inhabs * 1000;
      } else {
        heatDemandDomesticHotWater = 0;
      }

      if (colNumber !== 1) {
        const utilizationRateMainEnergySource =
          costCalculatorBaseSettings.utilizationRateList[energySource];

        let fundingRatiosEnergySourceOrginal =
          costCalculatorBaseSettings.CO2factor[energySource] === 0 ? 0.35 : 0;
        let fundingRatiosEnergySource;
        if (colNumber === 3) {
          fundingRatiosEnergySource = whichValue(
            costCalculaterEditable.fundingRatiosMainEnergySource,
            fundingRatiosEnergySourceOrginal
          );
        } else {
          fundingRatiosEnergySource = whichValue(
            costCalculaterEditable.fundingRatiosSecoundEnergySource,
            fundingRatiosEnergySourceOrginal
          );
        }

        let acquisitionCostsMainEnergySourceOrginal;
        if (colNumber === 3) {
          acquisitionCostsMainEnergySourceOrginal = whichValue(
            costCalculaterEditable.acquisitionCostsMainEnergySource,
            costCalculatorBaseSettings.acquisitionCosts[energySource]
          );
        } else {
          acquisitionCostsMainEnergySourceOrginal = whichValue(
            costCalculaterEditable.acquisitionCostsSecondEnergySource,
            costCalculatorBaseSettings.acquisitionCosts[energySource]
          );
        }

        let acquisitionCostsMainEnergySource =
          acquisitionCostsMainEnergySourceOrginal *
          (1 - fundingRatiosEnergySource);

        let provisionCostsMainEnergySource;
        if (colNumber === 3) {
          provisionCostsMainEnergySource = whichValue(
            costCalculaterEditable.deliveryCostsMainEnergySource,
            costCalculatorBaseSettings.provisionCosts[energySource]
          );
        } else {
          provisionCostsMainEnergySource = whichValue(
            costCalculaterEditable.deliveryCostsSecondEnergySource,
            costCalculatorBaseSettings.provisionCosts[energySource]
          );
        }

        let priceIncreasesMainEnergySource;
        if (colNumber === 3) {
          priceIncreasesMainEnergySource = whichValue(
            costCalculaterEditable.priceIncreaseMainEnergySource,
            costCalculatorBaseSettings.priceIncreases[energySource]
          );
        } else {
          priceIncreasesMainEnergySource = whichValue(
            costCalculaterEditable.priceIncreaseSecondEnergySource,
            costCalculatorBaseSettings.priceIncreases[energySource]
          );
        }

        const maintenanceMainEnergySource =
          costCalculatorBaseSettings.maintenance[energySource];
        const chimneySweeperCostsMainEnergySource =
          costCalculatorBaseSettings.chimneySweeperCosts[energySource];
        const co2CostsMainEnergySource =
          (((costCalculatorBaseSettings.CO2factor[energySource] *
            heatingDemand) /
            utilizationRateMainEnergySource) *
            average(costCalculatorBaseSettings.co2fee, consideredPeriod)) /
          1000;
        const energyDemandMainEnergySource =
          (heatingDemand + heatDemandDomesticHotWater) /
          utilizationRateMainEnergySource;

        let specificFuleCostsMainEnergySource;
        let specificFuleCosts;
        if (colNumber === 3) {
          specificFuleCosts = whichValue(
            costCalculaterEditable.specificFuleCostsMainEnergySource,
            costCalculatorBaseSettings.specificFuleCosts[energySource]
          );
          specificFuleCostsMainEnergySource = whichValue(
            costCalculaterEditable.specificFuleCostsMainEnergySource,
            Number(
              (
                costCalculatorBaseSettings.specificFuleCosts[energySource] *
                headLineHandler(energySource).value
              ).toFixed(4)
            )
          );
        } else {
          specificFuleCosts = whichValue(
            costCalculaterEditable.specificFuleCostsSecondEnergySource,
            costCalculatorBaseSettings.specificFuleCosts[energySource]
          );
          specificFuleCostsMainEnergySource = whichValue(
            costCalculaterEditable.specificFuleCostsSecondEnergySource,
            Number(
              (
                costCalculatorBaseSettings.specificFuleCosts[energySource] *
                headLineHandler(energySource).value
              ).toFixed(4)
            )
          );
        }
        if (
          costCalculaterEditable.specificFuleCostsSecondEnergySource ||
          costCalculaterEditable.specificFuleCostsMainEnergySource
        ) {
          specificFuleCosts /= headLineHandler(energySource).value;
        }

        let energyCostsMainEnergySource =
          specificFuleCosts * energyDemandMainEnergySource;

        let totalOperatingCosts = 0;
        let totalEnergyCosts = 0;
        let totalCostsMainEnergySource = 0;
        const totalCostsMainEnergySourceArr = [];
        const totalOperatingCostsYearArr = [];
        const totalEnergyCostsYearArr = [];
        const totalEnergyCostsArr = [];
        for (let i = 0; i < consideredPeriod; i++) {
          const totalEnergyCostsYear =
            costCalculatorBaseSettings.CO2factor[energySource] *
              energyDemandMainEnergySource *
              (costCalculatorBaseSettings.co2fee[i] / 1000) +
            energyCostsMainEnergySource *
              Math.pow(1 + priceIncreasesMainEnergySource, i);
          totalEnergyCostsYearArr.push(totalEnergyCostsYear);

          const totalOperatingCostsYear =
            totalEnergyCostsYear +
            chimneySweeperCostsMainEnergySource +
            maintenanceMainEnergySource +
            provisionCostsMainEnergySource;
          totalOperatingCostsYearArr.push(totalOperatingCostsYear);

          totalEnergyCosts += totalEnergyCostsYear;
          totalOperatingCosts += totalOperatingCostsYear;

          totalEnergyCostsArr.push(totalOperatingCosts);
        }
        for (let i = 0; i < consideredPeriod; i++) {
          if (i === 0) {
            totalCostsMainEnergySource =
              totalOperatingCostsYearArr[i] + acquisitionCostsMainEnergySource;
          } else {
            totalCostsMainEnergySource =
              totalOperatingCostsYearArr[i] +
              totalCostsMainEnergySourceArr[i - 1];
          }
          totalCostsMainEnergySourceArr.push(totalCostsMainEnergySource);
        }

        const energyCostsAverageMainEnergySource =
          totalEnergyCosts / consideredPeriod;

        const operationCostsMainEnergySource =
          chimneySweeperCostsMainEnergySource +
          energyCostsAverageMainEnergySource -
          co2CostsMainEnergySource +
          provisionCostsMainEnergySource +
          maintenanceMainEnergySource +
          Math.ceil(co2CostsMainEnergySource);

        const newCostCalculatorResult = costCalculatorResult;

        switch (colNumber) {
          case 3:
            newCostCalculatorResult._3thCol = {
              energySource:
                costCalculatorBaseSettings.translationsHeaterDE[energySource],
              energySourceLogo:
                costCalculatorBaseSettings.energySourceLogo[energySource],
              acquisitionCostsMainEnergySource,
              acquisitionCostsMainEnergySourceOrginal,
              utilizationRateMainEnergySource,
              specificFuleCosts,
              specificFuleCostsMainEnergySource,
              fundingRatiosEnergySource,
              priceIncreasesMainEnergySource,
              operationCostsMainEnergySource,
              chimneySweeperCostsMainEnergySource,
              maintenanceMainEnergySource,
              energyCostsMainEnergySource,
              co2CostsMainEnergySource,
              provisionCostsMainEnergySource,
              totalCostsMainEnergySource:
                totalCostsMainEnergySourceArr[consideredPeriod - 1],
              totalEnergyCostsYearArr,
              totalEnergyCostsArr,
              totalOperatingCostsYearArr,
              totalCostsMainEnergySourceArr,
              energyDemandMainEnergySource,
              energyCostsAverageMainEnergySource:
                energyCostsAverageMainEnergySource - co2CostsMainEnergySource,
            };
            break;

          default:
            newCostCalculatorResult._2thCol = {
              energySource:
                costCalculatorBaseSettings.translationsHeaterDE[energySource],
              energySourceLogo:
                costCalculatorBaseSettings.energySourceLogo[energySource],
              acquisitionCostsMainEnergySource,
              acquisitionCostsMainEnergySourceOrginal,
              utilizationRateMainEnergySource,
              specificFuleCosts,
              specificFuleCostsMainEnergySource,
              fundingRatiosEnergySource,
              priceIncreasesMainEnergySource,
              operationCostsMainEnergySource,
              chimneySweeperCostsMainEnergySource,
              maintenanceMainEnergySource,
              energyCostsMainEnergySource,
              co2CostsMainEnergySource,
              provisionCostsMainEnergySource,
              totalCostsMainEnergySource:
                totalCostsMainEnergySourceArr[consideredPeriod - 1],
              totalEnergyCostsYearArr,
              totalEnergyCostsArr,
              totalOperatingCostsYearArr,
              totalCostsMainEnergySourceArr,
              energyDemandMainEnergySource,
              energyCostsAverageMainEnergySource:
                energyCostsAverageMainEnergySource - co2CostsMainEnergySource,
            };
            break;
        }

        setCostCalculatorResult(newCostCalculatorResult);
      } else {
        const energySource = choosenEnergySource;

        const utilizationRateThirdEnergySource = whichValue(
          costCalculaterEditable.utilizationRateThirdEnergySource,
          costCalculatorBaseSettings.utilizationRateList[energySource]
        );

        const energyDemandThirdEnergySource =
          (heatingDemand + heatDemandDomesticHotWater) /
          utilizationRateThirdEnergySource;

        const hpElectricityTariff =
          energyDemandThirdEnergySource *
            costCalculatorBaseSettings.specificFuleCosts["heatPump"] +
            costCalculatorBaseSettings.provisionCosts["heatPump"] >
          energyDemandThirdEnergySource *
            costCalculatorBaseSettings.specificFuleCosts["HPonlie"] +
            costCalculatorBaseSettings.provisionCosts["HPonlie"]
            ? true
            : false;

        let HPpower;
        if (
          costCalculaterEditable.HPpower === "HPonlie" ||
          costCalculaterEditable.HPpower === "heatPump"
        ) {
          HPpower = costCalculaterEditable.HPpower;
        } else {
          HPpower = hpElectricityTariff ? "HPonlie" : "heatPump";
        }

        const fundingRatiosHP = whichValue(
          costCalculaterEditable.fundingRatiosHP,
          costCalculatorBaseSettings.fundingRatios[HPpower]
        );

        const provisionCostsThirdeEnergySource = whichValue(
          costCalculaterEditable.deliveryCostsThirdEnergySource,
          costCalculatorBaseSettings.provisionCosts[HPpower]
        );

        const maintenanceThirdEnergySource =
          costCalculatorBaseSettings.maintenance[HPpower];

        const acquisitionCostsThirdEnergySourceOrginal = whichValue(
          costCalculaterEditable.acquisitionCostsThirdEnergySource,
          costCalculatorBaseSettings.acquisitionCosts[energySource]
        );

        let acquisitionCostsThirdEnergySource =
          costCalculatorBaseSettings.localFoundingCosts[energySource] *
            costCalculatorBaseSettings.specificFoundationCosts[energySource] +
          acquisitionCostsThirdEnergySourceOrginal * (1 - fundingRatiosHP);

        const specificFuleCostsThirdEnergySource = whichValue(
          costCalculaterEditable.specificFuleCostsThirdEnergySource,
          costCalculatorBaseSettings.specificFuleCosts[HPpower] *
            headLineHandler(energySource).value
        );

        let specificFuleCosts = whichValue(
          costCalculaterEditable.specificFuleCostsThirdEnergySource,
          costCalculatorBaseSettings.specificFuleCosts[HPpower]
        );
        if (costCalculaterEditable.specificFuleCostsThirdEnergySource) {
          specificFuleCosts /= headLineHandler(energySource).value;
        }
        const energyCostsThirdEnergySource =
          energyDemandThirdEnergySource * specificFuleCosts;

        const priceIncreasesThirdEnergySource = whichValue(
          costCalculaterEditable.priceIncreaseThirdEnergySource,
          costCalculatorBaseSettings.priceIncreases[HPpower]
        );

        let totalOperatingCosts = 0;
        let totalEnergyCosts = 0;
        let totalCostsMainEnergySource = 0;
        const totalCostsMainEnergySourceArr = [];
        const totalOperatingCostsYearArr = [];
        const totalEnergyCostsYearArr = [];
        const totalEnergyCostsArr = [];
        for (let i = 0; i < consideredPeriod; i++) {
          const totalEnergyCostsYear =
            costCalculatorBaseSettings.CO2factor[HPpower] *
              energyDemandThirdEnergySource *
              (costCalculatorBaseSettings.co2fee[i] / 1000) +
            energyCostsThirdEnergySource *
              Math.pow(1 + priceIncreasesThirdEnergySource, i);
          totalEnergyCostsYearArr.push(totalEnergyCostsYear);

          const totalOperatingCostsYear =
            totalEnergyCostsYear +
            maintenanceThirdEnergySource +
            provisionCostsThirdeEnergySource;
          totalOperatingCostsYearArr.push(totalOperatingCostsYear);

          totalEnergyCosts += totalEnergyCostsYear;
          totalOperatingCosts += totalOperatingCostsYear;

          totalEnergyCostsArr.push(totalOperatingCosts);
        }
        for (let i = 0; i < consideredPeriod; i++) {
          if (i === 0) {
            totalCostsMainEnergySource =
              totalOperatingCostsYearArr[i] + acquisitionCostsThirdEnergySource;
          } else {
            totalCostsMainEnergySource =
              totalOperatingCostsYearArr[i] +
              totalCostsMainEnergySourceArr[i - 1];
          }
          totalCostsMainEnergySourceArr.push(totalCostsMainEnergySource);
        }

        const energyCostsAverageMainEnergySource =
          totalEnergyCosts / consideredPeriod;

        const operationCostsThirdEnergySource =
          Math.ceil(energyCostsAverageMainEnergySource) +
          provisionCostsThirdeEnergySource +
          maintenanceThirdEnergySource;

        const newCostCalculatorResult = costCalculatorResult;
        newCostCalculatorResult._1thCol = {
          energySource:
            costCalculatorBaseSettings.translationsHeaterDE[energySource],
          energySourceLogo:
            costCalculatorBaseSettings.energySourceLogo[energySource],
          acquisitionCostsThirdEnergySource,
          acquisitionCostsThirdEnergySourceOrginal,
          utilizationRateThirdEnergySource,
          specificFuleCosts,
          specificFuleCostsThirdEnergySource,
          fundingRatiosHP,
          priceIncreasesThirdEnergySource,
          operationCostsThirdEnergySource,
          hpElectricityTariff,
          chimneySweeperCostsMainEnergySource: 0,
          maintenanceThirdEnergySource,
          energyCostsThirdEnergySource,
          co2CostsMainEnergySource: 0,
          provisionCostsThirdeEnergySource,
          totalCostsThirdEnergySource:
            totalCostsMainEnergySourceArr[consideredPeriod - 1],
          totalEnergyCostsYearArr,
          totalEnergyCostsArr,
          totalOperatingCostsYearArr,
          totalCostsMainEnergySourceArr,
          energyCostsAverageMainEnergySource,
          energyDemandThirdEnergySource,
        };
        setCostCalculatorResult(newCostCalculatorResult);
      }
    },
    [
      costCalculatorResult,
      requestResult,
      consideredPeriod,
      costCalculaterEditable,
    ]
  );

  useEffect(() => {
    if (!energySourceChange.colNumber && !energySourceChange.energySource)
      return;
    if (energySourceChange.colNumber === 1) {
      costCaculaterByEnergySource(1, energySourceChange.energySource);
    } else if (energySourceChange.colNumber === 2) {
      costCaculaterByEnergySource(2, energySourceChange.energySource);
    } else if (energySourceChange.colNumber === 3) {
      costCaculaterByEnergySource(3, energySourceChange.energySource);
    } else {
      costCaculaterByEnergySource(
        energySourceChange.colNumber[0],
        objectKeySearchtranslationsHeaterDE(energySourceChange.energySource[0])
      );
      costCaculaterByEnergySource(
        energySourceChange.colNumber[1],
        objectKeySearchtranslationsHeaterDE(energySourceChange.energySource[1])
      );
      costCaculaterByEnergySource(
        energySourceChange.colNumber[2],
        objectKeySearchtranslationsHeaterDE(energySourceChange.energySource[2])
      );
    }
  }, [energySourceChange]);

  useEffect(() => {
    console.log("runsssss");
    if (!requestResult) return;
    costCaculaterByEnergySource(3);
    costCaculaterByEnergySource(2, "naturalGas");
    costCaculaterByEnergySource(1, "HPair");
  }, [requestResult, consideredPeriod]);

  return (
    <costCalculatorContext.Provider
      value={{
        requestResultState,
        costCalculatorResultState,
        loadedState,
        consideredPeriodState,
        costCalculaterEditableState,
        numberColChangeState,
        energySourceChangeState,
        costCaculaterByEnergySource,
      }}
    >
      {props.children}
    </costCalculatorContext.Provider>
  );
};

export const objectKeySearchtranslationsHeaterDE = (energySourceDE) => {
  for (var i in costCalculatorBaseSettings.translationsHeaterDE) {
    if (costCalculatorBaseSettings.translationsHeaterDE[i] === energySourceDE)
      return i.toString();
  }
};

const average = (arr, consideredPeriod) => {
  const newArr = [];
  for (let i = 0; i < consideredPeriod; i++) {
    newArr.push(arr[i]);
  }
  return newArr.reduce((a, b) => a + b, 0) / newArr.length;
};

export const numberWithCommas = (x) => {
  return Math.ceil(x)
    .toFixed()
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const removeCommasStringToNumber = (x) => {
  return parseInt(x.replace(".", ""));
};

const whichValue = (val1, val2) => {
  return val1 === null ? val2 : Number(val1);
};

const headLineHandler = (energySourceDE) => {
  const energySource = energySourceDE;
  if (energySource === "oil") {
    return {
      headline: "Heizöl in €/Liter",
      value: 12.5,
    };
  } else if (energySource === "wood") {
    return {
      headline: "Holz in €/RM",
      value: 1800,
    };
  } else if (energySource === "pellets") {
    return {
      headline: "Pellets in €/kg",
      value: 4.8,
    };
  } else if (energySource === "coal") {
    return {
      headline: "Kohle in €/kg",
      value: 7,
    };
  } else if (energySource === "naturalGas") {
    return {
      headline: "Erdgas in €/kWh",
      value: 1,
    };
  } else if (
    energySource === "HPair" ||
    energySource === "HPwater" ||
    energySource === "HPdeppEarth" ||
    energySource === "HPgroundEarth"
  ) {
    return {
      headline: "Wärmepumpen-Strom in €/kWh",
      value: 1,
    };
  } else {
    return {
      headline: "Energiepreis in €/kWh",
      value: 1,
    };
  }
};
