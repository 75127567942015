import React from 'react';
import styled from 'styled-components';
import { MobileStepper, Stepper, Step, StepLabel, StepConnector, withStyles } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Header, greenColor } from './shared';
import { BrowserView, MobileView } from "react-device-detect";
import {
  StartStep,
  LocationStep,
  EnergyStep,
  ConsumptionStep,
  DistributionStep,
  BuildingStep,
  HotWaterStep,
  SecondHeatingStep,
  FinishStep,
  HpLocationStep,
  WishesStep
} from './steps';
import { defaultFormData, getContent, useWindowSize } from './utils';
import { trackEvent } from './tracker';

const CustomizedStepper = styled(Stepper)`
  && {
    padding-left: 0;
    padding-right: 0;
  }
`;

const CustomizedStepConnector = withStyles({
  active: {
    '& $line': {
      borderColor: greenColor,
    },
  },
  completed: {
    '& $line': {
      borderColor: greenColor,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export const theme = createTheme({
  palette: {
    primary: { main: greenColor, contrastText: '#ffffff' },
    secondary: { main: greenColor },
  },
});

function getSteps() {
  return [
    getContent('startStepLabel'),
    getContent('locationStepLabel'),
    getContent('energySourceStepLabel'),
    getContent('consumptionStepLabel'),
    getContent('distributionStepLabel'),
    getContent('buildingStepLabel'),
    getContent('hotWaterStepLabel'),
    getContent('secondHeatingStepLabel'),
    getContent('hpLocationStepLabel'),
    getContent('wishesStepLabel'),
    getContent('finishStepLabel'),
  ];
}

function getStepContent(
  stepIndex,
  setCurrentStep,
  formData,
  setFormData,
  onSubmit,
) {
  switch (stepIndex) {
    case 0:
      return <StartStep setCurrentStep={setCurrentStep} />;
    case 1:
      return <LocationStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />;
    case 2:
      return <EnergyStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />;
    case 3:
      return <ConsumptionStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />;
    case 4:
      return <DistributionStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />;
    case 5:
      return <BuildingStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />;
    case 6:
      return <HotWaterStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />;
    case 7:
      return <SecondHeatingStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />;
    case 8:
      return <HpLocationStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />;
    case 9:
      return <WishesStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />;
    case 10:
      return (
        <FinishStep formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} onSubmit={onSubmit} />
      );
    default:
      return <></>;
  }
}

export const ConfiguratorForm = ({ onSubmit }) => {
  const configuratorSteps = getSteps();

  const [currentStep, setCurrentStep] = React.useState(0);
  const [formData, setFormData] = React.useState(defaultFormData);

  React.useEffect(() => {
    trackEvent("formStart", { area: "wpguru", destinationStepIndex: 0 });
  }, []);

  const currentPageWidth = useWindowSize().width;

  return (
    <MuiThemeProvider theme={theme}>
      <Header>{getContent('pageHeader')}</Header>
      <MobileView>    <MobileStepper variant="dots"
        steps={11}
        position="static"
        activeStep={currentStep}
      //  sx={{ maxWidth: 400, flexGrow: 1 }}
      >
      </MobileStepper></MobileView>
      <BrowserView>
        <CustomizedStepper alternativeLabel activeStep={currentStep} connector={<CustomizedStepConnector />}>
          {configuratorSteps.map((label) => (
            <Step key={label}>
              <StepLabel>{currentPageWidth > 850 && label}</StepLabel>
            </Step>
          ))}
        </CustomizedStepper>
      </BrowserView>
      {getStepContent(currentStep, setCurrentStep, formData, setFormData, onSubmit)}
    </MuiThemeProvider>
  );
};
