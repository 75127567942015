import React from "react";

import { Grid, Typography, Container } from "@material-ui/core";

import buildRequest from "./contentRequest"
import buildCustomer from "./contentCustomer"
import MuiMarkdown from 'mui-markdown';
import LeadQuickSettings from "../../LeadQuickSettings";

function RequestInfo(props) {

  const buildGroup = (data) => {
    var result = [];

    if (data && !data.hidden) {

      result.push(<Grid item={true} xs={12} md={12}>
        <Typography variant="h5" component="h5">{data.headline}</Typography>
      </Grid>);


      for (let i = 0; i < data.elements.length; i++) {
        result.push(<> <Grid item={true} xs={6} md={6}>
          <Typography>{data.elements[i].left}</Typography>
        </Grid>
          <Grid item={true} xs={6} md={6}>
            <Typography>{data.elements[i].right}</Typography>
          </Grid> </>);

      }


    }
    return result;

  }

  //incoming props
  const { request, lead } = props;
  /* 
    const [map, setMap] = useState({ loadMap: false, transformRequest: null, geojson: null, partnerRequest: null, viewport: null });
    const [popup, setPopup] = useState({ show: false }) */


  const buildElements = () => {

    console.log("buildElements")
    const requestObject = typeof request === 'string' ? JSON.parse(request) : request


    var customerObject = requestObject?.customer

    var data
    var result = [];


    if (customerObject) {
      data = buildCustomer(customerObject).groups
      for (let i = 0; i < data.length; i++) {
        result = [...result, ...buildGroup(data[i])]
      }
    }

    data = buildRequest(requestObject).groups

    for (let i = 0; i < data.length; i++) {
      result = [...result, ...buildGroup(data[i])]
    }
    return result
  }

  return (
    <>
      <Container>
        {props.isOwnerOfLead && (<LeadQuickSettings lead={lead}></LeadQuickSettings>)}
        <Grid container={true} spacing={1}>
          {lead?.notesPublic && (<Grid item={true} xs={12} md={12}>
            <Typography variant="h5" component="h5">Interne Notizen</Typography>

            <MuiMarkdown>{lead.notesPublic}</MuiMarkdown>
          </Grid>)}


          {buildElements()}

        </Grid >

      </Container>
    </>

  )
}

export default RequestInfo;
