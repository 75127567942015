import React from 'react';
import Section from './Section';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import HomeIcon from '@material-ui/icons/Home';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
}));

export const getIconColor = (resultLevel) => {
  switch (resultLevel) {
    case 5:
      return '#91c148'; // green color
    case 4:
      return '#b1c148'; // light green color
    case 3:
      return '#d64f40'; // yellow color
    case 2:
      return '#e38934'; // orange color
    case 1:
    default:
      return '#bf1717'; // red color
  }
};

function FeaturesSection(props) {
  const classes = useStyles();
  const { spaceHeatSys, house, area } = props.heatPumpData;

  const items = [
    {
      title: spaceHeatSys.name,
      subtitle: spaceHeatSys.text,
      icon: EmojiEmotionsIcon,
      iconColor: getIconColor(spaceHeatSys.value),
    },
    {
      title: house.name,
      subtitle: house.text,
      icon: HomeIcon,
      iconColor: getIconColor(house.value),
    },
    {
      title: area.name,
      subtitle: area.text,
      icon: Brightness4Icon,
      iconColor: getIconColor(area.value),
    },
  ];

  return (
    <Section bgColor={props.bgColor} size={props.size} bgImage={props.bgImage} bgImageOpacity={props.bgImageOpacity}>
      <Container className={classes.container}>
        <Grid container={true} justify="center" spacing={7}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Box textAlign="center">
                <Box color={item.iconColor} fontSize="4.5rem">
                  <item.icon fontSize="inherit" />
                </Box>
                <Typography variant="h5" gutterBottom={true}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1">{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
