import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from "react";
import LeadMap from '../../components/LeadMap';
import Meta from "../../components/Meta";
import Container from "@material-ui/core/Container";
import SectionHeader from "../../components/SectionHeader";
import Section from '../../components/Section';
import { requireAuth } from "../../util/auth";
import { trackEvent } from "../../components/ConfiguratorForm/tracker";

class newLeads extends Component {

  constructor(props) {


    super(props);
    this.mapName = "heatly"

    this.classes = makeStyles({
      table: {
        minWidth: 650,
      },
    });



    this.state = {
      user: null,
      authState: null,
      showPopup: false,
      popup: {},
      transformRequest: null,
      loadMap: false,
      viewport: {
        longitude: 6.912,
        latitude: 50.937,
        zoom: 5,

      }
    }
  }


  componentDidMount() {

    trackEvent("useDashboard", { area: "wpguru" });

  }

  /* async componentDidUpdate() {
    console.log("did update")

    if (this.state.authState === AuthState.SignedIn && !this.state.loadMap) {
      await this.loadMap()
      await this.loadLeads()
    }

    //  Auth.currentAuthenticatedUser()
    //    .then(user => {
    //      console.log(user);
    //      console.log("didupdate")
    //      this.setState({ user: user })
    //    })
    //    .catch(err => console.log(err))
  }
 */

  render() {
    //https://docs.amplify.aws/ui/auth/authenticator/q/framework/react/#components


    return (
      <Section
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity="1"
      >
        <Container maxWidth="lg">
          <SectionHeader
            title="Kundenaufträge"
            subtitle="Finden Sie neue Kundenaufträge"
            size={4}
            textAlign="center"
          />


          <LeadMap showOwnLeads={false} showFreeLeads={true} />

        </Container>
      </Section>

    )


  }

}

export default requireAuth(newLeads)