if (!window.dataLayer) {
  window.dataLayer = [];
}

/**
 * This is a simple tracking function, which pushes the events to the `window` `dataLayer`.
 *
 * @param eventName Name of the tracked event..
 * @param properties Optional dictionary of properties which may be tracked in the event.
 *
 * @remark
 *  Can be integrated with GTM or other web analytics tracking.
 */
export const trackEvent = (event, properties) => {

  if (process.env.NODE_ENV == "development") {
    // console.info(`Event tracked: ${event}`, properties);
  }

  window.dataLayer.push({ event, ...(properties && { properties }) });
};

export const trackStepChange = (
  originStepIndex,
  destinationStepIndex,
  formData,
) => {
  trackEvent('configuration-form-step-change', { originStepIndex, destinationStepIndex, formData });
};

export const trackInputFieldDataChange = (fieldName, data) => {
  trackEvent('configuration-form-field-data-change', { fieldName, data });
};
