/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const exploreOpenLeads = /* GraphQL */ `
  query ExploreOpenLeads {
    exploreOpenLeads {
      id
      status
      type
      notes
      notesPublic
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        bivaColdDayBooster
        powerBivaSource
        isOwner
        leadPreference
      }
      partnerStatus
      contractPrice
      notesPartner
      buyDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getLeadNoModel = /* GraphQL */ `
  query GetLeadNoModel($id: ID!) {
    getLeadNoModel(id: $id) {
      id
      status
      type
      notes
      notesPublic
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        bivaColdDayBooster
        powerBivaSource
        isOwner
        leadPreference
      }
      partnerStatus
      contractPrice
      notesPartner
      buyDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLeadNoModels = /* GraphQL */ `
  query ListLeadNoModels(
    $filter: ModelLeadNoModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeadNoModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        type
        notes
        notesPublic
        requestId
        partnerStatus
        contractPrice
        notesPartner
        buyDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLeadNoModels = /* GraphQL */ `
  query SyncLeadNoModels(
    $filter: ModelLeadNoModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeadNoModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        type
        notes
        notesPublic
        requestId
        partnerStatus
        contractPrice
        notesPartner
        buyDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      firstName
      lastName
      email
      phone
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      checkFoerderung
      checkNutzungsbedingungen
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      request {
        nextToken
        startedAt
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phone
        checkFoerderung
        checkNutzungsbedingungen
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        checkFoerderung
        checkNutzungsbedingungen
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      status
      type
      ageCurrentHeatingSystem
      energySource
      heaterConstructionYear
      accountingPeriodDays
      volumeConsumptionAccountingPeriod
      consumptionAccountingPeriod
      consumptionYear
      energySubSource
      accountingPeriodStartDate
      accountingPeriodEndDate
      spaceHeatSystem
      tempPreFlow
      buildingType
      buildingUsefulArea
      buildingConstructionYear
      hotDomesticWater
      hotDomesticWaterFutur
      inhabs
      bivalentMode
      energySubSourceBiva
      consumptionAccountingPeriodBiva
      energySourceBiva
      volumeConsumptionAccountingPeriodBiva
      accountingPeriodStartDateBiva
      accountingPeriodEndDateBiva
      heatingSystemChangePlannedMonths
      hpFeatures
      pvSettings
      heaterLocationOld
      customerId
      evaluation {
        specificHeatDemand
        heatingDemand
        heatLoad
        heaterConstructionYear
        designExternalTemp
        consumptionYearBiva
        consumptionYear
        resultVideoURL
      }
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      distanceRuleIsRespected
      acceptedDataProtection
      acceptedTerms
      isOwner
      bivaColdDayBooster
      powerBivaSource
      contactPerson {
        name
        phone
        email
      }
      leadPreference
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        checkFoerderung
        checkNutzungsbedingungen
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      lead {
        nextToken
        startedAt
      }
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        customerId
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        isOwner
        bivaColdDayBooster
        powerBivaSource
        leadPreference
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRequests = /* GraphQL */ `
  query SyncRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        customerId
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        isOwner
        bivaColdDayBooster
        powerBivaSource
        leadPreference
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLeads = /* GraphQL */ `
  query SyncLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        status
        partnerStatus
        contractPrice
        notes
        notesPublic
        partnerId
        notesPartner
        buyDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        requestId
      }
      nextToken
      startedAt
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      type
      status
      partnerStatus
      contractPrice
      notes
      notesPublic
      partnerId
      notesPartner
      buyDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      requestId
      request {
        id
        status
        type
        ageCurrentHeatingSystem
        energySource
        heaterConstructionYear
        accountingPeriodDays
        volumeConsumptionAccountingPeriod
        consumptionAccountingPeriod
        consumptionYear
        energySubSource
        accountingPeriodStartDate
        accountingPeriodEndDate
        spaceHeatSystem
        tempPreFlow
        buildingType
        buildingUsefulArea
        buildingConstructionYear
        hotDomesticWater
        hotDomesticWaterFutur
        inhabs
        bivalentMode
        energySubSourceBiva
        consumptionAccountingPeriodBiva
        energySourceBiva
        volumeConsumptionAccountingPeriodBiva
        accountingPeriodStartDateBiva
        accountingPeriodEndDateBiva
        heatingSystemChangePlannedMonths
        hpFeatures
        pvSettings
        heaterLocationOld
        customerId
        distanceRuleIsRespected
        acceptedDataProtection
        acceptedTerms
        isOwner
        bivaColdDayBooster
        powerBivaSource
        leadPreference
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      partner {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        status
        partnerStatus
        contractPrice
        notes
        notesPublic
        partnerId
        notesPartner
        buyDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        requestId
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      companyName
      email
      address {
        type
        street
        streetNo
        postalCode
        city
        country
      }
      staus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      leads {
        nextToken
        startedAt
      }
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPartners = /* GraphQL */ `
  query SyncPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnerUser = /* GraphQL */ `
  query GetPartnerUser($id: ID!) {
    getPartnerUser(id: $id) {
      id
      partnerId
      firstName
      lastName
      rechte
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      partner {
        id
        companyName
        email
        staus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPartnerUsers = /* GraphQL */ `
  query ListPartnerUsers(
    $filter: ModelPartnerUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        firstName
        lastName
        rechte
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPartnerUsers = /* GraphQL */ `
  query SyncPartnerUsers(
    $filter: ModelPartnerUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPartnerUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        firstName
        lastName
        rechte
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
