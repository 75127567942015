import React, { useEffect } from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import config from './aws-exports';
import API, { graphqlOperation } from '@aws-amplify/api';
import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import { Alert, TextField, Typography } from '@mui/material';

export default function LeadQuickSettings({ lead }) {
    const [loading, setLoading] = React.useState(true);
    const [partnerStatus, setpartnerStatus] = React.useState(lead.partnerStatus);
    const [showSnackBar, setShowSnackBar] = React.useState({ show: false, message: "", severity: "success" });
    const [notesPartner, setNotesPartner] = React.useState(lead.notesPartner);
    const [contractPrice, setContractPrice] = React.useState(lead.contractPrice);
    const [leadVersion, setLeadVersion] = React.useState(lead._version);

    useEffect(async () => {
        if (typeof lead == "string") {
            lead = JSON.parse(lead)
        }

        let apiresult = await API.graphql({
            query: queries.getLead,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            variables: {

                id: lead.id,

            }

        })

        setpartnerStatus(apiresult.data.getLead.partnerStatus);
        setNotesPartner(apiresult.data.getLead.notesPartner);
        setContractPrice(apiresult.data.getLead.contractPrice);
        setLeadVersion(apiresult.data.getLead._version);
        setLoading(false);
        //Runs only on the first render
    }, []);

    const handleChange = async (event) => {

        if (event.target.name === "partnerStatus") {
            setpartnerStatus(event.target.value);

        }
        if (event.target.name === "notesPartner") {
            setNotesPartner(event.target.value);

        }
        if (event.target.name === "contractPrice") {
            setContractPrice(event.target.value);

        }

    };

    const handleSubmit = async (event) => {

        let message = "Eintrag erfolgreich gespeichert";
        let severity = "success";
        try {
            let apiresult = await API.graphql({
                query: mutations.updateLead,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    input: {
                        id: lead.id,
                        notesPartner: notesPartner,
                        partnerStatus: partnerStatus,
                        contractPrice: contractPrice,
                        _version: leadVersion
                    }
                }

            })
            setLeadVersion(leadVersion + 1)
            console.log(apiresult)

        } catch (error) {
            message = "Fehler beim Speichern. Seite bitte neu laden";
            severity = "error";
        }
        setShowSnackBar({ show: true, message });
    };

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowSnackBar({ show: false, message: "" })}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Snackbar

                open={showSnackBar.show}
                autoHideDuration={6000}
                onClose={() => setShowSnackBar(false)}

                action={action}
                variant="filled"

            ><Alert
                severity={showSnackBar.severity}>
                    {showSnackBar.message}
                </Alert>
            </Snackbar>
            {loading ? <div>Lädt...</div> : (
                <>
                    <FormControl fullWidth>
                        <Typography>Status</Typography>
                        <Select
                            displayEmpty
                            value={partnerStatus}

                            onChange={handleChange}
                            name="partnerStatus">
                            <MenuItem value={null}>Nichts passiert</MenuItem>
                            <MenuItem value="contact">Kontaktiert</MenuItem>
                            <MenuItem value="contractSend">Vertrag abgegeben</MenuItem>
                            <MenuItem value="contractSigned">Vertrag unterschrieben</MenuItem>
                            <MenuItem value="done">Abgeschlossen</MenuItem>
                            <MenuItem value="customerNoInterest">Kein Interesse</MenuItem>

                        </Select>
                    </FormControl>
                    <FormControl>
                        <Typography>Vertragspreis</Typography>

                        <TextField

                            name='contractPrice'
                            autoFocus
                            variant="outlined"
                            value={contractPrice}
                            onChange={handleChange}
                            //label={getContent('energyConsumptionVolumeLabel')}
                            placeholder="Vertragspreis"

                        />
                    </FormControl>
                    <FormControl>
                        <Typography>Eigene Notizen</Typography>

                        <TextField
                            name='notesPartner'
                            autoFocus
                            variant="outlined"
                            value={notesPartner}
                            onChange={handleChange}
                            //label={getContent('energyConsumptionVolumeLabel')}
                            placeholder="Eigene Notizen"

                        />
                    </FormControl>
                    <br /> <Button variant="outlined" onClick={handleSubmit}>Speichern</Button>
                </>)}
        </>
    )
}
