import React from 'react';
import { Checkbox, RadioGroup, Radio, FormLabel, FormGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { StepContainer, useFormStyles, CustomizedButton, ButtonContainer } from '../shared';
import { getContent } from '../utils';
import { trackStepChange, trackInputFieldDataChange } from '../tracker';


export const WishesStep = ({ formData, setFormData, setCurrentStep }) => {

  const formStyles = useFormStyles();
  const disabledNextStep = !formData.heatingSystemChangePlannedMonths


  const changeCheckbox = (event) => {
    var oldValue = [...formData.hpFeatures]
    if (event.target.checked) {
      if (!formData.hpFeatures.includes(event.target.name)) {
        formData.hpFeatures.push(event.target.name)
      }
    } else {
      if (formData.hpFeatures.includes(event.target.name)) {
        formData.hpFeatures = formData.hpFeatures.filter(x => x != event.target.name);
      }
    }
    trackInputFieldDataChange('hpFeatures', {
      previousValue: oldValue,
      newValue: formData.hpFeatures,
    });
    setFormData({ ...formData, hpFeatures: [...formData.hpFeatures] });

  }

  return (
    <StepContainer>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" className={formStyles.formControl}>
        <FormLabel component="legend">{getContent('wishesLabel')}</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={formData.hpFeatures.includes("highQualityProduct")} onChange={changeCheckbox} name="highQualityProduct" />
            }
            label={getContent('wishesHighQualityProduct')}
          />
          <FormControlLabel
            control={
              <Checkbox checked={formData.hpFeatures.includes("coolingPossible")} onChange={changeCheckbox} name="coolingPossible" />
            }
            label={getContent('wishesCoolingPossible')}
          />
          <FormControlLabel
            control={
              <Checkbox checked={formData.hpFeatures.includes("premiumOptic")} onChange={changeCheckbox} name="premiumOptic" />
            }
            label={getContent('wishesPremiumOptic')}
          />
          <FormControlLabel
            control={
              <Checkbox checked={formData.hpFeatures.includes("highEfficiency")} onChange={changeCheckbox} name="highEfficiency" />
            }
            label={getContent('wishesHighEfficiency')}
          />

          <FormControlLabel
            control={
              <Checkbox checked={formData.hpFeatures.includes("lowInitialPrice")} onChange={changeCheckbox} name="lowInitialPrice" />
            }
            label={getContent('wishesLowTotalPrice')}
          />
        </FormGroup>

      </FormControl>

      <FormControl component="fieldset" className={formStyles.formControl}>
        <FormLabel component="legend">{getContent("wishesActionDateLabel")}</FormLabel>
        <RadioGroup
          aria-label=""
          name="controlled-radio-buttons-group"
          value={formData.heatingSystemChangePlannedMonths}
          onChange={(event) => {
            trackInputFieldDataChange('heatingSystemChangePlannedMonths', {
              previousValue: formData.heatingSystemChangePlannedMonths,
              newValue: event.target.value,
            });
            setFormData({ ...formData, heatingSystemChangePlannedMonths: event.target.value });
          }}
        >
          <FormControlLabel value="0" control={<Radio />} label={getContent("wishesActionDateImmediately")} />
          <FormControlLabel value="3" control={<Radio />} label={getContent("wishesActionDateNextMonth")} />
          <FormControlLabel value="12" control={<Radio />} label={getContent("wishesActionNextYear")} />
          <FormControlLabel value="99" control={<Radio />} label={getContent("wishesActionNotNow")} />
        </RadioGroup>
      </FormControl>

      <ButtonContainer>
        <CustomizedButton
          variant="outlined"
          onClick={() => {
            trackStepChange(9, 8, formData);
            setCurrentStep(8);
          }}>
          {getContent('backButtonLabel')}
        </CustomizedButton>
        <CustomizedButton
          onClick={() => {
            trackStepChange(9, 10, formData);
            setCurrentStep(10);
          }}
          disabled={disabledNextStep} >
          {getContent('nextButtonLabel')}
        </CustomizedButton>
      </ButtonContainer>
    </StepContainer>
  );
};
